import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-side-pannel-title',
  templateUrl: './side-pannel-title.component.html',
  styleUrls: ['./side-pannel-title.component.css']
})
export class SidePannelTitleComponent implements OnInit {
  @Input() title = '';
  @Input() width = 100;

  constructor() { }

  ngOnInit() {
  }

}
