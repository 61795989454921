import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
// import { error } from 'protractor';
import { throwError } from 'rxjs';
import { LocalStorageService } from './localStorage.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private localStorageService: LocalStorageService
    ) { }
    intercept(
        req: import('@angular/common/http').HttpRequest<any>,
        next: import('@angular/common/http').HttpHandler
    ): import('rxjs').Observable<import('@angular/common/http').HttpEvent<any>> {
        // debugger;
        return next.handle(req).pipe(
            // tslint:disable-next-line: no-shadowed-variable
            catchError(error => {
                if (error.status === 401) {
                    this.localStorageService.clearToken();
                    return throwError(this.errorToText(error.statusText));
                }

                if (error instanceof HttpErrorResponse) {
                    const applicationError = error.error && error.error.message;
                    if (applicationError) {
                        return throwError(this.errorToText(applicationError));
                    }
                }

                const serverError = error.error;
                return throwError(this.errorToText(serverError || 'Server Error'));
                // let modelStateErrors = '';
                // if (serverError && serverError.error)
            })
        );
    }

    errorToText(errorCode: string): string {

        switch (errorCode) {
            case 'UNAUTHORIZED':
                return 'כניסה שגויה למערכת';
            case 'too eazy password':
                return 'הסיסמה אינה מספיק חזקה. יש להקליד סיסמה קשה יותר';
            case 'missing data':
                return 'יש למלא את כל הפרטים';
            case 'existing user':
                return 'משתמש זה כבר רשום';
            case 'Bad Data Format':
                return 'שגיאה כללית - הגיע מידע לא נכון לשרת';
            case 'Server Error':
                return 'שגיאה כללית - יש לנסות שנית או לפנות לתמיכה';
            case 'length of username':
                return 'אורכו של שם המשתמש צריך להיות בין 6 ל 20 תוים';
            case 'both email and phone are missing':
                return 'יש למלא מייל או מספר טלפון';
            case 'identification_type invalid':
                return 'סוג הזיהוי אינו מוגדר';
            case 'not a valid phone':
                return 'מספר טלפון אינו תקין';
            default:
                return errorCode;
        }
    }

}

export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
};
