<section name="species-lobby-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div *ngIf="familyData" class="main-wrapper main-lobby-wrapper full-div">
      <h1>{{ familyData.name }} - {{ familyData.title }}</h1>
      <div>{{ familyData.description }}</div>
      <br>    
      <div *ngIf="species"> 
        <app-filter-bar (filterTextSync) = "onFilterTextChange($event)"></app-filter-bar>
        <div name="species" class="species-flex grid">
          <div *ngFor="let speTile of speTiles  | filter : filterText:false" >
            <app-tile [tile]=speTile [isSmall]=true></app-tile>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>