import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-social-shares',
  templateUrl: './social-shares.component.html',
  styleUrls: ['./social-shares.component.css']
})
export class SocialSharesComponent implements OnInit {

  // currentPath: string;
  mailShare: string;
  telegramShare: string;
  whatsappShare: string;
  twitterShare: string;
  facebookShare: string;

  constructor(private router: Router, private title: Title) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        // this.currentPath = e.url;
        this.prepareShares();
      }
    });
  }

  ngOnInit() {
    this.prepareShares();
  }

  refreshHrefs() {
    this.prepareShares();
  }

  private prepareShares() {
    // console.log(this.currentPath);
    /*
      this method was written using the great help from this post:
      https://github.com/bradvin/social-share-urls
    */

    const currentOrigin = window.location.origin + encodeURI(window.location.pathname);
    const currentTitle = encodeURIComponent(this.title.getTitle());

    // mailto:{email_address}?subject={title}&body={text}
    this.mailShare = 'mailto:?subject=' + currentTitle + '&body=' + currentOrigin;

    // https://t.me/share/url?url={url}&text={title}&to={phone_number}
    this.telegramShare = 'https://t.me/share/url?url=' + currentOrigin + '&text=' + currentTitle;

    // https://api.whatsapp.com/send?text=YourShareTextHere
    this.whatsappShare = 'https://api.whatsapp.com/send?text=' + currentOrigin + ' ' + currentTitle;
    // this.whatsappShare = 'https://wa.me/whatsappphonenumber/?text=' + currentOrigin + ' ' + currentTitle;

    // https://twitter.com/intent/tweet?url={url}&text={title}&via={user_id}&hashtags={hash_tags}
    this.twitterShare = 'https://twitter.com/intent/tweet?url=' + currentOrigin + '&text=' + currentTitle + '&hashtags=#birdingIL';

    // https://www.facebook.com/sharer.php?u={url}
    this.facebookShare = 'https://www.facebook.com/sharer.php?u=' + currentOrigin;

    // console.log('URL:' + window.location.href);
    // console.log('Path:' + window.location.pathname);
    // console.log('Host:' + window.location.host);
    // console.log('Hostname:' + window.location.hostname);
    // console.log('Origin:' + window.location.origin);
    // console.log('Port:' + window.location.port);
    // console.log('Search String:' + window.location.search);

    // console.log(this.telegramShare);

    // window.location.href = url;
  }
}
