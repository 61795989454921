<section name="species-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div *ngIf="this.species" class="main-wrapper main-species-wrapper full-div">
      <nav class="tabs-row full-div">
        <a class="tab title-t-28 tab-description" routerLink="species-description" routerLinkActive="active-route">
          <i class="fas fa-file-alt c-orange"></i> {{ this.tabDescription }}
        </a>
        <a class="tab title-t-28 tab-data" routerLink="species-trends" routerLinkActive="active-route">
          <i class="fas fa-chart-bar c-green"></i> {{ this.tabTrends }}
        </a>
        <a class="tab title-t-28 tab-more-info" routerLink="species-moreinfo" routerLinkActive="active-route">
          <i class="fas fa-info-circle c-brown"></i> {{ this.tabMoreInfo }}
        </a>
      </nav>
      <!-- <div (swiperight)="this.swipeHandlerRight()" (swipeleft)="this.swipeHandlerLeft()"> -->
      <div>
        <router-outlet class="full-div"></router-outlet>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>