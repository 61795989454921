<section name="ringing-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-ringing-wrapper full-div">
      <h1>{{title}}</h1>
      <div class="full-div {{dir}}">
        <form #f="ngForm" (ngSubmit)="checkRings(f)" class="form-inline ringing-flex-box flex-box-row">
          <input type="text" class="form-control ring-data" name="ringType" required placeholder="{{ringTypeLabel}}"
            [(ngModel)]="model.ringType" #ringType="ngModel" maxlength="2">
          <span *ngIf="ringType.invalid && (ringType.dirty || ringType.touched) && ringType.errors.required"
            class="error">{{errRequired}}</span>
          <!-- <span *ngIf="ringType.invalid && (ringType.dirty || ringType.touched) && ringType.errors.minlength" 
            class="error">{{errShort}}</span> -->

          <input type="number" class="form-control ring-data" id="rangeStart" name="rangeStart" placeholder="{{startRangeLabel}}" required 
            [(ngModel)]="model.rangeStart" #rangeStart="ngModel">
          <span *ngIf="rangeStart.invalid && (rangeStart.dirty || rangeStart.touched) && rangeStart.errors.required"
            class="error">{{errRequired}}</span>
          <!-- <span *ngIf="rangeStart.invalid && (rangeStart.dirty || rangeStart.touched) && rangeStart.errors.rangeStart" 
            class="error">{{errStartRange}}</span> -->
          <input type="number" class="form-control ring-data" id="rangeEnd" name="rangeEnd" placeholder="{{endRangeLabel}}" required 
            [(ngModel)]="model.rangeEnd" #rangeEnd="ngModel">
          <span *ngIf="rangeEnd.invalid && (rangeEnd.dirty || rangeEnd.touched) && rangeEnd.errors?.required"
            class="error">{{errRequired}}</span>
          <span *ngIf="rangeEnd.invalid && (rangeEnd.dirty || rangeEnd.touched) && rangeEnd.errors?.invalidRange"
            class="error">{{errEndRange}}</span>
         
          <div class="ng-autocomplete form-control">
            <ng-autocomplete [data]="ringers" [searchKeyword]="keyword" (selected)='selectEvent($event)' [(ngModel)]="model.ringsOwner" 
              #ringsOwner="ngModel" name="ringsOwner"
              (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
          
            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>
          
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>

            <span *ngIf="model.ringsOwnerId === 0 && (ringsOwner.dirty || ringsOwner.touched) && !ringsOwner.focused"
              class="error">{{errRequired}}</span>
          </div>
          <button type="submit" [disabled]="!f.valid && inSend" id="submitBtn" class="btn">{{checkRingsLabel}}</button>
          <!-- <button type="button" id="test" class="btn" (click)="testAlert()">בדיקה</button> -->
        </form>
      </div>
      <div class="full-div {{dir}}">
        <div *ngIf="sendResultMsg" class="send-result {{sendResult}} t-b">{{sendResultMsg}}</div>        
      </div>

      <div class="full-div {{dir}}" *ngIf="OK && OK.length > 0">
        <h2>{{transferRingsHeading}}</h2>
        <div class="ringing-flex-box flex-box-col" *ngIf="OK">
          <div *ngFor="let item of OK" class="full-div">
            <span class="third-div">
              <input type="checkbox" name="selected" id="c{{item.ringSerialNumberFrom}}" [checked]="true" [value]="item.selected"
                (click)="selectChange(item)">
              <label for="selected">{{item.ringSerialNumberFrom}} - {{item.ringSerialNumberTo}} </label>
            </span>
            <span class="third-div">{{item.status}}</span>
            <span class="third-div"></span>
          </div>

          <div class="full-div {{dir}}">
            <button id="moveRings" class="btn dir" (click)="transferRings()" >{{transferRingsLabel}}</button>
          </div>
        </div>
      </div>

      <div class="full-div {{dir}}" *ngIf="Check && Check.length > 0">
        <h2>{{checkRingsHeading}}</h2>
        <div class="ringing-flex-box flex-box-col" *ngIf="Check">
          <div *ngFor="let item of Check" class="full-div">
            <span class="third-div">
              {{item.ringSerialNumberFrom}}
              <ng-container *ngIf="item.ringSerialNumberTo && item.ringSerialNumberFrom !== item.ringSerialNumberTo">
                - {{item.ringSerialNumberTo}}
              </ng-container>
            </span>
            <span class="third-div">{{item.status}} </span>
            <span class="third-div">
              <a href="{{item.url}}" target="_blank">
                <span class="c-blue">{{displayRinging}}</span>
              </a>
            </span>
          </div>
        </div>
      </div>

      <div class="full-div {{dir}}" *ngIf="Ignore && Ignore.length > 0">
        <h2>{{ignoreRingsHeading}}</h2>
        <div class="ringing-flex-box flex-box-col" *ngIf="Ignore">
          <div *ngFor="let item of Ignore" class="full-div">
            <span class="third-div">
              {{item.ringSerialNumberFrom}}
              <ng-container *ngIf="item.ringSerialNumberTo && item.ringSerialNumberFrom !== item.ringSerialNumberTo">
                - {{item.ringSerialNumberTo}}
              </ng-container>
            </span>
            <span class="third-div">{{item.status}}</span>
            <span class="third-div"></span>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


