import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '../../_services/language.service';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
// import { isUndefined } from 'util';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css']
})
export class ComingSoonComponent implements OnInit {
  @Input() inputName: string;
  pageName: string;
  lang: string;

  constructor(
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private bcService: BreadcrumbsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.pageName = params['page_name'];

        this.lang = this.languageService.currentLang();

        this.onRouteChange();
      }
    );

    if (this.inputName !== '' && this.pageName === undefined) {
      this.pageName = this.inputName;
    }

  }

  onRouteChange() {
    this.pageName = this.route.snapshot.params.page_name;

    // prepare the breadcrumbs
    this.bcService.clearLevels();
    this.bcService.setLastItemTitle(this.pageName);

    // publish
    this.bcService.publishBreadcrumbs();

  }
}
