import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LanguageService } from '../_services/language.service';

@Injectable()
export class CountryResolver implements Resolve<string> {
  constructor(private languageService: LanguageService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot): string {
        const isIL = this.languageService.isIsrael();
        if (isIL) {
            // this.router.navigate(['/he']);
            return 'he';
        } else {
            // this.router.navigate(['/en']);
            return 'en';
        }
    }
}
