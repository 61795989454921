import { Injectable } from '@angular/core';
import { ArticleTypeResponse } from '../models/article-type-response';
import { ApiService } from '../_services/api.service';
import { HttpClient } from '@angular/common/http';
// import { LanguageService } from '../_services/language.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

constructor(
  private http: HttpClient,
  // private languageServices: LanguageService,
  private apiService: ApiService
  ) { }

  public hasKeys(): boolean {
    if (localStorage.getItem('ArticleTypeId:1') === null) {
      return false;
    }
    return true;
  }

  public loadAllArticleTypes(currentLang: string) {
    const _currentLang = (currentLang !== null) ? currentLang : 'he';
    this.http.get(this.apiService.getServerUrl() + '/api/articles/types')
    // this.http.get(this.apiService.getServerUrl() + '/api/articles/types?lang=' + _currentLang)
      .subscribe((response: ArticleTypeResponse[]) => {
        this.clearTypes();
        response.forEach(item => {
          this.setType(item);
        });
      }, error => {
        console.log(error);
      });
  }

  private clearTypes() {
    const arr = [];
    for (let index = 0; index < localStorage.length; index++) {
      if (localStorage.key(index).substring(0, 16) === 'ArticleTypeSlug:' ||
          localStorage.key(index).substring(0, 14) === 'ArticleTypeId:') {
        arr.push(localStorage.key(index));
      }
    }
    for (let index = 0; index < arr.length; index++) {
      localStorage.removeItem(arr[index]);
    }
  }

  public getMenuTypes(lang: string): ArticleTypeResponse[] {
    const arr: ArticleTypeResponse[] = [];
    for (let index = 0; index < localStorage.length; index++) {
      if (localStorage.key(index).substring(0, 14) === 'ArticleTypeId:') {
        const key = localStorage.key(index);
        const artType: ArticleTypeResponse = JSON.parse(localStorage.getItem(key));
        if ((lang === 'he' && artType.isTypesMenuHe) || (lang === 'en' && artType.isTypesMenuEn)) {
          arr.push(artType);
        }
      }
    }
    return arr;
  }

  public setType(item: ArticleTypeResponse) {
    const data = JSON.stringify(item);
    localStorage.setItem('ArticleTypeSlug:' + item.slug, data);
    localStorage.setItem('ArticleTypeId:' + item.id.toString(), data);
  }

  public getNameById(id: number, lang: string): string {
    if (id === undefined) {
      return '';
    }

    const key = 'ArticleTypeId:' + id.toString();
    const jsonString = localStorage.getItem(key);

    if (jsonString === null) {
      return '';
    }

    const json = JSON.parse(jsonString);
    if (json === undefined) {
      return '';
    }
    return (lang === 'he') ? json.nameHe : json.nameEn;
  }

  public getSlugById(id: number): string {
    const key = 'ArticleTypeId:' + id.toString();
    const jsonString = localStorage.getItem(key);

    if (jsonString === null) {
      return '';
    }

    const json = JSON.parse(jsonString);
    if (json === undefined) {
      return '';
    }
    return json.slug;
  }
  public getPluralsNameById(id: number, lang: string): string {
    const key = 'ArticleTypeId:' + id.toString();
    const jsonString = localStorage.getItem(key);
    if (jsonString === null) {
      return '';
    }

    const json = JSON.parse(jsonString);
    if (json === undefined) {
      return '';
    }

    return (lang === 'he') ? json.pluralsNameHe : json.pluralsNameEn;
  }
  public getDescriptionById(id: number, lang: string): string {
    const key = 'ArticleTypeId:' + id.toString();
    const jsonString = localStorage.getItem(key);

    if (jsonString === null) {
      return '';
    }

    const json = JSON.parse(jsonString);
    if (json === undefined) {
      return '';
    }
    return (lang === 'he') ? json.desciptionHe : json.descriptionEn;
  }

  public getNameBySlug(slug: string, lang: string): string {
    const key = 'ArticleTypeSlug:' + slug;
    const jsonString = localStorage.getItem(key);
    if (jsonString === null) {
      return '';
    }

    const json = JSON.parse(jsonString);
    if (json === undefined) {
      return '';
    }

    return (lang === 'he') ? json.nameHe : json.nameEn;
  }

  public getIdBySlug(slug: string): number {
    const key = 'ArticleTypeSlug:' + slug;
    const jsonString = localStorage.getItem(key);
    if (jsonString === null) {
      return null;
    }

    const json = JSON.parse(jsonString);
    if (json === undefined) {
      return null;
    }

    return json.id;
  }
  public getPluralsNameBySlug(slug: string, lang: string): string {
    const key = 'ArticleTypeSlug:' + slug;
    const jsonString = localStorage.getItem(key);
    if (jsonString === null) {
      return '';
    }

    const json = JSON.parse(jsonString);
    if (json === undefined) {
      return '';
    }

    return (lang === 'he') ? json.pluralsNameHe : json.pluralsNameEn;
  }
  public getDescriptionBySlug(slug: string, lang: string): string {
    const key = 'ArticleTypeSlug:' + slug;
    const jsonString = localStorage.getItem(key);
    if (jsonString === null) {
      return '';
    }

    const json = JSON.parse(jsonString);
    if (json === undefined) {
      return '';
    }

    return (lang === 'he') ? json.descriptionHe : json.descriptionEn;
  }

}
