<section name="birding-center-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div *ngIf="bc" class="main-wrapper main-birding-center-wrapper full-div">
      <div name="birding-center-header" *ngIf="!imgGalleryState" [@fadeInOut]
        class="hide-mobile birding-center-img-container full-div">
        <div *ngIf="bc && bc.largeImageUrl">
          <img [src]="bc.largeImageUrl.path" [alt]="bc.largeImageUrl.name" [title]="bc.largeImageUrl.title"
            class="birding-center-img full-div" />
        </div>
        <div class="image-element">
          <div>
            <!-- <div *ngIf="bc && bc.images && bc.images.length > 1" class="img-tooltip hand" (click)="imgGalleryOn()">+
              {{bc.images.length}} {{this.moreImagesCaption}}</div> -->
          </div>
          <div>
            <p
              class="birding-center-title-note {{this.dir}} c-white t-b birding-center-caption birding-center-{{ lang }}">
            </p>
            <h1 class="birding-center-title  {{this.dir}} c-white t-b">{{ bc.title }}</h1>
          </div>
        </div>
      </div>
      <div class="img-container-flex">
        <div name="birding-center-header" *ngIf="!imgGalleryState" [@fadeInOut]
          class="hide-desktop birding-center-img-container full-div">
          <div *ngIf="bc && bc.imageUrl">
            <img [src]="bc.imageUrl.path" [alt]="bc.imageUrl.name" [title]="bc.imageUrl.title"
              class="birding-center-img full-div" />
          </div>
          <div class="image-element">
            <div>
              <!-- <div *ngIf="bc && bc.images && bc.images.length > 1" class="img-tooltip hand" (click)="imgGalleryOn()">+
                {{bc.images.length}} {{this.moreImagesCaption}}</div> -->
            </div>
            <div>
              <p
                class="birding-center-title-note {{this.dir}} c-white t-b birding-center-caption birding-center-{{ lang }}">
              </p>
              <h1 class="birding-center-title  {{this.dir}} c-white t-b">{{ bc.title }}</h1>
            </div>
          </div>
        </div>
      </div>
      <!-- <app-image-slider *ngIf="imageSliderOn" class="article-img full-div" [images]="article.images"></app-image-slider> -->
      <!-- <app-image-slider *ngIf="imageSliderOn" [images]="article.images" [isShow]="imageSliderOn"></app-image-slider> -->
      <!-- <app-image-gallery *ngIf="imgGalleryState" [title]="bc.title" [images]="bc.images"
        [imgGalleryState]="this.imgGalleryState" (imgGalleryParentState)="onImgGalleryStateOff($event)"
        [badge]="'birding-center'"></app-image-gallery> -->
      <div class="third-div  {{this.dir}}">
        <div class="birding-center-subtxt hide-desktop full-div">
          <h2>{{ bc.shortDescription }}</h2>
        </div>
        <div class="birding-center-poc-inf full-div">
          <app-side-pannel-title class="hide-mobile" [title]=""></app-side-pannel-title>
          <app-side-pannel-title class="hide-desktop" [title]="" [width]="30"></app-side-pannel-title>
        </div>
        <app-tags class="hide-desktop" *ngIf="bc && bc.tags && bc.tags.length > 0" [tags]="bc.tags"></app-tags>
        <app-google-map class='small-map hide-mobile' [lat]="bc.lat" [lng]="bc.long" [zoom]="13" [height]="200"
          [heightUnits]="'px'"></app-google-map>
        <app-google-map class='small-map hide-desktop' [lat]="bc.lat" [lng]="bc.long" [zoom]="13" [height]="300"
          [heightUnits]="'px'"></app-google-map>
        <app-contact [lat]="bc.lat" [lng]="bc.long" [phone]="bc.phone" [destinationName]="bc.title"
          [contactUsId]="bc.id"></app-contact>
        <!-- <app-social-shares></app-social-shares> -->
        <app-linked-events class="hide-mobile" 
          *ngIf="bc && bc.linkedEvents && bc.linkedEvents.length > 0" 
          [linkedEvents]="bc.linkedEvents" 
          [moreEventsTitle]="bc.moreEventsTitle" 
          [lang]="lang"></app-linked-events>
        <app-linked-articles class="hide-mobile" 
          *ngIf="bc && bc.linkedArticles && bc.linkedArticles.length > 0" 
          [linkedArticles]="bc.linkedArticles" 
          [moreArticlesTitle]="bc.moreArticlesTitle" 
          [lang]="lang"></app-linked-articles>
        <app-linked-articles class="hide-mobile" 
          *ngIf="bc && bc.linkedProjects && bc.linkedProjects.length > 0" 
          [linkedArticles]="bc.linkedProjects" 
          [moreArticlesTitle]="bc.moreProjectsTitle" 
          [lang]="lang"></app-linked-articles>
        <app-tags class="hide-mobile" *ngIf="bc && bc.tags && bc.tags.length > 0" [tags]="bc.tags"></app-tags>
        <app-links class="hide-mobile" *ngIf="bc && bc.links && bc.links.length > 0" [links]="bc.links"
          [linksTitle]="bc.moreLinkesTitle"></app-links>
      </div>
      <div class=" birding-center-main two-third-div  {{this.dir}}">
        <div class="birding-center-subtxt full-div hide-mobile">
          <h2>{{ bc.shortDescription }}</h2>
        </div>
        <app-side-pannel-title class="hide-mobile" [title]="" [width]="30"></app-side-pannel-title>
        <div class="inner-html-txt full-div">
          <slot [innerHTML]="bc.description | imgHost | safe"></slot>
        </div>
        <app-social-shares></app-social-shares>
      </div>
    </div>
  </div>
</section>

<app-linked-events class="hide-desktop" 
*ngIf="bc && bc.linkedEvents && bc.linkedEvents.length > 0" 
[linkedEvents]="bc.linkedEvents" 
[moreEventsTitle]="bc.moreEventsTitle" 
[lang]="lang"></app-linked-events>
<app-linked-articles class="hide-desktop" 
*ngIf="bc && bc.linkedArticles && bc.linkedArticles.length > 0" 
[linkedArticles]="bc.linkedArticles" 
[moreArticlesTitle]="bc.moreArticlesTitle" 
[lang]="lang"></app-linked-articles>
<app-linked-articles class="hide-desktop" 
*ngIf="bc && bc.linkedProjects && bc.linkedProjects.length > 0" 
[linkedArticles]="bc.linkedProjects" 
[moreArticlesTitle]="bc.moreProjectsTitle" 
[lang]="lang"></app-linked-articles>

<app-links class="hide-desktop" *ngIf="bc && bc.links && bc.links.length > 0" [links]="bc.links"></app-links>
<!-- <app-comments></app-comments> -->
<app-scroll-top></app-scroll-top>