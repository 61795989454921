import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { Breadcrumbs } from '../../models/breadcrumbs';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { ImageSliderObject } from '../../models/image-slider-object';
import { Animations } from '../../animations';
import { getLocaleDateFormat } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { LoadingAnimationService } from '../../_services/loading-animation.service';
import { ArticleService } from '../../_services/article.service';
import { BirdingCenterResponse } from '../../models/birding-center-response';

@Component({
  selector: 'app-birding-center',
  templateUrl: './birding-center.component.html',
  styleUrls: ['./birding-center.component.css'],
  animations: [Animations],
  encapsulation: ViewEncapsulation.None
})
export class BirdingCenterComponent implements OnInit {
  @Output() setBreadcrumbs = new EventEmitter<Breadcrumbs>();
  bc: BirdingCenterResponse = new BirdingCenterResponse();
  lang: string;
  dir: string;
  imgGalleryState: boolean;
  moreImagesCaption: string;
  // bcSlug: string;
  // bcName: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    this.route.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');
      const id = parseInt(params.get('id'), 0);

      // business logic what changes you want on the page with this new data.
      if (id && !isNaN(id)) {
        this.getBirdingCenterById(id);
      }

      if (slug) {
        this.getBirdingCenterBySlug(slug);
      }

    });
  }

  private getBirdingCenterBySlug(bcSlug: string) {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.http.get(this.apiService.getServerUrl() + '/api/birdingcenters/byslug/' + this.lang + '/' + bcSlug)
      .subscribe((response: BirdingCenterResponse) => {
        this.fillBirdingCenter(response);
        this.loadingAnimationService.publishLoadingAnimation(false);
      },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        }
      );
  }

  // bc by id - keep this option for our convenience
  private getBirdingCenterById(bcId: number) {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.http.get(this.apiService.getServerUrl() + '/api/birdingcenters/byid/' + this.lang + '/' + bcId)
      .subscribe((response: BirdingCenterResponse) => {
        this.fillBirdingCenter(response);
        this.loadingAnimationService.publishLoadingAnimation(false);
      },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        }
      );
  }

  private fillBirdingCenter(bc: BirdingCenterResponse) {
    this.bc = new BirdingCenterResponse();
    this.bc = bc;
    // console.log(this.bc.linkedArticles.length);
    // prepare the breadcrumbs
    this.bcService.clearLevels();
    this.bcService.setLastItemTitle(this.bc.title);
    this.bcService.addLevel(this.languageService.getTextById('baseBirdingCentersTitle'), 'birding-centers/');
    const pageTitle = this.bc.title + ' | '
      + this.languageService.getTextById('baseBirdingCentersTitle') + ' - ' + this.languageService.getTextById('baseSeoTitle');
    const pageImage = this.bc.imageUrl && this.bc.imageUrl.path;
    const keywords = this.bc.poc + ',' + this.bc.title + ',' + this.languageService.getTextById('baseSeoKeywords');
    const desciption = this.bc.title + ',' + this.languageService.getTextById('baseSeoDescription');
    const pagePath = '/birdingcenters/' + this.bc.slug;
    const pageUrl = location.origin + pagePath;
    const canonicalUrl = location.origin + '/' + this.lang + pagePath;

    const pageType = this.languageService.getTextById('baseBirdingCenterTitle');
    this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, canonicalUrl);

    // publish
    this.bcService.publishBreadcrumbs();

    this.moreImagesCaption = this.languageService.getTextById('GalleryImagesCaption');
  }
  // private imgGalleryOn() {
  //   this.imgGalleryState = true;
  // }

  // onImgGalleryStateOff(state: boolean) {
  //   this.imgGalleryState = state;
  // }

}
