import { Component, OnInit, Input } from '@angular/core';
import { UrlResponse } from 'src/app/models/url-response';
import { iif } from 'rxjs';

@Component({
  selector: 'app-youtube-embed',
  templateUrl: './youtube-embed.component.html',
  styleUrls: ['./youtube-embed.component.css']
})
export class YoutubeEmbedComponent implements OnInit {
  @Input() videoObj: UrlResponse;
  @Input() videoID: string;
  _videoID: string;
  videoEmbedSrc: string;

  constructor() { }

  ngOnInit() {
    if (this.videoObj) {
      // extract youtube ID from href and build src
      // const regexp: RegExp = /watch\?v=(.*)\&/;
      const regexp: RegExp = /(watch\?v=(.*?)&)|(watch\?v=(.*?)(.*))/;
      // const myRegexp = new RegExp('/watch\?v=(.*)\&');
      const match = regexp.exec(this.videoObj.href);
      this._videoID = (match[2] === undefined) ? match[5] : match[2];
      this.videoEmbedSrc = 'https://www.youtube.com/embed/' + this._videoID;
    }

    if (this.videoID) {
      // tslint:disable-next-line:max-line-length
      this.videoEmbedSrc = 'https://www.youtube.com/embed/' + this.videoID + '?wmode=opaque&rel=0&autoplay=1&loop=1&playlist=' + this.videoID + '&controls=0';
    }
    // https://www.youtube.com/watch?v=HjatKTBx41k
    // this.videoEmbedSrc = this.videoObj.href.replace('watch?v=', 'embed/');
  }
}
