import { Component, OnInit, Input } from '@angular/core';
import { UrlResponse } from '../../models/url-response';
import { LanguageService } from '../../_services/language.service';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  @Input() links: UrlResponse[];
  @Input() linksTitle: string;

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    if (!this.linksTitle) {
      const lang = this.languageService.currentLang();
      if (lang === 'en') {
        this.linksTitle = 'Related links';
      } else {
        this.linksTitle = 'קישורים';
      }
    }
  }

}
