<section name="articles-lobby-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-lobby-wrapper full-div">
      <app-article-types-menu class="hide-mobile" [selectedTypeId]=_typeId></app-article-types-menu>
      <div class="header-filter full-div">
        <h1>{{ this.lobbyTitle }}</h1>
        <app-filter-bar (filterTextSync)="onFilterTextChange($event)"></app-filter-bar>
      </div>
      <div *ngIf="_typeDescription != null && _typeDescription != ''" class="articles-type-description">
        <slot [innerHTML]="_typeDescription | safe"></slot>
      </div>
      <div *ngIf="artTiles" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150"
        (scrolled)="onScroll()" [scrollWindow]="true">
        <div name="articles" class="articles-flex grid">
          <div *ngFor="let artTile of artTiles  | filter : filterText">
            <app-tile class="hide-mobile" [tile]=artTile></app-tile>
            <app-tile class="hide-desktop" [isSmall]="true" [tile]=artTile></app-tile>
          </div>
        </div>
      </div>
      <!-- <p *ngFor="let art of articles">
        <a [routerLink]="['/article', art.id]">{{ art.title }} / {{ art.author }} | {{ art.type }}</a>
      </p> -->
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>