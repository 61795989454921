import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor(private alertify: ToastrService) { }

  confirm(message: string, okCallback: () => any) {
    // this.alertify.confirm(message, (e: any) => {
    //   if (e) {
    //     okCallback();
    //   } else { }
    // });    
    okCallback();
  }

  success(message: string) {
    this.alertify.success(message);
  }

  warning(message: string) {
    this.alertify.warning(message);
  }

  message(message: string) {
    this.alertify.info(message);
  }

  error(message: string) {
    this.alertify.error(message);
  }
}
