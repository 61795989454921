import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ImageResponse } from '../../models/image-response';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize, NgxGalleryLayout } from 'ngx-gallery-9';
import { LanguageService } from '../../_services/language.service';
import { LoadingAnimationService } from '../../_services/loading-animation.service';
import { Animations } from 'src/app/animations';

@Component({
  selector: 'app-image-thumb-gallery',
  templateUrl: './image-thumb-gallery.component.html',
  styleUrls: ['./image-thumb-gallery.component.css'],
  animations: [Animations],

}) 
export class ImageThumbGalleryComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  lang: string;
  dir: string;
  galleryHeight: string;
  animationState = 'in';

  @Input() images: ImageResponse[];

  constructor(
    private languageService: LanguageService,
    ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    // this.galleryHeight = (window.innerWidth > 1366) ? '193px' : (window.innerWidth * 0.25) + 'px';
    if (window.innerWidth > 1366) {
      this.galleryHeight = '191px';
    } else if (window.innerWidth > 920) {
      this.galleryHeight = (window.innerWidth * 0.145) + 'px';
    } else if (window.innerWidth > 768) {
      this.galleryHeight = (window.innerWidth * 0.175) + 'px';
    } else if (window.innerWidth > 500) {
      this.galleryHeight = (window.innerWidth * 0.200) + 'px';
    } else if (window.innerWidth > 425) {
      this.galleryHeight = (window.innerWidth * 0.225) + 'px';
    }

    // prepare image gallery obj
    if (this.images && this.images.length > 1) {
      const imgs = [];
      this.images.forEach(img => {
        imgs.push({
          small: img.path,
          medium: img.path,
          big: img.path,
          description: img.tooltip,
          titleText: img.title
        });
      });

      this.galleryImages = imgs;
    }

    // set gallery options
    this.galleryOptions = [
      {
        width: '100%',
        height: this.galleryHeight,
        imageSize: NgxGalleryImageSize.Contain,
        imageDescription: true,
        imageArrows: true,
        imageArrowsAutoHide: true,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        imageSwipe: true,
        imageInfinityMove: true,
        imageAnimation: 'fade',
        image: false, // true- show large image, false- show only thumb slider
        thumbnails: true,
        thumbnailsColumns: 6,
        thumbnailMargin: 12,
        thumbnailSize: NgxGalleryImageSize.Contain,
        preview: true,
        // layout: NgxGalleryLayout.ThumbnailsBottom,
        // height: '100px',
        thumbnailsRemainingCount: true,
        // previewFullscreen: true,
        // previewKeyboardNavigation: true,
        // previewZoom: true,
        // thumbnailsArrows: true,
        // thumbnailsSwipe: true,
        previewSwipe: true,
        // imageArrowsAutoHide: true,
        // thumbnailsArrowsAutoHide: true
        previewCloseOnClick: true,
        previewCloseOnEsc: true
      },
      {
        breakpoint: 920,
        thumbnailsColumns: 5
      },
      {
        breakpoint: 768,
        thumbnailsColumns: 4
      },
      {
        breakpoint: 500,
        thumbnailsColumns: 3
      },
      {
        breakpoint: 425,
        thumbnailsColumns: 2
      }
    ];
  }
}

/* more documentation at https://www.npmjs.com/package/ngx-gallery

*** NgxGalleryOptions ***
*************************

width | Type: string | Default value: '500px' - gallery width
height | Type: string | Default value: '400px' - gallery height
breakpoint | Type: number | Default value: undefined - responsive breakpoint, works like media query max-width
fullWidth | Type: boolean | Default value: false - sets the same width as browser
layout | Type: string | Default value: NgxGalleryLayout.Bottom - sets thumbnails position
startIndex | Type: number | Default value: 0 - sets index of selected image on start
linkTarget | Type: string | Default value: _blank - sets target attribute of link
lazyLoading | Type: boolean | Default value: true - enables/disables lazy loading for images
image | Type: boolean | Default value: true - enables or disables image
imageDescription | Type: boolean | Default value: true - enables or disables description for images
imagePercent | Type: number | Default value: 75 - percentage height
imageArrows | Type: boolean | Default value: true - enables or disables arrows
imageArrowsAutoHide | Type: boolean | Default value: false - enables or disables arrows auto hide
imageSwipe | Type: boolean | Default value: false - enables or disables swipe
imageAnimation | Type: string | Default value: NgxGalleryAnimation.Fade - animation type
imageSize | Type: string | Default value: NgxGalleryImageSize.Cover - image size
imageAutoPlay | Type: boolean | Default value false - enables or disables auto play
imageAutoPlayInterval | Type: number | Default value: 2000 - interval for auto play (ms)
imageAutoPlayPauseOnHover | Type: boolean | Default value: false - enables or disables pouse auto play on hover
imageInfinityMove | Type: boolean | Default value: false - enables or disables infinity move by arrows
imageActions | Type: NgxGalleryAction[] | Default value: [] - Array of custom actions
imageBullets | Type: boolean | Default value: false - enables or disables navigation bullets
thumbnails | Type: boolean | Default value: true - enables or disables thumbnails
thumbnailsColumns | Type: number | Default value: 4 - columns count
thumbnailsRows | Type: number | Default value: 1 - rows count
thumbnailsPercent | Type: number | Default value: 25 - percentage height
thumbnailsMargin | Type: number | Default value: 10 - margin between thumbnails and image
thumbnailsArrows | Type: boolean | Default value: true - enables or disables arrows
thumbnailsArrowsAutoHide | boolean: string | Default value: false - enables or disables arrows auto hide
thumbnailsSwipe | Type: boolean | Default value: false - enables or disables swipe
thumbnailsMoveSize | Type: number | Default value: 1 - number of items to move on arrow click
thumbnailsOrder | Type: number | Default value: NgxGalleryOrder.Column - images order
thumbnailsRemainingCount | Type: boolean | Default value: false - if true arrows are disabled and last item has label with remaining count
thumbnailsAsLinks | Type: boolean | Default value: false - enables or disables links on thumbnails
thumbnailsAutoHide | Type: boolean | Default value: false - hides thumbnails if there is only one image
thumbnailMargin | Type: number | Default value: 10 - margin between images in thumbnails
thumbnailSize | Type: string | Default value: NgxGalleryImageSize.Cover - thumbnail size
thumbnailActions | Type: NgxGalleryAction[] | Default value: [] - Array of custom actions
preview | Type: boolean | Default value: true - enables or disables preview
previewDescription | Type: boolean | Default value: true - enables or disables description for images
previewArrows | Type: boolean | Default value: true - enables or disables arrows
previewArrowsAutoHide | boolean: string | Default value: false - enables or disables arrows auto hide
previewSwipe | Type: boolean | Default value: false - enables or disables swipe
previewFullscreen | Type: boolean | Default value: false - enables or disables fullscreen icon
previewForceFullscreen | Type: boolean | Default value: false - enables or disables opening preview in fullscreen mode
previewCloseOnClick | Type: boolean | Default value: false - enables or disables closing preview by click
previewCloseOnEsc | Type: boolean | Default value: false - enables or disables closing preview by esc keyboard
previewKeyboardNavigation | Type: boolean | Default value: false - enables or disables navigation by keyboard
previewAnimation | Type: boolean | Default value: true - enables or disables image loading animation
previewAutoPlay | Type: boolean | Default value false - enables or disables auto play
previewAutoPlayInterval | Type: number | Default value: 2000 - interval for auto play (ms)
previewAutoPlayPauseOnHover | Type: boolean | Default value: false - enables or disables pouse auto play on hover
previewInfinityMove | Type: boolean | Default value: false - enables or disables infinity move by arrows
previewZoom | Type: boolean | Default value: false - enables or disables zoom in and zoom out
previewZoomStep | Type: number | Default value: 0.1 - step for zoom change
previewZoomMax | Type: number | Default value: 2 - max value for zoom
previewZoomMin | Type: number | Default value: 0.5 - min value for zoom
previewRotate | Type: boolean | Default value: false - enables or disables rotate buttons
previewDownload | Type: boolean | Default value: false - enables or disables downoad button
previewBullets | Type: boolean | Default value: false - enables or disables navigation bullets
arrowPrevIcon | Type: string | Default value: 'fa fa-arrow-circle-left' - icon for prev arrow
arrowNextIcon | Type: string | Default value: 'fa fa-arrow-circle-right' - icon for next arrow
closeIcon | Type: string | Default value: 'fa fa-times-circle' - icon for close button
fullscreenIcon | Type: string | Default value: 'fa fa-arrows-alt' - icon for fullscreen button
spinnerIcon | Type: string | Default value: 'fa fa-spinner fa-pulse fa-3x fa-fw' - icon for spinner
zoomInIcon | Type: string | Default value: 'fa fa-search-plus' - icon for zoom in
zoomOutIcon | Type: string | Default value: 'fa fa-search-minus' - icon for zoom out
rotateLeftIcon | Type: string | Default value: 'fa fa-undo' - icon for rotate left
rotateRightIcon | Type: string | Default value: 'fa fa-repeat' - icon for rotate right
downloadIcon | Type: string | Default value: 'fa fa-arrow-circle-down' - icon for download
actions | Type: NgxGalleryAction[] | Default value: [] -
          Array of new custom actions that will be added to the left of the current close/zoom/fullscreen icons
*/
