export const environment = {
  production: true,
  apiUrl: 'https://api.birds.org.il',
  version: '2.06',
  routing: {
    logging: true
  },
  cameras: {
    lobbyPauseTimeout: 5,
    camPauseTimeout: 60,
    bypassCode: 8624
  },
  google: {
    analytics: {
      logging: {
        debug: false,
        exceptions: true,
        verbose: false,
      },
      active: true,
      uaId: 'UA-46201811-1'
    }
  }
};
