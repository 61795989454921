import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArticleTileResponse } from '../../models/article-tile-response';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Tile } from '../../models/tile';
import { TileComponent } from '../../_components/tile/tile.component';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { ArticleService } from '../../_services/article.service';
import { LoadingAnimationService } from '../../_services/loading-animation.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  // articles: ArticleTileResponse[];
  lang: string;
  page = 1;
  stopPaging = false;
  _typeSlug: string;
  _typeName: string;
  _typeId: number;
  _typeDescription: string;
  _author: string;
  artTiles: Tile[] = [];
  lobbyTitle: string;
  filterText: string;

  constructor(private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this._typeSlug = params.get('type');
      this._author = params.get('author');
      this.lang = this.languageService.currentLang();
      this.page = 1;
      this.stopPaging = false;

        // this.lang = this.languageService.currentLang();
        this.artTiles = []; // clear tile array on routing between types
        this.languageService.allTextsLoaded$.subscribe(ready => {
          if (ready) {
            this.getArticles(0);
            }
        });

        if (this.languageService.hasKeys()) {
          this.getArticles(0);
        }
      }
    );
  }

  private getArticles(currentPage: number) {
    let serviceUrl: string;
    this.loadingAnimationService.publishLoadingAnimation(true);

    if (this._typeSlug) {
      this._typeName = this.articleService.getNameBySlug(this._typeSlug, this.lang);
      this._typeId = this.articleService.getIdBySlug(this._typeSlug);
      this._typeDescription = this.articleService.getDescriptionBySlug(this._typeSlug, this.lang);
      serviceUrl = this.apiService.getServerUrl() + '/api/articles/type/' + this.lang + '/' + this._typeId + '/' + this.page;
    } else if (this._author) {
      serviceUrl = this.apiService.getServerUrl() + '/api/articles/author/' + this.lang + '/' + this._author + '/' + this.page;
    }

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: ArticleTileResponse[]) => {
          if (response !== undefined && response.length !== 0) {
            // this.articles = response;

            if (response) {
              this.createArtTiles(currentPage, response);


              // prepare the breadcrumbs and seo
              this.bcService.clearLevels();

              if (this._typeSlug !== 'allarticletypes') {
                this.bcService.addLevel(this.languageService.getTextById('allArticles'), 'articles/type/allarticletypes');
              }

              if (this._typeId) {
                if (this._typeId !== -1) {
                  const types = this.articleService.getPluralsNameBySlug(this._typeSlug, this.lang);
                  this.bcService.setLastItemTitle(types);
                } else {
                  this.bcService.setLastItemTitle(this._typeName);
                }
              } else if (this._author) {
                this.bcService.setLastItemTitle(this._author);
              }

              // tslint:disable-next-line:max-line-length
              const pageType = (this._author) ? this.articleService.getPluralsNameById(-1, this.lang) + ' - ' + this._author : this.articleService.getPluralsNameById(this._typeId, this.lang);
              // let pageTitle = pageType;
              // if (this._typeId !== 1) {
              //   pageTitle += ' | ' + this.articleService.getPluralsNameBySlug('article');
              // }
              const pageTitle = pageType + ' | ' + this.languageService.getTextById('baseSeoTitle');
              const pageImage = response[0].imageUrl && response[0].imageUrl.path; // get image from first
              const keywords = pageType + ',' + this.languageService.getTextById('baseSeoKeywords');
              const description = pageType + ',' + this.languageService.getTextById('baseSeoDescription');
              const pagePath = (this._author) ? '/articles/author/' + this._author : '/articles/type/' + this._typeSlug;
              const pageUrl = location.origin + pagePath;
              const canonicalUrl = location.origin + '/' + this.lang + pagePath;
              this.bcService.seoItems(pageTitle, keywords, description, pageImage, pageUrl, pageType, canonicalUrl);

              // publish
              this.bcService.publishBreadcrumbs();

              this.lobbyTitle = (this._typeId) ? this.articleService.getPluralsNameBySlug(this._typeSlug, this.lang) : this._author;
            } else {
              this.stopPaging = true; // no respons so no need for next page
            }
            this.loadingAnimationService.publishLoadingAnimation(false);
          } else {
            this.stopPaging = true; // no respons so no need for next page
            this.loadingAnimationService.publishLoadingAnimation(false);
          }
        }, error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });

        // // get more pages
        // if (this.page <= 5) {
        //   this.page = this.page + 1;
        //   this.getArticles(this.page - 1);
        // }
    }
  }

  private createArtTiles(currentPage: number, articles: ArticleTileResponse[]) {
    // this.artTiles[currentPage] = [];
    if (!articles || articles.length === 0) {
      this.stopPaging = true; // no respons so no need for next page
      return;
    }
    articles.forEach(article => {
      if (this.artTiles && this.artTiles.length > 0 &&
        this.artTiles.some(item => item.tileLinkID === article.slug)) {
        return;
      }

      const tile: Tile = {
        image: article.imageUrl,
        title: article.title,
        metaData1: article.author,
        metaData2: this.languageService.showDate(article.articleDate),
        showMD1: true,
        showMD2: true,
        italicMD1: false,
        italicMD2: false,
        filterData: article.title + ' | ' + article.author + '|' + article.shortDescription,
        shortDescription: article.shortDescription,
        tags: article.tags,
        badge: this.articleService.getNameById(article.typeId, this.lang),
        badgeColor: 'orange',
        tileLinkType: 'article',
        tileLinkID: article.slug
      };

      // special implementation for tours articles
      if (this._typeId === 4) {
        tile.badge = article.name;
      }
      this.artTiles.push(tile);
    });
    this.stopPaging = false; // turn on paging for next page
  }

  // When scroll down the screen
  public onScroll() {
    if (!this.stopPaging) {
      this.stopPaging = true; // avoid next page before current one is returned
      this.page = this.page + 1;
      this.getArticles(this.page - 1);
    }
  }

  onFilterTextChange (filterTextSync: string) {
    this.filterText = filterTextSync;
  }

  // onSelect(article: ArticleTileResponse) {
  //   this.router.navigate(['/article', article.id]);
  // }
}


