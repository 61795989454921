import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.css']
})
export class TopHeaderComponent implements OnInit {
  lang: string;
  donateLabel: string;
  externalDonationUrl: string;
  constructor(private languageService: LanguageService, private router: Router) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.donateLabel = this.languageService.getTextById('Donation');
    this.externalDonationUrl = (this.lang === 'he' ?
                                encodeURIComponent('https://secured.israeltoremet.org/donate/Birders') :
                                encodeURIComponent('https://secured.israelgives.org/donate/Birders'));
  // https://shopil.birds.org.il/IL/donations-for-the-birds
  // https://shop.birds.org.il/en/donations-for-the-birds
}

  toggleLanguage(toLang: string) {
    // console.log('current lang: ' + this.lang + ' clicked on: ' + toLang);

    this.languageService.appComponentChangeLang(toLang);
    this.lang = this.languageService.currentLang();
    // console.log('lang changed to: ' + this.lang);

    this.router.navigateByUrl('/' + this.lang).then(function () { window.location.reload(); });

    return true;
  }

  gotoAboutUs() {
    if (this.lang === 'he') {
      this.router.navigate(['/article', 'אודות-הפורטל']);
    } else {
      this.router.navigate(['/article', 'about-us']);
    }
  }
}
