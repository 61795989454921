<div class="card-box {{dir}}">
  <div *ngIf="obsData && obsData.records && obsData.records.length > 0">
    <div class="species-quantity full-div">
      <table class="species-quantity-table">
        <tr class="latest-observations-tr">
          <th>{{ speciesNameHeader }} ({{ obsData.records.length }})</th>
          <th>{{ countHeader }}</th>
        </tr>
        <tr class="species-quantity-tr" *ngFor="let rec of obsData.records">
          <!-- <td>
            <div class="tile-img-wrapper"> 
              <img *ngIf="rec.speciesImage" class="tile-img full-div"
              [src]="rec.speciesImage.path"
              [alt]="rec.speciesImage.name"
              [title]="rec.speciesImage.title"
              />
              <div *ngIf="!rec.speciesImage" class="bird-silhouette {{this.dir}}"></div>
            </div>
          </td> -->
          <td>
            <a *ngIf="rec.isSpeciesEncyclopedia" class="species-link {{dir}}" [routerLink]="['/' + lang + '/species-page/' + rec.speciesId]"><span class="base-t-16 c-blue t-b"><i class="book-icon fas fa-link"></i> {{ rec.speciesName }} <span class="c-darker-grey t-i t-nob small-t-14">{{ rec.latinName }}</span></span></a>
            <span *ngIf="!rec.isSpeciesEncyclopedia" class="base-t-16 c-blue t-b">{{ rec.speciesName }} <span class="c-darker-grey t-i t-nob small-t-14">{{ rec.latinName }}</span></span>
          </td>
          <td *ngIf="rec.quantity != 0">{{ rec.quantity }}</td>
          <td *ngIf="rec.quantity == 0">X</td>
        </tr>
      </table>
    </div>
  </div>
</div>
