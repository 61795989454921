<div class="full-div">
  <ngfFormData [files]="files" postName="file" [(FormData)]="sendableFormData"></ngfFormData>
  <div class="browse-container">
    <div class="browse-btn"
      ngfSelect
      accept    = "image/*"
      multiple
      [maxSize] = "maxSize"
      [(files)] = "files"
      style     = "border-style:groove;padding:0.5em;text-align:center;width:150px;"
      [(lastInvalids)] = "lastInvalids"
      (filesChange) = "lastFileAt=getDate()"
    >{{browseImageFiles}}</div>
    <div class="my-drop-zone hide-mobile"
      ngfDrop
      multiple         = "1"
      selectable       = "1"
      [(validDrag)]    = "validComboDrag"
      [(files)]        = "files"
      accept           = "image/*"
      [maxSize]        = "maxSize"
      [(lastInvalids)] = "lastInvalids"
      [(dragFiles)]    = "dragFiles"
      [class.invalid-drag] = "validComboDrag===false"
      [class.valid-drag]   = "validComboDrag"
      (filesChange) = "lastFileAt=getDate()"
      [fileDropDisabled] = "fileDropDisabled"
    >{{dropImageFilesZone}}</div>
  </div>

  <!-- <div class="bg-warning" *ngIf="lastInvalids?.length" style="margin-bottom: 40px">
    <h3 style="color:red;">Last {{ lastInvalids.length }} Invalid Selected Files</h3>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Error</th>
          <th>Type</th>
          <th>Size</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of lastInvalids;let i=index">
          <td>
            <div *ngIf="['image/gif','image/png','image/jpeg'].indexOf(item.file.type)>=0">
              <div class="previewIcon" [ngfBackground]="item.File"></div>
            </div>
            <strong>{{ item.file.name }}</strong>
          </td>
          <td nowrap>
            {{ item.type }}
          </td>
          <td nowrap>
            {{ item.file.type }}
          </td>
          <td nowrap>
            {{ item.file.size/1024/1024 | number:'.2' }} MB
          </td>
          <td nowrap>
            <button type="button"
              class="btn btn-danger btn-xs"
              (click)="lastInvalids.splice(i,1)"
            >
              <span class="far fa-trash-alt"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->

  <div style="margin-bottom: 40px">
    <div class="images-list" *ngFor="let item of files;let i=index">
      <div class="image-item">
        <div *ngIf="['image/gif','image/png','image/jpeg'].indexOf(item.type)>=0">
          <div class="previewIcon" [ngfBackground]="item"></div>
        </div>
        <div class="image-details base-t-18 hide-mobile">
          <strong class="file-name">{{ item.name }}</strong>
          <br/>          
          {{ item.type }}
          <br/>
          {{ item.size/1024/1024 | number:'.2' }} MB
        </div>
        <div class="image-details small-t-11 hide-desktop">
          <strong class="file-name">{{ item.name }}</strong>
          <br/>
          {{ item.type }}
          <br/>
          {{ item.size/1024/1024 | number:'.2' }} MB
        </div>
        <button type="button" class="btn btn-danger" (click)="files.splice(i,1)">
          <span class="far fa-trash-alt"></span>
        </button>
      </div>
    </div>
    <!-- <table class="table full-div">
      <tbody>
        <tr *ngFor="let item of files;let i=index">
          <td>
            <div *ngIf="['image/gif','image/png','image/jpeg'].indexOf(item.type)>=0">
              <div class="previewIcon" [ngfBackground]="item"></div>
            </div>
          </td>
          <td nowrap>
            <strong>{{ item.name }}</strong>
            <br/>
            {{ item.type }}
            <br/>
            {{ item.size/1024/1024 | number:'.2' }} MB
          </td>
          <td nowrap>
            <button type="button" class="btn btn-danger" (click)="files.splice(i,1)">
              <span class="far fa-trash-alt"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table> -->
  </div>
</div>
