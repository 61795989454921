import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LanguageService } from '../../_services/language.service';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.css']
})
export class FilterBarComponent implements OnInit {
  filterText: string;
  @Output() filterTextSync = new EventEmitter<string>();
  @Input() filterTextParam: string;

  lang: string;
  dir: string;
  filterPlaceholder: string;

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    this.filterPlaceholder = this.languageService.getTextById('Filter');
    if (this.filterTextParam) {
      this.filterText = this.filterTextParam;
      this.filterTextChange();
    }
  }

  filterTextChange() {
    this.filterTextSync.emit(this.filterText);
  }
}
