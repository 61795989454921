<section name="obs-news-section" class="full-div obs-news-section">
  <div class="grid" style="overflow: initial;">
    <div *ngIf="obsNews" class="main-obs-news-wrapper full-div">
      <div class="header-filter">
        <h1>{{ this.title }}</h1>
        <div class="view-options">
          <!-- <app-filter-bar (filterTextSync) = "onFilterTextChange($event)"></app-filter-bar> -->
          <div class="toggle-view title-t-22 c-green hide-desktop" (click)="toggleView()">
              <span class="toggle-view-button {{hideMap}}"><i class="fas fa-map-marked-alt"></i></span>  
              <span class="toggle-view-button {{hideList}}"><i class="fas fa-list"></i></span>  
          </div>
        </div>
      </div>
      <!-- <h2>{{ this.subtitle }}</h2> -->
      <div *ngIf="obsNews" class="obsNews">
        <div class="two-third-div {{dir}} tree mobile-hide-list {{hideList}}">
          <!-- <app-filter-bar (filterTextSync) = "onFilterTextChange($event)"></app-filter-bar> -->
          <div class="tree-box search {{dir}}">
            <div class="searchbar-wrapper {{dir}}" (clickOutside)="clickedOutsideSearchBar()">
              <div [ngClass]="(searchOn ? 'searchbar on' : 'searchbar')">
                <i (click)="searchClick()"
                  [ngClass]="(searchOn ? 'search-icon on fas fa-search' : 'search-icon fas fa-search')"></i>
                <input #searchInput (keyup.enter)="searchClick()" [ngClass]="(searchOn ? 'search-input on' : 'search-input')" type="text"
                  name="" placeholder="{{this.searchPlaceholder}}" [(ngModel)]="searchQuery">
                <i *ngIf="searchOn" (click)="searchClear()" class="clear-icon fas fa-times"></i>
              </div>
            </div>
          </div>    
          <ul *ngFor="let dateGroup of obsNews | filter : filterText; let i = index" class="obsNews-tree">
            <li class="tree-box {{this.dir}} dateGroup-{{i}}">
              <div class="tree-node {{this.dir}} tree-node-{{i}} isExpend-{{this.expendItemsGroups[i]}}" (click)="toggleExpend(i)"></div>
              <div class="tree-node-header t-b title-t-20">{{ this.dateGroupHeader }}{{ dateGroup.date | date :'dd/MM/yyyy' }}</div>
              <div class="node-items-box node-items-box-{{i}}" *ngIf="this.expendItemsGroups[i]">
                <div *ngFor="let item of dateGroup.obsNewsItems | filter : filterText" class="newsItem-flex">
                  <div class="tree-entry"></div>
                  <div class="odn-time t-b">{{ item.odnDateTime | date :'HH:mm'}}</div>
                  <div class="image-container" *ngIf="item.odnSpeImage">
                    <a [routerLink]="['/' + lang + '/species-page/' + item.odnSpeciesID]">
                      <img class="circle-img full-div"
                      [src]="item.odnSpeImage.path"
                      [alt]="item.odnSpeImage.name"
                      [title]="item.odnSpeImage.title"
                      />
                      <div *ngIf="item.odnRepOrigin" class="origin-icon origin-icon-{{item.odnRepOrigin}}"></div>
                    </a>
                  </div>
                  <div class="image-container" *ngIf="!item.odnSpeImage">
                    <!-- <img class="circle-img full-div" [src]="this.birdSilhouette"/> -->
                    <div *ngIf="item.odnRepOrigin" class="origin-icon origin-icon-{{item.odnRepOrigin}}"></div>
                    <div class="bird-silhouette {{this.dir}}"></div>
                  </div>
                  <p class="odn-text base-t-16 show-for-tablet" [innerHTML]="item.odnParagraph | BoldText : '**':'!!'"></p>
                  <p class="odn-text small-t-12 hide-for-tablet" [innerHTML]="item.odnParagraph | BoldText : '**':'!!'"></p>
                  <a class="location-icon" [href]="'' | GoogleMapLink : item.odnLocationLat:item.odnLocationLong" target="_blank" [title]="'Google Map'"><i class="fa fa-map-marker c-green"></i></a>
                  <a class="ebird-icon" *ngIf="item.odnRepOriginDetails" [href]="item.odnRepOriginDetails" target="_blank" [title]="item.odnRepOriginDetails"><i class="fa fa-link c-blue"></i></a>
                </div>
              </div>
            </li>
          </ul>
          <div class="end-tree {{dir}}"></div>
        </div>
        <div class="third-div obs-map {{dir}} mobile-hide-map {{hideMap}}" *ngIf="mapMarkers && mapMarkers.length > 0">
          <div class="side-panel {{dir}}">
            <app-side-pannel-title class="hide-mobile" [title] = "this.mapTitle"></app-side-pannel-title>
            <app-google-map-wrapper class='side-map' [height]="70" [heightUnits]="'vh'" [inputMarkers]="mapMarkers"></app-google-map-wrapper>
            <div class="map-legend small-t-12 {{dir}}">
              <span>{{ this.mapLegend }}</span>
              <span><img class="small-legend-icon" src="/assets/images/map-marker.png"/>{{ this.mapLegendIcon1 }}</span>
              <span><img class="small-legend-icon" src="/assets/images/map-marker-orange.png"/>{{ this.mapLegendIcon2 }}</span>
              <span><img class="large-legend-icon" src="/assets/images/map-marker-green-large.png"/>{{ this.mapLegendIcon3 }}</span>
              <span><img class="large-legend-icon" src="/assets/images/map-marker-orange-large.png"/>{{ this.mapLegendIcon4 }}</span>
              <!-- <span><img class="small-legend-icon" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/map-marker.png"/>{{ this.mapLegendIcon1 }}</span>
              <span><img class="small-legend-icon" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/map-marker-orange.png"/>{{ this.mapLegendIcon2 }}</span>
              <span><img class="large-legend-icon" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/map-marker-green-large.png"/>{{ this.mapLegendIcon3 }}</span>
              <span><img class="large-legend-icon" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/map-marker-orange-large.png"/>{{ this.mapLegendIcon4 }}</span> -->
            </div>
            <app-linked-articles class="hide-mobile" *ngIf="linkedArticles && linkedArticles.length > 0" 
              [linkedArticles]="linkedArticles" 
              [moreArticlesTitle]="articlesTitle" 
              [lang]="lang">
            </app-linked-articles>
          </div>
        </div> 
      </div>
    </div>  
  </div>
</section>
<app-linked-articles class="hide-desktop" *ngIf="linkedArticles && linkedArticles.length > 0" 
  [linkedArticles]="linkedArticles" 
  [moreArticlesTitle]="articlesTitle" 
  [lang]="lang">
</app-linked-articles>
<app-scroll-top></app-scroll-top>
