export class WeeklyBarChartConfig {
    theme: string;
    // width: number;
    height: number;
    legend: { position: string };
    backgroundColor: string;
    chartArea: { backgroundColor: string };
    areaOpacity: number;
    connectSteps: boolean;
    seriesType: string;
    series: {
        0: { color: string, areaOpacity: number },
        1: { color: string, type: string, lineWidth: number }
    };
    vAxis: any;
    hAxis: any;
    tooltip: {
        // 'textStyle': { color: 'black' },
        isHtml: boolean
    };

    constructor() {
        this.theme = 'material';
        // this.width = 600;
        this.height = 80;
        this.legend = { position: 'none' };
        this.backgroundColor = '#f7f5f3';
        this.chartArea = { backgroundColor: 'white' };
        this.areaOpacity = 0.7;
        this.connectSteps = false;
        this.seriesType = 'steppedArea';
        this.series = {
            0: { color: '#4ba600', areaOpacity: 0.9 },
            1: { color: 'black', type: 'line', lineWidth: 1 }
        };
        this.tooltip = {
            // 'textStyle': { color: 'black' },
            isHtml: true
        };

        // vAxis
        this.vAxis = {
            minValue: 0,
            maxValue: 5,
            title: '%',
            ticks: [
                { v: 0, f: 'X' },
                { v: 1, f: '0-2' },
                { v: 2, f: '2-10' },
                { v: 3, f: '10-25' },
                { v: 4, f: '25-40' },
                { v: 5, f: '40-75' },
                { v: 6, f: '75-100' }
            ],
            textStyle: { fontSize: 7, color: '#474747'},
            titleTextStyle: { fontSize: 10 },
            baselineColor: '#F9F9F9'
        };

        // calc week-months
        const weekMonth: number[] = [];
        const today = new Date(Date.now());
        const currMonth = today.getMonth() + 1;
        const currYear = today.getFullYear();
        for (let i = 0; i < 12; i++) {
            const middleOfMonth = (currMonth > i) ? new Date(currYear, i, 15) : new Date((currYear - 1), i, 15);
            const startOfMonth = (currMonth > i) ? new Date(currYear, i, 1) : new Date((currYear - 1), i, 1);
            const onejan = new Date(startOfMonth.getFullYear(), 0, 1);
            const currentWeek = Math.ceil((((+startOfMonth - +onejan) / 86400000) + onejan.getDay() + 1) / 7);
            weekMonth.push(currentWeek - 1);
        }

        // ticks
        const ticks: any[] = [];
        ticks.push({ v: weekMonth[0], f: 'Jan' }); // 3
        ticks.push({ v: weekMonth[1], f: 'Feb' }); // 7
        ticks.push({ v: weekMonth[2], f: 'Mar' }); // 11
        ticks.push({ v: weekMonth[3], f: 'Apr' }); // 15
        ticks.push({ v: weekMonth[4], f: 'May' }); // 20
        ticks.push({ v: weekMonth[5], f: 'Jun' }); // 24
        ticks.push({ v: weekMonth[6], f: 'Jul' }); // 29
        ticks.push({ v: weekMonth[7], f: 'Aug' }); // 33
        ticks.push({ v: weekMonth[8], f: 'Sep' }); // 38
        ticks.push({ v: weekMonth[9], f: 'Oct' }); // 42
        ticks.push({ v: weekMonth[10], f: 'Nov' }); // 46
        ticks.push({ v: weekMonth[11], f: 'Dec' }); // 51

        // hAxis
        this.hAxis = {
            minValue: 1,
            ticks: ticks,
            textStyle: { fontSize: 8 },
            slantedText: false,
            gridlines: {
                // color: '#efefef',
                // count: 54
            },
            baselineColor: '#efefef',
            maxAlternation: 2
        };

    }
}



