<div name="contact" class="full-div social-flex">      
  <div *ngIf="this.phone !== undefined && this.phone !== '' && this.phone !== '000-0000000'" class="social-share-btn phone-btn bg-phone text-center">
    <a href="tel:{{phone}}" target="_blank">
      <i class="fas fa-phone c-white" title={{phone}}></i>
    </a>
  </div>
  <div class="social-share-btn contact-us-btn bg-email text-center">
    <a [routerLink]="['/contactus']">
      <i class="fas fa-envelope c-white"></i>
    </a>
  </div>
  <div class="social-share-btn waze-btn bg-waze text-center">
    <a href="https://waze.com/ul?ll={{lat}},{{lng}}&z=10" target="_blank">
      <i class="fab fa-waze c-white"></i>
    </a>
  </div>
  <div class="social-share-btn moovit-btn bg-orange text-center">
    <a href="https://moovit.com/?to={{destinationName}}&tll={{lat}}_{{lng}}&metroId=1&lang=he" target="_blank">
      <i class="fas fa-bus-alt c-white"></i>  
    </a>
  </div>
</div>