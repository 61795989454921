import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgHost'
})
export class ImgHostPipe implements PipeTransform {

  transform(htmlString: string): any {
    if (typeof htmlString === 'undefined') {
      return htmlString;
    }

    const relativeFilesCDN = '//cms.birds.org.il/data/';
    const httpsFilesCDN = 'https://cms.birds.org.il/data/';
    const s3_relativeFilesCDN = 'https://bp-photos-new-portal.s3-eu-west-1.amazonaws.com/data/';
    const s3_httpsFilesCDN = 'https://bp-photos-new-portal.s3-eu-west-1.amazonaws.com/data/';

    let result = htmlString;

    result = this.replaceAll(result, 'https://www.birds.org.il/Data/', httpsFilesCDN);
    result = this.replaceAll(result, 'https://www.birds.org.il/data/', httpsFilesCDN);
    result = this.replaceAll(result, 'https://birds.org.il/Data/', httpsFilesCDN);
    result = this.replaceAll(result, 'https://birds.org.il/data/', httpsFilesCDN);
    result = this.replaceAll(result, '//www.birds.org.il/Data/', relativeFilesCDN);
    result = this.replaceAll(result, '//www.birds.org.il/data/', relativeFilesCDN);
    result = this.replaceAll(result, '//birds.org.il/Data/', relativeFilesCDN);
    result = this.replaceAll(result, '//birds.org.il/data/', relativeFilesCDN);

    return result;
  }

  private replaceAll(srcString: string, removePhrase: string, addPhrase: string): string {
    let exists = srcString.indexOf(removePhrase);
    while (exists >= 0) {
      srcString = srcString
        .replace(removePhrase, addPhrase);

      exists = srcString.indexOf(removePhrase);
    }
    return srcString;
  }
}
