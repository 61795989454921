<div class="item-banner">
  <a [routerLink]="['/' + lang + '/' + item.tileLinkType, item.tileLinkID]">
    <div *ngIf="item" class="item-shadow-box item-banner-flex">
      <div class="item-img-wrapper third-div"> 
      <img *ngIf="item.image" class="item-img full-div"
      [src]="item.image.path"
      [alt]="item.image.name"
      [title]="item.title"
      />
    </div>
    <div class="item-md-wrapper-flex two-third-div">
      <p class="item-type-caption item-md-wrapper base-t-16 c-white t-b"><span class="tile-badge-txt tile-badge-color {{ item.badgeColor }}">{{ item.badge }}</span></p>
      <div class="item-title base-t-18 t-b">{{ item.title}}</div>
      <div class="item-md-wrapper base-t-16">
        <span class="item-md border-{{this.dir}} italic-{{item.italicMD1}}" *ngIf="item.showMD1">{{ item.metaData1 }}</span>
      </div>
    </div>
  </div>
  </a>
</div>