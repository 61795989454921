import { Component, OnInit } from '@angular/core';
import { ObservationTileResponse } from 'src/app/models/observation-tile-response';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ArticleService } from 'src/app/_services/article.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-latest-observations',
  templateUrl: './latest-observations.component.html',
  styleUrls: ['./latest-observations.component.css']
})
export class LatestObservationsComponent implements OnInit {
  latestObs: ObservationTileResponse[];
  lang: string;
  dir: string;
  title: string;
  moreLinkTitle: string;


  constructor(private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';
    this.title = this.languageService.getTextById('LatestObservations'); // 'תצפיות אחרונות';
    this.moreLinkTitle = this.languageService.getTextById('MoreObservations');
    this.languageService.allTextsLoaded$.subscribe(ready => {
      if (ready) {
        this.getLatestObservations();
        }
    });

    if (this.languageService.hasKeys()) {
      this.getLatestObservations();
    }

  }

  private getLatestObservations() {
    let serviceUrl: string;

    serviceUrl = this.apiService.getServerUrl() + '/api/observation/' + this.lang + '/latest/4';

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: ObservationTileResponse[]) => {
          if (response !== undefined && response.length !== 0) {
            this.latestObs = response;
          }
        }, error => {
          console.log(error);
          // if (error.status === 404) {
          //   this.router.navigate([this.lang, 'not-found']);
          // } else {
          //   this.router.navigate([this.lang, 'error']);
          // }
        });
    }
  }

}
