import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { ObservationTileResponse } from 'src/app/models/observation-tile-response';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { FilterPipe } from 'src/app/_pipes/filter.pipe';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { MyMapMarker } from 'src/app/models/my-map-marker';

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.css']
})
export class ObservationsComponent implements OnInit, AfterViewChecked {
  observations: ObservationTileResponse[];
  lang: string;
  dir: string;
  title: string;
  filterText: string;
  obsForMarkers: ObservationTileResponse[];
  mapMarkers: MyMapMarker[] = [];
  selectedMarker: MyMapMarker[] = [];
  israelLat = 31.4117;
  israelLng = 35.0818;
  expendedObsId: number;
  mapTitle: string;
  obsRecordsCount: number;
  hideMap = true;
  hideList = false;
  isScroll = false;
  clickedObsId: string;

  constructor(private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private loadingAnimationService: LoadingAnimationService,
    // private route: ActivatedRoute,
    private router: Router,
    private filter: FilterPipe) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    this.title = this.languageService.getTextById('LatestObservations'); // 'תצפיות אחרונות';
    this.mapTitle = this.languageService.getTextById('ObservationsMapTitle'); // 'תצפיות אחרונות';

    this.languageService.allTextsLoaded$.subscribe(ready => {
      if (ready) {
        this.getLatestObservations();
        }
    });

    if (this.languageService.hasKeys()) {
      this.getLatestObservations();
      }
    }

  ngAfterViewChecked() {
    if (this.isScroll) {
      // document.getElementById('obsId' + this.clickedObsId).scrollIntoView();
      this.isScroll = false;
    }
  }



  private getLatestObservations() {
    let serviceUrl: string;
    this.loadingAnimationService.publishLoadingAnimation(true);

    serviceUrl = this.apiService.getServerUrl() + '/api/observation/' + this.lang + '/latest/20';

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: ObservationTileResponse[]) => {
          if (response !== undefined && response.length !== 0) {
            this.observations = response;
            this.obsForMarkers = this.observations;
          }
          // map markers
          this.CreateMapMarkers();

          // prepare the breadcrumbs
          this.bcService.clearLevels();
          this.bcService.setLastItemTitle(this.title);

          const pageTitle = this.title
                            + ' - ' + this.languageService.getTextById('baseSeoTitle');
          const keywords =  this.title
                            + ',' + this.languageService.getTextById('baseSeoKeywords');
          const desciption = this.title
                            + ',' + this.languageService.getTextById('baseSeoDescription');
          const pagePath = '/observations';
          const pageUrl = location.origin + pagePath;
          const canonicalUrl = location.origin + '/' + this.lang + pagePath;

          const pageType = this.title;
          this.bcService.seoItems(pageTitle, keywords, desciption, '', pageUrl, pageType, canonicalUrl);

          // publish
          this.bcService.publishBreadcrumbs();

          // open data card for clicked obsId
          this.expendedObsId = null;
          this.selectedMarker = [];
          const clickedObsId = sessionStorage.getItem('clickedObsId');
          if (clickedObsId !== undefined && clickedObsId !== null) {
            sessionStorage.removeItem('clickedObsId');
            this.getObsDataCard(+clickedObsId);
            console.log('setSelectedMarker' + clickedObsId);
            this.setSelectedMarker(+clickedObsId);
            this.isScroll = true;
          }

          this.loadingAnimationService.publishLoadingAnimation(false);
        }, error => {
          console.log(error);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
    }

  }

  private CreateMapMarkers() {
    this.mapMarkers = [];

    // map markers
    this.obsForMarkers.forEach(element => {
      if (element.lat && element.long) {
        const markerHtmlInfo = '<u>' + this.languageService.showLongDate(element.observationDate) + '</u>'
                                + '<br/>' + '<b>' + element.speciesShortenList + '</b>'
                                + '<br/>' + element.observerName;
        let marker = {
          lat: element.lat,
          lng: element.long,
          title: markerHtmlInfo
        };

        this.mapMarkers.push(marker);
      }
    });
  }


  onFilterTextChange (filterTextSync: string) {
    this.filterText = filterTextSync;

    // markers
    this.obsForMarkers = this.filter.transform(this.observations, this.filterText);
    this.CreateMapMarkers();
  }

  toggleView () {
    this.hideMap = !this.hideMap;
    this.hideList = !this.hideList;
    if (!this.hideMap) {
      this.CreateMapMarkers();
    }
  }

  updateRecordsCount (recordsCount: number) {
    this.obsRecordsCount = recordsCount;
  }

  getObsDataCard(obsId: number) {
    this.expendedObsId = obsId;

  }

  setSelectedMarker(obsId: number) {
    if (this.observations) {
      const _selectedObs = this.observations.find(x => x.id === obsId);
      const markerHtmlInfo = '<u>' + this.languageService.showLongDate(_selectedObs.observationDate) + '</u>'
        + '<br/>' + '<b>' + _selectedObs.speciesShortenList + '</b>'
        + '<br/>' + _selectedObs.observerName;
      

      let marker = {
        lat: _selectedObs.lat,
        lng: _selectedObs.long,
        title: markerHtmlInfo
      };
      this.selectedMarker.push(marker);
    }
  }

  closeObsDataCard() {
    this.expendedObsId = null;
    this.selectedMarker = [];
  }
}
