<div *ngIf="!isMobile" class="camera-wrapper hide-mobile">
  <div *ngIf="playerElement !== ''" id="{{playerElement}}-d" style="width:100%; height:0; padding:0 0 56.25% 0">Loading Player</div>
  <!-- testing related to home page cam tile -->
  <!-- <div *ngIf="playerElement !== ''" id="{{playerElement}}-d" class="playerElement" [ngStyle]="{'padding-top': aspectRatio + '%' }" -->
  <!--<div *ngIf="playerElement !== ''" id="{{playerElement}}-d1" class="playerElement aspectRatio-{{aspectRatio}}"> 
    <div id="{{playerElement}}-d" >Loading Player</div>
  </div>-->
</div>
<div *ngIf="isMobile" class="camera-wrapper hide-desktop">
  <div *ngIf="playerElement !== ''" id="{{playerElement}}-m" style="width:100%; height:0; padding:0 0 56.25% 0">Loading Player</div>
</div>