import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { LoginRequest } from 'src/app/models/login-request';
import { AlertifyService } from 'src/app/_services/alertify.service';

import { AuthService } from 'src/app/_services/auth.service';
import { LanguageService } from 'src/app/_services/language.service';
import { ValidationService } from 'src/app/_services/validation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  lang: string;
  dir: string;
  loginButton: string;
  emailPlaceholder: string;
  passwordPlaceholder: string;
  inSend = false;
  model: LoginRequest = { 'username': '', 'password': '' };

  constructor(private authService: AuthService,
    private languageService: LanguageService,
    private validationService: ValidationService,
    private alertify: AlertifyService,
    private router: Router) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    this.loginButton = this.languageService.getTextById('cmsLoginButtonLabel');
    this.emailPlaceholder = this.languageService.getTextById('cmsEmailPlaceholder');
    this.passwordPlaceholder = this.languageService.getTextById('cmsPasswordPlaceholder');

    if (this.authService.isLoggedIn()) {
      this.router.navigate([this.lang, 'cms', 'menu']);
    }
  }

  doLogin(f: NgForm) {
    if (this.inSend) {
      return false;
    }


    if (!this.validationService.isEmail(this.model.username)) {
      this.alertify.error(this.languageService.getTextById('errEmail'));
      return;
    }

    // this.inSend = true;

    this.authService.login(this.model).subscribe(data => {
      this.inSend = false;
    });
  }

  clearInSend() {
    this.inSend = false;
  }

}
