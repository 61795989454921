import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Tile } from '../../models/tile';
import { LanguageService } from '../../_services/language.service';
import { EventTileResponse } from '../../models/event-tile-response';

@Component({
  selector: 'app-linked-events',
  templateUrl: './linked-events.component.html',
  styleUrls: ['./linked-events.component.css']
})
export class LinkedEventsComponent implements OnInit, OnChanges {

  @Input() linkedEvents: EventTileResponse[];
  @Input() moreEventsTitle: string;
  @Input() lang: string;
  evtBannerItems: Tile[];

  constructor(
    private router: Router,
    private languageService: LanguageService,
  ) { }


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createEvtTiles();

    if (!this.moreEventsTitle || this.moreEventsTitle === '') {
      this.moreEventsTitle = this.languageService.getTextById('defaultMoreEventsTitle');
    }
  }

  createEvtTiles() {
    this.evtBannerItems = [];
    this.linkedEvents.forEach(event => {
      const item: Tile = {
        image: event.imageUrl,
        title: event.title,
        metaData1: this.languageService.showDate(event.endDate) + ' - ' + this.languageService.showDate(event.startDate),
        metaData2: '',
        showMD1: true,
        showMD2: false,
        italicMD1: false,
        italicMD2: false,
        filterData: null,
        shortDescription: event.shortDescription,
        tags: event.tags,
        badge: this.languageService.getTextById('baseEventTitle'),
        badgeColor: 'green',
        tileLinkType: 'event',
        tileLinkID: event.slug
      };

      this.evtBannerItems.push(item);
    });
  }

}
