import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../_modal';
import { LanguageService } from 'src/app/_services/language.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/_services/api.service';
import { NewsletterRegistration } from 'src/app/models/newsletter-registration';
import { NgForm } from '@angular/forms';
import { ImageResponse } from 'src/app/models/image-response';

@Component({
  selector: 'app-register-newsletter',
  templateUrl: './register-newsletter.component.html',
  styleUrls: ['./register-newsletter.component.css']
})
export class RegisterNewsletterComponent implements OnInit {
  @Input() modalId: string;
  @Input() image: ImageResponse;
  lang: string;
  dir: string;
  registerLabel: string;
  registerNewsletterHeader: string;
  closeLabel: string;
  submit: string;
  regEmail: string;
  regFirstName: string;
  regLastName: string;
  firstNamePlaceHolder: string;
  lastNamePlaceHolder: string;
  emailPlaceHolder: string;
  regResult: string;
  errRequired: string;
  errShort: string;
  errEmail: string;
  disableButton: boolean;
  showError = false;

  constructor(
    private modalService: ModalService,
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';
    this.registerLabel = this.languageService.getTextById('RegisterToNewsletterLink');
    this.registerNewsletterHeader = this.languageService.getTextById('RegisterToNewsletterHeader');
    this.closeLabel = this.languageService.getTextById('CloseModal');
    this.submit = this.languageService.getTextById('Submit');
    this.firstNamePlaceHolder = this.languageService.getTextById('FirstNamePlaceHolder');
    this.lastNamePlaceHolder = this.languageService.getTextById('FirstNamePlaceHolder');
    this.emailPlaceHolder = this.languageService.getTextById('ContactUsEmail');
    this.errRequired = this.languageService.getTextById('errRequired');
    this.errShort = this.languageService.getTextById('errShort');
    this.errEmail = this.languageService.getTextById('errEmail');

  }

  openModal(id: string) {
    this.modalService.open(id);
    this.regResult = '';
  }

  closeModal(id: string, f: NgForm) {
      this.modalService.close(id);
      this.regEmail = null;
      this.regFirstName = null;
      this.regLastName = null;
      this.regResult = '';
      f.resetForm();
  }

  register(f: NgForm) {
    const data: NewsletterRegistration = new NewsletterRegistration();
    data.lang = this.lang;
    data.email = this.regEmail;
    data.firstName = this.regFirstName;
    data.lastName = this.regLastName;

    this.disableButton = true;
    this.showError = false;

    this.http.post<boolean>(this.apiService.getServerUrl() + '/api/message/subscribe', data)
    .subscribe((response: boolean) => {
      if (response) {
        this.regResult = this.languageService.getTextById('NewsletterRegistrationSuccess');
        console.log(this.regResult);
        f.resetForm();
      }
      this.disableButton = false;
    }, error => {
      this.regResult = this.languageService.getTextById('NewsletterRegistrationError') + ' - ' + error.statusText;
        this.disableButton = false;
        this.showError = true;
      console.log(error);
    });
    // this.closeModal(id);
  }

}
