<!-- <img *ngIf="!tile" class="tile" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/Bird_Loading.gif"/>         -->
<img *ngIf="!tile" class="tile" src="/assets/images/Bird_Loading.gif" />
<div *ngIf="tile && tile.tileType != 5 && tile.tileType != 8" class="tile">
  <!-- <a [href]="tile.url"> -->
    <div class="tile-shadow-box isMain-{{tile.isMain}}">
      <a [href]="tile.url.href" [target]="tile.url.isBlank? '_blank' : ''" [rel]="tile.url.isNoFollow ? 'nofollow' : ''">
      <div class="tile-img-wrapper isMain-{{tile.isMain}} tile-wrapper-flex full-div"> 
        <img *ngIf="tile.tileImage" class="tile-img full-div"
        [src]="tile.tileImage.path"
        [alt]="tile.tileImage.name"
        [title]="tile.title"
        />
        <div *ngIf="tile.tileType != 3" class="tile-caption tile-wrapper-flex full-div">
          <p class="tile-badge item-md-wrapper badge-text c-white t-b"><span class="tile-badge-txt tile-badge-color {{ tile.badgeColor }}">{{ tile.badge }}</span></p>
          <h2 class="title title-text c-white t-b">{{ tile.title}}</h2>
          <h3 class="sub-title sub-title-text c-white">{{ tile.subTitle | truncatetext: 70}}</h3>
          <div *ngIf="tile.metaData" class="tile-md-wrapper md-text c-white">
            <span class="tile-md border-{{this.dir}}">{{ tile.metaData }}</span>
          </div>
        </div>
      </div>
      </a>
    </div>
  <!-- </a> -->
</div>
<div *ngIf="tile && tile.tileType == 5 && cameras" class="tile">
  <div class="tile-shadow-box isMain-{{tile.isMain}}">
    <!-- <a [routerLink]="['/' + lang + '/cameras']"> -->
    <a [href]="tile.url.href" [target]="tile.url.isBlank? '_blank' : ''" [rel]="tile.url.isNoFollow ? 'nofollow' : ''">
      <div class="tile-img-wrapper isMain-{{tile.isMain}} tile-wrapper-flex full-div"> 
        <div *ngIf="cameras.length == 1">
          <app-online-camera *ngIf="cameras[0].isOnline" 
            [camId]="cameras[0].id" 
            [isMobile]="this.isMobile"  
            [camera]="cameras[0].broadcast"
            [playerType]="cameras[0].playerType"
            [wowzaLicense]="cameras[0].wowzaLicense"
            [controls]="false" 
            [mute]="true"
            [pauseTimeout]="5"
            [aspectratioW]="2.18"
            [aspectratioH]="2"
          ></app-online-camera>
          <div class="img-wrapper">
            <img *ngIf="!cameras[0].isOnline && OfflineCameraFile" class="cam-img" [src]="OfflineCameraFile.path" alt=""/>
          </div>
        </div>
        <div *ngIf="cameras.length == 2">
          <app-online-camera *ngIf="cameras[0].isOnline" 
            [camId]="cameras[0].id" 
            [isMobile]="this.isMobile"  
            [camera]="cameras[0].broadcast"
            [playerType]="cameras[0].playerType"
            [wowzaLicense]="cameras[0].wowzaLicense"
            [controls]="false" 
            [mute]="true"
            [pauseTimeout]="5"
            [aspectratioW]="2.18"
            [aspectratioH]="1"
          ></app-online-camera>
          <div class="img-wrapper">
            <img *ngIf="!cameras[0].isOnline && OfflineCameraFile" class="cam-img" [src]="OfflineCameraFile.path" alt=""/>
          </div>
          <app-online-camera *ngIf="cameras[1].isOnline" 
            [camId]="cameras[1].id" 
            [isMobile]="this.isMobile"  
            [camera]="cameras[1].broadcast"
            [playerType]="cameras[1].playerType"
            [wowzaLicense]="cameras[1].wowzaLicense"
            [controls]="false" 
            [mute]="true"
            [pauseTimeout]="5"
            [aspectratioW]="2.18"
            [aspectratioH]="1"
          ></app-online-camera>
          <div class="img-wrapper">
            <img *ngIf="!cameras[1].isOnline && OfflineCameraFile" class="cam-img" [src]="OfflineCameraFile.path" alt=""/>
          </div>
        </div>
        <!-- <img class="cameras-caption" src="../../../assets/images/HP-cameras_title.gif" alt=""/> -->
        <div class="cameras-caption c-blue t-b">{{ tile.title }}</div>
      </div>
    </a>
  </div>
</div>
<div *ngIf="tile && tile.tileType == 8 && chartData" class="tile">
  <div class="tile-shadow-box isMain-{{tile.isMain}}">
    <!-- <a [routerLink]="['/' + lang + '/cameras']"> -->
    <a [href]="tile.url.href" [target]="tile.url.isBlank? '_blank' : ''" [rel]="tile.url.isNoFollow ? 'nofollow' : ''">
      <div class="tile-img-wrapper isMain-{{tile.isMain}} tile-wrapper-flex full-div"> 
        <app-ringing-donut-chart class="chart-tile" [chartData]="chartData" [locId]=null [chartTitle]="tile.title"></app-ringing-donut-chart>
      </div>
    </a>
  </div>
</div>


