<!-- <section name="comments" class="full-div bottom-form-desktop">
  <div class="grid">
    <div class="comments {{ this.dir }}">
      <div class="comments-reply full-div">
        <h2 class="comments-title t-b">תגובות</h2>
        <span class="comments-line bg-dark-grey"></span>
      </div>
    </div>
    <div class="comments-form full-div {{ this.dir }}">
      <fb-comments width="100%"></fb-comments>
    </div>
  </div>
</section> -->

<div name="comments" class="full-div bottom-form-desktop">
  <app-side-pannel-title [title] = "title"></app-side-pannel-title>
  <div class="comments-form full-div {{ this.dir }}">
    <fb-comments width="100%"  [width]="'100%'" [orderBy]="'reverse_time'"></fb-comments> 
    <!-- [orderBy]="'reverse_time'" -->
  </div>
</div>
  