<div class="obs-tile">
  <div class="tile-shadow-box full-div">
    <!-- <a [routerLink]="['/'+tile.tileLinkType, tile.tileLinkID]"> -->
      <div *ngIf="obsTile" class="tile-box-flex" id="obsId{{obsTile.id}}" [attr.data-obsid]="obsTile.id" (click)="routeToObservation(obsTile.id)">
        <div class="tile-img-wrapper {{dir}}"> 
          <img *ngIf="obsTile.image" class="tile-img full-div"
          [src]="obsTile.image.path"
          [alt]="obsTile.image.name"
          [title]="obsTile.image.title"
          />
          <div *ngIf="!obsTile.image" class="bird-silhouette {{dir}}"></div>
        </div>
        <div class="tile-data-wrapper {{dir}}">
            <div class="tile-species-list base-t-16 t-b">{{ obsTile.speciesShortenList}}</div>
            <!-- <div class="tile-species-list base-t-16 t-b hide-mobile">{{ obsTile.speciesShortenList | truncatetext: 56}}</div>
            <div class="tile-species-list base-t-16 t-b hide-desktop">{{ obsTile.speciesShortenList | truncatetext: 28}}</div> -->
            <div class="tile-md-wrapper">
            <div *ngIf="withTruncate">
              <div class="obs-location base-t-16">{{ obsTile.locationName  | truncatetext: 28}}</div>
              <div class="obs-date-observer small-t-14">{{ obsTile.observationDate | date :'dd/MM/yyyy' }} ({{obsTile.observerName | truncatetext: 30}})</div>
            </div>
            <div *ngIf="!withTruncate">
              <div class="obs-location base-t-16">{{ obsTile.locationName}}</div>
              <div class="obs-date-observer small-t-14">{{ obsTile.observationDate | date :'dd/MM/yyyy' }} ({{obsTile.observerName}})</div>
            </div>
        </div>
      </div>
    <!-- </a> -->
  </div>
</div>

