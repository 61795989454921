<div class="read-more-desktop full-div hide-mobile" *ngIf="linkedEvents">
  <app-side-pannel-title [title] = "moreEventsTitle"></app-side-pannel-title>
  <div name="linked-events" class="linked-events-flex full-div">
    <div *ngFor="let item of evtBannerItems" >
      <app-item-banner [item]=item></app-item-banner>
    </div>
  </div>
</div>

<section class="full-div read-more-section hide-desktop" *ngIf="linkedEvents">
  <div class="grid">
    <app-side-pannel-title [title] = "moreEventsTitle"></app-side-pannel-title>
  </div>
  <div class="grid more-events-flex">
    <div *ngFor="let item of evtBannerItems" >
      <app-tile [tile]=item></app-tile>
      <!-- <app-item-banner [item]=item></app-item-banner> -->
    </div>    
  </div>
</section>
