import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { ArticleService } from './article.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { TextsResponse } from '../models/texts-response';
import { MenuEntry } from '../models/menu-entry';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ScreenSizes } from '../models/screen-sizes';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {
  private allTextsLoaded: Subject<boolean> = new Subject<boolean>();
  public allTextsLoaded$ = this.allTextsLoaded.asObservable();

  private screenSizesUpdated: Subject<boolean> = new Subject<boolean>();
  public screenSizesUpdated$ = this.screenSizesUpdated.asObservable();

  constructor(
    private articleService: ArticleService,
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  public currentDir(): string {
    return (this.currentLang() === 'he') ? 'R' : 'L';
  }

  public hasLang(): boolean {
    const lang = sessionStorage.getItem('CurrentLang');
    if (lang === null) {
      return false;
    }

    return true;
  }

  public currentLang(): string {

    let _currentLang = '';
    // debugger;
    const langIdStr = sessionStorage.getItem('CurrentLang');

    if (langIdStr !== null && (langIdStr.toString() === 'he' || langIdStr.toString() === 'en')) {
      _currentLang = langIdStr.toString();

    } else {
      _currentLang = 'he';
      // sessionStorage.setItem('CurrentLang', _currentLang.toString());
    }

    // console.log(_currentLang);
    return _currentLang;
  }

  private _dateFormat(): string {
    return this.currentLang() === 'he' ? 'dd/MM/yyyy' : 'MM-dd-yyyy';
  }

  public _locale(): string {
    return this.currentLang() === 'he' ? 'he-IL' : 'en-US';
  }

  public hasKeys(): boolean {
    if (localStorage.getItem('txt_he:allArticles') === null) {
      return false;
    }
    return true;
  }

  public checkVersion() {
    const localStorageVersion = localStorage.getItem('version');
    const version = environment.version;
    return localStorageVersion === version;
  }

  public updateVersion() {
    // clear local storage
    localStorage.clear();

    // load texts
    this.loadAllTexts();

  }

  public loadAllTexts() {
    this.http.get(this.apiService.getServerUrl() + '/api/system/texts')
      .subscribe((response: TextsResponse[]) => {
        response.forEach(item => {
          localStorage.setItem('txt_he:' + item.textID, item.textHe);
          localStorage.setItem('txt_en:' + item.textID, item.textEn);
        });

        this.allTextsLoaded.next(true);

        // set version key
        localStorage.setItem('version', environment.version);

      }, error => {
        console.log(error);
      });
  }

  public getTextById(id: string) {
    const _currentLang = this.currentLang();
    const key = 'txt_' + _currentLang + ':' + id;
    const text = localStorage.getItem(key);

    if (text === null || text === undefined) {
      return '';
    } else {
      return text;
    }
  }

  public getTextByIdAndLang(id: string, lang: string) {
    const key = 'txt_' + lang + ':' + id;
    const text = localStorage.getItem(key);

    if (text === null || text === undefined) {
      return '';
    } else {
      return text;
    }
  }

  public appComponentChangeLang(newLangStr: string): string {
    const _currentLang = this.currentLang();
    if (newLangStr !== 'he' && newLangStr !== 'en') {
      newLangStr = 'he';
    }

    if (_currentLang !== newLangStr) {
      sessionStorage.setItem('CurrentLang', newLangStr);
      sessionStorage.setItem('GoogleMapLang', (newLangStr === 'he') ? 'iw' : 'en');

      this.articleService.loadAllArticleTypes(_currentLang);
    }

    return newLangStr;
  }

  public showLongDate(inDate: Date): string {
    return formatDate(inDate, 'longDate', this._locale());
  }

  public showDate(inDate: Date): string {
    return formatDate(inDate, this._dateFormat(), this._locale());
  }

  public showDateRange(startDate: string | Date, endDate: string | Date, lang: string): string {
    if (startDate === endDate) {
      return formatDate(startDate, this._dateFormat(), this._locale());
    } else if (lang === 'he') {
      return formatDate(endDate, this._dateFormat(), this._locale()) + ' - ' + formatDate(startDate, this._dateFormat(), this._locale());
    } else {
      return formatDate(startDate, this._dateFormat(), this._locale()) + ' - ' + formatDate(endDate, this._dateFormat(), this._locale());
    }
  }

  public isIsrael(): boolean {
    this.http.get(this.apiService.getServerUrl() + '/api/system/country/IL')
      .subscribe((response: boolean) => {
        return response;
    }, error => {
      console.log(error);
      return true;
    });
    return true;
  }

  public setScreenSizes(screenSizes: ScreenSizes) {
    if (screenSizes.isMobile) {
      sessionStorage.setItem('ScreenSize', 'mobile');
    }
    if (screenSizes.isTablet) {
      sessionStorage.setItem('ScreenSize', 'tablet');
    }
    if (screenSizes.isDesktop) {
      sessionStorage.setItem('ScreenSize', 'desktop');
    }

    this.screenSizesUpdated.next(true);
  }

  public getScreenSizes(): ScreenSizes {
    const screenSizes = new ScreenSizes();
    screenSizes.isMobile = false;
    screenSizes.isTablet = false;
    screenSizes.isDesktop = false;

    switch (sessionStorage.getItem('ScreenSize')) {
      case 'mobile':
        screenSizes.isMobile = true;
        break;
      case 'tablet':
        screenSizes.isTablet = true;
        break;
      case 'desktop':
        screenSizes.isDesktop = true;
        break;
      default:
        screenSizes.isDesktop = true;
        break;
    }

    return screenSizes;
  }
}
