<div class="tile">
    <a [routerLink]="['/' + lang + '/' + tile.tileLinkType, tile.tileLinkID]">
      <div *ngIf="tile" class="tile-shadow-box tile-box-flex">
        <div class="tile-img-wrapper"> 
          <img *ngIf="tile.image" class="tile-img {{dir}} full-div"
          [src]="tile.image.path"
          [alt]="tile.image.name"
          [title]="tile.title"
          />
          <!-- <div class="tile-caption tile-wrapper-flex full-div">
            <p class="tile-badge item-md-wrapper base-t-16 c-white t-b {{ tile.badge }}-{{ lang }}"></p>
          </div> -->
        </div>
        <div class="tile-data-wrapper">
          <div class="title-wrapper">
            <h2 class="tile-title title-t-25 t-b hide-mobile">{{ tile.title }}</h2>
            <div class="tile-md-wrapper base-t-16 hide-mobile">
              <span class="tile-md border-{{this.dir}} italic-{{tile.italicMD1}}" *ngIf="tile.showMD1">{{ tile.metaData1 }}</span>
              <span class="tile-md italic-{{tile.italicMD1}}" *ngIf="tile.showMD2">{{ tile.metaData2 }}</span>
            </div>
          </div>  
          <div class="tile-text base-t-18 hide-mobile" [innerHTML]="tile.shortDescription | truncatetext: viewWidth"></div>
          <div class="tile-bottom">
            <div class="tile-tags">
              <span *ngFor="let tag of tile.tags; let i=index;">
                <span class="tag-padding small-t-12" *ngIf="i<5">         
                  <i class="fas fa-tag c-green"></i>
                  {{ tag.name }}
                </span>
              </span>  
            </div>
            <div class="tile-goto-item">
              <a [routerLink]="['/' + lang + '/' + tile.tileLinkType, tile.tileLinkID]"><span><i class="read-more-icon fas fa-angle-double-left dir {{ this.dir }}" *ngIf="this.dir === 'R'" title="להמשך קריאה"></i></span></a>
              <a [routerLink]="['/' + lang + '/' + tile.tileLinkType, tile.tileLinkID]"><span><i class="read-more-icon fas fa-angle-double-right dir-op {{ this.dir }}" *ngIf="this.dir === 'L'" title="להמשך קריאה"></i></span></a>
            </div>
          </div>
        </div>
      </div>
    </a>
</div>
