<section name="blogs-lobby-section" class="full-div">
    <div class="grid" style="overflow: initial;">
      <div class="main-wrapper main-lobby-wrapper full-div">
        <div class="header-filter">
          <h1>{{ this.lobbyTitle }}</h1>
          <!-- <app-filter-bar (filterTextSync) = "onFilterTextChange($event)"></app-filter-bar> -->
        </div>
        <div *ngIf="blogs"> 
          <div name="blogs" class="blogs-flex grid">
            <div *ngFor="let blgTile of blgTiles  | filter : filterText" >
              <app-tile [tile]=blgTile></app-tile>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>