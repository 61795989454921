import { Component, OnInit, Input } from '@angular/core';
import { ArticleTypeResponse } from 'src/app/models/article-type-response';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { ArticleService } from 'src/app/_services/article.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-article-types-menu',
  templateUrl: './article-types-menu.component.html',
  styleUrls: ['./article-types-menu.component.css']
})
export class ArticleTypesMenuComponent implements OnInit {
  @Input() selectedTypeId: number;
  artTypes: ArticleTypeResponse[] = [];
  lang: string;
  dir: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private articleService: ArticleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    // business logic what changes you want on the page with this new data.

    this.getArticleTypes();
  }

  private getArticleTypes() {
    this.artTypes = this.articleService.getMenuTypes(this.lang);

    // this.http.get(this.apiService.getServerUrl() + '/api/articles/typesMenu/' + this.lang)
    //   .subscribe((response: ArticleTypeResponse[]) => {
    //     this.artTypes = response;
    //   },
    //   error => {
    //     console.log(error);
    //     if (error.status === 404) {
    //       this.router.navigate([this.lang, 'not-found']);
    //     } else {
    //       this.router.navigate([this.lang, 'error']);
    //     }
    // });
  }

  public routeToType(slug: string) {
    if (slug) {
      this.router.navigate(['/' + this.lang + '/articles/type/' + slug]);
    }
  }
}
