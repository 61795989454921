import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { Router } from '@angular/router';
import { DonutChartConfig } from 'src/app/models/donut-chart-config';
import { DatePipe } from '@angular/common';
import { RingingChartResponse } from 'src/app/models/ringing-chart-response';

// https://www.tutorialspoint.com/angular_googlecharts/angular_googlecharts_quick_guide.htm

@Component({
  selector: 'app-ringing-donut-chart',
  templateUrl: './ringing-donut-chart.component.html',
  styleUrls: ['./ringing-donut-chart.component.css']
})
export class RingingDonutChartComponent implements OnInit {
  @Input() locId: number;
  @Input() chartData: RingingChartResponse;
  @Input() chartTitle: string;  
  chartDataDate: Date;
  // date: Date;
  elementId = 'dailyRingingChart';
  lang: string;
  dir: string;
  data: any[] = [];
  chartConfig: DonutChartConfig;
  columnNames: string[];
  type = 'PieChart';
  title = 'Donut';
  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    if (this.chartData != null) {
        this.data = this.chartData.data;
        this.chartDataDate = this.chartData.date;
        this.columnNames = ['Species', 'Count'];
        this.chartConfig = new DonutChartConfig();
    } else if (this.locId != null) {
        this.getChartData();
    }
  }

  private getChartData() {

    this.http.get(this.apiService.getServerUrl() +
        '/api/Observation/' + this.lang + '/ringing/' + this.locId).subscribe((response: RingingChartResponse) => {
        this.columnNames = ['Species', 'Count'];
        this.chartConfig = new DonutChartConfig();
        this.data = response.data;
        this.chartDataDate = response.date;
      },
      error => {
        console.log(error);
        if (error.status === 404) {
          this.router.navigate([this.lang, 'not-found']);
        } else {
          this.router.navigate([this.lang, 'error']);
        }
      });
  }
}
