<section name="camera-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-camera-wrapper full-div">
      <div *ngIf="camera" class="full-div {{this.dir}}">
        <h1 class="camera-name t-b">{{ camera.name }}</h1>
        <app-side-pannel-title [title]="camera.titlePre"></app-side-pannel-title>
        <div class="cam-txt full-div">
          <slot [innerHTML]="camera.descriptionAbove | imgHost | safe"></slot>
        </div>
        <div *ngIf="this.screenSizes.isDesktop">
          <div *ngIf="broadcasts.length > 1" class="full-div cams-tabs">
            <div *ngFor="let c of broadcasts; let i = index">
              <div class="cams-tab" (click)="onNavigateFromCamTab(i)">
                <span class="fa-stack fa-2x">
                  <i class="fas fa-video fa-flip-horizontal fa-stack-2x c-green"></i>
                  <span style="top:15px; right:8px; position:absolute;" class="fa c-white" [innerHTML]="i+1"></span>
                </span>
              </div>
            </div>
          </div>
          <div *ngFor="let broadcast of broadcasts; let i = index">
            <app-online-camera *ngIf="camera.isOnline && !camera.isError" class="full-div online-camera cam-index-{{i}}" [isMobile]="false" [camId]="camera.id+'_'+i" 
              [camera]="broadcast" [pauseTimeout]="pauseTimeout" [wowzaLicense]="camera.wowzaLicense" [playerType]="camera.playerType">
            </app-online-camera>
          </div>
        </div>
        <div *ngIf="this.screenSizes.isMobile || this.screenSizes.isTablet">
          <div *ngFor="let broadcast of broadcasts; let i = index">
            <app-online-camera *ngIf="camera.isOnline && !camera.isError" class="full-div online-camera" [isMobile]="true" [camId]="camera.id+'_'+i" 
              [camera]="camera.broadcast" [pauseTimeout]="pauseTimeout" [wowzaLicense]="camera.wowzaLicense" [playerType]="camera.playerType">
            </app-online-camera>
          </div>
        </div>
        <!-- <app-side-pannel-title [title] = "camera.titlePost"></app-side-pannel-title>
        <div class="inner-html-txt full-div">
          <slot [innerHTML]="camera.descriptionPost | imgHost | safe"></slot>
        </div> -->
        <div class="cam-txt full-div">
          <slot [innerHTML]="camera.descriptionBelow | imgHost | safe"></slot>
        </div>
      </div>
      <app-side-pannel-title id="nestingBlogTitle" *ngIf="post" [title] = "nestingBlogTitle"></app-side-pannel-title>
      <div class="hide-mobile">
        <div class="third-div {{this.dir}} posts-tree">
          <app-more-posts-tree *ngIf="allBlogPosts && allBlogPosts.length > 0" 
            [allBlogPosts]=allBlogPosts 
            [post]=post
            (blogAndPostIds) = "onNavigateFromTree($event)"
            ></app-more-posts-tree>
        </div>
        <div *ngIf="post" class=" blogPost-main two-third-div {{this.dir}}">
          <div class="post-subtxt full-div hide-mobile">
            <h1 class="post-title t-b">{{ post.title }}</h1>
          </div>
          <app-side-pannel-title class="hide-mobile"  [width]="60"></app-side-pannel-title>
          <div class="base-t-16">{{ post.date | date:'dd/MM/yyyy':this._locale }}</div>
          <div class="inner-html-txt full-div">
            <slot [innerHTML]="post.content | imgHost | safe"></slot>
          </div>
          <app-comments></app-comments>
        </div>
        <app-side-pannel-title class="hide-mobile" [width]="100"></app-side-pannel-title>
        <div *ngIf="camera && camera.descriptionLast" class="cam-txt full-div">
          <slot [innerHTML]="camera.descriptionLast | imgHost | safe"></slot>
        </div>
      </div>
      <div class="hide-desktop">
        <div *ngIf="post" class=" blogPost-main full-div {{this.dir}}">
          <div class="post-subtxt full-div hide-mobile">
            <h1 class="post-title t-b">{{ post.title }}</h1>
          </div>
          <app-side-pannel-title class="hide-mobile"  [width]="60"></app-side-pannel-title>
          <div class="base-t-16">{{ post.date | date:'dd/MM/yyyy':this._locale }}</div>
          <div class="inner-html-txt full-div">
            <slot [innerHTML]="post.content | imgHost | safe"></slot>
          </div>
        </div>
        <div class="full-div {{this.dir}} posts-tree" *ngIf="allBlogPosts && allBlogPosts.length > 0">
          <app-side-pannel-title [title] = "this.morePostsTitle"></app-side-pannel-title>
          <app-more-posts-tree 
            [allBlogPosts]=allBlogPosts 
            [post]=post
            (blogAndPostIds) = "onNavigateFromTreeMobile($event)"
            ></app-more-posts-tree>
        </div>
        <app-comments></app-comments>
        <app-side-pannel-title class="hide-desktop"  [width]="100"></app-side-pannel-title>
        <div *ngIf="camera && camera.descriptionLast" class="cam-txt full-div">
          <slot [innerHTML]="camera.descriptionLast | imgHost | safe"></slot>
        </div>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>
