import { Component, OnInit } from '@angular/core';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/_services/language.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private router: Router,
    private languageService: LanguageService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const lang = this.languageService.currentLang();
    this.router.navigate([lang, '/article', 'אודות-הפורטל']);
  }

}
