<section name="events-lobby-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-lobby-wrapper full-div">
      <h1>{{ this.lobbyTitle }}</h1>
      <div *ngIf="this.lang === 'en'">
        <slot [innerHTML]="lobbyDescriptionEn | safe"></slot>
      </div>
      <div *ngIf="events" name="events" class="events-flex full-div"> 
        <div *ngFor="let evTile of evTiles" >
          <app-h-tile class="full-div hide-mobile" [tile]=evTile></app-h-tile>
          <app-tile class="hide-desktop" [tile]=evTile></app-tile>
        </div>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>