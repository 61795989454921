<section name="contactus-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-contactus-wrapper full-div">      
      <h1>{{contactUsTitle}}</h1>
      <div class="third-div {{dir}}">
        <h2 class="t-b">{{contactUsIOC}}</h2>
        <div class="contact-details c-green"><i class="fas fa-map-marker-alt link-padding"></i>{{contactUsAddress}}</div>
        <div class="contact-details c-green"><i class="fas fa-phone"></i><a class="c-green link-padding" href="tel:036388750"
            target="_blank">{{contactUsPhone}}</a></div>
        <div class="contact-details c-blue"><i class="fab fa-facebook-f"></i><a class="c-blue link-padding"
            href="https://www.facebook.com/birds.org.il/" target="_blank">{{contactUsFacebook}}</a></div>
        <div class="contact-details c-blue"><i class="fas fa-at"></i>
          <app-register-newsletter [modalId]="modalId" class="link-padding"></app-register-newsletter> 
        </div>
      </div>
      <div class="two-third-div {{dir}}">
        <form #f="ngForm" (ngSubmit)="sendMessage(f)" class="form-inline contactus-flex-box" >
          <input type="text" class="form-control" id="name" name="name" placeholder="{{contactUsFullName}}" 
            required minlength="4"
            [(ngModel)]="model.name" #name="ngModel">
          <span *ngIf="name.invalid && (name.dirty || name.touched) && name.errors.required" class="error">{{errRequired}}</span>
          <span *ngIf="name.invalid && (name.dirty || name.touched) && name.errors.minlength" class="error">{{errShort}}</span>
          <input type="email" class="form-control" id="email" name="email" placeholder="{{contactUsEmail}}" 
            required email
            [(ngModel)]="model.email" #email="ngModel">
          <span *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required" class="error">{{errRequired}}</span>
          <span *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email" class="error">{{errEmail}}</span>
          <input type="text" class="form-control" id="subject" name="subject" placeholder="{{contactUsSubject}}" 
            required
            [(ngModel)]="model.subject" #subject="ngModel">
            <span *ngIf="subject.invalid && (subject.dirty || subject.touched) && subject.errors.required" class="error">{{errRequired}}</span>
          <textarea rows="10" class="form-control" id="message" name="message" placeholder="{{contactUsMessage}}" 
            required
            minlength="20" [(ngModel)]="model.message" #message="ngModel"></textarea>
          <span *ngIf="message.invalid && (message.dirty || message.touched) && message.errors.required" class="error">{{errRequired}}</span>
          <span *ngIf="message.invalid && (message.dirty || message.touched) && message.errors.minlength" class="error">{{errShort}}</span>  
          
          <div (click)="addFiles()" class="full-div {{dir}} hand t-u c-blue add-images-div"><i class="far fa-images images-icon"></i><span class="add-images">{{addImagesBtn}}</span></div>
          <app-image-browser *ngIf="addFilesFlag" [files]="attachments" class="full-div"></app-image-browser>

          <button type="submit" [disabled]="!f.valid && inSend" id="submit">{{contactUsSubmit}}</button>
          <div *ngIf="sendResultMsg" class="send-result {{sendResult}} t-b">{{sendResultMsg}}</div>
        </form>
      </div>
    </div>
  </div>
</section>