import { Component, Inject, PLATFORM_ID, APP_ID, OnInit, HostListener, AfterViewInit, ViewChild } from '@angular/core';
import { Breadcrumbs } from './models/breadcrumbs';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from './_services/language.service';
import { ArticleService } from './_services/article.service';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ScreenSizes } from './models/screen-sizes';
import { FooterComponent } from './_components/footer/footer.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.fonts.css', './app.component.css']
})


export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(FooterComponent) footer: FooterComponent;
  title = 'app';
  lang: string;
  dir: string;
  screenSizes: ScreenSizes;
  path: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    private languageService: LanguageService,
    private articleServices: ArticleService,
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {
  }

  ngOnInit() {

    this.path = window.location.pathname;
    let _lang: string;

    if (this.path.indexOf('/en') === 0) {
      _lang = 'en';
    } else {
      _lang = 'he';
    }

    this.languageService.appComponentChangeLang(_lang);
    this.lang = this.languageService.currentLang();

    this.dir = this.languageService.currentDir();

    if (this.languageService.checkVersion() === false) {
      this.languageService.updateVersion();
      this.articleServices.loadAllArticleTypes(this.lang);
    } else {
      if (this.languageService.hasKeys() === false) {
        this.languageService.loadAllTexts();
      }
    }
    if (this.articleServices.hasKeys() === false) {
      this.articleServices.loadAllArticleTypes(this.lang);
    }

    // Google Tag Manager
    //  https://medium.com/quick-code/set-up-analytics-on-an-angular-app-via-google-tag-manager-5c5b31e6f41
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }


  @HostListener('window:resize', [])
  onResize() {
    this.screenSizes = new ScreenSizes();
    this.screenSizes.isMobile = false;
    this.screenSizes.isTablet = false;
    this.screenSizes.isDesktop = false;

    const width = window.innerWidth;
    if (width <= 452) {
      this.screenSizes.isMobile = true;
    } else if (width <= 768) {
      this.screenSizes.isTablet = true;
    } else {
      this.screenSizes.isDesktop = true;
    }
    // console.log('mobile:' + this.screenSizes.isMobile + ' tablet:' + this.screenSizes.isTablet + ' desktop:' + this.screenSizes.isDesktop);

    this.languageService.setScreenSizes(this.screenSizes);
  }


  onActivate(event: any) {
    // debugger;
    if (isPlatformBrowser(this.platformId)) {
      const scrollToTop = window.setInterval(() => {
        const pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }

  }

  ngAfterViewInit(): void {
    this.onResize();
    if (this.path === '/he/reg' || this.path === '/en/reg') {
      this.footer.regNl.openModal('register-modal-footer');
    }
  }

}

