import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RingRangeStatusResponse } from '../models/ring-range-status-response';
import { RingerResponse } from '../models/ringer-response';
import { RingingYearResponse } from '../models/ringing-year-response';
import { ApiService } from './api.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class RingsService {

  constructor(private http: HttpClient,
    private apiService: ApiService,
    private languageService: LanguageService) { }

  public getRingers(): Observable<RingerResponse[]> {
    return this.http.get<RingerResponse[]>(this.apiService.getServerUrl() + '/api/ringer/ringers');
  }

  public getRingingYears(): Observable<RingingYearResponse[]>{
    return this.http.get<RingingYearResponse[]>(this.apiService.getServerUrl() + '/api/ringer/years');
  }

  public getRingingYearData(year: Number, excelContentType: string): Observable<any> {
    return this.http.get(this.apiService.getServerUrl() + `/api/ringer/year/${this.languageService.currentLang()}/${year}/data`,
      { responseType: 'blob' });
  }

  public checkRings(ringType: string, rangeStart: number, rangeEnd: number, ringOwnerId: number) {
    const url = this.apiService.getServerUrl() + `/api/rings/${ringType}/${rangeStart}/${rangeEnd}/${ringOwnerId}`;
    return this.http.get<RingRangeStatusResponse>(url);    
  }

  public transferRings(ringType: string, rangeStart: number, rangeEnd: number, ringOwnerId: number, rangeAction: string) {
    const url = this.apiService.getServerUrl() + '/api/rings';
    const body = {
      ringType: ringType,
      ringSerialNumberFrom: rangeStart,
      ringSerialNumberTo: rangeEnd,
      ringOwnerId: ringOwnerId,
      rangeAction: rangeAction
    };

    return this.http.post<RingRangeStatusResponse>(url, body);
  }
}