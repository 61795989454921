<div class="main-wrapper main-species-wrapper full-div" *ngIf="species">
  <div class="third-div  {{this.dir}}">
    <app-side-pannel-title [title] = "species.name"></app-side-pannel-title>
    <div class="species-subtxt full-div hide-mobile">
      <h3 class="title-t-22">{{ species.shortDescription }}</h3>
    </div>
    <div class="species-subtxt hide-desktop full-div">
      <h2>{{ species.shortDescription }}</h2>
    </div>    
    <app-social-shares class="hide-mobile"></app-social-shares>
    <app-linked-articles class="hide-mobile" 
      *ngIf="species && species.linkedArticles && species.linkedArticles.length > 0" 
      [linkedArticles]="species.linkedArticles" 
      [lang]="lang">
    </app-linked-articles>
    <div *ngIf="lang == 'he'" class="hide-mobile">
      <!-- dont display rtg redbook on english because they dont support eng -->
      <app-rtg-redbook  class="full-div" *ngIf="species && species.showRedBook" [redbookTitle]="redbookTitle" [speciesName]="species.name"></app-rtg-redbook>
    </div>
    <app-links class="hide-mobile" *ngIf="species && species.links && species.links.length > 0" [links]="species.links" [linksTitle]="species.moreLinkesTitle"></app-links>
  </div>
  <div class=" species-main two-third-div  {{this.dir}}">
    <div class="hide-mobile">
      <div class="image-header-flex">
        <div name="species-header" *ngIf="!imgGalleryState && species && species.largeImageUrl === null" class="species-img-container small-image full-div">
          <div *ngIf="species && !species.largeImageUrl && species.imageUrl">
            <img
              [src]="species.imageUrl.path"
              [alt]="species.imageUrl.name"
              [title]="species.imageUrl.title"
              class="species-img small-image full-div"
            />
          </div>
          <div class="image-element">
            <div class="media-btn-container">
              <div *ngIf="species && species.images && species.images.length > 1" class="media-btn image-gallery-btn hand" (click)="routeToMoreInfo('sec-gallery')"><i class="fas fa-camera media-btn-icon"></i></div>
              <div *ngIf="species && species.sounds && species.sounds.length > 0" class="media-btn audio-gallery-btn hand" (click)="routeToMoreInfo('sec-audios')"><i class="fas fa-volume-up media-btn-icon"></i></div>
              <div *ngIf="species && species.videos && species.videos.length > 0" class="media-btn video-gallery-btn hand" (click)="routeToMoreInfo('sec-videos')"><i class="fab fa-youtube media-btn-icon"></i></div>
            </div>
            <div>
              <p class="species-title-note {{this.dir}} c-white t-b species-type-caption ">{{ this.species.speciesFamilyName }}</p>
              <div (contextmenu)="copySpeciesNames($event)">
                <h1 class="species-title  {{this.dir}} c-white t-b">{{ this.species.name }}</h1>
                <div class="img-md-wrapper base-t-16 c-white">
                  <h2 class="img-md border-{{this.dir}} italic-true">{{ this.species.latinName }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div name="species-header" *ngIf="!imgGalleryState && species && species.largeImageUrl !== null" class="species-img-container full-div">
          <div *ngIf="species && species.largeImageUrl">
            <img
              [src]="species.largeImageUrl.path"
              [alt]="species.largeImageUrl.name"
              [title]="species.largeImageUrl.title"
              class="species-img full-div"
            />
          </div>
          <div class="image-element">
            <div class="media-btn-container">
              <!-- <div *ngIf="species && species.images && species.images.length > 1" class="img-tooltip hand" (click)="imgGalleryOn()">+ {{species.images.length}} {{moreImagesCaption}}</div> -->
              <div *ngIf="species && species.images && species.images.length > 1" class="media-btn image-gallery-btn hand" (click)="routeToMoreInfo('sec-gallery')"><i class="fas fa-camera media-btn-icon"></i></div>
              <div *ngIf="species && species.sounds && species.sounds.length > 0" class="media-btn audio-gallery-btn hand" (click)="routeToMoreInfo('sec-audios')"><i class="fas fa-volume-up media-btn-icon"></i></div>
              <div *ngIf="species && species.videos && species.videos.length > 0" class="media-btn video-gallery-btn hand" (click)="routeToMoreInfo('sec-videos')"><i class="fab fa-youtube media-btn-icon"></i></div>
            </div>
            <div>
              <p class="species-title-note {{this.dir}} c-white t-b species-type-caption ">{{ this.species.speciesFamilyName }}</p>
              <h1 class="species-title  {{this.dir}} c-white t-b">{{ this.species.name }}</h1>
              <div class="img-md-wrapper base-t-16 c-white">
                <h2 class="img-md border-{{this.dir}} italic-true">{{ this.species.latinName }}</h2>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hide-desktop">
      <div class="image-header-flex">
        <div name="species-header" *ngIf="!imgGalleryState && species" class="species-img-container small-image full-div">
          <div *ngIf="species && species.imageUrl">
            <img
              [src]="species.imageUrl.path"
              [alt]="species.imageUrl.name"
              [title]="species.imageUrl.title"
              class="species-img small-image mobile-image full-div"
            />
          </div>
          <div class="image-element">
            <div class="media-btn-container">
              <!-- <div *ngIf="species && species.images && species.images.length > 1" class="img-tooltip hand" (click)="imgGalleryOn()">+ {{species.images.length}} {{moreImagesCaption}}</div> -->
              <div *ngIf="species && species.images && species.images.length > 1" class="media-btn image-gallery-btn hand" (click)="routeToMoreInfo('sec-gallery')"><i class="fas fa-camera media-btn-icon"></i></div>
              <div *ngIf="species && species.sounds && species.sounds.length > 0" class="media-btn audio-gallery-btn hand" (click)="routeToMoreInfo('sec-audios')"><i class="fas fa-volume-up media-btn-icon"></i></div>
              <div *ngIf="species && species.videos && species.videos.length > 0" class="media-btn video-gallery-btn hand" (click)="routeToMoreInfo('sec-videos')"><i class="fab fa-youtube media-btn-icon"></i></div>
            </div>
            <div>
              <p class="species-title-note {{this.dir}} c-white t-b species-type-caption ">{{ this.species.speciesFamilyName }}</p>
              <h1 class="species-title  {{this.dir}} c-white t-b">{{ this.species.name }}</h1>
              <div class="img-md-wrapper base-t-16 c-white">
                <h2 class="img-md border-{{this.dir}} italic-true">{{ this.species.latinName }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-image-gallery *ngIf="imgGalleryState" [title]= "article.title" [images]="article.images" [imgGalleryState]="this.imgGalleryState" (imgGalleryParentState) = "onImgGalleryStateOff($event)" [badge]="'article.articleSlug'"></app-image-gallery> -->
    <!-- <div class="tabs-row full-div">
      <div class="tab title-t-22 tab-description selected"><i class="fas fa-file-alt c-orange"></i> {{ tabDescription }}</div>
      <div class="tab title-t-22 tab-data"><i class="fas fa-chart-bar c-green"></i> {{ tabTrends }}</div>
      <div class="tab title-t-22 tab-more-info"><i class="fas fa-info-circle c-brown"></i> {{ tabMoreInfo }}</div>
    </div> -->
    <!-- <div class="species-subtxt full-div hide-mobile">
      <h2>{{ species.shortDescription }}</h2>
    </div> 
    <app-side-pannel-title class="hide-mobile" [title] = "" [width]="30"></app-side-pannel-title> -->
    <div class="inner-html-txt full-div">
      <slot [innerHTML]="species.description | imgHost | safe"></slot>
    </div>
    <app-social-shares></app-social-shares>
    <!-- <app-comments *ngIf="article.fbflag"></app-comments> -->
  </div>
</div>

<!-- <section name="species-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-species-wrapper full-div">
    </div>
  </div>
</section> -->
<div *ngIf="lang == 'he'" class="hide-desktop">
  <!-- dont display rtg redbook on english because they dont support eng -->
  <app-rtg-redbook  class="full-div" *ngIf="species && species.showRedBook" [redbookTitle]="redbookTitle" [speciesName]="species.name"></app-rtg-redbook>
</div>

<app-linked-articles class="hide-desktop" 
*ngIf="species && species.linkedArticles && species.linkedArticles.length > 0" 
[linkedArticles]="species.linkedArticles" 
[lang]="lang"></app-linked-articles>

<app-links class="hide-desktop" *ngIf="species && species.links && species.links.length > 0" [links]="species.links" [linksTitle]="species.moreLinkesTitle"></app-links>
