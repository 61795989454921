<header class="full-div bg-dark-grey">
    <div class="grid">
      <div class="top-header full-div">
        <div class="donate">
          <a [routerLink]="['/' + lang + '/externalRedirect/' + externalDonationUrl ]" class="c-white">
            <span> {{donateLabel}} </span>
          </a>
        </div>
        <div class="text-center">
            <a [routerLink]="['/' + lang + '/']">
              <img *ngIf="lang == 'he'"
                src="../assets/images/israeli_birds_logo-white_500x120px.png"
                alt=""
                class="top-header-logo {{this.lang}} responsive-img"
              />
              <!-- <img *ngIf="lang == 'he'"
                src="https://d3np07p3hj9hly.cloudfront.net/assets/images/israeli_birds_logo-white_500x120px.png"
                alt=""
                class="top-header-logo {{this.lang}} responsive-img"
              /> -->
              <img *ngIf="lang == 'en'"
                src="../assets/images/BirdlifeIsraelLogo2.png"
                alt=""
                class="top-header-logo {{this.lang}} responsive-img"
              />
              <!-- <img *ngIf="lang == 'en'"
                src="https://d3np07p3hj9hly.cloudfront.net/assets/images/BirdlifeIsraelLogo2.png"
                alt=""
                class="top-header-logo {{this.lang}} responsive-img"
              /> -->
            </a>
        </div>
        <div class="lang-wrapper">
          <span *ngIf="lang === 'en'" (click)="lang === 'en' && toggleLanguage('he')" class="t-b dir active">עברית</span>
          <span *ngIf="lang === 'he'" (click)="lang === 'he' && toggleLanguage('en')" class="t-b dir active">English</span>
          <!-- <span (click)="lang === 'en' && toggleLanguage('he')" [ngClass]="(lang === 'he' ? 't-b dir active' : 'hand t-b dir inactive')">He</span>
          <span class="dir c-white">&nbsp;|&nbsp;</span>
          <span (click)="lang === 'he' && toggleLanguage('en')" [ngClass]="(lang === 'en' ? 't-b dir active' : 'hand t-b dir inactive')">En</span> -->
    </div>
      </div>
    </div>
</header>