import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArticleTileResponse } from '../../models/article-tile-response';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Tile } from '../../models/tile';
import { TileComponent } from '../../_components/tile/tile.component';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { ArticleService } from '../../_services/article.service';
import { LoadingAnimationService } from '../../_services/loading-animation.service';
import { BirdingCenterTileResponse } from '../../models/birding-center-tile-response';

@Component({
  selector: 'app-birding-centers',
  templateUrl: './birding-centers.component.html',
  styleUrls: ['./birding-centers.component.css']
})
export class BirdingCentersComponent implements OnInit {
  birdingCenters: BirdingCenterTileResponse[];
  lang: string;
  bcTiles: Tile[] = [];
  lobbyTitle: string;

  constructor(private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    // private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {

    this.lang = this.languageService.currentLang();

    this.bcTiles = []; // clear tile array on routing between types
    this.languageService.allTextsLoaded$.subscribe(ready => {
      if (ready) {
        this.getBirdingCenters();
      }
    });

    if (this.languageService.hasKeys()) {
      this.getBirdingCenters();
    }
  }

  private getBirdingCenters() {
    this.lobbyTitle = this.languageService.getTextById('baseBirdingCentersLobbyTitle');
    let serviceUrl: string;

    this.loadingAnimationService.publishLoadingAnimation(true);

    serviceUrl = this.apiService.getServerUrl() + '/api/birdingcenters/' + this.lang;

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: BirdingCenterTileResponse[]) => {
          if (response !== undefined) {
            this.birdingCenters = response;

            if (this.birdingCenters) {
              this.createBCTiles();
            }

            // prepare the breadcrumbs
            this.bcService.clearLevels();
            this.bcService.setLastItemTitle(this.languageService.getTextById('baseBirdingCentersTitle'));

            const pageTitle = this.languageService.getTextById('baseBirdingCentersTitle')
                              + ' - ' + this.languageService.getTextById('baseSeoTitle');
            const pageImage = this.birdingCenters[0].imageUrl && this.birdingCenters[0].imageUrl.path; // get image from first
            const keywords = this.languageService.getTextById('baseBirdingCentersTitle')
                              + ',' + this.languageService.getTextById('baseSeoKeywords');
            let description = this.languageService.getTextById('baseBirdingCentersTitle');
            description += ',' + this.languageService.getTextById('baseSeoDescription');
            const pagePath = '/birding-centers';
            const pageUrl = location.origin + pagePath;
            const canonicalUrl = location.origin + '/' + this.lang + pagePath;

            const pageType = this.languageService.getTextById('baseBirdingCentersTitle');

            this.bcService.seoItems(pageTitle, keywords, description, pageImage, pageUrl, pageType, canonicalUrl);

            // publish
            this.bcService.publishBreadcrumbs();

            this.loadingAnimationService.publishLoadingAnimation(false);
          }
        }, error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
    }
  }

  private createBCTiles() {
    this.birdingCenters.forEach(bc => {
      if (this.bcTiles.some(item => item.tileLinkID === bc.id.toString())) {
        return;
      }
      const tile: Tile = {
        image: bc.imageUrl,
        title: bc.title,
        metaData1: bc.poc,
        metaData2: bc.phone,
        showMD1: true,
        showMD2: true,
        italicMD1: false,
        italicMD2: false,
        filterData: null,
        shortDescription: bc.shortDescription,
        tags: bc.tags,
        // badge: this.articleService.getSlugById(article.typeId),
        badge: this.languageService.getTextById('baseBirdingCenterTitle'),
        badgeColor: 'brown',
        tileLinkType: 'birding-center',
        tileLinkID: bc.slug
      };

      this.bcTiles.push(tile);
    });
  }

  // When scroll down the screen
  // private onScroll() {
  //   this.page = this.page + 1;
  //   this.getArticles();
  // }

  // onSelect(article: ArticleTileResponse) {
  //   this.router.navigate(['/article', article.id]);
  // }
}

