import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rtg-redbook',
  templateUrl: './rtg-redbook.component.html',
  styleUrls: ['./rtg-redbook.component.css']
})
export class RtgRedbookComponent implements OnInit {
  @Input() speciesName: string;
  @Input() redbookTitle: string;
  _speciesName: string;
  redbookHref: string;
  redbookTileSrc: string;

  constructor() { }

  ngOnInit() {

    this._speciesName = this.speciesName.replace(' ', '-');
    // RedBookMainLink.HRef = string.Format(Helpers.GenericHelper.GetLocalResourceString(GetLocalResourceObject("RedbookMainLink")));
    this.redbookHref = 'https://aves.redlist.parks.org.il/birds/' + this._speciesName + '/';
    this.redbookTileSrc = 'https://aves.redlist.parks.org.il/he/birds/' + this._speciesName + '/tile/';
  }

}
