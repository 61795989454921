import { Component, OnInit } from '@angular/core';
import { BlogResponse } from 'src/app/models/blog-response';
import { Tile } from 'src/app/models/tile';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ArticleService } from 'src/app/_services/article.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BlogPostMonthlyGroupedResponse } from 'src/app/models/blog-post-monthly-grouped-response';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  blogs: BlogResponse[];
  lang: string;
  _typeSlug: string;
  _typeName: string;
  _typeId: number;
  _author: string;
  blgTiles: Tile[] = [];
  lobbyTitle: string;
  filterText: string;

  constructor(private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
    ) { }
  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.languageService.allTextsLoaded$.subscribe(ready => {
      if (ready) {
        this.getBlogs();
      }
    });

    if (this.languageService.hasKeys()) {
      this.getBlogs();
    }
  }

  private getBlogs() {
    let serviceUrl: string;

    this.loadingAnimationService.publishLoadingAnimation(true);
    serviceUrl = this.apiService.getServerUrl() + '/api/blogs/' + this.lang;

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: BlogResponse[]) => {
          if (response !== undefined && response.length !== 0) {
            this.blogs = response;

            if (this.blogs) {
              this.createBlgTiles();
            }

            // prepare the breadcrumbs and seo
            this.bcService.clearLevels();
            this.bcService.setLastItemTitle(this.languageService.getTextById('blogs'));

            // tslint:disable-next-line:max-line-length
            const pageType = this.languageService.getTextById('blogPageType');
            const pageTitle = pageType + ' | ' + this.languageService.getTextById('baseSeoTitle');
            const pageImage = this.blogs[0].imageUrl && this.blogs[0].imageUrl.path; // get image from first
            const keywords = pageType + ',' + this.languageService.getTextById('baseSeoKeywords');
            const description = pageType + ',' + this.languageService.getTextById('baseSeoDescription');
            const pagePath = '/blogs/';
            const pageUrl = location.origin + pagePath;
            const canonicalUrl = location.origin + '/' + this.lang + pagePath;
            this.bcService.seoItems(pageTitle, keywords, description, pageImage, pageUrl, pageType, canonicalUrl);

            // publish
            this.bcService.publishBreadcrumbs();

            this.lobbyTitle = this.languageService.getTextById('blogs');

            this.loadingAnimationService.publishLoadingAnimation(false);
          } else {
            this.loadingAnimationService.publishLoadingAnimation(false);
          }
        }, error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
    }
  }

  private createBlgTiles() {
    this.blogs.forEach(blog => {
      if (this.blgTiles.some(item => item.tileLinkID === blog.id.toString())) {
        return;
      }
      const tile: Tile = {
        image: blog.imageUrl,
        title: blog.title,
        metaData1: blog.authorName,
        metaData2: this.languageService.showDate(blog.latestPostDate),
        showMD1: true,
        showMD2: true,
        italicMD1: false,
        italicMD2: false,
        filterData: blog.title + ' | ' + blog.latestPostTitle + '|' + blog.aboutAuthor,
        // shortDescription: this.languageService.getTextById('blogsLatestPostTitle') + blog.latestPostTitle,
        shortDescription: null,
        tags: blog.tags,
        badge:  this.languageService.getTextById('blogBadge'),
        badgeColor: 'green',
        // tileLinkType: 'blogPost/id',
        tileLinkType: 'blog/id/' + blog.id,
        tileLinkID: blog.latestPostId.toString()
      };

      // latest posts titles for tile
      const latesetPostTitles = [this.languageService.getTextById('blogsLatestPostTitle')];
      for (let y = 0; y < blog.allBlogPosts.length && latesetPostTitles.length < 6; y++) {
        const element = blog.allBlogPosts[y].blogPostMonthGroups;
        for (let m = 0; m < element.length  && latesetPostTitles.length < 6; m++) {
          const element2 = element[m].blogPostHeaders;
          for (let i = 0; i < element2.length && latesetPostTitles.length < 6; i++) {
            const element3 = element2[i];
            latesetPostTitles.push(element3.title);
          }
        }
      }
      tile.shortDescription = latesetPostTitles.join('\n');

      this.blgTiles.push(tile);
    });
  }
}
