<section name="search-results-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-lobby-wrapper full-div">
      <h1 *ngIf="searchFinished && searchResult"><span>{{SearchResultsLabel}}</span> {{ searchResult.searchTerm }} <span> | </span> {{ searchResult.totalResults }} <span>{{SearchResults}}</span></h1>
      <h1 *ngIf="searchFinished && !searchResult"><span>{{SearchNoResultsLabel}}</span> {{ this.searchTerm }} </h1>
      <div
      *ngIf="searchResultsItems"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="150"
      (scrolled)="onScroll()"
      [scrollWindow]="true" 
      > 
      <div class="desktop">
        <div class="search-results-flex">
          <div name="search-results" *ngFor="let resultTile of resultsTiles">
            <a class="search-result-box" href="{{ resultTile.link }}">
              <img *ngIf="resultTile.image" class="item-img full-div" [src]="resultTile.image | imgHost"/>
              <div class="item-data-wrapper">
                <h2 class="item-title title-t-22 t-b" [innerHTML]="resultTile.title | safe"></h2>
                <div class="item-text base-t-18" [innerHTML]="resultTile.shortDescription | safe"></div>
                <div class="item-bottom-wrapper">
                  <p class="tile-badge base-t-16 c-white t-b"><span class="tile-badge-txt tile-badge-color {{ resultTile.badgeColor }}">{{ resultTile.badge }}</span></p>
                  <a class="item-url small-t-12" href="{{ resultTile.link }}" [innerHTML]="resultTile.url | safe"></a>
                </div>
              </div>  
            </a>
          </div>
        </div>
      </div>
      <div class="tablet">
        <div class="search-results-flex">
          <div name="search-results" *ngFor="let resultTile of resultsTiles">
            <a class="search-result-box" href="{{ resultTile.link }}">
              <img *ngIf="resultTile.image" class="item-img full-div" [src]="resultTile.image | imgHost"/>
              <div class="item-data-wrapper">
                <p class="tile-badge {{dir}} small-t-14 c-white t-b"><span class="tile-badge-txt tile-badge-color {{ resultTile.badgeColor }}">{{ resultTile.badge }}</span></p>
                <h2 class="item-title base-t-18 t-b " [innerHTML]="resultTile.title | safe"></h2>
                <div class="item-text small-t-14 " [innerHTML]="resultTile.shortDescription | safe"></div>
                <a class="item-url small-t-12" href="{{ resultTile.link }}" [innerHTML]="resultTile.url | safe"></a>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div class="search-results-flex">
          <div name="search-results" *ngFor="let resultTile of resultsTiles">
            <a class="search-result-box" href="{{ resultTile.link }}">
              <div class="mobile-header">
              <img *ngIf="resultTile.image" class="item-img full-div" [src]="resultTile.image | imgHost"/>
              <h2 class="item-title base-t-18 t-b " [innerHTML]="resultTile.title | safe"></h2>
              </div>
              <div class="item-text small-t-14 " [innerHTML]="resultTile.shortDescription | safe"></div>
              <p class="tile-badge {{dir}} small-t-11 c-white t-b"><span class="tile-badge-txt tile-badge-color {{ resultTile.badgeColor }}">{{ resultTile.badge }}</span></p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
<app-scroll-top></app-scroll-top>