import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'BoldText'
})
export class BoldTextPipe implements PipeTransform {

  transform(value: string, startMarker: string, endMarker: string): string {
    if (typeof value === 'undefined') {
      return value;
    }
    if (value.length <= length) {
      return value;
    }
    const startRegexp = new RegExp(startMarker.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, '\\$&'), 'g');
    const endRegexp = new RegExp(endMarker.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, '\\$&'), 'g');

    const boldedText = value.replace(startRegexp, `<span class="t-b">`).replace(endRegexp, `</span>`);
    // const boldedText = value.replace(startMarker, '111').replace(endMarker, '222');
    return boldedText;
  }
}
