import { Pipe, PipeTransform, ChangeDetectionStrategy } from '@angular/core';
import { ObservationTileResponse } from '../models/observation-tile-response';
import Fuse from 'fuse.js';

@Pipe({
  name: 'filter',
  pure: false
  // pipe is stateless unless pure is false - change so the filter will be 'refreshed' when more data added to lobby
})

// https://fusejs.io/

export class FilterPipe implements PipeTransform {
  options: Fuse.IFuseOptions<ObservationTileResponse> = {
    // tokenize: false,
    // matchAllTokens: true,
    findAllMatches: true,
    // includeScore: true,
    threshold: 0.3, // 0.1 - full match
    // location: 0,
    // distance: 100,
    // maxPatternLength: 32,
    minMatchCharLength: 2,
    keys:
      [
        { name: 'filterData', weight: 0.5 },
        { name: 'locationName', weight: 0.3 },
        { name: 'observerName', weight: 0.2 }
      ]
  };

  transform(items: any[], searchText: string, withFuzzy: boolean = true): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    if (withFuzzy) {
      searchText = searchText.toLowerCase();
      const fuse = new Fuse(items, this.options);
      const results = fuse.search(searchText);
      return results.map(i => i.item);
    } else {
      return items.filter(it => {
        return it.filterData.toLowerCase().includes(searchText);
      });
    }
  }
}
