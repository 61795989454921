<section name="species-families-lobby-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-lobby-wrapper full-div">
      <h1>{{ this.title }}</h1>
      <h2 *ngIf="this.speciesChecklistData" class="sub-title">{{this.speCount}}{{this.subTitle}}</h2>
      <div class="tree">
        <ul class="species-tree family-node" *ngFor="let familyGroup of speciesChecklistData; let f = index;">
          <li class="tree-box {{dir}} familyGroup-{{f}}">
            <div class="family-data-flex">
              <div class="tree-node {{dir}} tree-node-{{f}} isExpend-{{expendGroups[f]}}" (click)="toggleExpendYears(f)">
                <i class="fas fa-chevron-left" *ngIf="!expendGroups[f] && dir == 'R'"></i>
                <i class="fas fa-chevron-right" *ngIf="!expendGroups[f] && dir == 'L'"></i>
                <i class="fas fa-chevron-down" *ngIf="expendGroups[f]"></i>
              </div>
              <div class="image-container" *ngIf="familyGroup.family.imageUrl">
                <!-- <a [routerLink]="['/species-page/' + item.odnSpeciesID]"> -->
                  <img class="circle-img full-div"
                  [src]="familyGroup.family.imageUrl.path"
                  [alt]="familyGroup.family.imageUrl.name"
                  [title]="familyGroup.family.imageUrl.title"
                  />
                <!-- </a> -->
              </div>
              <div class="image-container" *ngIf="!familyGroup.family.imageUrl">
                <!-- <img class="circle-img full-div" [src]="this.birdSilhouette"/> -->
                <div class="bird-silhouette {{this.dir}}"></div>
              </div>
              <h2 class="tree-node-header t-b title-t-20">{{ familyGroup.family.name }}</h2>
              <!-- <div class="base-t-16 t-b" *ngIf="!expendGroups[f]">({{ familyGroup.speciesList.length }})</div> -->
              <div class="base-t-16 t-b">({{ familyGroup.speciesList.length }})</div>
            </div>
            <div class="node-items-box {{this.dir}} node-items-box-{{f}}" *ngIf="expendGroups[f]">
              <div *ngFor="let item of familyGroup.speciesList">
                <a [routerLink]="['/' + lang + '/species-page/' + item.id]" class="speItem-flex">
                  <div class="tree-entry"><i class="fas fa-feather-alt c-green"></i></div>
                  <div class="species-data-flex" >
                    <span class="species-data spe-name base-t-18 t-b"> {{ item.name }} </span>
                    <span class="species-data spe-latin-name small-t-14 t-i"> [{{ item.latinName }}] </span>
                    <span class="species-data spe-desc base-t-16"> {{ item.statusDescription }} </span>
                  </div>
                </a>
              </div>
            </div> 
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>  