<section name="observations-lobby-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div  *ngIf="observations" class="main-wrapper main-lobby-wrapper full-div">
      <div class="header-filter">
        <h1>{{ this.title }}</h1>
        <div class="view-options">
          <app-filter-bar (filterTextSync) = "onFilterTextChange($event)"></app-filter-bar>
          <div class="toggle-view title-t-22 c-green hide-desktop" (click)="toggleView()">
              <span class="toggle-view-button {{hideMap}}"><i class="fas fa-map-marked-alt"></i></span>  
              <span class="toggle-view-button {{hideList}}"><i class="fas fa-list"></i></span>  
          </div>
        </div>
      </div>
      <!-- <h1>{{ this.title }}</h1> -->
      <div class="third-div obs-map {{dir}} mobile-hide-map {{hideMap}}" *ngIf="mapMarkers && mapMarkers.length > 0">
        <div class="side-panel {{dir}}">
          <app-side-pannel-title [title] = "this.mapTitle"></app-side-pannel-title>
          <app-google-map-wrapper class="side-map" [inputMarkers]="mapMarkers" [height]="70" [heightUnits]="'vh'" 
            [selectedMarker]="selectedMarker[0]"></app-google-map-wrapper>
        </div>
      </div>
      <div class="two-third-div {{dir}} mobile-hide-list {{hideList}}">
        <!-- <app-filter-bar (filterTextSync) = "onFilterTextChange($event)"></app-filter-bar> -->
        <app-side-pannel-title [title] = "this.title"></app-side-pannel-title>
        <div name="observations" class="observations-flex" *ngIf="observations">
          <div class="full-div" *ngFor="let obsTile of observations  | filter : filterText" >
            <div class="obs-tile-box" data-score="obsTile.score">
              <app-observation-tile class="full-div" [obsTile]=obsTile [withTruncate]="false"></app-observation-tile>
              <div class="expend-datacard {{dir}}" *ngIf="expendedObsId != obsTile.id">
                <i class="fas fa-chevron-down" (click)="getObsDataCard(obsTile.id); setSelectedMarker(obsTile.id)"></i>
              </div>
              <div class="expend-datacard {{dir}}" *ngIf="expendedObsId == obsTile.id">
                <i class="fas fa-chevron-up" (click)="closeObsDataCard()"></i>
              </div>
              <app-observation-data-card *ngIf="expendedObsId == obsTile.id" class="obs-data-card"
                [obsId]="obsTile.id"
                (returnRecordsCount) = "updateRecordsCount($event)" 
                ></app-observation-data-card>
              <div class="expend-datacard {{dir}}" *ngIf="expendedObsId == obsTile.id && obsRecordsCount > 10">
                <i class="fas fa-chevron-up" (click)="closeObsDataCard()"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>