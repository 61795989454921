import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
// import { stat } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private alertify: AlertifyService,
    private router: Router) { }

  canActivate(
    // route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): boolean {
    
    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.alertify.error('אינך מורשה לעמוד זה!!!');
    // state.url
    this.router.navigate(['/']);
    return false;
  }
}
