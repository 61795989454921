import { Component, OnInit, Input } from '@angular/core';
import { ImageResponse } from 'src/app/models/image-response';

@Component({
  selector: 'app-xeno-canto-player',
  templateUrl: './xeno-canto-player.component.html',
  styleUrls: ['./xeno-canto-player.component.css']
})
export class XenoCantoPlayerComponent implements OnInit {
  @Input() soundFile: ImageResponse;
  xenocantoTileSrc: string;

  constructor() { }

  ngOnInit() {

    this.xenocantoTileSrc = 'https://www.xeno-canto.org/' + this.soundFile.path + '/embed?simple=1';

  }


}
