<section name="article-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div *ngIf="article" class="main-wrapper main-article-wrapper full-div">
      <div name="large-image" *ngIf="article && article.largeImageUrl">
        <div name="article-header" class="article-img-container full-div" *ngIf="!imgGalleryState" [@fadeInOut]>
          <img
            class="article-img full-div show-for-tablet"
            [src]="article.largeImageUrl.path"
            [alt]="article.largeImageUrl.name"
            [title]="article.largeImageUrl.title"
          />
          <img
            class="article-img full-div hide-for-tablet"
            [src]="article.imageUrl.path"
            [alt]="article.imageUrl.name"
            [title]="article.imageUrl.title"
          />
          <div class="image-element">
            <div>
              <!-- <div *ngIf="article && article.images && article.images.length > 1" class="img-tooltip hand" (click)="imgGalleryOn()">+ {{article.images.length}} {{moreImagesCaption}}</div> -->
            </div>
            <div>
              <p class="article-title-note {{this.dir}} c-white t-b article-type-caption ">{{ articleName }}</p>
              <h1 class="article-title  {{this.dir}} c-white t-b">{{ article.title }}</h1>
            </div>
          </div>
        </div>
        <!-- <app-image-slider *ngIf="imageSliderOn" class="article-img full-div" [images]="article.images"></app-image-slider> -->
        <!-- <app-image-slider *ngIf="imageSliderOn" [images]="article.images" [isShow]="imageSliderOn"></app-image-slider> -->
        <!-- <app-image-gallery *ngIf="imgGalleryState" [title]= "article.title" [images]="article.images" [imgGalleryState]="this.imgGalleryState" (imgGalleryParentState) = "onImgGalleryStateOff($event)" [badge]="'article.articleSlug'"></app-image-gallery> -->
      </div>
      <div class="third-div  {{this.dir}}">
        <div *ngIf="article && !article.largeImageUrl && article.imageUrl" class="small-img-wrapper small-img-wrapper-flex full-div">
          <div name="article-header" class="article-img-container small full-div" *ngIf="!imgGalleryState" [@fadeInOut]>
            <img class="small-img full-div"
              [src]="article.imageUrl.path"
              [alt]="article.imageUrl.name"
              [title]="article.imageUrl.title"
            />
            <div class="image-element">
              <div>
                <!-- <div *ngIf="article && article.images && article.images.length > 1" class="img-tooltip small hand" (click)="imgGalleryOn()">+ {{article.images.length}} {{moreImagesCaption}}</div> -->
              </div>
              <div>
                <p class="article-title-note small {{this.dir}} c-white t-b article-type-caption ">{{ articleName }}</p>
                <h1 class="article-title small  {{this.dir}} c-white t-b">{{ article.title }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="article-subtxt hide-desktop full-div">
          <h2>{{ article.shortDescription }}</h2>
        </div>
        <div class="article-author-inf full-div">
          <app-side-pannel-title class="hide-mobile" [title] = ""></app-side-pannel-title>
          <app-side-pannel-title class="hide-desktop" [title] = "" [width]="30"></app-side-pannel-title>
          <a [routerLink]="['/' + lang + '/articles/author/', article.author]"><h3 class="t-b">{{ article.author }}</h3></a>
          <p>{{ articleDate }}</p>
        </div>
        <app-tags class="hide-desktop" *ngIf="article && article.tags && article.tags.length > 0" [tags]="article.tags"></app-tags>
        <app-social-shares></app-social-shares>
        <app-linked-articles class="hide-mobile" 
          *ngIf="article && article.linkedArticles && article.linkedArticles.length > 0" 
          [linkedArticles]="article.linkedArticles" 
          [moreArticlesTitle]="article.moreArticlesTitle" 
          [lang]="lang"></app-linked-articles>
        <app-tags class="hide-mobile" *ngIf="article && article.tags && article.tags.length > 0" [tags]="article.tags"></app-tags>
        <app-links class="hide-mobile" *ngIf="article && article.links && article.links.length > 0" [links]="article.links" [linksTitle]="article.moreLinkesTitle"></app-links>
      </div>
      <div class=" article-main two-third-div  {{this.dir}}">
        <div *ngIf="article && !article.largeImageUrl" class="article-title-small-img hide-mobile {{this.dir}} t-b">{{ article.title }}</div>
        <div class="article-subtxt full-div hide-mobile">
          <h2>{{ article.shortDescription }}</h2>
        </div>
        <app-side-pannel-title class="hide-mobile" [title] = "" [width]="30"></app-side-pannel-title>
        <div class="inner-html-txt full-div">
          <slot [innerHTML]="article.description | imgHost | safe"></slot>
        </div>
        <app-social-shares></app-social-shares>
        <app-comments *ngIf="article.fbflag"></app-comments>
        <!-- <app-words *ngIf="article && article.description" class="hide-mobile" [title]="article.title"
          [displayedWords]="article.description"></app-words> -->
      </div>
    </div>
  </div>
</section>

<app-linked-articles class="hide-desktop" 
*ngIf="article && article.linkedArticles && article.linkedArticles.length > 0" 
[linkedArticles]="article.linkedArticles" 
[moreArticlesTitle]="article.moreArticlesTitle" 
[lang]="lang"></app-linked-articles>

<app-links class="hide-desktop" *ngIf="article && article.links && article.links.length > 0" [links]="article.links" [linksTitle]="article.moreLinkesTitle"></app-links>
<app-scroll-top></app-scroll-top>
