<div class="app-main-body {{ this.dir }}">
  <app-top-header class="fixed full-div"></app-top-header> 
  <app-top-header></app-top-header> 
  <app-loading-bar></app-loading-bar>
  <app-nav-bar class="full-div"></app-nav-bar>
  <main class="full-div main-section">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </main>
  <app-footer></app-footer> 
</div>
