import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Tile } from '../../models/tile';
import { TileComponent } from '../../_components/tile/tile.component';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { LoadingAnimationService } from '../../_services/loading-animation.service';
import { SpeciesTileResponse } from '../../models/species-tile-response';
import { SpeciesFamilyResponse } from '../../models/species-family-response';

@Component({
  selector: 'app-species-lobby',
  templateUrl: './species-lobby.component.html',
  styleUrls: ['./species-lobby.component.css']
})
export class SpeciesLobbyComponent implements OnInit {
  familyData: SpeciesFamilyResponse;
  species: SpeciesTileResponse[];
  lang: string;
  speTiles: Tile[] = [];
  filterText: string;
  dir: string;


  constructor(private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {

    this.lang = this.languageService.currentLang();

    this.route.paramMap.subscribe((params: ParamMap) => {
      const familyId = parseInt(params.get('id'), 0);
      this.dir = (this.lang === 'he') ? 'R' : 'L';
      this.speTiles = []; // clear tile array on routing between types
      this.getSpecies(familyId);
    });
  }

  private getSpecies(familyId: number) {
    let serviceUrl: string;

    this.loadingAnimationService.publishLoadingAnimation(true);
    serviceUrl = this.apiService.getServerUrl() + '/api/species/family/' + this.lang + '/' + familyId;

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: SpeciesFamilyResponse) => {
          if (response !== undefined) {
            this.familyData = response;
            this.species = this.familyData.familySpecies;
            if (this.species) {
              this.createSpeTiles();
            }

            // prepare the breadcrumbs
            this.bcService.clearLevels();
            this.bcService.addLevel(this.languageService.getTextById('IsraelsBirds'), 'species-families/');
            this.bcService.setLastItemTitle(this.familyData.name);

            const pageTitle = this.languageService.getTextById('specieFamilyPrefix') + ' ' + this.familyData.name
            + ' | ' + this.languageService.getTextById('specieFamilyTitle')
            + ' - ' + this.languageService.getTextById('baseSeoTitle');

            const pageImage = this.species[0].imageUrl && this.species[0].imageUrl.path;
            const keywords = this.languageService.getTextById('IsraelsBirdsEncyclopedia')
                              + ',' + this.familyData.name + ','
                              + this.languageService.getTextById('baseSeoKeywords');

            const description = this.familyData.name
                              + ' - ' + this.languageService.getTextById('IsraelsBirdsEncyclopedia')
                              + ',' + this.languageService.getTextById('baseSeoDescription');

            const pagePath = '/species-family/' + this.familyData.id;
            const pageUrl = location.origin + pagePath;
            const canonicalUrl = location.origin + '/' + this.lang + pagePath;
            const pageType = this.languageService.getTextById('BirdOrders');
            this.bcService.seoItems(pageTitle, keywords, description, pageImage, pageUrl, pageType, canonicalUrl);

            // publish
            this.bcService.publishBreadcrumbs();

            this.loadingAnimationService.publishLoadingAnimation(false);
          }
        }, error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
    }
  }

  private createSpeTiles() {
    this.species.forEach(species => {
      if (this.speTiles.some(item => item.tileLinkID === species.id.toString())) {
        return;
      }
      const tile: Tile = {
        image: species.imageUrl,
        title: species.name,
        metaData1: species.latinName,
        metaData2: null,
        showMD1: true,
        showMD2: false,
        italicMD1: true,
        italicMD2: false,
        filterData: species.name + ', ' + species.latinName,
        shortDescription: species.shortDescription,
        tags: null,
        badge: species.speciesFamily,
        badgeColor: 'green',
        tileLinkType: (species.openEncyclopediaPage) ? 'species-page' : '',
        tileLinkID: (species.openEncyclopediaPage) ? species.id.toString() : ''
      };

      this.speTiles.push(tile);
    });
  }

  onFilterTextChange (filterTextSync: string) {
    this.filterText = filterTextSync;
  }

}

