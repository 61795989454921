import { Component, OnInit, Input } from '@angular/core';
import { Tile } from '../../models/tile';
import { LanguageService } from '../../_services/language.service';

@Component({
  selector: 'app-item-banner',
  templateUrl: './item-banner.component.html',
  styleUrls: ['./item-banner.component.css']
})
export class ItemBannerComponent implements OnInit {
  @Input() item: Tile;
  lang: string;
  dir: string;

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';
  }

}
