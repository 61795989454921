<div class="links-desktop full-div hide-mobile">
  <app-side-pannel-title [title] = "linksTitle"></app-side-pannel-title>
  <table>
    <div *ngFor="let link of links">
      <tr>
        <div class="full-div">
          <div class="link-wrapper">
            <td><i class="fa fa-link c-blue"></i></td>
            <td><a [href]="link.href" *ngIf="link && link.href" class="c-blue t-u" [target]="link.isBlank? '_blank' : ''" [rel]="link.isNoFollow ? 'nofollow' : ''" >
              {{ link.name }}</a></td>
          </div>
        </div>
      </tr>
    </div>
  </table>
</div>

<section class="full-div links-section hide-desktop">
    <div class="grid">
      <app-side-pannel-title [title] = "linksTitle"></app-side-pannel-title>
      <table>
        <div *ngFor="let link of links">
          <tr>
              <div class="full-div">
                <div class="link-wrapper">
                  <td><i class="fa fa-link c-blue"></i></td>
                  <td><a [href]="link.href" *ngIf="link && link.href" class="c-blue t-u" [target]="link.isBlank? '_blank' : ''" [rel]="link.isNoFollow ? 'nofollow' : ''" >
                    {{ link.name }}</a></td>
                </div>
              </div>
          </tr>
        </div>
      </table>
    </div>
  </section>