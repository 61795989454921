<div class="banners-row full-div" *ngIf="banners && banners.length > 0">
  <div *ngFor="let banner of banners" class="banner-container {{dir}}">
    <div *ngIf="banner.url && banner.url.href && banner.url.href == 'https://registration.birds.org.il'">
      <app-register-newsletter [modalId]="'Newsletter-Registration-Banner'" [image]="banner.bnrImage" class="link-padding"></app-register-newsletter> 
    </div>
    <a class="banner" [href]="banner.url.href" *ngIf="banner.url && banner.url.href && banner.url.href != 'https://registration.birds.org.il'" [target]="banner.url.isBlank? '_blank' : ''" [rel]="banner.url.isNoFollow ? 'nofollow' : ''" >
      <img class="banner-img" [src]="banner.bnrImage.path" [alt]="banner.bnrImage.name" [title]="banner.bnrImage.title"/>
    </a>
  </div>
</div>
