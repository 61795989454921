import { Component, OnInit, Input } from '@angular/core';
import { TagResponse } from '../../models/tag-response';
import { LanguageService } from '../../_services/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {
  @Input() tags: TagResponse[];
  lang: string;
  title: string;

  constructor (
    private languageService: LanguageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.title = this.languageService.getTextById('TagsTitle');
  }

  public routeToSpecies(tagName: string) {
    sessionStorage.setItem('clickedTagName', tagName);
    this.router.navigate(['/' + this.lang + '/species-families/']);
  }

}
