import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RingRangeStatusDto } from 'src/app/models/ring-range-status-dto';
import { RingerResponse } from 'src/app/models/ringer-response';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { LanguageService } from 'src/app/_services/language.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { RingsService } from 'src/app/_services/rings.service';


@Component({
  selector: 'app-ringing-rings',
  templateUrl: './ringing-rings.component.html',
  styleUrls: ['./ringing-rings.component.css']
})
export class RingingRingsComponent implements OnInit {

  constructor(private languageService: LanguageService,
    private bcService: BreadcrumbsService,
    private ringsService: RingsService,
    private alertify: AlertifyService,
    private loadingAnimationService: LoadingAnimationService){}
    
  lang: string;
  dir: string;
  title: string;
  rings: [] = [];
  
  model: any = {};
  OK: RingRangeStatusDto[];
  Ignore: RingRangeStatusDto[];
  Check: RingRangeStatusDto[];

  ringTypeLabel: string;
  ringRangeLabel: string;
  startRangeLabel: string;
  endRangeLabel: string;
  ringerLable: string;
  checkRingsLabel: string;
  transferRingsLabel: string;
  transferRingsHeading: string;
  checkRingsHeading: string;
  ignoreRingsHeading: string;
  displayRinging: string;

  sendResult: boolean;
  sendResultMsg: string;
  errRequired: string;
  errShort: string;
  errEndRange: string;
  inSend = false;

  keyword = 'name';
  ringers: RingerResponse[] = [];

  ngOnInit() {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    this.title = this.languageService.getTextById('ringsTitle');

    this.errRequired = this.languageService.getTextById('errRequired');
    this.errShort = this.languageService.getTextById('errShort');
    this.errEndRange = this.languageService.getTextById('errEndRange');
    this.ringTypeLabel = this.languageService.getTextById('ringType');
    this.ringRangeLabel = this.languageService.getTextById('ringRange');
    this.startRangeLabel = this.languageService.getTextById('ringStartRange');
    this.endRangeLabel = this.languageService.getTextById('ringEndRange');
    this.ringerLable = this.languageService.getTextById('ringer');
    this.checkRingsLabel = this.languageService.getTextById('checkRings');
    this.transferRingsLabel = this.languageService.getTextById('transferRings');
    this.transferRingsHeading = this.languageService.getTextById('transferRingsHeading');
    this.checkRingsHeading = this.languageService.getTextById('checkRingsHeading');
    this.ignoreRingsHeading = this.languageService.getTextById('ignoreRingsHeading');
    this.displayRinging = this.languageService.getTextById('displayRinging');

    // prepare the breadcrumbs
    this.bcService.clearLevels();
    this.bcService.setLastItemTitle(this.title);
    this.bcService.addLevel(this.languageService.getTextById('cmsMenuTitle'), 'cms/menu');


    const pageTitle = this.title
      + ' - ' + this.languageService.getTextById('baseSeoTitle');
    const keywords = this.title
      + ',' + this.languageService.getTextById('baseSeoKeywords');
    const desciption = this.title
      + ',' + this.languageService.getTextById('baseSeoDescription');
    const pagePath = '/cms/rings';
    const pageUrl = location.origin + pagePath;
    const canonicalUrl = location.origin + '/' + this.lang + pagePath;

    const pageType = this.title;
    this.bcService.seoItems(pageTitle, keywords, desciption, '', pageUrl, pageType, canonicalUrl);

    // publish
    this.bcService.publishBreadcrumbs();

    this.ringsService.getRingers()
      .subscribe(response => {
        this.ringers = response;
        this.loadingAnimationService.publishLoadingAnimation(false);
      }, err => {
        console.log(err);
        this.loadingAnimationService.publishLoadingAnimation(false);
      });
    
    this.model.ringsOwnerId = 0;
  }
  
  checkRings(f: NgForm) {

    if (this.inSend) {
      return false;
    }

    if (f.invalid) {
      return false;
    }

    if (this.model.rangeEnd < this.model.rangeStart) {
      this.model.invalid = true;
      const field = f.controls["rangeEnd"];
      field.setErrors({
        invalidRange: true
      });
      

      return false;
    }

    if (!this.model.ringsOwnerId) {
      this.model.invalid = true;
      const field = f.controls["ringsOwner"];
      field.setErrors({
        required: true
      });

      return false;
    }

    this.checkRingsStatus();
  }

  selectEvent(item) {
    // do something with selected item
    this.model.ringsOwnerId = item.id;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.model.ringsOwnerId = 0;
  }

  onFocused(e) {
    // do something when input is focused
    this.model.ringsOwnerId = 0;
  }

  selectChange(item: RingRangeStatusDto) {
    item.selected = !item.selected;
  }

  mapResponse(o: RingRangeStatusDto, ringers: RingerResponse[]): RingRangeStatusDto{

    o.selected = true;
    if (o.ownerId) {
      // intentionally compare using == and not ===, since RingerResponse.id althou define and number, contains '6'
      o.owner = ringers.find(x => x.id == o.ownerId)?.name; //if not found set id
      if (!o.owner) {
        o.owner = 'משתמש # ' + o.ownerId.toString();
      }
    } 

    if (o.ringReportRecTibuaId) {
      o.url = `https://cms.birds.org.il/he/bird-ringing.aspx?id=${o.ringReportRecTibuaId}`;
    }

    if (o.ringReportOwner) {
      o.reportOwner = this.ringers.find(x => x.id == o.ringReportOwner)?.name; //if not found set [user: id] + innsttead of find compare ids?
    }

    const statusDescription = this.languageService.getTextById(o.ringsRangeStatusId);
    o.status = statusDescription;
    if (o.owner && statusDescription && statusDescription.indexOf('@@') >= 0) {
      o.status = statusDescription.replace('@@', '"' + o.owner + '"');
    }
    return o;
  }

  checkRingsStatus() {
    this.inSend = true;

    this.loadingAnimationService.publishLoadingAnimation(true);
    this.ringsService.checkRings(this.model.ringType, this.model.rangeStart, this.model.rangeEnd, this.model.ringsOwnerId)
      .subscribe(response => {

        if (!response) {
          this.loadingAnimationService.publishLoadingAnimation(false);
          this.inSend = false;
          this.alertify.error('תקלה באיתור טבעות - פנה לתמיכה');
          return;
        }
        if (response.OK) {
          this.OK = response.OK.map((o) => {
            return this.mapResponse(o, this.ringers);
          });
        }
        else {
          this.OK = [];
        }

        if (response.Check) {
          this.Check = response.Check.map((o) => {
            return this.mapResponse(o, this.ringers);
          });
        }
        else {
          this.Check = [];
        }

        if (response.Ignore) {
          this.Ignore = response.Ignore.map((o) => {
            return this.mapResponse(o, this.ringers);
          });
        }
        else {
          this.Ignore = [];
        }

        this.loadingAnimationService.publishLoadingAnimation(false);
        this.inSend = false;
      }, err => {
        console.log(err);
        this.loadingAnimationService.publishLoadingAnimation(false);
        this.inSend = false;
      });
  }

  transferRings() {
    if (this.inSend) {
      return false;
    }

    if (!this.OK) {
      return false;
    }

    const sendItems = this.OK.filter(x => x.selected === true);
    if (sendItems === undefined || sendItems.length === 0) {
      this.alertify.error('לא נבחרו טבעות');
      return;
    }

    this.loadingAnimationService.publishLoadingAnimation(true);

    let i = 0;
    let errs = 0;
    let oks = 0;
    this.inSend = true;
    sendItems.forEach(item => {
      i = i + 1
      this.ringsService.transferRings(item.ringType, item.ringSerialNumberFrom, item.ringSerialNumberTo,
        Number(this.model.ringsOwnerId), item.ringsRangeStatusId)
        .subscribe(x => {
          oks = oks + 1;
          item.selected = false;
          i = i - 1;
          if (i === 0) {
            this.loadingAnimationService.publishLoadingAnimation(false);
            this.inSend = false;

            if (oks === 0 && errs > 0) {
              this.alertify.error('העברת הטבעות נכשלה. נסה שנית או דבר עם התמיכה');
            }
            else if (errs === 0) {
              this.alertify.success('העברת הטבעות הצליחה');
              this.checkRingsStatus();
            }
            else {
              this.alertify.message('יש לבדוק את העברת הטבעות');
            }
          }

        }, error => {
          errs = errs + 1;
          i = i - 1;
          if (i === 0) {
            this.loadingAnimationService.publishLoadingAnimation(false);
            this.inSend = false;

            if (oks === 0 && errs > 0) {
              this.alertify.error('העברת הטבעות נכשלה. נסה שנית או דבר עם התמיכה');
            }
            else if (errs === 0) {
              this.alertify.success('העברת הטבעות הצליחה');
            }
            else {
              this.alertify.message('יש לבדוק את העברת הטבעות');
            }
          }
        }, () => {
          // complete
        });
      
    });
    
  }
  
  // testAlert() {
  //   this.alertify.error('123');
  // }
}
