import { Component, OnInit } from '@angular/core';
import { SearchResults } from '../../models/SearchResults';
import { SearchResultTileResponse } from '../../models/SearchResultTileResponse';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { LoadingAnimationService } from '../../_services/loading-animation.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SlimTile } from '../../models/slim-tile';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {
  searchResult: SearchResults;
  searchResultsItems: SearchResultTileResponse[];
  searchTerm: string;
  lang: string;
  dir: string;
  page = 1;
  totalPages: number;
  resultsTiles: SlimTile[] = [];
  SearchResultsLabel: string;
  SearchResults: string;
  SearchNoResultsLabel: string;
  searchFinished: boolean;

  constructor(private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    // private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.searchTerm = params.get('query');
      this.lang = this.languageService.currentLang();
      this.dir = (this.lang === 'he') ? 'R' : 'L';

      this.resultsTiles = []; // clear tile array on routing between types
      this.getSearchResults();
    });
  }

  private getSearchResults() {
    this.searchFinished = false;
    let serviceUrl: string;

    this.loadingAnimationService.publishLoadingAnimation(true);

    serviceUrl = this.apiService.getServerUrl() + '/api/search/' + this.lang + '/' + this.searchTerm + '/' + this.page;

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: SearchResults) => {
          if (response !== undefined && response !== null) {
            this.searchResult = response;
            this.searchResultsItems = this.searchResult.results;
            this.totalPages = Math.round(this.searchResult.totalResults / 10) + 1;

            if (this.searchResultsItems && this.searchResultsItems.length > 0) {
              this.createResultsTiles();
            }

            // prepare the breadcrumbs and seo
            this.bcService.clearLevels();
            this.bcService.setLastItemTitle('תוצאות חיפוש' + ' - ' + this.searchTerm);

            const pageType = 'תוצאות חיפוש';
            const pageTitle = pageType + ' | ' + this.languageService.getTextById('baseSeoTitle');
            // const pageImage = this.articles[0].imageUrl && this.articles[0].imageUrl.path; // get image from first
            const keywords = this.searchTerm + ',' + pageType + ',' + this.languageService.getTextById('baseSeoKeywords');
            const description = pageType + ',' + this.languageService.getTextById('baseSeoDescription');
            // tslint:disable-next-line:max-line-length
            const pagePath = '/search/' + this.searchTerm;
            const pageUrl = location.origin + pagePath;
            const canonicalUrl = location.origin + '/' + this.lang + pagePath;

            this.bcService.seoItems(pageTitle, keywords, description, '', pageUrl, pageType, canonicalUrl);

            // publish
            this.bcService.publishBreadcrumbs();

            this.loadingAnimationService.publishLoadingAnimation(false);
            this.searchFinished = true;
          } else {
            this.loadingAnimationService.publishLoadingAnimation(false);
            this.searchFinished = true;
          }
        }, error => {
          console.log(error);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
      this.SearchResultsLabel = this.languageService.getTextById('SearchResultsLabel');
      this.SearchResults = this.languageService.getTextById('SearchResults');
      this.SearchNoResultsLabel = this.languageService.getTextById('SearchNoResultsLabel');
    }
  }

  private createResultsTiles() {
    this.searchResultsItems.forEach(resultItem => {
      if (this.resultsTiles.some(item => item.url === resultItem.htmlFormattedUrl)) {
        return;
      }

      const urlHe = resultItem.absolutePath.startsWith('/he');
      const urlEn = resultItem.absolutePath.startsWith('/en');
      const adjLink = (urlHe || urlEn) ? resultItem.link : '/' + this.lang + resultItem.absolutePath;

      const tile: SlimTile = {
        image: resultItem.imageSrc,
        title: resultItem.title,
        shortDescription: resultItem.htmlSnippet,
        url: resultItem.htmlFormattedUrl,
        link: adjLink,
        // metaData1: article.author,
        // metaData2: this.languageService.showDate(article.articleDate),
        // showMD1: true,
        // showMD2: true,
        // filterData: null,
        badge: resultItem.pageType,
        badgeColor: 'orange',
      };

      this.resultsTiles.push(tile);
    });
  }

  // When scroll down the screen
  public onScroll() {
    if (this.page <= this.totalPages) {
      this.page = this.page + 1;
      this.getSearchResults();
    }
  }
}

