import { Component, OnInit, Input } from '@angular/core';
import { ObservationTileResponse } from 'src/app/models/observation-tile-response';
import { LanguageService } from 'src/app/_services/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-observation-tile',
  templateUrl: './observation-tile.component.html',
  styleUrls: ['./observation-tile.component.css']
})
export class ObservationTileComponent implements OnInit {
  @Input() obsTile: ObservationTileResponse;
  @Input() withTruncate: boolean;
  lang: string;
  dir: string;

  constructor(
    private languageService: LanguageService,
    private router: Router) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';
  }

  public routeToObservation(clickedObsId: number) {
    sessionStorage.setItem('clickedObsId', clickedObsId.toString());
    this.router.navigate(['/' + this.lang + '/observations/']);
  }

}
