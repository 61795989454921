export class DonutChartConfig {
    title: string;
    pieHole: number;
    legend: {
        position: string,
        // textStyle: {color: string, fontSize: number},
        // maxLines: number,
        // alignment: string
    };
    chartArea: {
        left: number,
        top: number,
        width: string,
        height: string
    };
    width: string;
    height: string;
    pieSliceText: string;
    sliceVisibilityThreshold: number;
    pieResidueSliceColor: string;
    pieResidueSliceLabel: string;
    pieStartAngle: number;
    backgroundColor: string;

    constructor() {
        // this.title = '';
        this.pieHole = 0.3;
        this.legend = {
            position: 'none',
            // position: 'right',
            // textStyle: {color: 'black', fontSize: 10},
            // maxLines: 3,
            // alignment: 'end'
        };
        this.pieSliceText = 'label';
        this.chartArea = {
            left: 0,
            top: 0,
            width: '100%',
            height: '100%'
        };
        this.width = '100%';
        this.height = '100%';
        this.sliceVisibilityThreshold = 0.03;
        this.pieResidueSliceColor = '#C9CFD2';
        this.pieResidueSliceLabel = 'rest...';
        this.pieStartAngle = 270;
        this.backgroundColor = 'transparent';
    }
}



