import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

constructor() { }
  isEmail(search: string): boolean {
    let serchfind: boolean;

    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);


    serchfind = regexp.test(search);

    // console.log(serchfind);
    return serchfind;
  }

  isDate(date: string) {
    // return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
  }
}
