<div class="tags-line full-div hide-desktop">
  <div *ngIf="tags">
    <p>
      <span class="t-b">{{ title }}</span>&nbsp;|&nbsp;
      <span class="tag-padding" *ngFor="let tag of tags">          
        <!-- <a [routerLink]="['/' + lang + '/species-families/' + tag.name]">
          <i class="fas fa-tag"></i>
          {{ tag.name }}
        </a> -->
        <span (click)="routeToSpecies(tag.name)">
          <i class="fas fa-tag c-green"></i>
          {{ tag.name }}
        </span>
      </span>
    </p>
  </div>
</div>

<div class="tags-box full-div hide-mobile">
  <app-side-pannel-title [title] = "title"></app-side-pannel-title>
  <div class="full-div">
    <div *ngIf="tags">
      <p *ngFor="let tag of tags">
        <!-- <a [routerLink]="['/' + lang + '/species-families/' + tag.name]">
          <i class="fas fa-tag c-green"></i>
          {{ tag.name }}
        </a> -->
        <span class="hand" (click)="routeToSpecies(tag.name)">
          <i class="fas fa-tag c-green"></i>
          {{ tag.name }}
        </span>
      </p>
    </div>
  </div>
</div>

