<section name="ringing-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-ringing-wrapper full-div">
      <h1>{{title}}</h1>
      <h2>לחיצה על כל אחד מהכפתורים תייצר אקסל עם נתוני הטיבוע של אותה שנה.</h2>
      <p>שנים בהן הורדת את הנתוני הטיבוע של אותה שנה במחשב זה, צבועות בלבן.</p>
      <p>עם זאת, אין מניעה להוריד את הנתונים שוב.</p>
      <div class="full-div {{dir}}">
        <div class="ringing-flex-box flex-box-row">
          <div *ngFor="let yearData of yearsData">
            <button type="button" class="btn" [class.old-data]="!yearData.hasNewData" (click)="downloadYear(yearData)">
              {{yearData.year}}
              <!-- <sup>({{yearData.count}})</sup> -->
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
