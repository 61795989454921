import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { DynamicScriptLoaderService } from 'src/app/_services/DynamicScriptLoaderService.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { Observable } from 'rxjs';
declare var jwplayer: any;
declare var WowzaPlayer: any;
@Component({
  selector: 'app-online-camera',
  templateUrl: './online-camera.component.html',
  styleUrls: ['./online-camera.component.css']
})
export class OnlineCameraComponent implements OnInit, AfterViewInit {
  @Output() playerState = new EventEmitter<string>();
  @Input() camId: string;
  @Input() camera: string;
  @Input() playerType: string;
  @Input() wowzaLicense: string;
  @Input() isMobile: boolean;
  @Input() controls = true;
  @Input() mute = false;
  @Input() aspectratioW: string;
  @Input() aspectratioH: string;
  @Input() pauseTimeout: number;
  timer: Subscription;
  playerElement: string;
  aspectRatio: string;
  wowzaAspectRatio: string;
  cameraIframe: string;
  height = 309;

  WOWZA_PLAYER = 'WOWZA';
  JWP_PLAYER = 'JWP';

  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private loadingAnimationService: LoadingAnimationService
    ) { }

  ngOnInit() {
    // https://developer.jwplayer.com/jwplayer/docs/jw8-player-configuration-reference
    // https://www.wowza.com/docs/wowza-player-javascript-api-overview

    // this.camera = 'https://5e0da72d486c5.streamlock.net:8443/live/boker130.stream/playlist.m3u8';
    // this.camera = 'https://5e0da72d486c5.streamlock.net:8443/live/silo1.stream/playlist.m3u8';

    this.playerElement = 'player-' + this.camId;

    if (this.aspectratioW && this.aspectratioH) {
      this.aspectRatio = this.aspectratioW + ':' + this.aspectratioH;
    } else {
      this.aspectRatio = '16:9';  // default ratio 16:9
    }

    // this.loadScripts();
    // console.log('cam element: ' + this.playerElement + ' stream url: ' + this.camera);
    // this.loadScript();
  }

  ngAfterViewInit() {
    if (!this.isMobile) {
      this.SetupPlayer(this.playerElement + '-d');
    } else {
      this.SetupPlayer(this.playerElement + '-m');
    }
  }

  private SetupPlayer(playerDiv: string) {
    this.loadingAnimationService.publishLoadingAnimation(true);
    console.log('cam element: ' + playerDiv + ' stream url: ' + this.camera);
    console.log('pause timeout: ' + this.pauseTimeout);
    console.log('player-type:' + this.playerType);

    const that = this;

    if (this.playerType === this.JWP_PLAYER) {
      jwplayer(playerDiv).setup({
        file: this.camera,
        timeSliderAbove: true,
        autostart: true,
        displaytitle: true,
        controls: this.controls,
        // aspectratio: '1:1.83',
        // aspectratio: '2.18:1',
        aspectratio: this.aspectRatio,
        stretching: 'fill',
        mute: this.mute,
        localization: {
          liveBroadcast: 'playerTitle'
        },
        // "pid": "L7WjqwgG",
        // "key": "S08RnWzaC0cGDGi5s24VkGvrmSwPyXoMZZEEsl72oDv5DbtpFoYOLVBUDKR+ueOI"
      });


      jwplayer(playerDiv).on('ready', function (setupTime, viewable) {
        const minute = 60 * 1000;
        let state;

        const stateTimer = setInterval(function () {
          state = jwplayer(playerDiv).getState();
          console.log('jw player ' + playerDiv + ' state: ' + state);
          that.playerState.emit(that.camId.toString() + '|' + state);
          if (state === 'paused') {
            clearInterval(stateTimer);
          }
          // if (state === 'idle' || state === 'error') {
          //   console.log('jw player ' + playerDiv + ' go offline');
          //   that.playerState.emit(that.camId.toString() + '|' + state);
          // }
        }, 2000);

        const durationTimer = setTimeout(function () {
          // const time = jwplayer(playerDiv).getPosition();
          // console.log('jw player ' + playerDiv + ' time: ' + time);
          console.log('jw player ' + playerDiv + ' pausing at end of timeout');
          jwplayer(playerDiv).pause();
        }, that.pauseTimeout * 60 * 1000);
      });
    }

    if (this.playerType === this.WOWZA_PLAYER) {
      const myWowzaPlayer = WowzaPlayer.create(playerDiv, {
        'license': this.wowzaLicense,
        'sources': [{
          'sourceURL': this.camera
        }],
        'autoPlay': true,
        'mute': false,
        // 'volume': 0,
        'uiShowPlaybackControls': this.controls,
      });

      myWowzaPlayer.onPlay(function () {
        const minute = 60 * 1000;
        let state;

        const stateTimer = setInterval(function () {
          // state = myWowzaPlayer.getCurrentState();
          state = Object.keys(WowzaPlayer.State).find(name => WowzaPlayer.State[name] === myWowzaPlayer.getCurrentState()).toLowerCase();
          console.log('wowza player ' + playerDiv + ' state: ' + state);
          that.playerState.emit(that.camId.toString() + '|' + state);
          if (state === WowzaPlayer.State.PAUSED) {
            clearInterval(stateTimer);
          }
          // if (state === 'idle' || state === 'error') {
          //   console.log('jw player ' + playerDiv + ' go offline');
          //   that.playerState.emit(that.camId.toString() + '|' + state);
          // }
        }, 2000);

        const durationTimer = setTimeout(function () {
          console.log('wowza player ' + playerDiv + ' pausing at end of timeout');
          myWowzaPlayer.pause();
        }, that.pauseTimeout * 60 * 1000);
      });
    }

    this.loadingAnimationService.publishLoadingAnimation(false);
  }

  // private loadScripts() {
  //   // You can load multiple scripts by just providing the key as argument into load method of the service
  //   // this.dynamicScriptLoader.load('jwplayer').then(data => {
  //   //   // Script Loaded Successfully
  //   // }).catch(error => console.log(error));
  //   this.dynamicScriptLoader.load('wowzaplayer').then(data => {
  //     // Script Loaded Successfully
  //   }).catch(error => console.log(error));
  // }

//   private loadScript() {
//     const head = <HTMLDivElement> document.head;
//     const script = document.createElement('script');
//     // script.innerHTML = 'https://content.jwplatform.com/libraries/OhtIhj2U.js';
//     // script.src = 'url';
//     script.src = 'https://player.wowza.com/player/latest/wowzaplayer.min.js';
//     script.async = true;
//     script.defer = true;
//     head.appendChild(script);
// }

}
