import { Component, OnInit, QueryList, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { FooterCategoryResponse } from '../../models/footer-category-response';
import { FooterItemResponse } from '../../models/footer-item-response';
import { Router } from '@angular/router';
import { RegisterNewsletterComponent } from '../register-newsletter/register-newsletter.component';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @ViewChild(RegisterNewsletterComponent)
  regNl: RegisterNewsletterComponent;

  sponsors: string;
  categories: FooterCategoryResponse[];
  lang: string;
  dir: string;
  toggleMobileCategory = '';
  catItems: FooterItemResponse[];
  loginLabel: string;
  cmsLoginLabel: string;
  cmsLogoutLabel: string;
  externalLoginUrl: string;
  modalId = 'register-modal-footer';
  loggedIn = false;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router
    ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    this.loginLabel = this.languageService.getTextById('loginLabel');
    this.loggedIn = this.authService.isLoggedIn();
    this.cmsLoginLabel = this.languageService.getTextById('cmsLoginLabel') || 'כניסה';
    this.cmsLogoutLabel = this.languageService.getTextById('cmsLogoutLabel');
    this.externalLoginUrl = encodeURIComponent('https://cms.birds.org.il/' + this.lang + '/newIndex.aspx');
    // this.externalLoginUrl = encodeURIComponent('https://dev.birds.org.il/' + this.lang + '/newIndex.aspx'); // for testing
    this.sponsors = (this.lang === 'he') ? 'האתר הוקם בסיוע' : 'Sponsored By';
    this.getFooterCategories();
  }

  private getFooterCategories() {
    this.http.get(this.apiService.getServerUrl() + '/api/footer/categories/' + this.lang)
      .subscribe((response: FooterCategoryResponse[]) => {
        this.categories = response;
      }, error => {
        console.log(error);
        if (error.status === 404) {
          this.router.navigate([this.lang, 'not-found']);
        } else {
          this.router.navigate([this.lang, 'error']);
        }
      });
  }

  public toggleMobileFooter(_catName: string, _catItems: FooterItemResponse[]) {
    if (this.toggleMobileCategory === _catName) {
      this.toggleMobileCategory = '';
      this.catItems = null;
    } else {
      this.toggleMobileCategory = _catName;
      this.catItems = _catItems;
    }
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['/', this.lang]);
  }
}
