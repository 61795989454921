<google-map width="100%" [options]="options" [zoom]="zoom" [style.height]="height" position="center"> 
  <!-- [fitBounds]="true" -->
  <!-- <map-marker [latitude]="this.mapCenterlat" [longitude]="this.mapCenterlng"></map-marker> -->
  <!-- <map-marker *ngIf="position" [position]="position"></map-marker> -->
  <map-marker  *ngIf="selectedMarker" [position]="selectedMarker.position" [title]="selectedMarker.title" >
    <map-info-window
      [panOnOpen]="true" 
      [closeWhenOthersOpen]="true"
      [closeOnMapClick]="true"
    >
      <ng-template>
        <p [innerHtml]="selectedMarker.info"></p>
      </ng-template>
    </map-info-window> 
  </map-marker>
  <div *ngIf="markers && markers.length > 0">
    <map-marker *ngFor="let marker of markers"
      [position]="marker.position" #markerElem 
      [title]="marker.title"
      [options]="marker.options"
      (mapClick)="openInfo(markerElem, marker.title)" >
      <!-- <agm-info-window [disableAutoPan]="false">{{ marker.info }}</agm-info-window> -->
      <map-info-window [panOnOpen]="true" [closeWhenOthersOpen]="true" [closeOnMapClick]="true">
        <ng-template>
          <p [innerHtml]="markerInfo"></p>
        </ng-template>
      </map-info-window>
    </map-marker>
    
  </div>  
</google-map>

  