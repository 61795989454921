<footer class="full-div">
  <section class="full-div">
    <div class="sponsors-bg-img-flex full-div">
      <div class="grid sponsors">
        <div class="sponsors-title full-div">
          <app-side-pannel-title [title] = "this.sponsors"></app-side-pannel-title>
        </div>
      </div>
      <div class="grid sponsors sponsors-logos-flex">
        <img class="responsive-img sponsors" src="../assets/images/footer-img3.png" alt="">
        <img class="responsive-img sponsors" src="../assets/images/footer-img2.png" alt="">
        <img class="responsive-img sponsors" src="../assets/images/footer-img1.png" alt="">
        <img class="responsive-img sponsors" src="../assets/images/footer-img4.png" alt="">
        <!-- <img class="responsive-img sponsors" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/footer-img3.png" alt="">
        <img class="responsive-img sponsors" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/footer-img2.png" alt="">
        <img class="responsive-img sponsors" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/footer-img1.png" alt="">
        <img class="responsive-img sponsors" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/footer-img4.png" alt=""> -->
      </div>
    </div>
  </section>
  <section class="footer-links full-div bg-dark-grey">
    <div class="grid">
      <div class="full-div show-for-tablet">
        <div class="footer-nav-wrapper-desktop">
          <div class="sixth-div" *ngFor="let cat of categories">
            <h4 class="c-white t-b">{{ cat.name }}</h4>
            <ul class="footer-nav-desktop-col full-div">
              <li class="{{cat.columns? 'half-div' : 'full-div'}} s-15 c-white" *ngFor="let itm of cat.items">
                <a [href]="itm.url.href" *ngIf="itm.url && itm.url.href" class="c-white" [target]="itm.url.isBlank? '_blank' : ''" [rel]="itm.url.isNoFollow ? 'nofollow' : ''" >{{ itm.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-nav-wrapper-mobile hide-for-tablet">
        <div class="footer-mobile-flex" *ngIf="this.toggleMobileCategory === ''">
          <span class="c-white t-b title-t-20 footer-cat-mobile" *ngFor="let cat of categories" (click)="toggleMobileFooter(cat.name, cat.items)">
              {{ cat.name }}
              <i class="fas fa-angle-down c-white cat-expand"></i>
            <span class="footer-cat-spliter-mobile c-grey"> | </span>
          </span>
        </div>
        <div class="hide-desktop" *ngIf="this.toggleMobileCategory !== ''">
          <span class="c-white t-b title-t-20 footer-cat-mobile c-green" (click)="toggleMobileFooter(this.toggleMobileCategory, '')">
              <i class="fas fa-chevron-circle-up c-green"></i>
              {{ this.toggleMobileCategory }}
          </span>
          <ul class="footer-nav-desktop-col full-div">
            <li class="full-div s-15 c-white" *ngFor="let itm of catItems">
              <a [href]="itm.url.href" *ngIf="itm.url && itm.url.href" class="c-white" [target]="itm.url.isBlank? '_blank' : ''" [rel]="itm.url.isNoFollow ? 'nofollow' : ''" >{{ itm.name }}</a>
            </li>
          </ul> 
        </div>
      </div>
    </div>

    <div class="grid footer-bottom-desktop">
      <!-- <div class="footer-logo full-div text-center">
        <img class="responsive-img" src="../assets/images/site_logo_white_green_{{ this.lang }}.png" alt="">
      </div> -->
      <div class="footer-buttons-wrapper">
        <div class="menu-mob hide-mobile">
          <a [routerLink]="['/' + lang + '/externalRedirect/' + externalLoginUrl ]" class="c-white">
            <i class="fa fa-user"></i>
            <span>{{ loginLabel }}</span>
          </a>
        </div>
        <div class="menu-mob">
          <a [routerLink]="['/' + lang + '/login' ]" class="c-white" *ngIf="!loggedIn">
            <i class="fa fa-edit"></i>
            <span>{{ cmsLoginLabel }}</span>
          </a>

          <a class="c-white" *ngIf="loggedIn" click="logout()">
            <i class="fa fa-sign-out"></i>
            <span>{{ cmsLogoutLabel }}</span>
          </a>
        </div>
        <div class="menu-mob c-white">
          <i class="fas fa-at"></i>
          <app-register-newsletter [modalId]="modalId"></app-register-newsletter> 
        </div>
      </div>

      <div class="footer-social-row">
        <div class="footer-social-link">
          <a href="https://www.youtube.com/user/israelbirdingportal" target="_blank">
            <i class="fab fa-youtube"></i>
          </a>
        </div>
        <div class="footer-social-link">
          <a href="https://www.facebook.com/birds.org.il/" target="_blank">
            <i class="fab fa-facebook-square"></i>
          </a>
        </div>
        <div class="footer-social-link">
          <a href="http://www.birdingtop500.com/">
           <img src="//www.birdingtop500.com/button.php?u=ibrc" class="birdingtop500" alt="Fatbirder's Top 1000 Birding Websites" border="0" />
          </a>
        </div>
      </div>
      <div class="footer-credits">
        <img class="responsive-img fishler" src="../assets/images/Shimsh-LOGO-{{lang}}-white.png" alt="">        
        <img class="responsive-img work-room" src="../assets/images/workroom-studio-logo.png" alt="">
        <!-- <img class="responsive-img fishler" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/Shimsh-LOGO-{{lang}}-white.png" alt="">        
        <img class="responsive-img work-room" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/workroom-studio-logo.png" alt=""> -->
      </div>
    </div>
  </section>
</footer>
