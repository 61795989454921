import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingAnimationService {

  private loadingAnimationTransmitter: Subject<boolean> = new Subject<boolean>();
  public loadingAnimationTransmitter$ = this.loadingAnimationTransmitter.asObservable();
  // private animationShowing = false;
  constructor() { }

  publishLoadingAnimation(animationShowing: boolean) {
    this.loadingAnimationTransmitter.next(animationShowing);
  }
}
