<div style="overflow: initial;" *ngIf="latestObs && latestObs.length">
  <!-- <app-side-pannel-title class="show-for-tablet" [title] = this.title [width]="100"></app-side-pannel-title> -->
  <app-side-pannel-title class="hide-column-tiles" [title] = this.title [width]="100"></app-side-pannel-title>
  <!-- <div class="hide-mobile">  -->
  <div class="four-tiles"> 
    <div class="full-div latest-observations-flex-box full-div">
      <app-observation-tile class="qrtr-div" [obsTile]=latestObs[0]></app-observation-tile>
      <app-observation-tile class="qrtr-div" [obsTile]=latestObs[1]></app-observation-tile>
      <app-observation-tile class="qrtr-div" [obsTile]=latestObs[2]></app-observation-tile>
      <app-observation-tile class="qrtr-div" [obsTile]=latestObs[3]></app-observation-tile>
    </div>
  </div>
  <!-- <div class="show-for-tablet hide-desktop"> -->
  <div class="three-tiles">
    <div class="full-div latest-observations-flex-box full-div">
      <app-observation-tile class="third-div" [obsTile]=latestObs[0]></app-observation-tile>
      <app-observation-tile class="third-div" [obsTile]=latestObs[1]></app-observation-tile>
      <app-observation-tile class="third-div" [obsTile]=latestObs[2]></app-observation-tile>
    </div>
  </div>
  <!-- < class="hide-for-tablet"> -->
  <div class="column-tiles">
    <div class="latest-observations-flex-box-mobile">
      <h2 class="mobile-title t-b full-div">{{ this.title }}</h2>      
      <app-observation-tile [obsTile]=latestObs[0]></app-observation-tile>
      <app-observation-tile [obsTile]=latestObs[1]></app-observation-tile>
      <app-observation-tile [obsTile]=latestObs[2]></app-observation-tile>
      <app-observation-tile [obsTile]=latestObs[3]></app-observation-tile>
    </div>
  </div>
  <a *ngIf="dir === 'R'" class="ebird {{dir}} base-t-18 c-green" href="https://ebird.org/israel/submit" target="_blank">
    <span class="hide-mobile show-for-tablet"><span><i class="fas fa-binoculars"></i> דווח תצפית</span><span> @</span><span class="ebird-font">e<span class="c-black">Bird</span></span></span>
    <span class="hide-desktop hide-for-tablet"><span><i class="fas fa-binoculars"></i> </span><span> @</span><span class="ebird-font">e<span class="c-black">Bird</span></span></span>
  </a>
  <a *ngIf="dir === 'L'" class="ebird {{dir}} base-t-18 c-green" href="https://ebird.org/israel/submit" target="_blank">
    <span class="hide-mobile show-for-tablet"><span><i class="fas fa-binoculars"></i> Submit Sighting</span><span> @</span><span class="ebird-font">e<span class="c-black">Bird</span></span></span>
    <span class="hide-desktop hide-for-tablet"><span><i class="fas fa-binoculars"></i> </span><span> @</span><span class="ebird-font">e<span class="c-black">Bird</span></span></span>
  </a>
  <a *ngIf="dir === 'R'" class="more-link {{dir}} base-t-18" [routerLink]="['/' + lang + '/observations']"><span>{{ moreLinkTitle }} <i class="fas fa-angle-double-left" title="{{ moreLinkTitle }}"></i></span></a>
  <a *ngIf="dir === 'L'" class="more-link {{dir}} base-t-18" [routerLink]="['/' + lang + '/observations']"><span>{{ moreLinkTitle }} <i class="fas fa-angle-double-right" title="{{ moreLinkTitle }}"></i></span></a>
</div>