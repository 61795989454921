import { Component, OnInit, Input } from '@angular/core';
import { Tile } from '../../models/tile';
import { LanguageService } from '../../_services/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-h-tile',
  templateUrl: './h-tile.component.html',
  styleUrls: ['./h-tile.component.css']
})

export class HTileComponent implements OnInit {
  @Input() tile: Tile;
  lang: string;
  dir: string;
  viewWidth: number;

  constructor(
    private languageService: LanguageService,
    private router: Router) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';

    this.viewWidth = Math.round((window.innerWidth - 768) / 65) * 65;

  }
}
