import { Component, OnInit, Input } from '@angular/core';
import { LoadingAnimationService } from '../../_services/loading-animation.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit {

  @Input()
  animationShowing: boolean;
  constructor(private animationLoading: LoadingAnimationService) { }

  ngOnInit() {

    this.animationLoading.loadingAnimationTransmitter$
      .subscribe(as => this.animationShowing = as);
  }

}
