<div class="flex-bars {{dir}}">
  <div *ngFor="let week of this.data; let i = index" id="week-{{i}}" 
       class="week-bar fq-cat-{{week[1]}} tooltip" 
       [class.currentWeek]="this.currentWeek === i + 1"
  >
    <span class="tooltiptext small-t-11" [innerHTML]="week[2] | safe"></span>
  </div>
</div>
<div class="flex-bars {{dir}}">
  <div *ngFor="let month of [1,2,3,4,5,6,7,8,9,10,11,12]" class="hide-desktop monthes small-t-12" [innerText]="month | MonthName: this.lang:true"></div>
  <div *ngFor="let month of [1,2,3,4,5,6,7,8,9,10,11,12]" class="hide-mobile monthes small-t-12" [innerText]="month | MonthName: this.lang:false"></div>
</div>

