import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { MenuEntry } from '../../models/menu-entry';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})

export class NavBarComponent implements OnInit {
  @ViewChild('searchInput') nameField: ElementRef;
  lang: string;
  dir: string;
  clickedMenuItem = 0;
  clickedMobileMenuItem = 0;
  mobileParentMenu: MenuEntry;
  mobileMenu = 'off';
  searchOn = false;
  searchQuery: string;
  searchPlaceholder: string;
  menuEntries: MenuEntry[] = [];
  shopIL: string;
  shopUS: string;
  spniHe: string;
  spniEn: string;

  constructor(
    private languageService: LanguageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.shopIL = encodeURIComponent('https://shopil.birds.org.il');
    this.shopUS = encodeURIComponent('https://shop.birds.org.il');
    this.spniHe = encodeURIComponent('https://www.teva.org.il/');
    this.spniEn = encodeURIComponent('https://natureisrael.org/Home');

  }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    this.languageService.allTextsLoaded$.subscribe(ready => {
      if (ready) {
        this.InitMenuEntriesData();
        window.location.reload();
      }
    });

    if (this.languageService.hasKeys()) {
      this.InitMenuEntriesData();
    }
  }

  public closeSubMenus(txt: string) {
    this.clickedMenuItem = 0;
  }

  public openSubMenu(menuItem: number) {
    this.clickedMenuItem = menuItem;
  }

  public toggleMobileMenu(state: string) {
    this.mobileMenu = state;
  }

  public toggleMobileSubMenu(menuItem: number) {
    if (this.clickedMobileMenuItem === 0 || this.clickedMobileMenuItem !== menuItem) {
      this.clickedMobileMenuItem = menuItem;
      this.mobileParentMenu = this.menuEntries.find(i => i.id === menuItem);
    } else {
      this.clickedMobileMenuItem = 0;
      this.mobileParentMenu = null;
    }
    // console.log('toggleMobileSubMenu: ' + 'mobileSubMenu-' + this.clickedMobileMenuItem);
  }

  public ClickOutsideMobileMenu(e) {
    if (!e.target.classList.contains('ignore-click-outside')) {
      // console.log('ClickOutsideMobileMenu ' + e.target.classList);
      if (this.mobileMenu === 'on' || this.clickedMobileMenuItem !== 0) {
        this.toggleMobileMenu('off');
        this.toggleMobileSubMenu(0);
      }
    }
  }

  clickedOutsideSearchBar() {
    this.searchOn = false;
  }

  searchClick() {
    if (this.searchOn && this.searchQuery !== undefined && this.searchQuery !== '') {
      this.router.navigate(['/' + this.lang + '/search/' + this.searchQuery]);
      // console.log('perform search with query string: ' + this.searchQuery);
    } else {
      this.searchOn = !this.searchOn;
      if (this.searchOn) {
        this.nameField.nativeElement.focus();
      }
    }
  }

  searchClear() {
    this.searchQuery = '';
  }

  private InitMenuEntriesData() {

    // console.log('start of InitMenuEntriesData');
    this.searchPlaceholder = this.languageService.getTextById('Search');

    this.menuEntries = [];
    switch (this.lang) {
      case 'he':
        // מידע על ציפורים
        this.menuEntries.push({
          id: 1,
          isParent: true,
          routing: '',
          label: this.languageService.getTextByIdAndLang('Menu1_He', 'he'),
          isDisabled: false,
          childrens: []
        });
        // בלוגים
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.1, isParent: false, routing: '/' + this.lang + '/blogs',
          label: this.languageService.getTextByIdAndLang('Menu1.1_He', 'he'),
          isDisabled: false
        });
        // מצלמות
        this.menuEntries.find(i => i.id === 1).childrens.push({
          // id: 1.2, isParent: false, routing: '/' + this.lang + '/camera/26',
          id: 1.2, isParent: false, routing: '/' + this.lang + '/cameras',
          label: this.languageService.getTextByIdAndLang('Menu1.2_He', 'he'),
          isDisabled: false
        });
        // מאמרים
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.3, isParent: false, routing: '/' + this.lang + '/articles/type/article',
          label: this.languageService.getTextByIdAndLang('Menu1.3_He', 'he'),
          isDisabled: false
        });
        // checklist
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.4, isParent: false, routing: '/' + this.lang + '/species-checklist',
          label: this.languageService.getTextByIdAndLang('Menu1.4_He', 'he'),
          isDisabled: false
        });
        // תצפיות בנדירים
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.5, isParent: false, routing: '/' + this.lang + '/rarityObservationNews',
          label: this.languageService.getTextByIdAndLang('Menu1.5_He', 'he'),
          isDisabled: false
        });
        // מידע לתלמידים ומורים
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.6, isParent: false, routing: '/' + this.lang + '/articles/type/education',
          label: this.languageService.getTextByIdAndLang('Menu1.6_He', 'he'),
          isDisabled: false
        });
        // טיולי צפרות
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.7, isParent: false, routing: '/' + this.lang + '/articles/type/tour',
          label: this.languageService.getTextByIdAndLang('Menu1.7_He', 'he'),
          isDisabled: false
        });
        // // חדשות הצפרות
        // this.menuEntries.find(i => i.id === 1).childrens.push({
        //   id: 1.8, isParent: false, routing: '/' + this.lang + '/coming-soon/חדשות הצפרות',
        //   label: this.languageService.getTextByIdAndLang('Menu1.8_He', 'he'),
        //   isDisabled: true
        // });
        // מרכזי צפרות
        this.menuEntries.push({
          id: 2,
          isParent: true,
          routing: '',
          label: this.languageService.getTextByIdAndLang('Menu2_He', 'he'),
          isDisabled: false,
          childrens: []
        });
        // jbo
        this.menuEntries.find(i => i.id === 2).childrens.push({
          id: 2.1, isParent: false, routing: '/' + this.lang + '/birding-center/התחנה-לחקר-ציפורי-ירושלים-ע-ש-נילי-ודוד',
          label: this.languageService.getTextByIdAndLang('Menu2.1_He', 'he'),
          isDisabled: false
        });
        // eilat
        this.menuEntries.find(i => i.id === 2).childrens.push({
          id: 2.2, isParent: false, routing: '/' + this.lang + '/birding-center/פארק-הצפרות-אילת',
          label: this.languageService.getTextByIdAndLang('Menu2.2_He', 'he'),
          isDisabled: false
        });
        // rupin
        this.menuEntries.find(i => i.id === 2).childrens.push({
          id: 2.3, isParent: false, routing: '/' + this.lang + '/birding-center/Jordan-Valley-Center',
          label: this.languageService.getTextByIdAndLang('Menu2.3_He', 'he'),
          isDisabled: false
        });
        // negev
        this.menuEntries.find(i => i.id === 2).childrens.push({
          id: 2.4, isParent: false, routing: '/' + this.lang + '/birding-center/מרכז-צפרות-רמת-הנגב',
          label: this.languageService.getTextByIdAndLang('Menu2.4_He', 'he'),
          isDisabled: false
        });

        // אירועים
        this.menuEntries.push({
          id: 3,
          isParent: false,
          routing: '/' + this.lang + '/events',
          label: this.languageService.getTextByIdAndLang('Menu3_He', 'he'),
          isDisabled: false,
          childrens: []
        });

        // ציפורי ישראל
        this.menuEntries.push({
          id: 4,
          isParent: false,
          routing: '/' + this.lang + '/species-families',
          label: this.languageService.getTextByIdAndLang('Menu4_He', 'he'),
          isDisabled: false,
          childrens: []
        });
        // שומרים על הציפורים
        this.menuEntries.push({
          id: 5,
          isParent: false,
          routing: '/' + this.lang + '/articles/type/savethebirds',
          label: this.languageService.getTextByIdAndLang('Menu5_He', 'he'),
          isDisabled: false,
          childrens: []
        });

        // מי אנחנו
        this.menuEntries.push({
          id: 6,
          isParent: true,
          routing: '',
          label: this.languageService.getTextByIdAndLang('Menu6_He', 'he'),
          isDisabled: false,
          childrens: []
        });
        // מרכז הצפרות הישראלי
        this.menuEntries.find(i => i.id === 6).childrens.push({
          id: 6.1, isParent: false, routing: '/' + this.lang + '/birding-center/מרכז-הצפרות-הישראלי',
          label: this.languageService.getTextByIdAndLang('Menu6.1_He', 'he'),
          isDisabled: false
        });
        // פרוייקטים
        this.menuEntries.find(i => i.id === 6).childrens.push({
          id: 6.2, isParent: false, routing: '/' + this.lang + '/articles/type/project',
          label: this.languageService.getTextByIdAndLang('Menu6.2_He', 'he'),
          isDisabled: false
        });
        // IBRC
        this.menuEntries.find(i => i.id === 6).childrens.push({
          id: 6.3, isParent: false, routing: '/' + this.lang + '/birding-center/מרכז-הטיבוע-הישראלי',
          label: this.languageService.getTextByIdAndLang('Menu6.3_He', 'he'),
          isDisabled: false
        });
        // contact us
        this.menuEntries.find(i => i.id === 6).childrens.push({
          id: 6.4, isParent: false, routing: '/' + this.lang + '/contactus',
          label: this.languageService.getTextByIdAndLang('Menu6.4_He', 'he'),
          isDisabled: false
        });
        break;

      case 'en':
        // Birding Israel
        this.menuEntries.push({
          id: 1,
          isParent: true,
          routing: '',
          label: this.languageService.getTextByIdAndLang('Menu1_En', 'en'),
          isDisabled: false,
          childrens: []
        });
        // blogs
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.1, isParent: false, routing: '/' + this.lang + '/blogs',
          label: this.languageService.getTextByIdAndLang('Menu1.0_En', 'en'),
          isDisabled: false
        });
        // recent rarities
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.2, isParent: false, routing: '/' + this.lang + '/rarityObservationNews',
          label: this.languageService.getTextByIdAndLang('Menu1.1_En', 'en'),
          isDisabled: false
        });
        // checklist
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.3, isParent: false, routing: '/' + this.lang + '/species-checklist',
          label: this.languageService.getTextByIdAndLang('Menu1.2_En', 'en'),
          isDisabled: false
        });
        // birding in israel article 150
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.4, isParent: false, routing: '/' + this.lang + '/article/Jonathan-Birding-in-israel',
          label: this.languageService.getTextByIdAndLang('Menu1.3_En', 'en'),
          isDisabled: false
        });
        // birding month by month article 124
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.5, isParent: false, routing: '/' + this.lang + '/article/Jonathan-Birding-month-by-month',
          label: this.languageService.getTextByIdAndLang('Menu1.4_En', 'en'),
          isDisabled: false
        });
        // birding destinations
        this.menuEntries.find(i => i.id === 1).childrens.push({
          id: 1.6, isParent: false, routing: '/' + this.lang + '/articles/type/tour',
          label: this.languageService.getTextByIdAndLang('Menu1.5_En', 'en'),
          isDisabled: false
        });

        // birding centers
        this.menuEntries.push({
          id: 2,
          isParent: true,
          routing: '',
          label: this.languageService.getTextByIdAndLang('Menu2_En', 'en'),
          isDisabled: false,
          childrens: []
        });
        // jbo
        this.menuEntries.find(i => i.id === 2).childrens.push({
          id: 2.1, isParent: false, routing: '/' + this.lang + '/birding-center/The-Nili-and-David-Jerusalem-Birds-Observatory',
          label: this.languageService.getTextByIdAndLang('Menu2.1_En', 'en'),
          isDisabled: false
        });
        // eilat
        this.menuEntries.find(i => i.id === 2).childrens.push({
          id: 2.2, isParent: false, routing: '/' + this.lang + '/birding-center/IBRCE-Eilat-Birdwatching-Center',
          label: this.languageService.getTextByIdAndLang('Menu2.2_En', 'en'),
          isDisabled: false
        });
        // rupin
        this.menuEntries.find(i => i.id === 2).childrens.push({
          id: 2.3, isParent: false, routing: '/' + this.lang + '/birding-center/Jordan-valley-birding-center',
          label: this.languageService.getTextByIdAndLang('Menu2.3_En', 'en'),
          isDisabled: false
        });
        // negev
        this.menuEntries.find(i => i.id === 2).childrens.push({
          id: 2.4, isParent: false, routing: '/' + this.lang + '/birding-center/Ramat-Ha-Negev-birding-center',
          label: this.languageService.getTextByIdAndLang('Menu2.4_En', 'en'),
          isDisabled: false
        });

        // conservation
        this.menuEntries.push({
          id: 3,
          isParent: true,
          routing: '',
          label: this.languageService.getTextByIdAndLang('Menu3_En', 'en'),
          isDisabled: false,
          childrens: []
        });
        // threatend species
        this.menuEntries.find(i => i.id === 3).childrens.push({
          id: 3.1, isParent: false, routing: '/' + this.lang + '/article/project-threatened-species-jan-2020',
          label: this.languageService.getTextByIdAndLang('Menu3.1_En', 'en'),
          isDisabled: false
        });
        // Save the Birds
        this.menuEntries.find(i => i.id === 3).childrens.push({
          id: 3.2, isParent: false, routing: '/' + this.lang + '/articles/type/savethebirds',
          label: this.languageService.getTextByIdAndLang('Menu3.2_En', 'en'),
          isDisabled: false
        });
        // Nestcams
        this.menuEntries.find(i => i.id === 3).childrens.push({
          // id: 3.3, isParent: false, routing: '/' + this.lang + '/camera/26',
          id: 3.3, isParent: false, routing: '/' + this.lang + '/cameras',
          label: this.languageService.getTextByIdAndLang('Menu3.3_En', 'en'),
          isDisabled: false
        });
        // IBRC
        this.menuEntries.find(i => i.id === 3).childrens.push({
          id: 3.4, isParent: false, routing: '/' + this.lang + '/birding-center/The-Israeli-Bird-Ringing-Center',
          label: this.languageService.getTextByIdAndLang('Menu3.4_En', 'en'),
          isDisabled: false
        });
        // Bird Tourism for Conservation
        this.menuEntries.find(i => i.id === 3).childrens.push({
          id: 3.5, isParent: false, routing: '/' + this.lang + '/article/project-birdwatching-and-nature-tourism-for-conservation-jan-2020',
          label: this.languageService.getTextByIdAndLang('Menu3.5_En', 'en'),
          isDisabled: false
        });
        // Champions of the Flyway
        this.menuEntries.find(i => i.id === 3).childrens.push({
          id: 3.6, isParent: false, routing: '/' + this.lang + '/articles/type/flywaychampions',
          label: this.languageService.getTextByIdAndLang('Menu3.6_En', 'en'),
          isDisabled: false
        });
        // Artists for Nature
        this.menuEntries.find(i => i.id === 3).childrens.push({
          id: 3.7, isParent: false, routing: '/' + this.lang + '/article/project-artists-for-nature-jan-2020',
          label: this.languageService.getTextByIdAndLang('Menu3.7_En', 'en'),
          isDisabled: false
        });
        // Support Conservation Work - donations
        this.menuEntries.find(i => i.id === 3).childrens.push({
          id: 3.8, isParent: false,
          routing: '/' + this.lang + '/externalRedirect/' + this.shopUS,
          // todo - direct link to donations page
          label: this.languageService.getTextByIdAndLang('Menu3.8_En', 'en'),
          isDisabled: false
        });

        // events
        this.menuEntries.push({
          id: 4,
          isParent: true,
          routing: '/' + this.lang + '/events',
          label: this.languageService.getTextByIdAndLang('Menu4_En', 'en'),
          isDisabled: false,
          childrens: []
        });
        // eilat birds festival
        this.menuEntries.find(i => i.id === 4).childrens.push({
          // id: 4.1, isParent: false, routing: '/' + this.lang + '/articles/type/eilatfestival',
          id: 4.1, isParent: false, routing: '/' + this.lang + '/event/Eilat-Birds-Festival',
          label: this.languageService.getTextByIdAndLang('Menu4.1_En', 'en'),
          isDisabled: false
        });
        // our bird tours
        this.menuEntries.find(i => i.id === 4).childrens.push({
          id: 4.2, isParent: false, routing: '/' + this.lang + '/events',
          label: this.languageService.getTextByIdAndLang('Menu4.2_En', 'en'),
          isDisabled: false
        });

        // shop
        this.menuEntries.push({
          id: 5,
          isParent: false,
          routing: '/' + this.lang + '/externalRedirect/' + this.shopUS,
          label: this.languageService.getTextByIdAndLang('Menu5_En', 'en'),
          isDisabled: false,
          childrens: []
        });

        // about
        this.menuEntries.push({
          id: 6,
          isParent: true,
          routing: '',
          label: this.languageService.getTextByIdAndLang('Menu6_En', 'en'),
          isDisabled: false,
          childrens: []
        });
        // about us
        this.menuEntries.find(i => i.id === 6).childrens.push({
          id: 6.1, isParent: false, routing: '/' + this.lang + '/article/BirdLife-Israel',
          label: this.languageService.getTextByIdAndLang('Menu6.1_En', 'en'),
          isDisabled: false
        });
        // contact
        this.menuEntries.find(i => i.id === 6).childrens.push({
          id: 6.2, isParent: false, routing: '/' + this.lang + '/contactus',
          label: this.languageService.getTextByIdAndLang('Menu6.2_En', 'en'),
          isDisabled: false
        });

        break;
    }

    // // מידע על ציפורים
    // this.menuEntries.push({
    //   id: 1,
    //   isParent: true,
    //   routing: '',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu1', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu1', 'he'),
    //   isDisabled: false,
    //   childrens: []
    // });
    // // פרוייקטים
    // this.menuEntries.find(i => i.id === 1).childrens.push({
    //   id: 1.1, isParent: false, routing: '/' + this.lang + '/articles/type/project',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu1.1', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu1.1', 'he'),
    //   isDisabled: false
    // });
    // // בלוגים
    // this.menuEntries.find(i => i.id === 1).childrens.push({
    //   id: 1.2, isParent: false, routing: '/' + this.lang + '/blogs',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu1.2', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu1.2', 'he'),
    //   isDisabled: false
    // });
    // // מצלמות
    // this.menuEntries.find(i => i.id === 1).childrens.push({
    //   id: 1.3, isParent: false, routing: '/' + this.lang + '/coming-soon/מצלמות Live',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu1.3', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu1.3', 'he'),
    //   isDisabled: true
    // });
    // // מאמרים
    // this.menuEntries.find(i => i.id === 1).childrens.push({
    //   id: 1.4, isParent: false, routing: '/' + this.lang + '/articles/type/article',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu1.4', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu1.4', 'he'),
    //   isDisabled: false
    // });
    // // מידע לתלמידים ומורים
    // this.menuEntries.find(i => i.id === 1).childrens.push({
    //   id: 1.5, isParent: false, routing: '/' + this.lang + '/articles/type/education',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu1.5', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu1.5', 'he'),
    //   isDisabled: false
    // });
    // // טיולי צפרות
    // this.menuEntries.find(i => i.id === 1).childrens.push({
    //   id: 1.6, isParent: false, routing: '/' + this.lang + '/articles/type/tour',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu1.6', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu1.6', 'he'),
    //   isDisabled: false
    // });
    // // חדשות הצפרות
    // this.menuEntries.find(i => i.id === 1).childrens.push({
    //   id: 1.7, isParent: false, routing: '/' + this.lang + '/coming-soon/חדשות הצפרות',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu1.7', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu1.7', 'he'),
    //   isDisabled: false
    // });

    // // מרכזי צפרות
    // this.menuEntries.push({
    //   id: 2,
    //   isParent: false,
    //   routing: '/' + this.lang + '/birding-centers',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu2', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu2', 'he'),
    //   isDisabled: false,
    //   childrens: []
    // });

    // // אירועים
    // this.menuEntries.push({
    //   id: 3,
    //   isParent: false,
    //   routing: '/' + this.lang + '/events',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu3', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu3', 'he'),
    //   isDisabled: false,
    //   childrens: []
    // });

    // // ציפורי ישראל
    // this.menuEntries.push({
    //   id: 4,
    //   isParent: true,
    //   routing: '',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4', 'he'),
    //   isDisabled: false,
    //   childrens: []
    // });
    // // כל הציפורים
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.1, isParent: false, routing: '/' + this.lang + '/species-families',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.1', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.1', 'he'),
    //   isDisabled: false
    // });
    // // דורסי יום
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.2, isParent: false, routing: '/' + this.lang + '/species-family/7',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.2', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.2', 'he'),
    //   isDisabled: false
    // });
    // // ציפורי שיר
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.3, isParent: false, routing: '/' + this.lang + '/species-family/16',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.3', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.3', 'he'),
    //   isDisabled: false
    // });
    // // ינשופים
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.4, isParent: false, routing: '/' + this.lang + '/species-family/14',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.4', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.4', 'he'),
    //   isDisabled: false
    // });
    // // עגוראים וחסידות
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.5, isParent: false, routing: '/' + this.lang + '/species-family/8',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.5', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.5', 'he'),
    //   isDisabled: false
    // });
    // // עגור אפור
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.6, isParent: false, routing: '/' + this.lang + '/species-page/162',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.6', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.6', 'he'),
    //   isDisabled: false
    // });
    // // פלמינגו גדול
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.7, isParent: false, routing: '/' + this.lang + '/species-page/68',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.7', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.7', 'he'),
    //   isDisabled: false
    // });
    // // תצפיות בנדירים
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.8, isParent: false, routing: '/' + this.lang + '/rarityObservationNews',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.8', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.8', 'he'),
    //   isDisabled: false
    // });
    // // checklist
    // this.menuEntries.find(i => i.id === 4).childrens.push({
    //   id: 4.9, isParent: false, routing: '/' + this.lang + '/species-checklist',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu4.9', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu4.9', 'he'),
    //   isDisabled: false
    // });

    // // נדידה
    // this.menuEntries.push({
    //   id: 5,
    //   isParent: false,
    //   routing: '/' + this.lang + '/articles/type/migration',
    //   labelEn: this.languageService.getTextByIdAndLang('Menu5', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu5', 'he'),
    //   isDisabled: false,
    //   childrens: []
    // });

    // // חנות
    // this.menuEntries.push({
    //   id: 6,
    //   isParent: false,
    //   // routing: '/' + this.lang + '/articles/type/migration',
    //   routing: '/' + this.lang + '/externalRedirect/' +
    //     (this.lang === 'he' ? encodeURIComponent('https://shopil.birds.org.il') : encodeURIComponent('https://shop.birds.org.il')),
    //   labelEn: this.languageService.getTextByIdAndLang('Menu6', 'en'),
    //   labelHe: this.languageService.getTextByIdAndLang('Menu6', 'he'),
    //   isDisabled: false,
    //   childrens: []
    // });

  }
}
