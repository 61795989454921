<section name="menu" class="full-div nav-bar {{ this.dir }}">
  <div class="top-header-img full-div">
    <div class="grid header-menu-flex">
      <div class="menu-selector" name="menu">
        <div class="humburger-menu dir-op hide-desktop" (clickOutside)="ClickOutsideMobileMenu($event)">
          <div class="icons-container">
            <i class="fas fa-bars mobile-menu-hamburger ignore-click-outside" *ngIf="mobileMenu === 'off' && this.clickedMobileMenuItem === 0" (click)="toggleMobileMenu('on'); toggleMobileSubMenu(0)"></i>
            <i class="fas fa-times" *ngIf="mobileMenu === 'on' || this.clickedMobileMenuItem !== 0" (click)="toggleMobileMenu('off'); toggleMobileSubMenu(0)"></i>
            <div class="sub-menu-box" *ngIf="mobileMenu === 'on'">
              <ul class="sub-menu">
                <div *ngFor="let entry of this.menuEntries">
                  <li *ngIf="entry.isParent && !entry.isDisabled" class="sub-menu-item-mobile {{ this.dir }} mobile-parent-menu-item ignore-click-outside" (click)="toggleMobileMenu('off'); toggleMobileSubMenu(entry.id)">            
                    <span class="t-b {{ this.dir }} sub-menu-item-text ignore-click-outside" routerLinkActive="active-route">
                      <span class="ignore-click-outside">{{ entry.label }}</span>
                    </span>
                    <i class="fas fa-caret-left caret-back {{ this.dir }}" *ngIf="this.dir === 'R'"></i>  
                    <i class="fas fa-caret-right caret-back {{ this.dir }}" *ngIf="this.dir === 'L'"></i>   
                  </li>
                  <li *ngIf="entry.isParent && entry.isDisabled" class="sub-menu-item-mobile {{ this.dir }} mobile-parent-menu-item ignore-click-outside">            
                    <span class="t-b c-light-grey {{ this.dir }} sub-menu-item-text-disabled ignore-click-outside">
                      <span class="ignore-click-outside">{{ entry.label }}</span>
                    </span>
                  </li>
                  <li *ngIf="!entry.isParent && !entry.isDisabled" class="sub-menu-item-mobile {{ this.dir }}">            
                    <a class="t-b {{ this.dir }} sub-menu-item-text"
                      (click)="toggleMobileMenu('off'); toggleMobileSubMenu(0);" [routerLink]="[entry.routing]"
                      routerLinkActive="active-route">
                      <span>{{ entry.label }}</span>
                    </a>
                  </li>
                  <li *ngIf="!entry.isParent && entry.isDisabled" class="sub-menu-item-mobile {{ this.dir }}">            
                    <div class="t-b c-light-grey{{ this.dir }} sub-menu-item-text-disabled">
                      <span>{{ entry.label }}</span>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
            <div *ngIf="mobileParentMenu && mobileParentMenu.isParent && this.clickedMobileMenuItem !== 0" class="sub-menu-box sub-menu-{{clickedMobileMenuItem}}">
              <ul class="sub-menu">
                <li class="sub-menu-item-mobile {{ this.dir }} mobile-parent-menu-item ignore-click-outside" (click)="toggleMobileMenu('on'); toggleMobileSubMenu(0);">            
                  <i class="fas fa-caret-left caret-back {{ this.dir }}" *ngIf="this.dir === 'L'" ></i>  
                  <i class="fas fa-caret-right caret-back {{ this.dir }}" *ngIf="this.dir === 'R'" ></i>   
                  <span class="t-b {{ this.dir }} sub-menu-item-text ignore-click-outside" routerLinkActive="active-route">
                    <span class="ignore-click-outside">{{ mobileParentMenu.label }}</span>
                  </span>
                </li>
                <li *ngFor="let subEntry of mobileParentMenu.childrens" class="sub-menu-item-mobile {{ this.dir }}">            
                  <div *ngIf="!subEntry.isDisabled">
                    <a class="t-b {{ this.dir }} sub-menu-item-text" (click)="toggleMobileMenu('off'); toggleMobileSubMenu(0)" [routerLink]="[subEntry.routing]" routerLinkActive="active-route">
                      <span>{{ subEntry.label }}</span>
                    </a>
                  </div>
                  <div *ngIf="subEntry.isDisabled">
                    <div class="t-b c-light-grey {{ this.dir }} sub-menu-item-text-disabled">
                      <span>{{ subEntry.label }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <nav class="header-menu hide-mobile">
          <ul class="full-div top-header-menu-row-flex full-div header-nav-bar hand">
            <li class="nav-bar-row-desktop" *ngFor="let entry of this.menuEntries" (mouseleave)="closeSubMenus('li:' + entry.id.toString())">
              <span *ngIf="entry.isParent && !entry.isDisabled" class="t-b {{ this.dir }} parent-menu-item" (mouseenter)="openSubMenu(entry.id)">
                <span>{{ entry.label }}</span>
                <i class="fas fa-caret-down" *ngIf="this.clickedMenuItem !== entry.id"></i>
                <i class="fas fa-caret-up" *ngIf="this.clickedMenuItem === entry.id"></i>
              </span>
              <span *ngIf="entry.isParent && entry.isDisabled" class="t-b c-light-grey {{ this.dir }} parent-menu-item-disabled">
                <span>{{ entry.label }}</span>
              </span>
              <div *ngIf="entry.isParent && this.clickedMenuItem === entry.id" class="sub-menu-box">
                <ul class="sub-menu">
                  <li class="sub-menu-item {{ this.dir }}" *ngFor="let subEntry of entry.childrens">            
                    <a *ngIf="!subEntry.isDisabled" class="t-b {{ this.dir }} sub-menu-item-text" (click)="closeSubMenus('click on sub menu item')" [routerLink]="[subEntry.routing]" routerLinkActive="active-route">
                      <span>{{ subEntry.label }}</span>
                    </a>
                    <div *ngIf="subEntry.isDisabled" class="t-b c-light-grey { this.dir }} sub-menu-item-text-disabled">
                      <span>{{ subEntry.label }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <a *ngIf="!entry.isParent && !entry.isDisabled" class="t-b" [routerLink]="[entry.routing]" >
                <span>{{ entry.label }}</span>
              </a>
              <div *ngIf="!entry.isParent && entry.isDisabled" class="t-b c-light-grey">
                <span>{{ entry.label }}</span>
              </div>
              <span class="c-dark-grey">&nbsp;|&nbsp;</span>
            </li>
          </ul>
        </nav>        
      </div>
      <div class="searchbar-wrapper" (clickOutside)="clickedOutsideSearchBar()">
        <span class="c-dark-grey hide-desktop">&nbsp;|&nbsp;</span>
        <div [ngClass]="(searchOn ? 'searchbar on' : 'searchbar')">
          <i (click)="searchClick()"
            [ngClass]="(searchOn ? 'search-icon on fas fa-search' : 'search-icon fas fa-search')"></i>
          <input #searchInput (keyup.enter)="searchClick()" [ngClass]="(searchOn ? 'search-input on' : 'search-input')" type="text"
            name="" placeholder="{{this.searchPlaceholder}}" [(ngModel)]="searchQuery">
          <i *ngIf="searchOn" (click)="searchClear()" class="clear-icon fas fa-times"></i>
        </div>
      </div>
      <div [ngClass]="(searchOn ? 'dir nav-logo-wrapper narrow-search-on' : 'dir nav-logo-wrapper')">
        <!-- <a [routerLink]="['/coming-soon','צור קשר']"><span><i class="fas fa-envelope c-green contact-us"></i></span></a> -->
        <a *ngIf="lang == 'he'" [routerLink]="['/' + lang + '/externalRedirect/' + shopIL]"><span><i class="fas fa-shopping-cart fa-flip-horizontal c-orange shop"></i></span></a>
        <a *ngIf="lang == 'en'" [routerLink]="['/' + lang + '/externalRedirect/' + shopUS]"><span><i class="fas fa-shopping-cart c-orange shop"></i></span></a>
        <a *ngIf="lang == 'he'" [routerLink]="['/' + lang + '/externalRedirect/' + spniHe]">
          <img src="../assets/images/spni_logo_he_22.png" alt="" class="dir top-header-img-logo responsive-img" />
            
        </a>
        <a *ngIf="lang == 'en'" [routerLink]="['/' + lang + '/externalRedirect/' + spniEn]">
          <img src="../assets/images/spni_logo_en_22.png" alt="" class="dir top-header-img-logo responsive-img" />
        </a>
      </div>
    </div>
    <app-breadcrumbs></app-breadcrumbs>
  </div>
</section>