<section name="homepage-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-lobby-wrapper full-div">
      <app-latest-observations *ngIf="this.screenSizes.isDesktop" class="large-main-area"></app-latest-observations>
      <app-latest-observations *ngIf="this.screenSizes.isTablet" class="medium-main-area"></app-latest-observations>
        <!-- <div *ngIf="this.allTiles && this.allTiles.length > 0" class="main-area full-div"> -->
      <div class="main-area full-div">
        <!-- <div *ngIf="this.screenSizes.isDesktop" class="large-main-area"> -->
        <div *ngIf="this.screenSizes.isDesktop">
          <div class="content-row-1">
            <div class="big-tile-area main-tile">
              <app-homepage-tile  id="tile1" class="tile-main" [tile]="allTiles[0]"></app-homepage-tile>
            </div>
            <div class="big-tile-area tiles-2to5">
              <app-homepage-tile id="tile2" class="tile-not-main" [tile]="allTiles[1]" [isMobile]="false"></app-homepage-tile>
              <app-homepage-tile id="tile3" class="tile-not-main" [tile]="allTiles[2]" [isMobile]="false"></app-homepage-tile>
              <app-homepage-tile id="tile4" class="tile-not-main" [tile]="allTiles[3]" [isMobile]="false"></app-homepage-tile>
              <app-homepage-tile id="tile5" class="tile-not-main" [tile]="allTiles[4]" [isMobile]="false"></app-homepage-tile>
            </div>
          </div>
          <div class="content-row-2">
            <app-homepage-tile id="tile6" class="tile-not-main" [tile]="allTiles[5]" [isMobile]="false"></app-homepage-tile>
            <app-homepage-tile id="tile7" class="tile-not-main" [tile]="allTiles[6]" [isMobile]="false"></app-homepage-tile>
            <app-homepage-tile id="tile8" class="tile-not-main" [tile]="allTiles[7]" [isMobile]="false"></app-homepage-tile>
            <app-homepage-tile id="tile9" class="tile-not-main" [tile]="allTiles[8]" [isMobile]="false"></app-homepage-tile>
          </div>
          <app-banners-row bannerType="HPRow1Large"></app-banners-row>
          <app-side-pannel-title class="hide-column-tiles" [title] = moreTilesTitle [width]="100"></app-side-pannel-title>
          <div class="content-row-2">
            <app-homepage-tile id="tile10" class="tile-not-main" [tile]="allTiles[9]" [isMobile]="false"></app-homepage-tile>
            <app-homepage-tile id="tile11" class="tile-not-main" [tile]="allTiles[10]" [isMobile]="false"></app-homepage-tile>
            <app-homepage-tile id="tile12" class="tile-not-main" [tile]="allTiles[11]" [isMobile]="false"></app-homepage-tile>
            <app-homepage-tile id="tile13" class="tile-not-main" [tile]="allTiles[12]" [isMobile]="false"></app-homepage-tile>
          </div>
          <app-banners-row bannerType="HPRow2Large"></app-banners-row>
        </div>
        <!-- <div *ngIf="this.screenSizes.isTablet" class="medium-main-area"> -->
        <div *ngIf="this.screenSizes.isTablet">
          <div class="content-row-1">
            <div class="big-tile-area main-tile">
                <app-homepage-tile class="tile-main" [tile]="allTiles[0]" [isMobile]="true"></app-homepage-tile>
            </div>
            <div class="big-tile-area tiles-2to3-medium">
                <app-homepage-tile class="tile-not-main" [tile]="allTiles[1]" [isMobile]="true"></app-homepage-tile>
                <app-homepage-tile class="tile-not-main" [tile]="allTiles[2]" [isMobile]="true"></app-homepage-tile>
            </div>
          </div>
          <div class="content-row-2">
              <app-homepage-tile class="tile-not-main" [tile]="allTiles[3]" [isMobile]="true"></app-homepage-tile>
              <app-homepage-tile class="tile-not-main" [tile]="allTiles[4]" [isMobile]="true"></app-homepage-tile>
              <app-homepage-tile class="tile-not-main" [tile]="allTiles[5]" [isMobile]="true"></app-homepage-tile>
          </div>
          <div class="content-row-2">
              <app-homepage-tile class="tile-not-main" [tile]="allTiles[6]" [isMobile]="true"></app-homepage-tile>
              <app-homepage-tile class="tile-not-main" [tile]="allTiles[7]" [isMobile]="true"></app-homepage-tile>
              <app-homepage-tile class="tile-not-main" [tile]="allTiles[8]" [isMobile]="true"></app-homepage-tile>
          </div>
          <app-banners-row bannerType="HPRow1Medium"></app-banners-row>
          <app-side-pannel-title class="hide-column-tiles" [title] = moreTilesTitle [width]="100"></app-side-pannel-title>
          <div class="content-row-2">
            <app-homepage-tile class="tile-not-main" [tile]="allTiles[9]" [isMobile]="true"></app-homepage-tile>
            <app-homepage-tile class="tile-not-main" [tile]="allTiles[10]" [isMobile]="true"></app-homepage-tile>
            <app-homepage-tile class="tile-not-main" [tile]="allTiles[11]" [isMobile]="true"></app-homepage-tile>
          </div>
          <app-banners-row bannerType="HPRow2Medium"></app-banners-row>
        </div>
        <!-- <div *ngIf="this.screenSizes.isMobile" class="small-main-area"> -->
        <div *ngIf="this.screenSizes.isMobile">
          <div class="content-row-1">
            <div class="big-tile-area main-tile">
              <app-homepage-tile class="tile-main" *ngIf="allTiles[0]" [tile]="allTiles[0]" [isMobile]="true"></app-homepage-tile>
            </div>
          </div>
          <div class="content-row-2">
            <app-homepage-tile class="tile-not-main" *ngIf="allTiles[1]" [tile]="allTiles[1]" [isMobile]="true"></app-homepage-tile>
            <app-homepage-tile class="tile-not-main" *ngIf="allTiles[2]" [tile]="allTiles[2]" [isMobile]="true"></app-homepage-tile>
          </div>
          <div class="content-row-2">
            <app-homepage-tile class="tile-not-main" *ngIf="allTiles[3]" [tile]="allTiles[3]" [isMobile]="true"></app-homepage-tile>
            <app-homepage-tile class="tile-not-main" *ngIf="allTiles[4]" [tile]="allTiles[4]" [isMobile]="true"></app-homepage-tile>
          </div>
          <div class="content-row-2">
            <app-homepage-tile class="tile-not-main" *ngIf="allTiles[5]" [tile]="allTiles[5]" [isMobile]="true"></app-homepage-tile>
            <app-homepage-tile class="tile-not-main" *ngIf="allTiles[6]" [tile]="allTiles[6]" [isMobile]="true"></app-homepage-tile>
          </div>
          <div class="content-row-2">
            <app-homepage-tile class="tile-not-main" *ngIf="allTiles[7]" [tile]="allTiles[7]" [isMobile]="true"></app-homepage-tile>
            <app-homepage-tile class="tile-not-main" *ngIf="allTiles[8]" [tile]="allTiles[8]" [isMobile]="true"></app-homepage-tile>
          </div>
          <app-banners-row bannerType="HPRow1Mobile"></app-banners-row>
          <app-banners-row bannerType="HPRow2Mobile"></app-banners-row>
        </div>
        <app-latest-observations *ngIf="this.screenSizes.isMobile" class="small-main-area"></app-latest-observations>
      </div>
    </div>
  </div>
</section>



