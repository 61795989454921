import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MonthName'
})
export class MonthNamePipe implements PipeTransform {
  heMonthNames: string[];
  enMonthNames: string[];
  heMonthShortNames: string[];
  enMonthShortNames: string[];

  transform(value: number, lang: string, isShort = false): string {
        if (typeof value === 'undefined') {
          return 'unknown month';
        }
        if (value < 1 || value > 12) {
          return 'unknown month';
        }

        let monthName = 'unknown month';
        this.heMonthNames = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];
        this.heMonthShortNames = ['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יוני', 'יולי', 'אוג', 'ספט', 'אוק', 'נוב', 'דצמ'];
        // tslint:disable-next-line:max-line-length
        this.enMonthNames = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.enMonthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        if (isShort) {
          return monthName = (lang === 'he') ? this.heMonthShortNames[value - 1] : this.enMonthShortNames[value - 1];
        }
        return monthName = (lang === 'he') ? this.heMonthNames[value - 1] : this.enMonthNames[value - 1];
      }
}
