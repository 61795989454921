<div class="grid">
  <div class="breadcrumbs" *ngIf="breadcrumbs">
    <!-- <div class="hide-mobile"> -->
    <div class="show-for-tablet">
      <p class="bc-row {{ this.dir }}" *ngIf="breadcrumbs.items.length > 0 || breadcrumbs.lastItemTitle">
        <span class="bc-item">
          <a [routerLink]="['/' + lang + '/']">
            <span class="c-blue single-breadcrumb">{{breadcrumbs.rootItem}}</span>
          </a>
          <span>&nbsp;/&nbsp;</span>
        </span>
        <span class="bc-item" *ngFor="let bc of breadcrumbs.items;">
          <a href="{{bc.href}}">
            <span class="c-blue">{{bc.name}}</span>
            <span>&nbsp;/&nbsp;</span>
          </a>
        </span>
        <span class="bc-item">
          <span class="t-b">{{breadcrumbs.lastItemTitle}}</span>
        </span>
      </p>
    </div>
    <!-- <div class="hide-desktop"> -->
    <div class="hide-for-tablet">
      <p class="bc-row {{ this.dir }}" *ngIf="breadcrumbs.items.length > 0 || breadcrumbs.lastItemTitle">
        <span class="bc-item">
          <a [routerLink]="['/' + lang + '/']">
            <span class="c-blue single-breadcrumb"><i class="fas fa-home"></i></span>
          </a>
          <span>&nbsp;/&nbsp;</span>
        </span>
        <span class="bc-item" *ngFor="let bc of breadcrumbs.items; let last = last;">
          <span *ngIf="!last">
            <a [routerLink]="['/' + bc.href]" title="{{bc.name}}">
              <span class="c-blue">...</span>
              <span>&nbsp;/&nbsp;</span>
            </a>
          </span>
          <span *ngIf="last">
            <a href="{{bc.href}}">
              <span class="c-blue">{{bc.name}}</span>
              <span>&nbsp;/&nbsp;</span>
            </a>
          </span>
        </span>
        <span class="bc-item">
          <span class="t-b">{{breadcrumbs.lastItemTitle}}</span>
        </span>  
      </p> 
    </div>
  </div>
</div>