import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { Breadcrumbs } from '../../models/breadcrumbs';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { LoadingAnimationService } from '../../_services/loading-animation.service';

import { SpeciesResponse } from 'src/app/models/species-response';
import { SpeciesService } from 'src/app/_services/species.service';
// import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-species-page',
  templateUrl: './species-page.component.html',
  styleUrls: ['./species-page.component.css']
})
export class SpeciesPageComponent implements OnInit {
  @Output() setBreadcrumbs = new EventEmitter<Breadcrumbs>();
  species: SpeciesResponse;
  lang: string;
  dir: string;
  imgGalleryState: boolean;
  moreImagesCaption: string;
  tabDescription: string;
  tabTrends: string;
  tabMoreInfo: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router,
    public speciesService: SpeciesService,
    // public matDialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');
      const id = parseInt(params.get('id'), 0);
      this.lang = this.languageService.currentLang();
      this.dir = this.languageService.currentDir();

      // business logic what changes you want on the page with this new data.

      this.languageService.allTextsLoaded$.subscribe(ready => {
        if (ready) {
          this.tabDescription = this.languageService.getTextById('speciesPageTabDescription');
          this.tabTrends = this.languageService.getTextById('speciesPageTabTrends');
          this.tabMoreInfo = this.languageService.getTextById('speciesPageTabMoreInfo');

          if (id) {
            this.getSpeciesById(id);
          }
        }
      });
      if (this.languageService.hasKeys()) {
        this.tabDescription = this.languageService.getTextById('speciesPageTabDescription');
        this.tabTrends = this.languageService.getTextById('speciesPageTabTrends');
        this.tabMoreInfo = this.languageService.getTextById('speciesPageTabMoreInfo');

        if (id) {
          this.getSpeciesById(id);
        }
      }
    });

  }

  private getArticleBySlug(articleSlug: string) {
  }

  // article by id - keep this option for our convenience
  private getSpeciesById(speciesId: number) {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.http.get(this.apiService.getServerUrl() + '/api/species/byid/' + this.lang + '/' + speciesId)
      .subscribe((response: SpeciesResponse) => {
        this.species = response;

        this.speciesService.setSrvSpecies(this.species);

        // prepare the breadcrumbs
        this.bcService.clearLevels();
        this.bcService.setLastItemTitle(this.species.name);
        this.bcService.addLevel(this.languageService.getTextById('IsraelsBirds'), 'species-families/');
        this.bcService.addLevel(this.species.speciesFamilyName, 'species-family/' + this.species.speciesFamilyId);

        let pageTitle = this.species.name + ' | ';
        pageTitle += this.species.speciesFamilyName;

        pageTitle += ' - ' + this.languageService.getTextById('baseSeoTitle');
        const pageImage = this.species.imageUrl && this.species.imageUrl.path;
        const keywords =  this.species.name + ','
                          + this.species.latinName + ','
                          + this.languageService.getTextById('baseSeoKeywords');

        const desciption = this.species.shortDescription + ','
                            + this.languageService.getTextById('baseSeoDescription');

        const pageUrl = location.origin + '/species/' + this.species.name;
        const canonicalUrl = location.origin + '/' + this.lang + '/species/' + this.species.id;
        const pageType = 'עמוד מין';
        this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, canonicalUrl);

        // publish
        this.bcService.publishBreadcrumbs();

        this.moreImagesCaption = this.languageService.getTextById('GalleryImagesCaption');
        this.loadingAnimationService.publishLoadingAnimation(false);
        this.routeToInitTab();
      },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        }
      );
  }

  private routeToInitTab() {
    this.router.navigate(['./species-description'], { relativeTo: this.route });
  }

  swipeHandlerLeft() {
    this.swipeTabs(this.dir, 'L');
  }
  swipeHandlerRight() {
    this.swipeTabs(this.dir, 'R');
  }

  private swipeTabs(dir: string, swipeDir: string) {
    // alert('Swipe next!');
    const isNext = (dir === swipeDir) ? false : true;
    const _activeTab = this.getActiveTab();
    if (isNext) {
      switch (_activeTab) {
        case 'species-description': {
          this.router.navigate(['./species-trends'], { relativeTo: this.route });
          break;
        }
        case 'species-trends': {
          this.router.navigate(['./species-moreinfo'], { relativeTo: this.route });
          break;
        }
      }
    } else {
        switch (_activeTab) {
          case 'species-trends': {
            this.router.navigate(['./species-description'], { relativeTo: this.route });
            break;
          }
          case 'species-moreinfo': {
            this.router.navigate(['./species-trends'], { relativeTo: this.route });
            break;
          }
        }
    }
  }

  private getActiveTab() {
    const urlParts = this.router.url.split('/');
    const _len = urlParts.length;
    const activeTab = urlParts[_len - 1];

    return activeTab;
  }

}
