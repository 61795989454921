import { UrlResponse } from './url-response';

export class Breadcrumbs {
    lastItemTitle: string;
    items: UrlResponse[];
    rootItem: string;
    // pageTitle: string;
    // pageDescription: string;
    // pageKeywords: string;
}
