<div class="full-div">
  <div class="grid">
    <div class="text-align-center">
      <h2> {{ pageTitle }} </h2>

      <p>{{ this.languageService.getTextById('ErrorPageP1')}}</p>
      <p>{{ this.languageService.getTextById('PageNotFoundP2')}}
        <a class="link" [routerLink]="['', this.lang]">{{ this.languageService.getTextById('PageNotFoundP3')}}</a>
      </p>

      <img src="/assets/images/crying-bird.gif" [alt]="imgAlt">
      <!-- <img src="https://d3np07p3hj9hly.cloudfront.net/assets/images/crying-bird.gif" [alt]="imgAlt"> -->
    </div>
  </div>
</div>