import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { LifecycleHooks } from '@angular/compiler/src/lifecycle_reflector';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  @Input() lat: string;
  @Input() lng: string;
  @Input() destinationName: string;
  @Input() phone: string;
  @Input() contactUsId: number;

  constructor() {}

  ngOnInit() {

    // this.moovit = 'https://moovit.com/?to=' + this.destinationName + '&tll=' + this.lat + '_' + this.lng + '&metroId=1&lang=he';

    // alternate moovit href for different device browsers...
    // this.moovit = 'moovit://directions?dest_lat=' + this.lat + '&dest_lon=' + this.lng + '&dest_name=' + this.destinationName;
  }
}
