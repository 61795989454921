import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Animations } from 'src/app/animations';
import { ViewEncapsulation } from '@angular/core';
import { Breadcrumbs } from 'src/app/models/breadcrumbs';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ArticleService } from 'src/app/_services/article.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CameraResponse } from 'src/app/models/camera-response';
import { BlogPostResponse } from 'src/app/models/blog-post-response';
import { BlogPostYearlyGroupedResponse } from 'src/app/models/blog-post-yearly-grouped-response';
import { CameraBroadcastResponse } from 'src/app/models/camera-broadcast-response';
import { ImageResponse } from 'src/app/models/image-response';
import { ScreenSizes } from 'src/app/models/screen-sizes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cameras',
  templateUrl: './cameras.component.html',
  styleUrls: ['./cameras.component.css'],
  animations: [Animations],
  encapsulation: ViewEncapsulation.None

})
export class CamerasComponent implements OnInit {
  @Output() setBreadcrumbs = new EventEmitter<Breadcrumbs>();
  cameras: CameraBroadcastResponse[];
  lang: string;
  dir: string;
  _locale: string;
  pageTitle: string;
  activeCamerasTitle: string;
  camerasLobbyDescription: string;
  donationCameraFile: ImageResponse;
  donationPage: string;
  OfflineCameraFile: ImageResponse;
  screenSizes: ScreenSizes;
  pauseTimeout: number;
  isValidByPassCode = false;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';
    this._locale = this.languageService._locale();
    this.activeCamerasTitle = this.languageService.getTextById('activeCamerasTitle');
    this.pageTitle = this.languageService.getTextById('camerasLobbyTitle');
    this.camerasLobbyDescription = this.languageService.getTextById('camerasLobbyDescription');
    this.screenSizes = this.languageService.getScreenSizes();
    this.pauseTimeout = environment.cameras.lobbyPauseTimeout;

    this.languageService.screenSizesUpdated$.subscribe(ready => {
      if (ready) {
        this.screenSizes = this.languageService.getScreenSizes();
      }
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      const code = parseInt(params.get('code'), 0);
      this.isValidByPassCode = (code === environment.cameras.bypassCode) ? true : false;
    });

    this.getActiveCameras();
  }

  private getActiveCameras() {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.http.get(this.apiService.getServerUrl() + '/api/cameras/onlineCameras/' + this.lang)
    .subscribe((response: CameraBroadcastResponse[]) => {
      this.cameras = response;

      // init player state
      this.cameras.forEach(element => {
        element.playerState = '';
      });

      // prepare the breadcrumbs
      this.bcService.clearLevels();
      this.bcService.setLastItemTitle(this.languageService.getTextById('cameras'));

      let pageTitle = this.languageService.getTextById('cameras') + ' | ';
      pageTitle += ' - ' + this.languageService.getTextById('baseSeoTitle');
      const pageImage = null;
      const keywords =  this.languageService.getTextById('cameras') + ','
                        + this.languageService.getTextById('baseSeoKeywords');

      const desciption = this.camerasLobbyDescription + ','
                        + this.languageService.getTextById('baseSeoDescription');

      const pageUrl = location.origin + '/cameras/';
      const canonicalUrl = location.origin + '/' + this.lang + '/cameras/';
      const pageType = this.languageService.getTextById('cameras');

      this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, canonicalUrl);

      // publish
      this.bcService.publishBreadcrumbs();

      // get image file of "donation camera"
      this.http.get(this.apiService.getServerUrl() + '/api/files/byname/' + this.lang + '/Donation-Online-Project-587-' + this.lang)
      .subscribe((imgResponse: ImageResponse) => {
        this.donationCameraFile = imgResponse;
        if (this.donationCameraFile) {
          this.donationPage = encodeURIComponent(this.donationCameraFile.tooltip);
        }
      },
      error => {
        console.log(error);
        this.loadingAnimationService.publishLoadingAnimation(false);
      });

      // get image file of "offline camera"
      this.http.get(this.apiService.getServerUrl() + '/api/files/byname/' + this.lang + '/Camera-Offline-587-' + this.lang)
      .subscribe((imgResponse: ImageResponse) => {
        this.OfflineCameraFile = imgResponse;
      },
      error => {
        console.log(error);
        this.loadingAnimationService.publishLoadingAnimation(false);
      });

      this.loadingAnimationService.publishLoadingAnimation(false);
    },
    error => {
      console.log(error);
      this.loadingAnimationService.publishLoadingAnimation(false);
      if (error.status === 404) {
        this.router.navigate([this.lang, 'not-found']);
      } else {
        this.router.navigate([this.lang, 'error']);
      }
    });
  }

  onPlayerState(state: string) {
    console.log('player state: ' + state);
    const camState = state.split('|');
    const cam = this.cameras.find(i => i.id.toString() === camState[0]);
    cam.playerState = camState[1];
    console.log('set state' + state + 'to cam ' + cam.id);
  }
}
