<section name="species-families-lobby-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-lobby-wrapper full-div">
      <h1>{{ this.title }}</h1>
      <div>{{ this.pageDesc }}</div>
      <br>    
      <div *ngIf="speciesFamilies"> 
        <app-filter-bar [filterTextParam]='filterTextParam' (filterTextSync) = "onFilterTextChange($event)"></app-filter-bar>
        <app-side-pannel-title *ngIf="speTiles && speTiles.length > 0" [title]="familiesTitle" [width]="100"></app-side-pannel-title>
        <div name="species-families" class="species-families-flex grid">
          <div *ngFor="let spfTile of spfTiles | filter : filterText:false" >
            <app-tile [tile]=spfTile [isSmall]="true"></app-tile>
          </div>
        </div>
        <app-side-pannel-title *ngIf="speTiles && speTiles.length > 0" [title]="speciesTitle" [width]="100"></app-side-pannel-title>
        <div name="species-families" class="species-families-flex grid">
          <div *ngFor="let speTile of speTiles" >
            <app-tile [tile]=speTile [isSmall]="true"></app-tile>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>