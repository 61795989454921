import { Component, OnInit, Input } from '@angular/core';
import { Tile } from '../../models/tile';
import { LanguageService } from '../../_services/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css']
})

export class TileComponent implements OnInit {
  @Input() tile: Tile;
  @Input() isSmall = false;
  lang: string;
  dir: string;
  maxTags: number;

  constructor(
    private languageService: LanguageService,
    private router: Router) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    
    this.maxTags = this.isSmall ? 3 : 5;
  }
}
