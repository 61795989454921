<div class="main-wrapper main-species-wrapper full-div">
  <div class="third-div  {{this.dir}}">
    <div class="species-subtxt hide-desktop full-div">
      <h2>{{ species.shortDescription }}</h2>
    </div>
    <app-ebird-map  class="full-div" *ngIf="species && species.ebirdCode" 
      [eBirdMapTitle]="ebirdMapTitle" [eBirdSpeciesCode]="species.ebirdCode" [GotoEBirdMap]="this.gotoEBirdMap"></app-ebird-map>
    <div *ngIf="species && species.conservationLevelIL && species.conservationLevelEU && species.conservationLevelWo">
      <app-side-pannel-title [title] = "conservationLevelTitle" [width]="100"></app-side-pannel-title>
      <div class="conservation-levels-container">
        <div class="conservation-level title">{{conservationLevelTitleIL}}</div>
        <div class="conservation-level title">{{conservationLevelTitleEU}}</div>
        <div class="conservation-level title">{{conservationLevelTitleWo}}</div>
      </div>
      <div class="conservation-levels-container">
        <div class="conservation-level badge {{species.conservationLevelIL}}">{{ species.conservationLevelIL }}</div>
        <div class="conservation-level badge {{species.conservationLevelEU}}">{{ species.conservationLevelEU }}</div>
        <div class="conservation-level badge {{species.conservationLevelWo}}">{{ species.conservationLevelWo }}</div>
      </div>
      <div class="conservation-level-legend">
        <span>{{ conservationLevelsLegend }}</span>
      </div>
    </div>
  </div>
  <div class="species-main two-third-div  {{this.dir}}">
    <app-side-pannel-title [title]="weeklychartTitle" [width]="100"></app-side-pannel-title>
    <!-- <div class="weekly-chart full-div hide-mobile">
      <app-weekly-bar-chart [speId]="species.id"></app-weekly-bar-chart>
    </div> -->
    <div class="weekly-chart full-div">
      <app-speceis-obs-frequencies-chart [speId]="species.id"></app-speceis-obs-frequencies-chart>
    </div>
    <div *ngIf="species.isSensitiveSpecies">
      <p class="sensitive">{{ sensitiveSpeciesTitle }}</p>
    </div>
    <div *ngIf="species && species.latestObservations && species.latestObservations.length > 0">
      <app-side-pannel-title [title]= "latestObservationsTitle" [width]="100"></app-side-pannel-title>
      <div class="latest-obs full-div">
        <table class="latest-observations-table">
          <tr class="latest-observations-tr">
            <th>{{ dateHeader }}</th>
            <th>{{ locationHeader }}</th>
            <th>{{ countHeader }}</th>
            <th>{{ observerHeader }}</th>
          </tr>
          <tr class="latest-observations-tr" *ngFor="let obsRow of species.latestObservations">
            <td>{{ obsRow.date | date:'dd/MM/yyyy':this._locale }}</td>
            <!-- <td>{{ obsRow.date }}</td> -->
            <td>{{ obsRow.location }}</td>
            <td>{{ obsRow.speciesCount }}</td>
            <td>{{ obsRow.observerName }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
