import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Routing301 } from '../models/routing301';
import { LanguageService } from './language.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PathResolveService implements Resolve<Routing301 | null> {
  lang: string;

  constructor(private languageService: LanguageService,
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Routing301 | null {
    // console.log(state.url);

    const result: Routing301 = {
      foundReplacement: false,
      navigateTo: ''
    };

    const url = state.url.toLowerCase();

    console.log(url);
    if (url.trim() === '/') {
      // if (this.languageService.hasLang()) {
      const currentLang = this.languageService.currentLang();
      result.foundReplacement = true;
      result.navigateTo = '/' + currentLang;

      return result;
      // } else {
      //   this.http.get(this.apiService.getServerUrl() + '/api/system/country/IL')
      //     .subscribe((response: boolean) => {
      //       if (response) {
      //         this.languageService.appComponentChangeLang('he');
      //       } else {
      //         this.languageService.appComponentChangeLang('en');
      //       }
      //       window.location.reload();
      //     });
      // }
    }

    if (url.indexOf('/he/') >= 0) {
      this.lang = 'he';
    } else if (url.indexOf('langid=1') >= 0) {
      this.lang = 'he';
    } else if (url.indexOf('/en/') >= 0) {
      this.lang = 'en';
    } else if (url.indexOf('langid=2') >= 0) {
      this.lang = 'en';
    } else {
      this.lang = 'he';
    }

    const articleRegex = /article-page\.aspx.+articleId=(\d+)/gi;
    let m = articleRegex.exec(url);
    if (m) {
      // console.log(m);
      const artId = m[1];
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/article/id/' + artId;
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

   const authorArticles = /\?author=([\w\S]+)\&?/gi;
    m = authorArticles.exec(url);
    if (m) {
      // console.log(m);
      const author = m[1];
      result.foundReplacement = true;
      result.navigateTo = '/he/articles/author/' + author;
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const birdingCentersRegex = /birding-centers\.aspx/gi;
    m = birdingCentersRegex.exec(url);
    if (m) {
      // console.log(m);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/birding-centers';
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const birdingCenterRegex = /bird-center-page\.aspx.+centerId=(\d+)/gi;
    m = birdingCenterRegex.exec(url);
    if (m) {
      // console.log(m);
      const bcId = m[1];
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/birding-center/id/' + bcId;
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const homePageRegex = /index\.aspx/gi;
    m = homePageRegex.exec(url);
    if (m) {
      // console.log(m);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang;
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const speciesOrderRegex = /species-order\.aspx/gi;
    m = speciesOrderRegex.exec(url);
    if (m) {
      // console.log(m);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/species-families';
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const speciesLobbyRegex = /species-lobby\.aspx.+orderId=(\d+)/gi;
    m = speciesLobbyRegex.exec(url);
    if (m) {
      // console.log(m);
      const orderId = m[1];
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/species-family/' + orderId;
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const speciesRegex = /species-page\.aspx.+speciesId=(\d+)/gi;
    m = speciesRegex.exec(url);
    if (m) {
      // console.log(m);
      const speId = m[1];
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/species-page/' + speId;
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const speciesIdRegex = /species\/+(\d+)/gi;
    m = speciesIdRegex.exec(url);
    if (m) {
      // console.log(m);
      const speId = m[1];
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/species-page/' + speId;
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const eventsRegex = /events\.aspx/gi;
    m = eventsRegex.exec(url);
    if (m) {
      // console.log(m);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/events';
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const eventRegex = /event-page\.aspx.+eventId=(\d+)/gi;
    m = eventRegex.exec(url);
    if (m) {
      // console.log(m);
      const eventId = m[1];
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/event/id/' + eventId;
      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    // blogPost-page blogPost-page.aspx?bpstID=0&blgId=5
    const blogPostRegex = /blogpost-page\.aspx.+bpstid=(\d+).+blgid=(\d+)/gi;
    m = blogPostRegex.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      const postId = parseInt(m[1]);
      // tslint:disable-next-line: radix
      const blogId = parseInt(m[2]);
      result.foundReplacement = true;
      if (postId === 0) {
        result.navigateTo = '/' + this.lang + '/blog/id/' + blogId;
      } else {
        result.navigateTo = '/' + this.lang + '/blog/id/' + blogId + '/' + postId;
      }

      console.log('navigate to:' + result.navigateTo);

      return result;
    }

    const blogPostRegex2 = /blogpost-page\.aspx.+blgid=(\d+).+bpstid=(\d+)/gi;
    m = blogPostRegex2.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      const blogId = parseInt(m[1]);
      // tslint:disable-next-line: radix
      const postId = parseInt(m[2]);
      result.foundReplacement = true;
      if (postId === 0) {
        result.navigateTo = '/' + this.lang + '/blog/id/' + blogId;
      } else {
        result.navigateTo = '/' + this.lang + '/blog/id/' + blogId + '/' + postId;
      }

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const blogPostRegex3 = /blogpost-page\.aspx.+blgid=(\d+)/gi;
    m = blogPostRegex3.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      const blogId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/blog/id/' + blogId;

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const rarityObsRegex = /ObsRarityRecords\.aspx/gi;
    m = rarityObsRegex.exec(url);
    if (m) {
      // console.log(m);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/rarityObservationNews';

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const cameraRegex = /tracking-methods\.aspx.+camId=(\d+)/gi;
    m = cameraRegex.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/camera/' + camId;

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const obsDetails = /observationDetails\.aspx/gi;
    m = obsDetails.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/observations';

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const countPage = /count\.aspx/gi;
    m = countPage.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/';

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const blogs = /blogs\.aspx/gi;
    m = blogs.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/blogs';

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const articles = /articles[(\.aspx)\?]/gi;
    m = articles.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/articles/type/article';

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const hebArticles = /%D7%9B%D7%AA%D7%91%D7%95%D7%AA/gi;
    m = hebArticles.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/articles/type/article';

      console.log('navigate to:' + result.navigateTo);
      return result;
    }
    const newsFlash = /news-flash\.aspx/gi;
    m = newsFlash.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang;

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const imgGallery1 = /image-gallery\.aspx/gi;
    m = imgGallery1.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang;

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const imgGallery2 = /albums\.aspx/gi;
    m = imgGallery2.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang;

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    const cameras = /cameras\.aspx/gi;
    m = cameras.exec(url);
    if (m) {
      // console.log(m);
      // tslint:disable-next-line: radix
      // const camId = parseInt(m[1]);
      result.foundReplacement = true;
      if (this.lang === 'he') {
        result.navigateTo = '/' + this.lang + '/camera/26';
      } else {
        result.navigateTo = '/' + this.lang;
      }

      console.log('navigate to:' + result.navigateTo);
      return result;
    }
    const dutchTripRegex = /Deutsch\-Trip/gi;
    m = dutchTripRegex.exec(url);
    if (this.lang === 'en' && m) {
      // console.log(m);
      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang + '/event/birds-trip-deutsch';

      console.log('navigate to:' + result.navigateTo);
      return result;
    }

    // Vero old site routing
    const oldHebSiteRegex = /\/(\d+\-he)\/birding\-israel\.aspx/gi;
    m = oldHebSiteRegex.exec(url);
    if (m) {
      this.lang = 'he';
      const page = m[1];

      result.foundReplacement = true;
      if (page === '743-he') {
        result.navigateTo = '/' + this.lang + '/article/id/21';
      } else if (page === '842-he') {
        result.navigateTo = '/' + this.lang + '/species-page/376/species-description';
      } else {
        result.navigateTo = '/' + this.lang;
      }
      return result;
    }

    const oldEngSiteRegex = /\/(\d+\-en)\/birding\-israel\.aspx/gi;
    m = oldEngSiteRegex.exec(url);
    if (m) {
      this.lang = 'en';
      const page = m[1];

      result.foundReplacement = true;
      result.navigateTo = '/' + this.lang;
      return result;
    }

    return result;

    // const typoPath = state.url.replace('/', '');
    // const threshold = this.getThreshold(typoPath);
    // const dictionary = Object.values(paths)
    //   .filter(path => Math.abs(path.length - typoPath.length) < threshold);

    // if (!dictionary.length) return null;

    // this.sortByDistances(typoPath, dictionary);

    // return '';
    // return `/${dictionary[0]}`;
  }
}
