import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { LanguageService } from 'src/app/_services/language.service';

@Component({
  selector: 'app-cms-menu',
  templateUrl: './cms-menu.component.html',
  styleUrls: ['./cms-menu.component.css']
})
export class CmsMenuComponent implements OnInit {

  constructor(private authService: AuthService, private languageService: LanguageService) { }
  isRingingManager: boolean;
  isRinger: boolean;
  lang: string;
  isEbird = false;


  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.isRingingManager = this.authService.isPermittedTo('IsRingingManager');
    this.isRinger = this.authService.isPermittedTo('IsRinger');    
  }

  
}
