import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Animations } from 'src/app/animations';
import { ViewEncapsulation } from '@angular/core';
import { Breadcrumbs } from 'src/app/models/breadcrumbs';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ArticleService } from 'src/app/_services/article.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { BlogPostResponse } from 'src/app/models/blog-post-response';
import { BlogResponse } from 'src/app/models/blog-response';
import { BlogPostYearlyGroupedResponse } from 'src/app/models/blog-post-yearly-grouped-response';
import { NestedArray } from 'src/app/models/nested-array';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.css'],
  animations: [Animations],
  encapsulation: ViewEncapsulation.None

})
export class BlogPostComponent implements OnInit {
  @Output()
  setBreadcrumbs = new EventEmitter<Breadcrumbs>();

  post: BlogPostResponse;
  blog: BlogResponse;
  allBlogPosts: BlogPostYearlyGroupedResponse[] = [];
  lang: string;
  dir: string;
  _locale: string;
  aboutAuthorTitle = '';
  morePostsTitle = '';
  badgeText = '';
  showAboutAuthor: boolean;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.route.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');
      const bid = parseInt(params.get('id'), 0);
      let pid = parseInt(params.get('pid'), 0);
      this.dir = (this.lang === 'he') ? 'R' : 'L';
      this._locale = this.languageService._locale();
      this.aboutAuthorTitle = this.languageService.getTextById('blogAboutAuthor');
      this.morePostsTitle = this.languageService.getTextById('blogMorePosts');
      this.badgeText = this.languageService.getTextById('blogBadge');


      if (pid) {
        this.getPostById(pid);
      } else if (bid) {
        this.http.get(this.apiService.getServerUrl() + '/api/blogs/blogLatestPost/' + this.lang + '/' + bid)
        .subscribe((response: number) => {
          pid = response;
          this.getPostById(pid);
        },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });

      }

      if (slug) {
        // this.getArticleBySlug(slug);
      }
    });

  }

    // by id - keep this option for our convenience
    private getPostById(id: number) {
      this.loadingAnimationService.publishLoadingAnimation(true);

      this.http.get(this.apiService.getServerUrl() + '/api/blogs/post/' + this.lang + '/' + id)
        .subscribe((response: BlogPostResponse) => {
          this.post = response;
          this.blog = this.post.blog;
          this.allBlogPosts = this.blog.allBlogPosts;

          // prepare the breadcrumbs
          this.bcService.clearLevels();
          this.bcService.setLastItemTitle(this.post.title);
          this.bcService.addLevel(this.languageService.getTextById('blogs'), 'blogs');
          this.bcService.addLevel(this.blog.title, 'blog/id/' + this.blog.id + '/' + this.blog.latestPostId);
                                            // + this.allBlogPosts[0]['blogPostMonthGroups'][0]['blogPostHeaders'][0]['id']);

          let pageTitle = this.post.title + ' | ';
          pageTitle += this.blog.title + ' | ';
          pageTitle += this.languageService.getTextById('blogs');

          pageTitle += ' - ' + this.languageService.getTextById('baseSeoTitle');
          const pageImage = this.blog.imageUrl && this.blog.imageUrl.path;
          const keywords =  this.blog.authorName + ','
                            + this.blog.title + ','
                            + this.post.title + ','
                            + this.languageService.getTextById('baseSeoKeywords');

          const desciption = this.post.title + ','
                              + this.blog.title
                              + this.languageService.getTextById('baseSeoDescription');

          const pageUrl = location.origin + '/' + this.lang + '/blog/id/' + this.blog.id + '/' + id;
          // const canonicalUrl = location.origin + '/' + this.lang + '/blog/' + encodeURIComponent(this.blog.title) + '/' +
            encodeURIComponent(this.post.title);
          const pageType = this.languageService.getTextById('blogPageType');

          this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, pageUrl);

          // publish
          this.bcService.publishBreadcrumbs();

          this.loadingAnimationService.publishLoadingAnimation(false);
        },
          error => {
            console.log(error);
            this.loadingAnimationService.publishLoadingAnimation(false);
            if (error.status === 404) {
              this.router.navigate([this.lang, 'not-found']);
            } else {
              this.router.navigate([this.lang, 'error']);
            }
          }
        );
    }

    public toggleAboutAuthor(action: string) {
      if (action === 'show') {
        this.showAboutAuthor = true;
      }
      if (action === 'hide') {
        this.showAboutAuthor = false;
      }
    }

    public onNavigateFromTree ({blogId, blogPostId}) {
      this.router.navigate(['/' + this.lang + '/blog/id/' + blogId + '/' + blogPostId]);
    }

  }
