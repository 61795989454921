import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Breadcrumbs } from 'src/app/models/breadcrumbs';
import { ObservationsNewsListResponse } from 'src/app/models/ObservationsNewsListResponse';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ArticleTileResponse } from 'src/app/models/article-tile-response';
import { ObservationsNewsResponse } from 'src/app/models/observations-news-response';
import { FilterPipe } from 'src/app/_pipes/filter.pipe';
import { MyMapMarker } from 'src/app/models/my-map-marker';

@Component({
  selector: 'app-obs-news',
  templateUrl: './obs-news.component.html',
  styleUrls: ['./obs-news.component.css']
})
export class ObsNewsComponent implements OnInit, OnDestroy {
  @Output() setBreadcrumbs = new EventEmitter<Breadcrumbs>();

  @ViewChild('searchInput') nameField: ElementRef;
  searchOn = false;
  searchQuery: string;
  searchPlaceholder: string;

  obsNews: ObservationsNewsListResponse[] = [];
  obsLatestNewsItems: ObservationsNewsResponse[] = [];
  obsForMarkers: ObservationsNewsResponse[] = [];
  linkedArticles: ArticleTileResponse[];
  lang: string;
  dir: string;
  expendItemsGroups: boolean[] = [];
  dateGroupHeader: string;
  birdSilhouette: string;
  lastPageOpenDate?: Date;
  title: string;
  subtitle: string;
  mapTitle: string;
  articlesTitle: string;
  mapMarkers: MyMapMarker[] = [];
  // mapMarkersCont: MyMapMarker[] = [];
  // mapMarkersNew: MyMapMarker[] = [];
  // mapMarkersMegaCont: MyMapMarker[] = [];
  // mapMarkersMegaNew: MyMapMarker[] = [];
  israelLat = 31.4117;
  israelLng = 35.0818;
  filterText: string;
  hideMap = true;
  hideList = false;
  count: number;
  mapLegend: string;
  mapLegendIcon1: string;
  mapLegendIcon2: string;
  mapLegendIcon3: string;
  mapLegendIcon4: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router,
    private filter: FilterPipe
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();

    this.route.paramMap.subscribe((params: ParamMap) => {

      this.count = parseInt(params.get('count'), 0);
      // count = (count) ? count : 100; // default and max count is 100
      this.count = (this.count) ? this.count : 31; // 1 month
      this.dir = this.languageService.currentDir();
      this.title = this.languageService.getTextById('baseRarityObservationsTitle');
      this.subtitle = this.languageService.getTextById('obsNewsSubTitle');
      this.mapTitle = this.languageService.getTextById('LatestRaritiesMapTitle');
      this.articlesTitle = this.languageService.getTextById('RaritiesArticlesTitle');
      this.searchPlaceholder = this.languageService.getTextById('Search');
      this.mapLegend = this.languageService.getTextById('MapLegend');
      this.mapLegendIcon1 = this.languageService.getTextById('MapLegendIcon1');
      this.mapLegendIcon2 = this.languageService.getTextById('MapLegendIcon2');
      this.mapLegendIcon3 = this.languageService.getTextById('MapLegendIcon3');
      this.mapLegendIcon4 = this.languageService.getTextById('MapLegendIcon4');

      this.languageService.allTextsLoaded$.subscribe(ready => {
        if (ready) {
          this.getObsDailyNews(this.count);
          this.getLinkedArticles();
        }
      });
      if (this.languageService.hasKeys()) {
        this.getObsDailyNews(this.count);
        this.getLinkedArticles();
      }
    });
  }

  private getObsDailyNews(count: number) {
    this.loadingAnimationService.publishLoadingAnimation(true);
    this.http.get(this.apiService.getServerUrl() + '/api/ObservationNews/latest/' + this.lang + '/' + count)
      .subscribe((response: ObservationsNewsListResponse[]) => {
        this.obsNews = response;
        this.processObsDailyNews();
      },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
  }

  private processObsDailyNews() {
    this.dateGroupHeader = this.languageService.getTextById('obsNewsDateGroupHeader');
    this.birdSilhouette = '../assets/images/Sparrow_Silhouette.png';
    // this.birdSilhouette = 'https://d3np07p3hj9hly.cloudfront.net/assets/images/Sparrow_Silhouette.png';

    // group expend logic
    const _LastObsNewsOpenDate = localStorage.getItem('LastObsNewsOpenDate');
    if (_LastObsNewsOpenDate === '' || _LastObsNewsOpenDate === null || _LastObsNewsOpenDate === undefined) {
      this.lastPageOpenDate = null;
    } else {
      this.lastPageOpenDate = new Date(_LastObsNewsOpenDate);
    }

    if (this.obsNews) {
      this.obsNews.forEach(element => {
        if (this.lastPageOpenDate === null) {
          this.expendItemsGroups.push(true);
        } else if (new Date(element.date) > this.lastPageOpenDate) {
          this.expendItemsGroups.push(true);
        } else {
          this.expendItemsGroups.push(false);
        }
      });
      this.expendItemsGroups[0] = true;

      // map markers
      this.obsForMarkers = this.obsLatestNewsItems = [];
      for (let index = 0; index < this.obsNews.length; index++) { // iterate over date groups
        this.obsNews[index].obsNewsItems.forEach(element => {
          if (element.odnLocationLat && element.odnLocationLong) {
            this.obsLatestNewsItems.push(element);
          }
        });
      }
      this.obsForMarkers = this.obsLatestNewsItems;
      this.CreateMapMarkers();
    }
    // prepare the breadcrumbs
    this.bcService.clearLevels();
    this.bcService.setLastItemTitle(this.languageService.getTextById('baseRarityObservationsTitle'));
    // this.bcService.addLevel(this.languageService.getTextById('baseRarityObservationsTitle'), 'rarityObservationNews/');
    const pageTitle = this.languageService.getTextById('baseRarityObservationsTitle')
      + ' - ' + this.languageService.getTextById('baseSeoTitle');
    // const pageImage = this.bc.imageUrl && this.bc.imageUrl.path;
    const keywords = this.languageService.getTextById('baseRarityObservationsTitle')
      + ',' + this.languageService.getTextById('baseSeoKeywords');
    const desciption = this.languageService.getTextById('baseRarityObservationsTitle')
      + ',' + this.languageService.getTextById('baseSeoDescription');
    const pagePath = '/rarityObservationNews/';
    const pageUrl = location.origin + pagePath;
    const canonicalUrl = location.origin + '/' + this.lang + pagePath;

    const pageType = this.languageService.getTextById('baseBirdingCenterTitle');
    this.bcService.seoItems(pageTitle, keywords, desciption, '', pageUrl, pageType, canonicalUrl);

    // publish
    this.bcService.publishBreadcrumbs();

    this.loadingAnimationService.publishLoadingAnimation(false);
  }

  public toggleExpend(groupId: number) {
    this.expendItemsGroups[groupId] = !this.expendItemsGroups[groupId];
  }

  private getLinkedArticles(artCount: number = 5) {
    this.loadingAnimationService.publishLoadingAnimation(true);
    const type = 12; // 12: ArticleType = RaritesTales
    this.http.get(this.apiService.getServerUrl() + '/api/articles/latest/' + this.lang + '/' + type + '/' + artCount)
      .subscribe((response: ArticleTileResponse[]) => {
        this.linkedArticles = response;
      },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          // no need for that because its only related articles on side panel
          // if (error.status === 404) {
          //   this.router.navigate([this.lang, 'not-found']);
          // } else {
          //   this.router.navigate([this.lang, 'error']);
          // }
        }
      );
  }

  private CreateMapMarkers() {
    const markerIconCont = '/assets/images/map-marker.png';
    const markerIconNew = '/assets/images/map-marker-orange.png';
    const markerIconMegaCont = '/assets/images/map-marker-green-large.png';
    const markerIconMegaNew = '/assets/images/map-marker-orange-large.png';

    this.mapMarkers = [];
    this.obsForMarkers.forEach(element => {
      const markerHtmlInfo = '<u>' + this.languageService.showLongDate(element.odnDateTime) + '</u>'
        + '<br/>' + element.odnParagraph.replace('**', '<b>').replace('!!', '</b>');
      

      if (element && element.odnLocationLat && element.odnLocationLong) {
        
        let marker: MyMapMarker = {
          lat: element.odnLocationLat,
          lng: element.odnLocationLong,
          title: markerHtmlInfo
        };

        //console.log(marker);
      
        if (!element.odnIsNew && !element.odnIsMega) {
          marker.icon = markerIconCont;
          this.mapMarkers.push(marker);
        }
        if (!element.odnIsNew && element.odnIsMega) {
          marker.icon = markerIconMegaCont;
          this.mapMarkers.push(marker);
        }
        if (element.odnIsNew && !element.odnIsMega) {
          marker.icon = markerIconNew;
          this.mapMarkers.push(marker);
        }
        if (element.odnIsNew && element.odnIsMega) {
          marker.icon = markerIconMegaNew;
          this.mapMarkers.push(marker);
        }
      }
    });
    // console.log('leaving CreateMapMarkers()');
    // console.log(this.mapMarkers)
  }

  toggleView() {
    this.hideMap = !this.hideMap;
    this.hideList = !this.hideList;
    if (!this.hideMap) {
      this.CreateMapMarkers();
    }
  }

  onFilterTextChange(filterTextSync: string) {
    this.filterText = filterTextSync;

    // markers
    this.obsForMarkers = this.obsLatestNewsItems;
    this.obsForMarkers = this.filter.transform(this.obsForMarkers, this.filterText);
    this.CreateMapMarkers();
  }

  clickedOutsideSearchBar() {
    this.searchOn = false;
  }

  searchClick() {
    if (this.searchOn && this.searchQuery !== undefined && this.searchQuery !== '') {
      this.loadingAnimationService.publishLoadingAnimation(true);
      // console.log('perform search with query string: ' + this.searchQuery);
      this.http.get(this.apiService.getServerUrl() + '/api/ObservationNews/search/' + this.lang + '/' + this.searchQuery)
        .subscribe((response: ObservationsNewsListResponse[]) => {
          this.obsNews = response;
          // console.log('search returned with: ' + this.obsNews.length);
          this.processObsDailyNews();
        },
          error => {
            console.log(error);
            this.loadingAnimationService.publishLoadingAnimation(false);
            if (error.status === 404) {
              this.router.navigate([this.lang, 'not-found']);
            } else {
              this.router.navigate([this.lang, 'error']);
            }
          });

      // this.router.navigate(['/' + this.lang + '/search/' + this.searchQuery]);
    } else {
      this.searchOn = !this.searchOn;
      if (this.searchOn) {
        this.nameField.nativeElement.focus();
      }
    }
  }

  searchClear() {
    this.searchQuery = '';
    this.getObsDailyNews(this.count);
  }

  ngOnDestroy() {
    localStorage.setItem('LastObsNewsOpenDate', this.obsNews[0].date.toString());
  }
}
