import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Animations } from 'src/app/animations';
import { ViewEncapsulation } from '@angular/core';
import { Breadcrumbs } from 'src/app/models/breadcrumbs';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ArticleService } from 'src/app/_services/article.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CameraResponse } from 'src/app/models/camera-response';
import { BlogPostResponse } from 'src/app/models/blog-post-response';
import { BlogPostYearlyGroupedResponse } from 'src/app/models/blog-post-yearly-grouped-response';
import { ScreenSizes } from 'src/app/models/screen-sizes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css'],
  animations: [Animations],
  encapsulation: ViewEncapsulation.None

})
export class CameraComponent implements OnInit {
  @Output() setBreadcrumbs = new EventEmitter<Breadcrumbs>();
  camera: CameraResponse;
  broadcasts: string[];
  lang: string;
  dir: string;
  _locale: string;
  post: BlogPostResponse;
  allBlogPosts: BlogPostYearlyGroupedResponse[] = [];
  nestingBlogTitle: string;
  morePostsTitle = '';
  screenSizes: ScreenSizes;
  pauseTimeout: number;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe((params: ParamMap) => {
      const camId = parseInt(params.get('id'), 0);
      this.lang = this.languageService.currentLang();
      this.dir = this.languageService.currentDir();
      this._locale = this.languageService._locale();
      this.nestingBlogTitle = this.languageService.getTextById('NestingBlogTitle');
      this.morePostsTitle = this.languageService.getTextById('blogMorePosts');
      this.screenSizes = this.languageService.getScreenSizes();
      this.pauseTimeout = environment.cameras.camPauseTimeout;

      this.languageService.screenSizesUpdated$.subscribe(ready => {
        if (ready) {
          this.screenSizes = this.languageService.getScreenSizes();
        }
      });

      if (camId) {
        this.getCameraById(camId);
      }
    });
  }

  private getCameraById(camId: number) {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.http.get(this.apiService.getServerUrl() + '/api/cameras/camera/' + this.lang + '/' + camId)
    .subscribe((response: CameraResponse) => {
      this.camera = response;

      this.broadcasts = this.camera.broadcast.split('||');

      if (this.camera.blogPost) {
        this.post = this.camera.blogPost;
        this.allBlogPosts = this.post.blog.allBlogPosts;
      }
      // prepare the breadcrumbs
      this.bcService.clearLevels();
      this.bcService.setLastItemTitle(this.camera.name);
      this.bcService.addLevel(this.languageService.getTextById('cameras'), 'cameras');

      let pageTitle = this.camera.name + ' | ';
      pageTitle += this.languageService.getTextById('cameras');
      pageTitle += ' - ' + this.languageService.getTextById('baseSeoTitle');
      const pageImage = null;
      const keywords =  this.camera.name + ','
                        + this.languageService.getTextById('baseSeoKeywords');

      const desciption = this.camera.name + ','
                        + this.camera.titlePre + ' - '
                        + this.camera.descriptionAbove + ' - '
                        + this.camera.descriptionBelow
                        + this.languageService.getTextById('baseSeoDescription');

      const pageUrl = location.origin + '/camera/' + this.camera.name;
      const canonicalUrl = location.origin + '/' + this.lang + '/camera/' + encodeURIComponent(this.camera.name);
      const pageType = this.languageService.getTextById('cameras');

      this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, canonicalUrl);

      // publish
      this.bcService.publishBreadcrumbs();

      this.loadingAnimationService.publishLoadingAnimation(false);
    },
    error => {
      console.log(error);
      this.loadingAnimationService.publishLoadingAnimation(false);
      if (error.status === 404) {
        this.router.navigate([this.lang, 'not-found']);
      } else {
        this.router.navigate([this.lang, 'error']);
      }
    });
  }

  onNavigateFromTree ({blogId, blogPostId}) {
    // this.router.navigate(['/' + this.lang + '/blog/id/' + blogId + '/' + blogPostId]);
    this.http.get(this.apiService.getServerUrl() + '/api/blogs/post/' + this.lang + '/' + blogPostId)
    .subscribe((response: BlogPostResponse) => {
      this.camera.blogPost = response;
      this.post = this.camera.blogPost;
      this.allBlogPosts = this.post.blog.allBlogPosts;
    },
    error => {
      console.log(error);
      this.loadingAnimationService.publishLoadingAnimation(false);
      if (error.status === 404) {
        this.router.navigate([this.lang, 'not-found']);
      } else {
        this.router.navigate([this.lang, 'error']);
      }
    });
  }

  onNavigateFromTreeMobile ({blogId, blogPostId}) {
    this.onNavigateFromTree ({blogId, blogPostId});
    document.querySelector('#nestingBlogTitle').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  onNavigateFromCamTab (camIndex) {
    document.querySelector('.cam-index-' + camIndex).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

}
