<div class="full-div">
  <div id="notfound">
    <div class="notfound">
      <h2>{{ pageTitle }}</h2>
      <img class="eggs-img" src="/assets/images/eggs.png" [alt]="imgAlt">
      <!-- <img class="eggs-img" src="https://d3np07p3hj9hly.cloudfront.net/assets/images/eggs.png" [alt]="imgAlt"> -->
      <div class="texts">
        <p>{{ this.languageService.getTextById('PageNotFoundP1')}}</p>
        <p>{{ this.languageService.getTextById('PageNotFoundP2')}}
          <a class="btn-outline"
            [routerLink]="['', this.lang]">{{ this.languageService.getTextById('PageNotFoundP3')}}</a>
        </p>
      </div>

    </div>
  </div>
</div>