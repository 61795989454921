<div class="main-wrapper main-species-wrapper full-div">
  <div class="full-div {{dir}}">
    <div class="species-subtxt hide-desktop full-div">
      <h2>{{ species.shortDescription }}</h2>
    </div>
    <div id="sec-gallery" *ngIf="species && species.images && species.images.length > 1">
      <app-side-pannel-title [title]="speciesImagesTitle" [width]="100"></app-side-pannel-title>
      <app-image-thumb-gallery [images]="species.images"></app-image-thumb-gallery>
    </div>  
    <div id="sec-audios" *ngIf="species.sounds && species.sounds.length > 0">
      <app-side-pannel-title [title]="audiosTitle" [width]="100"></app-side-pannel-title>
      <div class="flex-player-container">
        <app-xeno-canto-player *ngFor="let sound of species.sounds" [soundFile]=sound></app-xeno-canto-player>
      </div>
    </div> 
    <div id="sec-videos">
      <div *ngIf="species && species.videos && species.videos.length > 0">
        <app-side-pannel-title [title]="videosTitle" [width]="100"></app-side-pannel-title>
        <div class="flex-player-container">
          <app-youtube-embed *ngFor="let video of species.videos" [videoObj]=video></app-youtube-embed>
        </div>
      </div>  
    </div>
    <div *ngIf="relatedSpeciesTiles && relatedSpeciesTiles.length > 0">
      <app-side-pannel-title [title]="relatedSpeciesTitle" [width]="100"></app-side-pannel-title>
      <div name="species" class="species-flex grid">
        <div *ngFor="let speTile of relatedSpeciesTiles" >
          <app-tile [tile]=speTile [isSmall]=true></app-tile>
        </div>
      </div>
    </div>
    <div *ngIf="moreSpeciesTiles && moreSpeciesTiles.length > 0">
      <app-side-pannel-title [title]="moreSpeciesFromFamilyTitle" [width]="100"></app-side-pannel-title>
      <div name="species" class="species-flex grid">
        <div *ngFor="let speTile of moreSpeciesTiles" >
          <app-tile [tile]=speTile [isSmall]=true></app-tile>
        </div>
      </div>
    </div>
  </div>
</div>
   