import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'GoogleMapLink'
})
export class GoogleMapLinkPipe implements PipeTransform {

  transform(value: string, lat: number, long: number): string {

    if (typeof lat === 'undefined' || typeof long === 'undefined') {
      return null;
    }
    return 'https://www.google.com/maps/place/' + lat + ',' + long + '/@' + lat + ',' + long + ',17z';
  }

}
