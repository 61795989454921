import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { HomepageTileResponse } from 'src/app/models/homepage-tile-response';
import { HttpClient } from '@angular/common/http';
import { HomepageTypeTilesResponse } from 'src/app/models/homepage-type-tiles-response';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { Breadcrumbs } from 'src/app/models/breadcrumbs';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { UrlResponse } from 'src/app/models/url-response';
import { ScreenSizes } from 'src/app/models/screen-sizes';
import { FooterComponent } from 'src/app/_components/footer/footer.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  @Output() setBreadcrumbs = new EventEmitter<Breadcrumbs>();
  homepageTiles: HomepageTypeTilesResponse[];
  allTiles: HomepageTileResponse[] = [];
  lang: string;
  moreTilesTitle: string;
  bannersEnLgR1: number;
  bannersHeLgR1: number;
  bannersEnLgR2: number;
  bannersHeLgR2: number;
  screenSizes: ScreenSizes;

  constructor(private languageService: LanguageService,
    private http: HttpClient,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.moreTilesTitle = this.languageService.getTextById('HomePageMoreReadingTitle'); // 'עוד כתבות...';
    this.bannersEnLgR1 = 13; // 13 is the banner type for home page banners row 2 large view English
    this.bannersHeLgR1 = 14; // 14 is the banner type for home page banners row 1 large view Hebrew
    this.bannersEnLgR2 = 15; // 15 is the banner type for home page banners row 2 large view English
    this.bannersHeLgR2 = 16; // 16 is the banner type for home page banners row 1 large view Hebrew

    this.screenSizes = this.languageService.getScreenSizes();

    this.languageService.screenSizesUpdated$.subscribe(ready => {
      if (ready) {
        this.screenSizes = this.languageService.getScreenSizes();
      }
    });


    this.languageService.allTextsLoaded$.subscribe(ready => {
      if (ready) {
        this.getHomepageTiles();
      }
    });

    if (this.languageService.hasKeys()) {
      this.getHomepageTiles();
    }

  }

  private getHomepageTiles() {
    let serviceUrl: string;

    // console.log('getHomepageTiles');
    this.loadingAnimationService.publishLoadingAnimation(true);

    serviceUrl = this.apiService.getServerUrl() + '/api/homepage/' + this.lang;

    if (serviceUrl) {
      this.http.get(serviceUrl).subscribe((response: HomepageTileResponse[]) => {
        if (response !== undefined) {
          this.allTiles = response;
        }

        // prepare the breadcrumbs
        const pageTitle = this.languageService.getTextById('homepage')
        + ' - ' + this.languageService.getTextById('baseSeoTitle');
        // const pageImage = this.bc.imageUrl && this.bc.imageUrl.path;
        const keywords = this.languageService.getTextById('baseSeoKeywords');
        // console.log(keywords);
        const desciption = this.languageService.getTextById('baseSeoDescription');
        const pagePath = '/';
        const pageUrl = location.origin + pagePath;
        const canonicalUrl = location.origin + '/' + this.lang + pagePath;

        const pageType = this.languageService.getTextById('homepage');
        // this.bcService.seoItems(pageTitle, keywords, desciption,  '/assets/images/birds-org-il.png', pageUrl, pageType, canonicalUrl);
        this.bcService.seoItems(
          pageTitle, keywords, desciption,
          '/assets/images/birds-org-il.png',
          // 'https://d3np07p3hj9hly.cloudfront.net/assets/images/birds-org-il.png',
          pageUrl, pageType, canonicalUrl
        );
        this.bcService.emptyBreadcrumbs();

        this.loadingAnimationService.publishLoadingAnimation(false);

      }, error => {
        console.log(error);
        if (error.status === 404) {
          this.router.navigate([this.lang, 'not-found']);
        } else {
          this.router.navigate([this.lang, 'error']);
        }
      });
    }
  }
}
