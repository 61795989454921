import { Component, OnInit } from '@angular/core';
import { FacebookService, InitParams } from 'ngx-facebook';
import { LanguageService } from '../../_services/language.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  lang: string;
  dir: string;
  title: string;

  constructor(
    private fb: FacebookService,
    private languageService: LanguageService
    ) {
    const initParams: InitParams = {
      appId: '1652279325061088',
      xfbml: true,
      version: 'v2.8'
    };

    fb.init(initParams);

  }

  // https://zyra.github.io/ngx-facebook/comments/

  ngOnInit() {
    // this.fb.ui;
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    this.title = this.languageService.getTextById('CommentsTitle');
  }

}
