<div class="wrapper fadeInDown">
  <div id="formContent">
    <!-- Tabs Titles -->

    <!-- Icon -->
    <div class="fadeIn first">
      <img src="../assets/images/sparrows-2759978_640.jpg" id="icon" alt="User Icon" />
    </div>

    <!-- Login Form -->
    <form #f="ngForm" (ngSubmit)="doLogin(f)">
      <input type="text" id="loginEmail" class="fadeIn second" name="loginEmail" placeholder="{{emailPlaceholder}}"
        required email
        [(ngModel)]="model.username" #loginEmail="ngModel">
      <input type="password" id="loginPassword" class="fadeIn third" name="loginPassword" placeholder="{{passwordPlaceholder}}"
        required
        [(ngModel)]="model.password" #loginPassword="ngModel">

      <button type="submit" class="fadeIn fourth" [disabled]="!f.valid && inSend" id="loginSubmit">{{loginButton}}</button>
    </form>

    <!-- Remind Passowrd -->
    <div id="formFooter">
      <a class="underlineHover" href="/" aria-disabled="true">Forgot Password?</a>
    </div>

  </div>
</div>