import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NestedArray } from 'src/app/models/nested-array';
import { BlogPostYearlyGroupedResponse } from 'src/app/models/blog-post-yearly-grouped-response';
import { BlogPostResponse } from 'src/app/models/blog-post-response';
import { LanguageService } from 'src/app/_services/language.service';
import { ItemBannerComponent } from '../item-banner/item-banner.component';

@Component({
  selector: 'app-more-posts-tree',
  templateUrl: './more-posts-tree.component.html',
  styleUrls: ['./more-posts-tree.component.css']
})
export class MorePostsTreeComponent implements OnInit {
  @Input() allBlogPosts: BlogPostYearlyGroupedResponse[] = [];
  @Input() post: BlogPostResponse;
  @Output() blogAndPostIds = new EventEmitter<object>();
  lang: string;
  dir: string;
  _locale: string;
  expendYearGroups: boolean[] = [];
  expendYearMonthGroups: NestedArray[] = [];
  expendMonthGroups: boolean[] = [];
  _tempGroup: NestedArray;

  constructor(
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';

    const postDate = (this.post.date != null) ? new Date(this.post.date) : new Date();
    const postYear =  postDate.getFullYear();
    const postMonth = postDate.getMonth() + 1;

    // grouping tree logic
    for (let index = 0; index < this.allBlogPosts.length; index++) {
      const yearElement = this.allBlogPosts[index];
      if (postYear === yearElement.year) {
        this.expendYearGroups.push(true);
      } else {
        this.expendYearGroups.push(false);
      }
      this.expendMonthGroups = [];
      for (let index2 = 0; index2 < yearElement.blogPostMonthGroups.length; index2++) {
        const monthElement = yearElement.blogPostMonthGroups[index2];
        if (postMonth === monthElement.month) {
          this.expendMonthGroups.push(true);
        } else {
          this.expendMonthGroups.push(false);
        }
      }
      this._tempGroup = {parent: yearElement.year, childrens: this.expendMonthGroups};
      this.expendYearMonthGroups.push(this._tempGroup);
      this._tempGroup = null;
    }
    this.expendYearGroups[0] = true;
    this.expendYearMonthGroups[0].childrens[0] = true;
  }

  public toggleExpendYears(groupId: number) {
    this.expendYearGroups[groupId] = !this.expendYearGroups[groupId];
  }
  public toggleExpendYearMonths(year: number, groupId: number) {
    this.expendYearMonthGroups[year].childrens[groupId] = !this.expendYearMonthGroups[year].childrens[groupId];
  }

  public navigateToBlogPost(blogId: number, blogPostId: number) {
    this.blogAndPostIds.emit({blogId: blogId, blogPostId: blogPostId});
  }

}

