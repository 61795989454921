<div name="social" class="full-div social-flex" (mouseenter)="refreshHrefs()" (pointerenter)="refreshHrefs()">
  <!-- <div class="grid social-row-desktop"> -->
    <!-- <div class="social-share-btns full-div text-center"> -->
      <div class="social-share-btn heart-btn bg-heart text-center">
        <i class="fas fa-heart c-white"></i>
      </div>
      <div class="social-share-btn bg-whatsapp text-center">
        <a href="{{whatsappShare}}" data-action="share/whatsapp/share" target="_blank">
          <i class="fab fa-whatsapp c-white"></i>
        </a>
      </div>
      <div class="social-share-btn bg-email text-center">
        <a href="{{mailShare}}" target="_blank">
          <i class="fas fa-envelope c-white"></i>
        </a>
      </div>
      <div class="social-share-btn bg-twitter text-center">
        <a href="{{twitterShare}}" target="_blank">
          <i class="fab fa-twitter c-white"></i>
        </a>
      </div>
      <div class="social-share-btn bg-facebook text-center">
        <a data-href="{{facebookShare}}" target="_blank">
          <i class="fab fa-facebook-f c-white"></i>
        </a>       
      </div>
      <div class="social-share-btn bg-telegram text-center">
        <a href="{{telegramShare}}" target="_blank">
          <i class="fab fa-telegram-plane c-white"></i>
        </a>
      </div>
    <!-- </div> -->
  <!-- </div> -->
</div>