<div class="tree">
  <ul *ngFor="let yearGroup of allBlogPosts; let y = index" class="posts-tree">
    <li class="tree-box {{this.dir}} yearGroup-{{y}}">
      <div class="tree-node {{this.dir}} tree-node-{{y}} isExpend-{{this.expendYearGroups[y]}}" (click)="toggleExpendYears(y)">
        <i class="fas fa-chevron-left" *ngIf="!expendYearGroups[y] && this.dir == 'R'"></i>
        <i class="fas fa-chevron-right" *ngIf="!expendYearGroups[y] && this.dir == 'L'"></i>
        <i class="fas fa-chevron-down" *ngIf="expendYearGroups[y]"></i>
      </div>
      <div class="tree-node-header base-t-16 hand" (click)="toggleExpendYears(y)">{{ yearGroup.year }}</div>
      <div class="node-items-box {{this.dir}} node-items-box-{{y}}" *ngIf="this.expendYearGroups[y]">
        <ul *ngFor="let monthGroup of yearGroup.blogPostMonthGroups; let m = index" class="posts-tree">
          <li class="tree-box {{this.dir}} monthGroup-{{m}}">
            <div class="tree-node {{this.dir}} tree-node-{{m}} isExpend-{{this.expendYearMonthGroups[y].childrens[m]}}" (click)="toggleExpendYearMonths(y,m)">
              <i class="fas fa-chevron-left" *ngIf="!expendYearMonthGroups[y].childrens[m] && this.dir == 'R'"></i>
              <i class="fas fa-chevron-right" *ngIf="!expendYearMonthGroups[y].childrens[m] && this.dir == 'L'"></i>
              <i class="fas fa-chevron-down" *ngIf="expendYearMonthGroups[y].childrens[m]"></i>
            </div>
            <div class="tree-node-header base-t-16 hand" (click)="toggleExpendYearMonths(y,m)">{{ monthGroup.month | MonthName: this.lang}}</div>
            <div class="node-items-box {{this.dir}} node-items-box-{{m}}" *ngIf="this.expendYearMonthGroups[y].childrens[m]">
              <div *ngFor="let item of monthGroup.blogPostHeaders">
                <div (click)="navigateToBlogPost(item.blogId,item.id)" class="postItem-flex hand">
                  <div class="tree-entry curent-post-{{item.id == post.id}}"><i class="far fa-file-alt"></i></div>
                  <p class="post-title curent-post-{{item.id == post.id}} title-t-18 t-b" [innerHTML]="item.title"></p>
                </div>
                <!-- <a [routerLink]="['/' + lang + '/blog/id/' + item.blogId + '/' + item.id]" class="postItem-flex">
                  <div class="tree-entry curent-post-{{item.id == post.id}}"><i class="far fa-file-alt"></i></div>
                  <p class="post-title curent-post-{{item.id == post.id}} title-t-18 t-b" [innerHTML]="item.title"></p>
                </a> -->
            </div>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
