<section name="taxon-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-taxon-wrapper full-div">
      <h1>{{title}}</h1>
      <div class="full-div {{dir}}">
        <form #f="ngForm" (ngSubmit)="searchTaxon(f)" class="form-inline taxon-flex-box flex-box-row">
          <input type="text" class="form-control taxon-data" name="englishName"
            placeholder="{{englishNameLabel}}" [(ngModel)]="model.englishName" #englishName="ngModel">
 


          <input type="text" class="form-control taxon-data" id="sciName" name="sciName"
            placeholder="{{sciNameLabel}}" [(ngModel)]="model.sciName" #sciName="ngModel" >
         
          <button type="submit" [disabled]="!f.valid && inSend" id="submitBtn" class="btn">{{searchBtn}}</button>
          <!-- <button type="button" id="test" class="btn" (click)="testAlert()">בדיקה</button> -->
        </form>
      </div>

      <div class="full-div {{dir}}">
        <div *ngIf="sendResultMsg" class="send-result {{sendResult}} t-b">{{sendResultMsg}}</div>
      </div>

      <div class="full-div {{dir}}" *ngIf="speTiles && speTiles.length > 0">        
        <div class="main-wrapper main-lobby-wrapper full-div">
          <h2>תוצאות החיפוש</h2>
          <app-filter-bar (filterTextSync)="onFilterTextChange($event)"></app-filter-bar>
          <div name="species" class="species-flex grid">
            <div *ngFor="let speTile of speTiles  | filter : filterText:false">
              <app-tile [tile]=speTile [isSmall]=true></app-tile>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>