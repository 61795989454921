import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SpeciesTileResponse } from 'src/app/models/species-tile-response';
import { Tile } from 'src/app/models/tile';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { LanguageService } from 'src/app/_services/language.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { SpeciesService } from 'src/app/_services/species.service';

@Component({
  selector: 'app-ebird-taxon-search',
  templateUrl: './ebird-taxon-search.component.html',
  styleUrls: ['./ebird-taxon-search.component.css']
})
export class EbirdTaxonSearchComponent implements OnInit {

  constructor(private languageService: LanguageService,
    private bcService: BreadcrumbsService,
    private speciesService: SpeciesService,
    private alertify: AlertifyService,
    private loadingAnimationService: LoadingAnimationService) { }

  lang: string;
  dir: string;

  title: string = this.languageService.getTextById('SearchEbirdTaxonTitle');
  searchBtn: string = this.languageService.getTextById('SearchBtn');
  englishNameLabel: string = this.languageService.getTextById('EnglishNameLabel');
  sciNameLabel: string = this.languageService.getTextById('SciNameLabel');

  model: any = {
    sciName: "Oenanthe hispanica melanoleuca",
    englishName: "Black-eared Wheatear"
  };

  inSend = false;
  sendResultMsg: string;
  sendResult: string;
  // species: SpeciesTileResponse[];
  speTiles: Tile[] = [];
  filterText: string;

  ngOnInit() {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    // prepare the breadcrumbs
    this.bcService.clearLevels();
    this.bcService.setLastItemTitle(this.title);
    this.bcService.addLevel(this.languageService.getTextById('cmsMenuTitle'), 'cms/menu');


    const pageTitle = this.title
      + ' - ' + this.languageService.getTextById('baseSeoTitle');
    const keywords = this.title
      + ',' + this.languageService.getTextById('baseSeoKeywords');
    const desciption = this.title
      + ',' + this.languageService.getTextById('baseSeoDescription');
    const pagePath = '/cms/ebirdtaxon';
    const pageUrl = location.origin + pagePath;
    const canonicalUrl = location.origin + '/' + this.lang + pagePath;

    const pageType = this.title;
    this.bcService.seoItems(pageTitle, keywords, desciption, '', pageUrl, pageType, canonicalUrl);

    // publish
    this.bcService.publishBreadcrumbs();

    this.loadingAnimationService.publishLoadingAnimation(false);
  }

  searchTaxon(f: NgForm) {

    if (this.inSend) {
      return false;
    }
    
    if (f.invalid) {
      return false;
    }

    this.inSend = true;

    this.loadingAnimationService.publishLoadingAnimation(true);

    this.speciesService.searcheBirdTaxon(this.model.latinName, this.model.englishName).subscribe(response => {
      console.log(response);      
      if (response)
        this.speTiles = this.createSpeTiles(response);
      
      
      this.inSend = false;
      this.loadingAnimationService.publishLoadingAnimation(false);
    }, error => {
      console.log(error);
      this.speTiles = [];
      this.inSend = false;
      this.loadingAnimationService.publishLoadingAnimation(false);
    });
  }

  private createSpeTiles(speciesTilesData: SpeciesTileResponse[]) : Tile[] {
    const speTiles = [];
    speciesTilesData.forEach(species => {
      if (speTiles.some(item => item.tileLinkID === species.id.toString())) {
        return;
      }
      const tile: Tile = {
        image: species.imageUrl,
        title: species.name,
        metaData1: species.latinName,
        metaData2: null,
        showMD1: true,
        showMD2: false,
        italicMD1: true,
        italicMD2: false,
        filterData: species.name + ', ' + species.latinName,
        shortDescription: species.shortDescription,
        tags: null,
        badge: 'בחר',
        badgeColor: 'green',
        tileLinkType: (species.openEncyclopediaPage) ? 'species-page' : '',
        tileLinkID: (species.openEncyclopediaPage) ? species.id.toString() : ''
      };

      speTiles.push(tile);
    });

    return speTiles;
  }

  onFilterTextChange(filterTextSync: string) {
    this.filterText = filterTextSync;
  }
}
