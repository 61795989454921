import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { Breadcrumbs } from '../../models/breadcrumbs';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { ImageSliderObject } from '../../models/image-slider-object';
import { Animations } from '../../animations';
import { getLocaleDateFormat } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { LoadingAnimationService } from '../../_services/loading-animation.service';
import { ArticleService } from '../../_services/article.service';
import { EventResponse } from '../../models/event-response';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  animations: [Animations],
  encapsulation: ViewEncapsulation.None
})
export class EventComponent implements OnInit {
  @Output() setBreadcrumbs = new EventEmitter<Breadcrumbs>();
  ev: EventResponse;
  lang: string;
  dir: string;
  imgGalleryState: boolean;
  moreImagesCaption: string;
  // bcSlug: string;
  // bcName: string;
  detailsTitle: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.lang = this.languageService.currentLang();

      const id = parseInt(params.get('id'), 0);
      const slug = params.get('slug');
      this.dir = (this.lang === 'he') ? 'R' : 'L';
      // business logic what changes you want on the page with this new data.

      if (id) {
        this.getEventById(id);
      }

      if (slug) {
        this.getEventBySlug(slug);
      }

    });
  }

  private getEventBySlug(evSlug: string) {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.http.get(this.apiService.getServerUrl() + '/api/events/byslug/' + this.lang + '/' + evSlug)
      .subscribe((response: EventResponse) => {
        this.ev = response;

        // prepare the breadcrumbs
        this.bcService.clearLevels();
        this.bcService.setLastItemTitle(this.ev.title);
        this.bcService.addLevel(this.languageService.getTextById('baseEventsTitle'), 'events/');

        const pageTitle = this.ev.title + ' | '
                          + this.languageService.getTextById('baseEventsTitle') + ' - '
                          + this.languageService.getTextById('baseSeoTitle');
        const pageImage = this.ev.imageUrl && this.ev.imageUrl.path;
        const keywords =  this.ev.title + ','
                          + this.languageService.getTextById('baseEventsBoardTitle') + ','
                          + this.languageService.getTextById('baseSeoKeywords');
        const desciption = this.ev.title + ','
                          + this.languageService.getTextById('baseEventsBoardTitle') + ','
                          + this.languageService.getTextById('baseSeoDescription');

        const pagePath = '/events/' + evSlug;
        const pageUrl = location.origin + pagePath;
        const canonicalUrl = location.origin + '/' + this.lang + pagePath;

        const pageType = this.languageService.getTextById('baseEventTitle');
        this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, canonicalUrl);

        // publish
        this.bcService.publishBreadcrumbs();

        this.moreImagesCaption = this.languageService.getTextById('GalleryImagesCaption');
        this.detailsTitle = this.languageService.getTextById('detailsTitle');

        this.loadingAnimationService.publishLoadingAnimation(false);
      },
      error => {
        console.log(error);
        this.loadingAnimationService.publishLoadingAnimation(false);
        if (error.status === 404) {
          this.router.navigate([this.lang, 'not-found']);
        } else {
          this.router.navigate([this.lang, 'error']);
        }
      }
    );
  }

  // event by id - keep this option for our convenience
  private getEventById(evId: number) {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.http.get(this.apiService.getServerUrl() + '/api/events/byid/' + this.lang + '/' + evId)
      .subscribe((response: EventResponse) => {
        this.ev = response;

        // prepare the breadcrumbs
        this.bcService.clearLevels();
        this.bcService.setLastItemTitle(this.ev.title);
        this.bcService.addLevel(this.languageService.getTextById('baseEventsTitle'), 'events/');

        const pageTitle = this.ev.title + ' | '
                          + this.languageService.getTextById('baseEventsTitle') + ' - '
                          + this.languageService.getTextById('baseSeoTitle');
        const pageImage = this.ev.imageUrl && this.ev.imageUrl.path;
        const keywords =  this.ev.title + ','
                          + this.languageService.getTextById('baseEventsBoardTitle') + ','
                          + this.languageService.getTextById('baseSeoKeywords');
        const desciption = this.ev.title + ','
                          + this.languageService.getTextById('baseEventsBoardTitle') + ','
                          + this.languageService.getTextById('baseSeoDescription');

        const pagePath = '/events/' + evId.toString();
        const pageUrl = location.origin + pagePath;
        const canonicalUrl = location.origin + '/' + this.lang + pagePath;

        const pageType = this.languageService.getTextById('baseEventTitle');
        this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, canonicalUrl);

        // publish
        this.bcService.publishBreadcrumbs();

        this.moreImagesCaption = this.languageService.getTextById('GalleryImagesCaption');
        this.detailsTitle = this.languageService.getTextById('detailsTitle');

        this.loadingAnimationService.publishLoadingAnimation(false);
      },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        }
      );
  }

  // private imgGalleryOn() {
  //   this.imgGalleryState = true;
  // }

  // onImgGalleryStateOff(state: boolean) {
  //   this.imgGalleryState = state;
  // }

}
