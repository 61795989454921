<section name="event-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div *ngIf="ev" class="main-wrapper main-event-wrapper full-div">
      <div name="event-header" *ngIf="!imgGalleryState" [@fadeInOut] class="hide-mobile event-img-container full-div">
        <div *ngIf="ev && ev.largeImageUrl">
          <img
            [src]="ev.largeImageUrl.path"
            [alt]="ev.largeImageUrl.name"
            [title]="ev.largeImageUrl.title"
            class="event-img full-div"
          />
        </div>
        <div class="image-element">
          <div>
            <!-- <div *ngIf="ev && ev.images && ev.images.length > 1" class="img-tooltip hand" (click)="imgGalleryOn()">+ {{ev.images.length}} {{this.moreImagesCaption}}</div> -->
          </div>
          <div>
            <p class="event-title-note {{this.dir}} c-white t-b event-caption event-{{ lang }}"></p>
            <h1 class="event-title  {{this.dir}} c-white t-b">{{ ev.title }}</h1>
          </div>
        </div>
      </div>
      <div class="img-container-flex">
        <div name="event-header" *ngIf="!imgGalleryState" [@fadeInOut] class="hide-desktop event-img-container full-div">
          <div *ngIf="ev && ev.imageUrl">
            <img
              [src]="ev.imageUrl.path"
              [alt]="ev.imageUrl.name"
              [title]="ev.imageUrl.title"
              class="event-img full-div"
            />
          </div>
          <div class="image-element">
            <div>
              <!-- <div *ngIf="ev && ev.images && ev.images.length > 1" class="img-tooltip hand" (click)="imgGalleryOn()">+ {{ev.images.length}} {{this.moreImagesCaption}}</div> -->
            </div>
            <div>
              <p class="event-title-note {{this.dir}} c-white t-b event-caption event-{{ lang }}"></p>
              <h1 class="event-title  {{this.dir}} c-white t-b">{{ ev.title }}</h1>
            </div>
          </div>
        </div>
      </div>
      <!-- <app-image-slider *ngIf="imageSliderOn" class="article-img full-div" [images]="article.images"></app-image-slider> -->
      <!-- <app-image-slider *ngIf="imageSliderOn" [images]="article.images" [isShow]="imageSliderOn"></app-image-slider> -->
      <!-- <app-image-gallery *ngIf="imgGalleryState" [title]= "ev.title" [images]="ev.images" [imgGalleryState]="this.imgGalleryState" (imgGalleryParentState) = "onImgGalleryStateOff($event)" [badge]="'birding-center'"></app-image-gallery> -->
      <div class="third-div  {{this.dir}}">
        <div class="event-subtxt hide-desktop full-div">
          <h2>{{ ev.shortDescription }}</h2>
        </div>
        <div *ngIf="ev && ev.lat > 0 && ev.long > 0">
          <app-google-map class='small-map hide-mobile' [lat]="ev.lat" [lng]="ev.long" [zoom]="13" [height]="200" [heightUnits]="'px'"></app-google-map>
          <app-contact class="hide-mobile" [lat]="ev.lat" [lng]="ev.long" [phone]="" [destinationName]="ev.title" [contactUsId]="ev.id"></app-contact>
        </div>
        <div class="event-details-wrapper hide-mobile" *ngIf="ev.details">
          <app-side-pannel-title [title] = "detailsTitle"></app-side-pannel-title>
          <div class="full-div event-details">
            <span>{{ ev.details }}</span>
          </div>
        </div>
        <app-links class="hide-mobile" *ngIf="ev && ev.links && ev.links.length > 0" [links]="ev.links" [linksTitle]="ev.moreLinkesTitle"></app-links>
        <app-tags class="hide-mobile" *ngIf="ev && ev.tags && ev.tags.length > 0" [tags]="ev.tags"></app-tags>
      </div>
      <div class=" event-main two-third-div  {{this.dir}}">
        <div class="event-subtxt full-div hide-mobile">
          <h2>{{ ev.shortDescription }}</h2>
        </div>
        <app-side-pannel-title [title] = "" [width]="30"></app-side-pannel-title>
        <div class="inner-html-txt full-div">
          <slot [innerHTML]="ev.description | imgHost | safe"></slot>
        </div>
        <app-tags class="hide-desktop" *ngIf="ev && ev.tags && ev.tags.length > 0" [tags]="ev.tags"></app-tags>
        <app-social-shares></app-social-shares>
        <div class="event-details-wrapper hide-desktop" *ngIf="ev.details">
          <app-side-pannel-title [title] = "detailsTitle" [width]="100"></app-side-pannel-title>
          <div class="full-div event-details">
            <span>{{ ev.details }}</span>
          </div>
          <app-side-pannel-title [title] = "" [width]="30"></app-side-pannel-title>
        </div>
        <div *ngIf="ev && ev.lat > 0 && ev.long > 0">
          <app-google-map class='small-map hide-desktop' [lat]="ev.lat" [lng]="ev.long" [zoom]="13" [height]="300" [heightUnits]="'px'"></app-google-map>
          <app-contact class='hide-desktop' [lat]="ev.lat" [lng]="ev.long" [phone]="" [destinationName]="ev.title" [contactUsId]="ev.id"></app-contact>
        </div>
      </div>
    </div>
  </div>
</section>
<app-links class="hide-desktop" *ngIf="ev && ev.links && ev.links.length > 0" [links]="ev.links"></app-links>
<!-- <app-comments></app-comments> -->
<app-scroll-top></app-scroll-top>