import {
  trigger, state, style, transition, animate, group, query, stagger, keyframes
} from '@angular/animations';


export const Animations = [
//   trigger('EnterLeaveY', [
//     state('flyIn', style({ transform: 'translatey(0)' })),
//     transition(':enter', [
//       style({ transform: 'translateY(-100%)' }),
//       animate('30s ease-in')
//     ]),
//     transition(':leave', [
//       animate('20s ease-out', style({ transform: 'translateY(100%)' }))
//     ])
//   ]),
trigger('EnterLeaveX', [
    state('flyIn', style({ transform: 'translateX(0)' })),
    transition(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('0.3s 50ms ease-in')
    ]),
    transition(':leave', [
      animate('20ms 50ms ease-out', style({ transform: 'translateX(100%)' }))
    ])
  ]),
  trigger('fadeInOut', [
    state(
      'void',
      style({
        opacity: 0
      })
    ),
    // transition('void <=> *', animate(1500))
    transition(':enter', [
        style({opacity: 0}),
        animate('200ms 200ms ease-in')
      ]),
    transition(':leave',
      animate('0.5s 300ms', style({opacity: 0})))
  ])
];
