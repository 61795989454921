<span *ngIf="image == null" class="pointer" (click)="openModal(modalId)">{{registerLabel}}</span>

<img *ngIf="image != null" class="banner-img" [src]="image.path" [alt]="image.name" [title]="image.title" (click)="openModal(modalId)"/>

<jw-modal id={{modalId}} class="full-div {{lang}}-dir">
  <div class="full-div title-t-28 {lang}}-dir">{{registerNewsletterHeader}}</div>
  <form #f="ngForm" (ngSubmit)="register(f)"  class="form-inline register-flexbox" >
    <input type="email" class="form-control" id="email" name="email" placeholder="{{emailPlaceHolder}}" 
      required email
      [(ngModel)]="regEmail" #email="ngModel">
    <span *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required" class="error">{{errRequired}}</span>
    <span *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email" class="error">{{errEmail}}</span>

    <input type="text" class="form-control" id="firstName" name="firstName" placeholder="{{firstNamePlaceHolder}}" 
      required minlength="2"
      [(ngModel)]="regFirstName" #firstName="ngModel">
    <span *ngIf="firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors.required" class="error">{{errRequired}}</span>
    <span *ngIf="firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors.minlength" class="error">{{errShort}}</span>

    <input type="text" class="form-control" id="lastName" name="lastName" placeholder="{{lastNamePlaceHolder}}" 
      required minlength="2"
      [(ngModel)]="regLastName" #lastName="ngModel">
    <span *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors.required" class="error">{{errRequired}}</span>
    <span *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors.minlength" class="error">{{errShort}}</span>

    <div class="buttons full-div">
      <div class="button small-t-14" (click)="closeModal(modalId, f);">{{closeLabel}}</div>
      <button type="submit" [disabled]="!f.valid || disableButton" class="register button small-t-14" id="submit">{{submit}}</button>
    </div>
    <div *ngIf="!f.dirty && !f.touched || showError">{{regResult}}</div>
  </form>
</jw-modal>
