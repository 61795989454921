import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ebird-map',
  templateUrl: './ebird-map.component.html',
  styleUrls: ['./ebird-map.component.css']
})
export class EbirdMapComponent implements OnInit {
  @Input() eBirdSpeciesCode: string;
  @Input() eBirdMapTitle: string;
  @Input() GotoEBirdMap: string;

  // _speciesName: string;
  ebirdMapHref: string;
  ebirdSpeciesMapSrc: string;
  currentYear: number;

  constructor() { }

  ngOnInit() {

    const dt = new Date();
    this.currentYear = dt.getUTCFullYear();

    // this._speciesName = this.speciesName.replace(' ', '-');
    this.ebirdMapHref = 'https://ebird.org/map/'
                      + this.eBirdSpeciesCode
                      + '?neg=true&amp;env.minX=&amp;env.minY=&amp;env.maxX=&amp;env.maxY=&amp;zh=false&amp;'
                      + 'gp=false&amp;ev=Z&amp;mr=1-12&amp;bmo=1&amp;emo=12&amp;yr=all&amp;byr=1900&amp;eyr='
                      + this.currentYear + '}';
    this.ebirdSpeciesMapSrc = 'https://ebird.org/embedmap/'
                            + this.eBirdSpeciesCode
                            + '?scrollwheel=true&draggable=true'
                            + '&env.minX=34.220819&env.minY=29.416604&env.maxX=35.752239&env.maxY=33.325187&mapType=roadmap';
  }
}
