// angular core modules
import { BrowserModule } from '@angular/platform-browser';
// import { HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

// 3rd party modules
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClickOutsideModule } from 'ng-click-outside';
import { FacebookModule } from 'ngx-facebook';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';
import { ngfModule } from 'angular-file';
import { ModalModule } from './_modal';
import { GoogleMapsModule } from '@angular/google-maps';


// services
import { AuthService } from './_services/auth.service';
import { ArticleService } from './_services/article.service';
import { ApiService } from './_services/api.service';
import { DynamicScriptLoaderService } from './_services/DynamicScriptLoaderService.service';
import { CountryResolver } from './app-routing/CountryResolver';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { JwtInterceptor } from './_services/jwt.interceptor';


// components
import { AppComponent } from './app.component';
import { TopHeaderComponent } from './_components/top-header/top-header.component';
import { NavBarComponent } from './_components/nav-bar/nav-bar.component';
import { BreadcrumbsComponent } from './_components/breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './_components/footer/footer.component';
import { SocialSharesComponent } from './_components/social-shares/social-shares.component';
import { CommentsComponent } from './_components/comments/comments.component';
import { TagsComponent } from './_components/tags/tags.component';
import { LinkedArticlesComponent } from './_components/linked-articles/linked-articles.component';
import { LinkedEventsComponent } from './_components/linked-events/linked-events.component';
import { LinksComponent } from './_components/links/links.component';
import { ImageSliderComponent } from './_components/image-slider/image-slider.component';
import { TileComponent } from './_components/tile/tile.component';
import { HTileComponent } from './_components/h-tile/h-tile.component';
import { ImageGalleryComponent } from './_components/image-gallery/image-gallery.component';
import { ImageThumbGalleryComponent } from './_components/image-thumb-gallery/image-thumb-gallery.component';
import { ScrollTopComponent } from './_components/scroll-top/scroll-top.component';
import { LoadingBarComponent } from './_components/loading-bar/loading-bar.component';
import { ItemBannerComponent } from './_components/item-banner/item-banner.component';
import { SidePannelTitleComponent } from './_components/side-pannel-title/side-pannel-title.component';
import { GoogleMapWrapperComponent } from './_components/google/google-map-wrapper/google-map-wrapper.component';
import { WordsComponent } from './_components/_charts/words/words.component';
import { RtgRedbookComponent } from './_components/rtg-redbook/rtg-redbook.component';
import { EbirdMapComponent } from './_components/ebird-map/ebird-map.component';

import { HomePageComponent } from './_pages/home-page/home-page.component';
import { ComingSoonComponent } from './_pages/coming-soon/coming-soon.component';
import { ContactComponent } from './_components/contact/contact.component';
import { SearchResultsComponent } from './_pages/search-results/search-results.component';
import { FilterBarComponent } from './_components/filter-bar/filter-bar.component';
import { AboutUsComponent } from './_pages/about-us/about-us.component';
import { XenoCantoPlayerComponent } from './_components/xeno-canto-player/xeno-canto-player.component';
import { YoutubeEmbedComponent } from './_components/youtube-embed/youtube-embed.component';
import { ObservationTileComponent } from './_components/observation-tile/observation-tile.component';
import { HomepageTileComponent } from './_components/homepage-tile/homepage-tile.component';
import { BannersRowComponent } from './_components/banners-row/banners-row.component';
import { ObservationDataCardComponent } from './_components/observation-data-card/observation-data-card.component';
import { MorePostsTreeComponent } from './_components/more-posts-tree/more-posts-tree.component';
import { ModalComponent } from './_components/modal/modal.component';
import { ArticleTypesMenuComponent } from './_components/article-types-menu/article-types-menu.component';
import { OnlineCameraComponent } from './_components/online-camera/online-camera.component';
import { VideoPlayerComponent } from './_components/video-player/video-player.component';
import { HomepageCameraTileComponent } from './_components/homepage-camera-tile/homepage-camera-tile.component';
import { FileBrowserDemoComponent } from './_components/file-browser-demo/file-browser-demo.component';
import { ImageBrowserComponent } from './_components/image-browser/image-browser.component';
import { RegisterNewsletterComponent } from './_components/register-newsletter/register-newsletter.component';
import { LoginComponent } from './_pages/_cms/login/login.component';
import { CmsMenuComponent } from './_pages/_cms/cms-menu/cms-menu.component';

// pages
import { ArticlesComponent } from './_pages/articles/articles.component';
import { ArticleComponent } from './_pages/article/article.component';
import { ArticleNewComponent } from './_pages/article-new/article-new.component';
import { BirdingCentersComponent } from './_pages/birding-centers/birding-centers.component';
import { BirdingCenterComponent } from './_pages/birding-center/birding-center.component';
import { SpeciesFamiliesComponent } from './_pages/species-families/species-families.component';
import { SpeciesLobbyComponent } from './_pages/species-lobby/species-lobby.component';
import { SpeciesPageComponent } from './_pages/species-page/species-page.component';
import { SpeciesDescriptionComponent } from './_pages/species-page/species-description/species-description.component';
import { SpeciesTrendsComponent } from './_pages/species-page/species-trends/species-trends.component';
import { SpeciesMoreInfoComponent } from './_pages/species-page/species-more-info/species-more-info.component';
import { EventsComponent } from './_pages/events/events.component';
import { EventComponent } from './_pages/event/event.component';
import { ContactUsComponent } from './_pages/contact-us/contact-us.component';
import { BlogsComponent } from './_pages/blogs/blogs.component';
import { SpeciesChecklistComponent } from './_pages/species-checklist/species-checklist.component';
import { ObservationsComponent } from './_pages/observations/observations.component';
import { NotFoundComponent } from './_pages/not-found/not-found.component';
import { ErrorPageComponent } from './_pages/error-page/error-page.component';
import { BlogPostComponent } from './_pages/blog-post/blog-post.component';
import { ObsNewsComponent } from './_pages/obs-news/obs-news.component';
import { LatestObservationsComponent } from './_components/latest-observations/latest-observations.component';
import { CameraComponent } from './_pages/camera/camera.component';
import { CamerasComponent } from './_pages/cameras/cameras.component';

// pipes
import { SafePipe } from './_pipes/safe.pipe';
import { TruncateTextPipe } from './_pipes/TruncateText.pipe';
import { FilterPipe } from './_pipes/filter.pipe';
import { SafeUrlPipe } from './_pipes/safeUrl.pipe';
import { BoldTextPipe } from './_pipes/BoldText.pipe';
import { GoogleMapLinkPipe } from './_pipes/GoogleMapLink.pipe';
import { MonthNamePipe } from './_pipes/MonthName.pipe';
import { ImgHostPipe } from './_pipes/imgHost.pipe';

// charts
import { GoogleChartsModule } from 'angular-google-charts';
import { WeeklyBarChartComponent } from './_components/weekly-bar-chart/weekly-bar-chart.component';
import { SpeceisObsFrequenciesChartComponent } from './_components/speceis-obs-frequencies-chart/speceis-obs-frequencies-chart.component';
import { RingingDonutChartComponent } from './_components/ringing-donut-chart/ringning-donut-chart.component';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { RingingRingsComponent } from './_pages/_cms/ringing-rings/ringing-rings.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { RingingDataComponent } from './_pages/_cms/ringing-data/ringing-data.component';
import { ToastrModule } from 'ngx-toastr';
import { EbirdTaxonSearchComponent } from './_pages/_cms/ebird-taxon-search/ebird-taxon-search.component';


@NgModule({
   declarations: [
      AppComponent,
      ArticleComponent,
      ArticleNewComponent,
      FooterComponent,
      ArticlesComponent,
      SocialSharesComponent,
      CommentsComponent,
      HomePageComponent,
      BreadcrumbsComponent,
      LinkedArticlesComponent,
      LinkedEventsComponent,
      TagsComponent,
      LinksComponent,
      ImageSliderComponent,
      TopHeaderComponent,
      TileComponent,
      HTileComponent,
      ImageGalleryComponent,
      ImageThumbGalleryComponent,
      ScrollTopComponent,
      LoadingBarComponent,
      ItemBannerComponent,
      SidePannelTitleComponent,
      NavBarComponent,
      ContactUsComponent,
      ComingSoonComponent,
      SafePipe,
      SafeUrlPipe,
      BirdingCentersComponent,
      BirdingCenterComponent,
      GoogleMapWrapperComponent,
      ContactComponent,
      TruncateTextPipe,
      SpeciesFamiliesComponent,
      SpeciesLobbyComponent,
      SpeciesPageComponent,
      SpeciesDescriptionComponent,
      SpeciesTrendsComponent,
      SpeciesMoreInfoComponent,
      EventsComponent,
      EventComponent,
      FilterPipe,
      FilterBarComponent,
      SearchResultsComponent,
      AboutUsComponent,
      WordsComponent,
      RtgRedbookComponent,
      EbirdMapComponent,
      XenoCantoPlayerComponent,
      YoutubeEmbedComponent,
      ObsNewsComponent,
      BoldTextPipe,
      ImgHostPipe,
      GoogleMapLinkPipe,
      NotFoundComponent,
      ErrorPageComponent,
      LatestObservationsComponent,
      ObservationTileComponent,
      HomepageTileComponent,
      BannersRowComponent,
      BlogPostComponent,
      MonthNamePipe,
      MorePostsTreeComponent,
      BlogsComponent,
      SpeciesChecklistComponent,
      ObservationsComponent,
      ObservationDataCardComponent,
      WeeklyBarChartComponent,
      ModalComponent,
      ArticleTypesMenuComponent,
      OnlineCameraComponent,
      CameraComponent,
      SpeceisObsFrequenciesChartComponent,
      CamerasComponent,
      VideoPlayerComponent,
      HomepageCameraTileComponent,
      RingingDonutChartComponent,
      FileBrowserDemoComponent,
      ImageBrowserComponent,
      RegisterNewsletterComponent,
      LoginComponent,
      CmsMenuComponent,
      RingingRingsComponent,
      RingingDataComponent,
      EbirdTaxonSearchComponent
   ],
   imports: [
      BrowserModule,
      HttpClientModule,
      FormsModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      FacebookModule.forRoot(),
      NgxGalleryModule,
      ClickOutsideModule,
      GoogleMapsModule,
      // tslint:disable-next-line:max-line-length
      // <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY"></script>
      // AgmCoreModule.forRoot({ apiKey: 'AIzaSyBWGMBZS55rLnVFIc88xLBo93qQ9u0q7U0', language: sessionStorage.getItem('GoogleMapLang')}), // apiKey: 'AIzaSyBWGMBZS55rLnVFIc88xLBo93qQ9u0q7U0'
      GoogleChartsModule,
      ClipboardModule,
      ngfModule,
      ModalModule,
      CommonModule,
      // HammerModule,
      AutocompleteLibModule,
      ToastrModule.forRoot({
         positionClass: 'toast-top-right'
      })
   ],
   providers: [
      AuthService,
      ArticleService,
      ApiService,
      DynamicScriptLoaderService,
      CountryResolver,
      FilterPipe,
      DatePipe,
      {
         provide: 'googleTagManagerId', useValue: 'GTM-PT59RM'
      },
      ErrorInterceptorProvider,
      {
         provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true
      },
      // {
      //    provide: 'externalUrlResolver',
      //    useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      //       window.location.href = `${route.data.externalUrl}${state.url}`;
      //    }
      // }
   ],
   bootstrap: [AppComponent],
   entryComponents: [ModalComponent]
})
export class AppModule {
   constructor() {
      registerLocaleData(localeHe, 'he');
   }
}
