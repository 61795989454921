<section name="blogPost-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div *ngIf="post && blog" class="main-wrapper main-blogPost-wrapper full-div hide-mobile">
      <div class="third-div {{this.dir}}">
        <div class="blog-img-wrapper blog-wrapper-flex full-div">
          <img *ngIf="blog && blog.imageUrl" class="blog-img full-div"
            [src]="blog.imageUrl.path"
            [alt]="blog.imageUrl.name"
            [title]="blog.imageUrl.title"
          />
          <div class="blog-caption blog-wrapper-flex full-div">
            <p class="blog-badge blog-md-wrapper base-t-16 c-white t-b"><span class="blog-badge-txt">{{ this.badgeText }}</span></p>
            <h2 class="blog-title title-t-25 c-white t-b">{{ blog.title}}</h2>
            <div class="blog-md-wrapper base-t-16 c-white">
              <span class="blog-author border-{{this.dir}} italic-false">{{ blog.authorName }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="blog.aboutAuthor">
          <app-side-pannel-title [title] = "this.aboutAuthorTitle"></app-side-pannel-title>
          <div class="about-author small-t-14">
            <slot [innerHTML]="blog.aboutAuthor | safe"></slot>
          </div>
        </div>
        <app-social-shares></app-social-shares>
        <app-tags class="hide-mobile" *ngIf="blog && blog.tags && blog.tags.length > 0" [tags]="blog.tags"></app-tags>
        <app-side-pannel-title class="hide-mobile" [title] = "this.morePostsTitle"></app-side-pannel-title>
        <app-more-posts-tree *ngIf="allBlogPosts && allBlogPosts.length > 0" 
          [allBlogPosts]=allBlogPosts 
          [post]=post
          (blogAndPostIds) = "onNavigateFromTree($event)"
          ></app-more-posts-tree>
      </div>
      <div class=" blogPost-main two-third-div {{this.dir}}">
        <div class="post-subtxt full-div hide-mobile">
          <h1 class="post-title t-b">{{ post.title }}</h1>
        </div>
        <app-side-pannel-title class="hide-mobile" [title] = "" [width]="60"></app-side-pannel-title>
        <div class="base-t-16">{{ post.date | date:'dd/MM/yyyy':this._locale }}</div>
        <div class="inner-html-txt full-div">
          <slot [innerHTML]="post.content | imgHost | safe"></slot>
        </div>
        <app-social-shares></app-social-shares>
        <app-comments></app-comments>
      </div>
    </div>
    <div *ngIf="post && blog" class="main-wrapper main-blogPost-wrapper full-div hide-desktop">
      <div>
        <div class="blog-header-mobile {{this.dir}}">
          <div class="blog-img-wrapper third-div blog-wrapper-flex">
            <img *ngIf="blog && blog.imageUrl" class="blog-img full-div"
              [src]="blog.imageUrl.path"
              [alt]="blog.imageUrl.name"
              [title]="blog.imageUrl.title"
            />
            <div class="blog-caption blog-wrapper-flex full-div">
              <p class="blog-badge blog-md-wrapper base-t-16 c-white t-b"><span class="blog-badge-txt">{{ this.badgeText }}</span></p>
            </div>
          </div>
          <div class="blog-header-wrapper-mobile two-third-div">
            <div class="post-subtxt full-div">
              <h1 class="post-title title-t-28 t-b">{{ post.title }}</h1>
              <app-side-pannel-title [title] = "" [width]="80"></app-side-pannel-title>
            </div>
            <h2>
              <span class="blog-title {{this.dir}} title-t-22 t-b">{{ blog.title}}</span>
            </h2>
            <div class="base-t-16">
              <span class="blog-author border-{{this.dir}} italic-false">{{ blog.authorName }}, </span>
              <span>{{ post.date | date:'dd/MM/yyyy':this._locale }}</span>  
            </div>
          </div>
        </div>
      </div>
      <div class="blogPost-main full-div {{this.dir}}">
        <div *ngIf="blog.aboutAuthor">
          <!-- <app-side-pannel-title [title] = "this.aboutAuthorTitle" [width]="100"></app-side-pannel-title> -->
          <div class="hide-for-tablet">
            <div class="show-aboutAuthor small-t-14 {{dir}}" (click)="toggleAboutAuthor('show')" *ngIf="!showAboutAuthor"><i class="fas fa-caret-down"></i> {{ aboutAuthorTitle }} </div>
            <div class="hide-aboutAuthor small-t-14 {{dir}}" (click)="toggleAboutAuthor('hide')" *ngIf="showAboutAuthor"><i class="fas fa-caret-up"></i></div>
            <div class="about-author {{dir}} small-t-14" *ngIf="this.showAboutAuthor">
              <slot [innerHTML]="blog.aboutAuthor | safe"></slot>
            </div>  
            <!-- <app-side-pannel-title [title] = "" [width]="100" *ngIf="this.showAboutAuthor"></app-side-pannel-title> -->
          </div>
          <div class="show-for-tablet">
            <span class="small-t-14 t-b">{{ aboutAuthorTitle }}</span>
            <div class="about-author {{dir}} small-t-14">
              <slot [innerHTML]="blog.aboutAuthor | safe"></slot>
            </div>
          </div>
        </div>
        <div class="inner-html-txt full-div">
          <slot [innerHTML]="post.content | imgHost | safe"></slot>
        </div>
        <app-social-shares></app-social-shares>
        <app-side-pannel-title [title] = "this.morePostsTitle"></app-side-pannel-title>
        <app-more-posts-tree *ngIf="allBlogPosts && allBlogPosts.length > 0" 
          [allBlogPosts]=allBlogPosts 
          [post]=post
          (blogAndPostIds) = "onNavigateFromTree($event)"
          ></app-more-posts-tree>

        <app-tags *ngIf="blog && blog.tags && blog.tags.length > 0" [tags]="blog.tags"></app-tags>
        <app-comments></app-comments>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>
