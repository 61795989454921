import { Component, OnInit } from '@angular/core';
import { SpeciesResponse } from 'src/app/models/species-response';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SpeciesService } from 'src/app/_services/species.service';

@Component({
  selector: 'app-species-trends',
  templateUrl: './species-trends.component.html',
  styleUrls: ['./species-trends.component.css']
})
export class SpeciesTrendsComponent implements OnInit {
  species: SpeciesResponse;
  lang: string;
  dir: string;
  ebirdMapTitle: string;
  gotoEBirdMap: string;
  weeklychartTitle: string;
  latestObservationsTitle: string;
  conservationLevelTitle: string;
  conservationLevelTitleIL: string;
  conservationLevelTitleEU: string;
  conservationLevelTitleWo: string;
  conservationLevelsLegend: string;
  sensitiveSpeciesTitle: string;
  _locale: string;
  dateHeader: string;
  locationHeader: string;
  countHeader: string;
  observerHeader: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private speciesService: SpeciesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.lang = this.languageService.currentLang();
      this.dir = this.languageService.currentDir();
      this.ebirdMapTitle = this.languageService.getTextById('ebirdMapTitle');
      this.gotoEBirdMap = this.languageService.getTextById('GotoEBirdMap');
      this.weeklychartTitle = this.languageService.getTextById('weeklychartTitle');
      this.latestObservationsTitle = this.languageService.getTextById('latestObservationsTitle');
      this.conservationLevelTitle = this.languageService.getTextById('conservationLevelTitle');
      this.conservationLevelTitleIL = this.languageService.getTextById('conservationLevelTitleIL');
      this.conservationLevelTitleEU = this.languageService.getTextById('conservationLevelTitleEU');
      this.conservationLevelTitleWo = this.languageService.getTextById('conservationLevelTitleWo');
      this.conservationLevelsLegend = this.languageService.getTextById('ConservationLevelsLegend');
      this.dateHeader = this.languageService.getTextById('Date');
      this.locationHeader = this.languageService.getTextById('Location');
      this.countHeader = this.languageService.getTextById('Count');
      this.observerHeader = this.languageService.getTextById('ObserverName');
      this.sensitiveSpeciesTitle = this.languageService.getTextById('SensitiveSpeciesHidden');
      this._locale = this.languageService._locale();


      this.species = this.speciesService.getSrvSpecies();
      const slug = params.get('slug');
      const id = parseInt(params.get('id'), 0);

      this.species = this.speciesService.getSrvSpecies();
      if (this.species === undefined) {

        if (id !== undefined && !isNaN(id)) {
          this.router.navigate(['/' + this.lang + '/species-page/' + id]);
        } else if (slug !== null) {
          // currently - not implemented
        } else {
          this.router.navigate(['/' + this.lang + './species-families']);
        }
      }
    });
  }

}
