import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ObservationResponse } from 'src/app/models/observation-response';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BannerResponse } from 'src/app/models/banner-response';
import { Router } from '@angular/router';

@Component({
  selector: 'app-observation-data-card',
  templateUrl: './observation-data-card.component.html',
  styleUrls: ['./observation-data-card.component.css']
})
export class ObservationDataCardComponent implements OnInit {
  @Input() obsId: number;
  @Output() returnRecordsCount = new EventEmitter<number>();

  obsData: ObservationResponse;
  lang: string;
  dir: string;
  speciesNameHeader: string;
  countHeader: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private router: Router
    ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    this.countHeader = this.languageService.getTextById('Count');
    this.speciesNameHeader = this.languageService.getTextById('SpeciesNameHeader');

    this.languageService.allTextsLoaded$.subscribe(ready => {
      if (ready) {
        this.getObservation();
        }
    });

    if (this.languageService.hasKeys()) {
      this.getObservation();
    }
  }

  private getObservation() {
    let serviceUrl: string;

    serviceUrl = this.apiService.getServerUrl() + '/api/observation/' + this.lang + '/' + this.obsId;

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: ObservationResponse) => {
          if (response !== undefined) {
            this.obsData = response;
            this.returnRecordsCount.emit(this.obsData.records.length);
          }
        }, error => {
          console.log(error);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
    }
  }
}
