import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Routing301 } from '../../models/routing301';
import { LanguageService } from 'src/app/_services/language.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  path: Routing301;
  pageTitle: string;
  imgAlt: string;
  lang: string;

  constructor(private route: ActivatedRoute, private router: Router, public languageService: LanguageService) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();

    // console.log('in not found comp. trying to match');
    this.route.data.pipe(take(1))
      .subscribe((data: { path: Routing301 }) => {
        try {
          this.path = data.path;

          if (this.path.foundReplacement) {
            this.router.navigateByUrl(this.path.navigateTo);
          } else {
            console.log(`not found solution for url:${data.path}`);

            this.imgAlt = this.languageService.getTextById('ErrorPageAnimationAlt');
            this.pageTitle = this.languageService.getTextById('ErrorPageTitle');
          }
        } catch (error) {
          console.log(error);
        }
      }, (error) => {
        console.log(error);
      });
  }

}
