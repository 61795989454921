import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpeciesResponse } from '../models/species-response';
import { SpeciesTileResponse } from '../models/species-tile-response';
import { ApiService } from './api.service';
import { LanguageService } from './language.service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class SpeciesService {
  srvSpecies: SpeciesResponse;
  lang: string;
  scrollToFragment: string;

  constructor(
    private languageService: LanguageService,
    private http: HttpClient,
    private apiService: ApiService,
  ) { }

  public getSrvSpecies(): SpeciesResponse {
    this.lang = this.languageService.currentLang();
      return this.srvSpecies;
  }

  public setSrvSpecies(value: SpeciesResponse) {
    this.srvSpecies = value;
  }

  public getFragment(): string {
    return this.scrollToFragment;
  }

  public setFragment(value: string) {
    this.scrollToFragment = value;
  }

  public searcheBirdTaxon(sciName: string, englishName: string): Observable<SpeciesTileResponse[]> {
    const url = this.apiService.getServerUrl() + '/api/species/ebird/taxon';
    const body = {
      latinName: sciName,
      englishName: englishName
    };

    return this.http.post<SpeciesTileResponse[]>(url, body);
  }
}
