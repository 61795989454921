<section name="cameras-lobby-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-lobby-wrapper full-div">
      <h1>{{ this.pageTitle }}</h1>
      <!-- <div *ngIf="this.camerasLobbyDescription != null && this.camerasLobbyDescription != ''" class="cameras-lobby-description">
        <slot [innerHTML]="this.camerasLobbyDescription | safe"></slot>
      </div> -->
      <div *ngIf="this.screenSizes.isDesktop">
        <div *ngIf="lang === 'he'" class="cameras-lobby-description">
          <div>ברוכים הבאים אל שנתו החמישית של פרויקט <strong>&quot;עופות דורסים בשידור חי&quot;</strong> - הפרויקט שחושף אתכם לעולמם המרתק של העופות הדורסים הגדולים המקננים בישראל: העקב העיטי (<em>Buteo rufinus</em>), החיוויאי (<em>Circaetus gallicus</em>), האוח העיטי (<em>Bubo bubo</em>) והנשר (<em>Gyps fulvus</em>).&nbsp;</div>
          <div>בעזרת מצלמות Online מיוחדות (HD) שהותקנו בסמוך לקינים, אנחנו משדרים LIVE 24/7- משלב הדגירה, דרך הטיפול בגוזלים ועד לפריחתם המוצלחת מהקן .&nbsp;</div>
          <div><u>מיליוני צופים, מכל רחבי העולם</u>, עוקבים אחר השידורים החיים מישראל - נחשפים לדרמות ולאקשן חסר מעצורים.&nbsp;</div>
          <div>&nbsp;</div>
          <div>עונת השידורים מלווה בהסברים מקצועיים המאפשרים לצופים להעמיק את האינטימיות עם העופות המרשימים. כעת גם הצופים מבינים את החשיבות של שמירה על המערכות הטבעיות - אחרי שצפו באלפי פרטי הטרף שהביאו ההורים אל הקינים. כך ברור לכולם כי על מנת להשלים מחזור קינון אחד ולהצליח להפריח גוזלים בטבע הישראלי יש צורך ברצף שטחים פתוחים, איכותיים ובהם כמות מזון גדולה ועשירה ביותר! הדורסים בפרויקט הפכו להיות &quot;שגרירים&quot; או &quot;מיני דגל&quot;&nbsp; שמנחילים לציבור הרחב ערכים של שמירת טבע ושמירה על המארג הביולוגי כולו.</div>
          <div>&nbsp;</div>
          <div>לא פעם המצלמות שלנו תיעדו הפרעה לקינון - ברוב הפעמים ההפרעה נעשתה בתמימות (מטיילים שירדו משבילים מסומנים) אבל במקרים אחרים היא הייתה מכוונת, כולל ניסיון &quot;חטיפה&quot; של גוזל בשידור חי! אנחנו מנצלים את השידורים החיים כדי להפיק תובנות הנוגעות לשמירה על העופות הרגישים ויוצרים תשתית מדעית רחבה ובהירה על מהלך הקינון בטבע. תשתית זו מסייעת לקהילה המדעית להבין טוב יותר את ביולוגיית הקינון של הדורסים ומעניקה כלים ממשיים ואופטימליים עבור פעולות הממשק הנחוצות כדי לשמור על העופות הדורסים.</div>
          <div>&nbsp;</div>
          <div>פרויקט צילום האונליין הוא שיתוף פעולה בין רשות הטבע והגנים, החברה להגנת הטבע, קרן הדוכיפת,&nbsp; צה&quot;ל (צבא הגנה לסביבה), &quot;קרן המורשת&quot; ו&quot;פורשים כנף&quot;&lrm;</div>
          <div>&nbsp;</div>
        </div>
        <div *ngIf="lang === 'en'" class="cameras-lobby-description">
          <div>We are excited to welcome you to the 5th season of the <strong>Raptor Nestcam project</strong>!&nbsp;</div>
          <div>The project offers an astounding insight to the fascinating lives of 4 of Israel&#39;s largest breeding birds of prey: Long legged Buzzard (<em>Buteo rufinus</em>), Short-toed Eagle (<em>Circaetus gallicus</em>), Eurasian Eagle Owl (<em>Bubu bubo</em>) and Griffon Vulture (<em>Gyps fulvus</em>).</div>
          <div>By using state of the art HD cameras that have been placed in breeding territories, we are able to broadcast live, 24/7 and allow an intimate look at the complete nesting cycle of these amazing birds. The live stream offers non stop action and drama and is <u>followed by millions of viewers worldwide</u>!&nbsp;</div>
          <div>The Raptor Nestcam season includes regular summaries and explanations to further enhance the experience.&nbsp;</div>
          <div>The breeding raptors supply their offspring with hundreds of prey items during the season, highlighting the importance of a healthy Eco-system and high quality natural habitats. The Nestcam subjects have become flagship species for a healthy environment and the importance of undisturbed open spaces.&nbsp;</div>
          <div> Over the years our cameras have revealed many cases of nesting disturbance. In most cases the disturbance was unintentional (hikers going off trail) but in some cases the disturbance was intentional, including an attempt to &quot;steal&quot; an Eagle chick that was caught on camera! We use this information to better protect the breeding birds and immediate habitats. The documentation of the numerous prey items over the years has resulted in a valuable scientific database that is now used in practical research projects. All this information helps us to learn more about the breeding biology and needs of our breeding raptors and the insights are translated into action on the ground to better protect these birds (and others).&nbsp;</div>
          <div>The Raptor Nestcam project is a joint project of the Israel Nature and Parks Authourity, The Society for the Protection of Nature in Israel, The Hoopoe Foundation, the IDF, &quot;Legacy&quot; trust and &quot;Porsim Kanaf&quot;.&nbsp;</div>
          <div>&nbsp;</div>
        </div>
        <app-side-pannel-title [title] = "this.activeCamerasTitle"></app-side-pannel-title>
        <div *ngIf="this.cameras && this.cameras.length > 0" class="cameras-flex">
          <div *ngFor="let cam of this.cameras" class="camera">
            <a [routerLink]="['/' + lang + '/camera/' + cam.id]">
              <h3 class="cam-name">{{cam.name}}</h3>
              <div class="state-border {{cam.playerState}}">
                <app-online-camera *ngIf="(cam.isOnlineOnLobby || isValidByPassCode) && cam.playerState !== 'error' && cam.playerState !== 'idle'" 
                  [camId]="cam.id" 
                  [isMobile]="false" 
                  [camera]="cam.broadcast" 
                  [playerType]="cam.playerType"
                  [wowzaLicense]="cam.wowzaLicense"
                  [controls]="false" 
                  [mute]="true" 
                  [pauseTimeout]="pauseTimeout"
                  (playerState)="onPlayerState($event)">
                </app-online-camera>
                <div class="img-wrapper replacement-img" *ngIf="!cam.isError && (!cam.isOnline || !cam.isOnlineOnLobby)">
                  <img class="cam-img" [src]="cam.replacementImage.path" [alt]="cam.replacementImage.title"/>
                </div>
                <div class="img-wrapper offline-img" *ngIf="OfflineCameraFile && (cam.isError || cam.playerState === 'error' || cam.playerState === 'idle')">
                  <img class="cam-img" [src]="OfflineCameraFile.path" alt=""/>
                </div>
              </div>
            </a>
          </div>
          <div class="camera" *ngIf="donationCameraFile">
            <a [routerLink]="['/' + lang + '/externalRedirect/' + donationPage]">
              <h3 class="cam-name">{{ donationCameraFile.title }}</h3>
              <div class="img-wrapper">
                <img class="cam-img state-border" [src]="donationCameraFile.path" alt=""/>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="this.screenSizes.isMobile || this.screenSizes.isTablet">
        <div *ngIf="lang === 'he'" class="cameras-lobby-description">
          <div>ברוכים הבאים אל שנתו החמישית של פרויקט <strong>&quot;עופות דורסים בשידור חי&quot;</strong> - הפרויקט שחושף אתכם לעולמם המרתק של העופות הדורסים הגדולים המקננים בישראל: העקב העיטי (<em>Buteo rufinus</em>), החיוויאי (<em>Circaetus gallicus</em>), האוח העיטי (<em>Bubo bubo</em>) והנשר (<em>Gyps fulvus</em>).&nbsp;</div>
          <div>&nbsp;</div>
        </div>
        <div *ngIf="lang === 'en'" class="cameras-lobby-description">
          <div>We are excited to welcome you to the 5th season of the <strong>Raptor Nestcam project</strong>!&nbsp;</div>
        </div>
        <app-side-pannel-title [title]="this.activeCamerasTitle"></app-side-pannel-title>
        <div *ngIf="this.cameras && this.cameras.length > 0" class="cameras-flex">
          <div *ngFor="let cam of this.cameras" class="camera">
            <a [routerLink]="['/' + lang + '/camera/' + cam.id]">
              <h3 class="cam-name">{{cam.name}}</h3>
              <div class="state-border {{cam.playerState}}">
                <app-online-camera *ngIf="(cam.isOnlineOnLobby || isValidByPassCode) && cam.playerState !== 'error' && cam.playerState !== 'idle'" 
                  [camId]="cam.id" 
                  [isMobile]="true" 
                  [camera]="cam.broadcast" 
                  [controls]="false" 
                  [mute]="true"
                  [pauseTimeout]="pauseTimeout"
                  (playerState)="onPlayerState($event)">
                </app-online-camera>
                <div class="img-wrapper" *ngIf="!cam.isError && (!cam.isOnline || !cam.isOnlineOnLobby)">
                  <img class="cam-img" [src]="cam.replacementImage.path" [alt]="cam.replacementImage.title"/>
                </div>
                <div class="img-wrapper" *ngIf="OfflineCameraFile && (cam.isError || cam.playerState === 'error' || cam.playerState === 'idle')">
                  <img class="cam-img" [src]="OfflineCameraFile.path" alt=""/>
                </div>
              </div>
            </a>
          </div>
          <div class="camera" *ngIf="donationCameraFile">
            <a [routerLink]="['/' + lang + '/externalRedirect/' + donationPage]">
              <h3 class="cam-name">{{ donationCameraFile.title }}</h3>
              <div class="img-wrapper">
                <img class="cam-img state-border" [src]="donationCameraFile.path" alt=""/>
              </div>
            </a>
          </div>
        </div>
        <app-side-pannel-title [title]=""></app-side-pannel-title>
        <div *ngIf="lang === 'he'" class="cameras-lobby-description">
          <div>בעזרת מצלמות Online מיוחדות (HD) שהותקנו בסמוך לקינים, אנחנו משדרים LIVE י24/7 - משלב הדגירה, דרך הטיפול בגוזלים ועד לפריחתם המוצלחת מהקן.&nbsp;</div>
          <div><u>מיליוני צופים, מכל רחבי העולם</u>, עוקבים אחר השידורים החיים מישראל - נחשפים לדרמות ולאקשן חסר מעצורים.&nbsp;</div>
          <div>&nbsp;</div>
          <div>עונת השידורים מלווה בהסברים מקצועיים המאפשרים לצופים להעמיק את האינטימיות עם העופות המרשימים. כעת גם הצופים מבינים את החשיבות של שמירה על המערכות הטבעיות - אחרי שצפו באלפי פרטי הטרף שהביאו ההורים אל הקינים. כך ברור לכולם כי על מנת להשלים מחזור קינון אחד ולהצליח להפריח גוזלים בטבע הישראלי יש צורך ברצף שטחים פתוחים, איכותיים ובהם כמות מזון גדולה ועשירה ביותר! הדורסים בפרויקט הפכו להיות &quot;שגרירים&quot; או &quot;מיני דגל&quot;&nbsp; שמנחילים לציבור הרחב ערכים של שמירת טבע ושמירה על המארג הביולוגי כולו.</div>
          <div>&nbsp;</div>
          <div>לא פעם המצלמות שלנו תיעדו הפרעה לקינון - ברוב הפעמים ההפרעה נעשתה בתמימות (מטיילים שירדו משבילים מסומנים) אבל במקרים אחרים היא הייתה מכוונת, כולל ניסיון &quot;חטיפה&quot; של גוזל בשידור חי! אנחנו מנצלים את השידורים החיים כדי להפיק תובנות הנוגעות לשמירה על העופות הרגישים ויוצרים תשתית מדעית רחבה ובהירה על מהלך הקינון בטבע. תשתית זו מסייעת לקהילה המדעית להבין טוב יותר את ביולוגיית הקינון של הדורסים ומעניקה כלים ממשיים ואופטימליים עבור פעולות הממשק הנחוצות כדי לשמור על העופות הדורסים.</div>
          <div>&nbsp;</div>
          <div>פרויקט צילום האונליין הוא שיתוף פעולה בין רשות הטבע והגנים, החברה להגנת הטבע, קרן הדוכיפת,&nbsp; צה&quot;ל (צבא הגנה לסביבה), &quot;קרן המורשת&quot; ו&quot;פורשים כנף&quot;&lrm;</div>
        </div>
        <div *ngIf="lang === 'en'" class="cameras-lobby-description">
          <div>The project offers an astounding insight to the fascinating lives of 4 of Israel&#39;s largest breeding birds of prey: Long legged Buzzard (<em>Buteo rufinus</em>), Short-toed Eagle (<em>Circaetus gallicus</em>), Eurasian Eagle Owl (<em>Bubu bubo</em>) and Griffon Vulture (<em>Gyps fulvus</em>).</div>
          <div>By using state of the art HD cameras that have been placed in breeding territories, we are able to broadcast live, 24/7 and allow an intimate look at the complete nesting cycle of these amazing birds. The live stream offers non stop action and drama and is <u>followed by millions of viewers worldwide</u>!&nbsp;</div>
          <div>The Raptor Nestcam season includes regular summaries and explanations to further enhance the experience.&nbsp;</div>
          <div>The breeding raptors supply their offspring with hundreds of prey items during the season, highlighting the importance of a healthy Eco-system and high quality natural habitats. The Nestcam subjects have become flagship species for a healthy environment and the importance of undisturbed open spaces.&nbsp;</div>
          <div> Over the years our cameras have revealed many cases of nesting disturbance. In most cases the disturbance was unintentional (hikers going off trail) but in some cases the disturbance was intentional, including an attempt to &quot;steal&quot; an Eagle chick that was caught on camera! We use this information to better protect the breeding birds and immediate habitats. The documentation of the numerous prey items over the years has resulted in a valuable scientific database that is now used in practical research projects. All this information helps us to learn more about the breeding biology and needs of our breeding raptors and the insights are translated into action on the ground to better protect these birds (and others).&nbsp;</div>
          <div>The Raptor Nestcam project is a joint project of the Israel Nature and Parks Authourity, The Society for the Protection of Nature in Israel, The Hoopoe Foundation, the IDF, &quot;Legacy&quot; trust and &quot;Porsim Kanaf&quot;.&nbsp;</div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-scroll-top></app-scroll-top>