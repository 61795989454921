import { UrlResponse } from './url-response';
import { ImageResponse } from './image-response';
import { TagResponse } from './tag-response';
import { ArticleTileResponse } from './article-tile-response';

export class ArticleResponse {
    id: number;
    slug: string;
    typeId: number;
    articleDate: Date;
    images: ImageResponse[];
    largeImage: ImageResponse[];
    tags: TagResponse[];
    linkedArticles: ArticleTileResponse[];
    fbflag: boolean;
    langId: number;
    title: string;
    author: string;
    shortDescription: string;
    description: string;
    links: UrlResponse[];
    moreArticlesTitle: string;
    moreLinkesTitle: string;
    imageUrl: ImageResponse;
    largeImageUrl: ImageResponse;
    badge: string;
}
