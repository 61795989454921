import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { GoogleMap, MapAnchorPoint, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MyMapMarker } from 'src/app/models/my-map-marker';

@Component({
  selector: 'app-google-map-wrapper',
  templateUrl: './google-map-wrapper.component.html',
  styleUrls: ['./google-map-wrapper.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapWrapperComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  @Input() lat: number;
  @Input() lng: number;
  @Input() inputMarkers: MyMapMarker[];
  @Input() selectedMarker: MyMapMarker;
  @Input() zoom: number;
  @Input() height: string;
  @Input() heightUnits: string;

  markers: google.maps.Marker[] = [];
  markerInfo = '';

  position: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    maxZoom: 15
  };
  bounds: google.maps.LatLngBounds;

  constructor() { }

  
  ngOnInit() {
    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   }
    // })
    if (this.lat && this.lng) {
      this.position = {
        lat: this.lat,
        lng: this.lng
      };
    }
    else {
      // מרכז הצפרות הישראלי
      this.position = {
        lat: 32.062049614352205,
        lng: 34.77794616828557
      }
    }
    // console.log('googlemap - selectedMarker' + this.selectedMarker.lat + ',' + this.selectedMarker.long);
    this.height = this.height + this.heightUnits; // 'px';
  }

  CreateMapMarkers(myMarkers: MyMapMarker[]): any[]{
    const result: any[] = [];

    if (myMarkers) {
      myMarkers.forEach(myMarker => {
        // console.log(myMarker);
        const position = {
          lat: myMarker.lat,
          lng: myMarker.lng
        };
        
        let iconUrl = '';
        if (myMarker.icon) {
          iconUrl = window.location.origin + myMarker.icon;
          // console.log(iconUrl);
        }

        const opts = {
          icon: iconUrl,
          visible: true
        }

        const marker = {
          position: position,
          title: myMarker.title,
          options: opts
        }

        // marker.setPosition(position);
        // marker.setTitle(myMarker.title);        
        // marker.setVisible(true);

        // if (myMarker.icon) {
        //   const url = window.location.origin + myMarker.icon;
        //   console.log(url);
        //   marker.setIcon(url);
        // }

        this.bounds.extend(position);
        result.push(marker);
      });
    }
    return result;
  }

  openInfo(marker: MapMarker, title: string) {
    // console.log(marker);
    // console.log(this.info)
    this.markerInfo = title;
    // this.info.open(marker);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.inputMarkers) {
        this.markers = changes.inputMarkers.currentValue;
      }
    }
  }

  ngAfterViewInit() {
    this.bounds = new google.maps.LatLngBounds();

    this.markers = this.CreateMapMarkers(this.inputMarkers);    
    this.map.fitBounds(this.bounds, 4);
    this.map.panToBounds(this.bounds);
  }
}
