import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Tile } from '../../models/tile';
import { TileComponent } from '../../_components/tile/tile.component';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { LoadingAnimationService } from '../../_services/loading-animation.service';
import { SpeciesFamilyTileResponse } from '../../models/species-family-tile-response';
import { SpeciesTileResponse } from 'src/app/models/species-tile-response';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-species-families',
  templateUrl: './species-families.component.html',
  styleUrls: ['./species-families.component.css']
})
export class SpeciesFamiliesComponent implements OnInit {
  // @Input() filterTextParam: string;
  filterTextParam: string;
  speciesFamilies: SpeciesFamilyTileResponse[];
  speciesSearch: SpeciesTileResponse[];
  lang: string;
  spfTiles: Tile[] = [];
  speTiles: Tile[] = [];
  filterText: string;
  title: string;
  pageDesc: string;
  searchSubscription: Subscription;
  familiesTitle: string;
  speciesTitle: string;

  constructor(private http: HttpClient,
    public languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
    ) {}

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.title = this.languageService.getTextById('specieFamilyTitle');
    this.pageDesc = this.languageService.getTextById('encyclopediaPageDesc');
    this.familiesTitle = this.languageService.getTextById('FamiliesTitle');
    this.speciesTitle = this.languageService.getTextById('SpeciesTitle');
    this.spfTiles = []; // clear tile array on routing between types
    // this.route.paramMap.subscribe((params: ParamMap) => {
    // const filterParam = params.get('filterParam');
    //   if (filterParam) {
    //     this.filterTextParam = filterParam;
    //   }
    //   this.getSpeciesFamilies();
    // });

    const filterParam = sessionStorage.getItem('clickedTagName');
    if (filterParam) {
      this.filterTextParam = filterParam;
      sessionStorage.removeItem('clickedTagName');
    }
    this.getSpeciesFamilies();

  }

  private getSpeciesFamilies() {
    // let serviceUrl: string;

    this.loadingAnimationService.publishLoadingAnimation(true);
    const serviceUrl = this.apiService.getServerUrl() + '/api/species/families/' + this.lang;

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: SpeciesFamilyTileResponse[]) => {
          if (response !== undefined) {
            this.speciesFamilies = response;

            if (this.speciesFamilies) {
              this.createSpfTiles();
            }

            // prepare the breadcrumbs
            this.bcService.clearLevels();
            this.bcService.setLastItemTitle(this.languageService.getTextById('IsraelsBirds'));

            const pageTitle = this.languageService.getTextById('BirdOrders')
                              + ' | ' + this.languageService.getTextById('specieFamilyTitle')
                              + ' - ' + this.languageService.getTextById('baseSeoTitle');
            const pageImage = this.speciesFamilies[0].imageUrl && this.speciesFamilies[0].imageUrl.path; // get image from first
            const keywords = this.languageService.getTextById('IsraelsBirdsEncyclopedia')
                              + ',' + this.languageService.getTextById('BirdOrders') + ','
                              + this.languageService.getTextById('baseSeoKeywords');
            const description = this.languageService.getTextById('BirdOrders')
                                + ' - ' + this.languageService.getTextById('IsraelsBirdsEncyclopedia')
                                + ',' + this.languageService.getTextById('baseSeoDescription');
            const pagePath = '/species-families/';
            const pageUrl = location.origin + pagePath;
            const canonicalUrl = location.origin + '/' + this.lang + pagePath;

            const pageType = this.languageService.getTextById('BirdOrders');
            this.bcService.seoItems(pageTitle, keywords, description, pageImage, pageUrl, pageType, canonicalUrl);

            // publish
            this.bcService.publishBreadcrumbs();

            this.loadingAnimationService.publishLoadingAnimation(false);
          }
        }, error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
    }
  }

  private createSpfTiles() {
    this.speciesFamilies.forEach(family => {
      if (this.spfTiles.some(item => item.tileLinkType === 'species-family' && item.tileLinkID === family.id.toString())) {
        return;
      }
      const tile: Tile = {
        image: family.imageUrl,
        title: family.title,
        metaData1: null,
        metaData2: null,
        showMD1: false,
        showMD2: false,
        italicMD1: false,
        italicMD2: false,
        filterData: family.speciesList + ' | ' + family.speciesListLatin,
        shortDescription: family.shortDescription,
        tags: null,
        badge: family.name,
        badgeColor: 'green',
        tileLinkType: 'species-family',
        tileLinkID: family.id.toString()
      };

      this.spfTiles.push(tile);
    });
  }

  onFilterTextChange (filterTextSync: string) {
    if (filterTextSync.length > 2) {
      this.filterText = filterTextSync;
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
        this.speTiles = [];
        this.loadingAnimationService.publishLoadingAnimation(false);
      }
      this.searchSpecies(filterTextSync);
    } else {
      this.filterText = '';
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
        this.speTiles = [];
        this.loadingAnimationService.publishLoadingAnimation(false);
      }
    }
  }

  private searchSpecies(query: string) {
    this.loadingAnimationService.publishLoadingAnimation(true);
    const serviceUrl = this.apiService.getServerUrl() + '/api/species/search/' + this.lang + '/' + query;

    if (serviceUrl) {
      this.searchSubscription = this.http.get(serviceUrl)
        .subscribe((response: SpeciesTileResponse[]) => {
          if (response !== undefined) {
            this.speciesSearch = response;

            if (this.speciesSearch) {
              this.createSpeTiles();
            }
          }
        }, error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          // add notification about search empty
        });
        this.loadingAnimationService.publishLoadingAnimation(false);
      }
    }

  private createSpeTiles() {    
    this.speciesSearch.forEach(species => {
      if (this.spfTiles.some(item => item.tileLinkType === 'species-page' && item.tileLinkID === species.id.toString())) {
        return;
      }
      const tile: Tile = {
        image: species.imageUrl,
        title: species.name,
        metaData1: species.latinName,
        metaData2: null,
        showMD1: true,
        showMD2: false,
        italicMD1: true,
        italicMD2: false,
        filterData: species.name + ', ' + species.latinName,
        shortDescription: species.shortDescription,
        tags: null,
        badge: species.speciesFamily,
        badgeColor: 'green',
        tileLinkType: (species.openEncyclopediaPage) ? 'species-page' : '',
        tileLinkID: (species.openEncyclopediaPage) ? species.id.toString() : ''
      };

      this.speTiles.push(tile);
    });
  }

}

