import { Component, OnInit } from '@angular/core';
import { SpeciesChecklistResponse } from 'src/app/models/species-checklist-response';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-species-checklist',
  templateUrl: './species-checklist.component.html',
  styleUrls: ['./species-checklist.component.css']
})
export class SpeciesChecklistComponent implements OnInit {
  speciesChecklistData: SpeciesChecklistResponse[];
  lang: string;
  dir: string;
  // filterText: string;
  title: string;
  subTitle: string;
  expendGroups: boolean[] = [];
  speCount = 0;

  constructor(private http: HttpClient,
    public languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    this.title = this.languageService.getTextById('SpeciesChecklistTitle');
    this.subTitle = this.languageService.getTextById('checklistSubTitle');
    this.getSpeciesChecklistData();
  }

  private getSpeciesChecklistData() {
    this.loadingAnimationService.publishLoadingAnimation(true);
    const serviceUrl = this.apiService.getServerUrl() + '/api/species/checklist/' + this.lang;

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: SpeciesChecklistResponse[]) => {
          if (response !== undefined) {
            this.speciesChecklistData = response;

            // prepare the breadcrumbs
            this.bcService.clearLevels();
            this.bcService.setLastItemTitle(this.languageService.getTextById('SpeciesChecklistTitle'));

            const pageTitle = this.languageService.getTextById('SpeciesChecklistTitle')
              + ' | ' + this.languageService.getTextById('baseSeoTitle');
            const pageImage = this.speciesChecklistData[0].family.imageUrl && this.speciesChecklistData[0].family.imageUrl.path;
            const keywords = this.languageService.getTextById('SpeciesChecklistTitle')
              + this.languageService.getTextById('baseSeoKeywords');
            const description = this.languageService.getTextById('SpeciesChecklistTitle')
              + ',' + this.languageService.getTextById('baseSeoDescription');
            const pagePath = '/species-checklist/';
            const pageUrl = location.origin + pagePath;
            const canonicalUrl = location.origin + '/' + this.lang + pagePath;

            const pageType = this.languageService.getTextById('SpeciesChecklistTitle');
            this.bcService.seoItems(pageTitle, keywords, description, pageImage, pageUrl, pageType, canonicalUrl);

            // count and expend groups
            this.speciesChecklistData.forEach(element => {
              this.speCount += element.speciesList.length;

              this.expendGroups.push(true);
            });

            // publish
            this.bcService.publishBreadcrumbs();

            this.loadingAnimationService.publishLoadingAnimation(false);
          }
        }, error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
    }
  }

  public toggleExpendYears(familyId: number) {
    this.expendGroups[familyId] = !this.expendGroups[familyId];
  }

}
