import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LocalStorageService } from './localStorage.service';
import { LoginRequest } from '../models/login-request';
import { Observable } from 'rxjs';
import { AlertifyService } from './alertify.service';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  jwtHelper = new JwtHelperService();
  decodedToken: any;
  //model: LoginRequest;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private alertify: AlertifyService,
    private router: Router,
    private languageService: LanguageService,
    private localStorageService: LocalStorageService) { }

  login(model: LoginRequest): Observable<void> {

    return this.http.post(this.apiService.getServerUrl() + '/api/auth/login', model)
      .pipe(
        map((response: any) => {
          console.log(response);
          const user = response;
          if (user) {
            console.log('has user');
            this.localStorageService.setToken(user.token);
            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            this.router.navigate([this.languageService.currentLang(), 'cms', 'menu']);
            // return true;
          } else {
            console.log('no user');
            this.alertify.error(this.languageService.getTextById('invalidLogin'));
            // return false;
          }
        })
      );
  }

  isLoggedIn(): boolean {
    const token = this.localStorageService.getToken();
    if (!this.jwtHelper.isTokenExpired(token)) {
      this.decodedToken = this.jwtHelper.decodeToken(token);
      return true;
    }

    this.decodedToken = undefined;
    return false;
  }

  getUserId(): Number | null {
    if (!this.isLoggedIn())
      return null;
    
    return this.decodedToken.nameid;
  }
  logout(): void {
    this.localStorageService.clearToken();
  }

  isPermittedTo(item: string): boolean {
    const token = this.localStorageService.getToken();
    
    if (this.jwtHelper.isTokenExpired(token)) {
      console.log('token expired');
      return false;
    }
    this.decodedToken = this.jwtHelper.decodeToken(token);
    if (!this.decodedToken) {
      console.log('failed to decode token');
      return false;
    }

    if (this.decodedToken[item] === "true") {
      console.log(this.decodedToken);
      return true;
    }
    
    return false;
  }
}
