import { Component, OnInit, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../_services/language.service';
import { ApiService } from '../../_services/api.service';
import { ArticleResponse } from '../../models/article-response';
import { Breadcrumbs } from '../../models/breadcrumbs';
import { UrlResponse } from '../../models/url-response';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { ImageSliderObject } from '../../models/image-slider-object';
import { Animations } from '../../animations';
import { getLocaleDateFormat } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { LoadingAnimationService } from '../../_services/loading-animation.service';
import { ArticleService } from '../../_services/article.service';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css'],
  animations: [Animations],
  encapsulation: ViewEncapsulation.None
})
export class ArticleComponent implements OnInit {
  @Output() setBreadcrumbs;
  article: ArticleResponse;
  lang: string;
  dir: string;
  imgGalleryState: boolean;
  articleDate: string;
  articleSlug: string;
  articleName: string;
  moreImagesCaption: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService,
    private articleService: ArticleService,
    private loadingAnimationService: LoadingAnimationService,
    private route: ActivatedRoute,
    private router: Router

    // private router: Router
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');
      const id = parseInt(params.get('id'), 0);
      this.lang = this.languageService.currentLang();
      this.dir = (this.lang === 'he') ? 'R' : 'L';
      // business logic what changes you want on the page with this new data.

      if (id) {
        this.getArticleById(id);
      }

      if (slug) {
        this.getArticleBySlug(slug);
      }
    });
  }

  private getArticleBySlug(articleSlug: string) {
  // private getArticle(articleId: number) {
      this.loadingAnimationService.publishLoadingAnimation(true);

    // this.http.get(this.apiService.getServerUrl() + '/api/articles/byid/' + this.lang + '/' + articleId)
    this.http.get(this.apiService.getServerUrl() + '/api/articles/byslug/' + this.lang + '/' + encodeURIComponent(articleSlug))
      .subscribe((response: ArticleResponse) => {
        this.article = response;

        this.articleSlug = this.articleService.getSlugById(this.article.typeId);
        this.articleName = this.articleService.getNameById(this.article.typeId, this.lang);
        this.articleDate = this.languageService.showLongDate(response.articleDate);
        // prepare the breadcrumbs
        this.bcService.clearLevels();
        this.bcService.setLastItemTitle(this.article.title);
        this.bcService.addLevel(this.languageService.getTextById('allArticles'), 'articles/type/allarticletypes');
        this.bcService.addLevel(this.articleService.getPluralsNameById(this.article.typeId, this.lang),
          'articles/type/' + this.articleService.getSlugById(this.article.typeId));

        let pageTitle = this.article.title + ' | ';
        pageTitle += this.articleService.getPluralsNameById(this.article.typeId, this.lang);
        if (this.article.typeId !== 1) {
          pageTitle += ' | ' + this.articleService.getPluralsNameBySlug('article', this.lang);
        }

        pageTitle += ' - ' + this.languageService.getTextById('baseSeoTitle');
        const pageImage = this.article.imageUrl && this.article.imageUrl.path;
        const keywords =  this.article.author + ','
                          + this.article.title + ','
                          + this.articleService.getPluralsNameById(this.article.typeId, this.lang) + ','
                          + this.languageService.getTextById('baseSeoKeywords');

        const desciption = this.article.title + ','
                           + this.articleService.getPluralsNameById(this.article.typeId, this.lang) + ','
                           + this.languageService.getTextById('baseSeoDescription');

        const pageUrl = location.origin + '/' + this.lang + '/article/' + encodeURI(this.article.slug);
        const canonicalUrl = location.origin + '/' + this.lang + '/article/' + encodeURI(this.article.slug);
        const pageType = 'article';
        this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, canonicalUrl);

        // publish
        this.bcService.publishBreadcrumbs();

        this.moreImagesCaption = this.languageService.getTextById('GalleryImagesCaption');
        this.loadingAnimationService.publishLoadingAnimation(false);
      },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        }
      );
  }

  // article by id - keep this option for our convenience
  private getArticleById(articleId: number) {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.http.get(this.apiService.getServerUrl() + '/api/articles/byid/' + this.lang + '/' + articleId)
      .subscribe((response: ArticleResponse) => {
        this.article = response;

        this.articleSlug = this.articleService.getSlugById(this.article.typeId);
        this.articleName = this.articleService.getNameById(this.article.typeId, this.lang);
        this.articleDate = this.languageService.showLongDate(response.articleDate);
        // prepare the breadcrumbs
        this.bcService.clearLevels();
        this.bcService.setLastItemTitle(this.article.title);
        this.bcService.addLevel(this.languageService.getTextById('allArticles'), 'articles/type/allarticletypes');
        this.bcService.addLevel(this.articleService.getPluralsNameById(this.article.typeId, this.lang),
          'articles/type/' + this.articleService.getSlugById(this.article.typeId));

        let pageTitle = this.article.title + ' | ';
        pageTitle += this.articleService.getPluralsNameById(this.article.typeId, this.lang);
        if (this.article.typeId !== 1) {
          pageTitle += ' | ' + this.articleService.getPluralsNameBySlug('article', this.lang);
        }

        pageTitle += ' - ' + this.languageService.getTextById('baseSeoTitle');
        const pageImage = this.article.imageUrl && this.article.imageUrl.path;
        const keywords =  this.article.author + ','
                          + this.article.title + ','
                          + this.articleService.getPluralsNameById(this.article.typeId, this.lang) + ','
                          + this.languageService.getTextById('baseSeoKeywords');

        const desciption = this.article.title + ','
                            + this.articleService.getPluralsNameById(this.article.typeId, this.lang) + ','
                            + this.languageService.getTextById('baseSeoDescription');

        const pageUrl = location.origin + '/article/' + this.articleSlug;
        const canonicalUrl = location.origin + '/' + this.lang + '/article/' + this.articleSlug;
        const pageType = 'כתבה';
        this.bcService.seoItems(pageTitle, keywords, desciption, pageImage, pageUrl, pageType, canonicalUrl);

        // publish
        this.bcService.publishBreadcrumbs();

        this.moreImagesCaption = this.languageService.getTextById('GalleryImagesCaption');
        this.loadingAnimationService.publishLoadingAnimation(false);
      },
        error => {
          console.log(error);
          this.loadingAnimationService.publishLoadingAnimation(false);
        }
      );
  }

  // private imgGalleryOn() {
  //   this.imgGalleryState = true;
  // }

  // onImgGalleryStateOff(state: boolean) {
  //   this.imgGalleryState = state;
  // }
}
