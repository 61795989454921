import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumbs } from '../../models/breadcrumbs';
import { LanguageService } from '../../_services/language.service';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {
  lang: string;
  dir: string;

  @Input()
  breadcrumbs: Breadcrumbs;

  constructor(private languageService: LanguageService, private breadcrumbsService: BreadcrumbsService) {
  }

  breadcrumbsRootTitle: string;

  ngOnInit() {
    this.breadcrumbsService.breadcrumbsTransmitter$
      .subscribe(bc => this.breadcrumbs = bc);

    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';

  }

}
