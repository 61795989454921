import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';

@Component({
  selector: 'app-image-browser',
  templateUrl: './image-browser.component.html',
  styleUrls: ['./image-browser.component.css']
})
export class ImageBrowserComponent implements OnInit {
  accept = '*';
  @Input() files: File[] = [];
  progress: number;
  // url = 'https://evening-anchorage-3159.herokuapp.com/api/'
  url = 'https://jquery-file-upload.appspot.com/';
  hasBaseDropZoneOver = false;
  httpEmitter: Subscription;
  httpEvent: HttpEvent<{}>;
  lastFileAt: Date;

  sendableFormData: FormData; // populated via ngfFormData directive

  dragFiles: any;
  validComboDrag: any;
  lastInvalids: any;
  fileDropDisabled: any;
  maxSize: any;
  baseDropValid: any;
  browseImageFiles: string;
  dropImageFilesZone: string;

  constructor(
    public client: HttpClient,
    private languageService: LanguageService
    ) { }

  ngOnInit() {
    this.browseImageFiles = this.languageService.getTextById('BrowseImageFiles');
    this.dropImageFilesZone = this.languageService.getTextById('DropImageFilesZone');

  }


  cancel() {
    this.progress = 0;
    if (this.httpEmitter) {
      console.log('cancelled');
      this.httpEmitter.unsubscribe();
    }
  }

  uploadFiles(): Subscription {
    const req = new HttpRequest<FormData>(
      'POST',
      this.url,
      this.sendableFormData, {
      reportProgress: true // , responseType: 'text'
    });

    return this.httpEmitter = this.client.request(req)
    .subscribe(
      event => {
        this.httpEvent = event;

        if (event instanceof HttpResponse) {
          delete this.httpEmitter;
          console.log('request done', event);
        }
      },
      error => alert('Error Uploading Files: ' + error.message)
    );
  }

  getDate() {
    return new Date();
  }

}
