<div class="full-div">
  <div *ngIf="artTypes" class="article-types-menu">
    <div *ngFor="let item of artTypes" class="menu-item">
      <div *ngIf="item.id === selectedTypeId || !item.slug">
        <span class="article-type-button selected">
          <span *ngIf="lang === 'he'">{{ item.pluralsNameHe }}</span>
          <span *ngIf="lang === 'en'">{{ item.pluralsNameEn }}</span>
        </span>
      </div>
      <div *ngIf="item.id !== selectedTypeId">
        <a class="article-type-button" (click)="routeToType(item.slug)">
          <span *ngIf="lang === 'he'">{{ item.pluralsNameHe }}</span>
          <span *ngIf="lang === 'en'">{{ item.pluralsNameEn }}</span>
        </a>
      </div>
    </div>
  </div>
</div>