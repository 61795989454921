import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SpeciesResponse } from 'src/app/models/species-response';
import { LanguageService } from 'src/app/_services/language.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SpeciesService } from 'src/app/_services/species.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-species-description',
  templateUrl: './species-description.component.html',
  styleUrls: ['./species-description.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpeciesDescriptionComponent implements OnInit {
  species: SpeciesResponse;
  lang: string;
  dir: string;
  redbookTitle: string;
  imgGalleryState: boolean;

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private speciesService: SpeciesService,
    private router: Router,
    private _clipboardService: ClipboardService
  ) { }

  ngOnInit() {
    // console.log('species-description  on init');
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    
    this.redbookTitle = this.languageService.getTextById('redbookTitle');
    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');
      const id = parseInt(params.get('id'), 0);

      this.species = this.speciesService.getSrvSpecies();
      if (this.species === undefined) {

        if (id !== undefined && !isNaN(id)) {
          this.router.navigate(['/' + this.lang + '/species-page/' + id]);
        } else if (slug !== null) {
          // currently - not implemented
        } else {
          this.router.navigate(['/' + this.lang + './species-families']);
        }
      }

    });

  }

  public routeToInitTab() {
    this.router.navigate(['./species-description'], { relativeTo: this.route });
  }

  public routeToMoreInfo(fragment: string) {
    this.speciesService.setFragment(fragment);
    this.router.navigate(['./species-moreinfo'], { relativeTo: this.route.parent });
  }

  onImgGalleryStateOff(state: boolean) {
    this.imgGalleryState = state;
  }

  copySpeciesNames($event) {
    this._clipboardService.copyFromContent(($event.target).innerText);
    return false;
  }
}
