import { Injectable, Inject } from '@angular/core';
import { Breadcrumbs } from '../models/breadcrumbs';
import { UrlResponse } from '../models/url-response';
import { Subject } from 'rxjs';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
// import { MetadataFactory } from '@angular/compiler/src/core';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  private breadcrumbsTransmitter: Subject<Breadcrumbs> = new Subject<Breadcrumbs>();
  public breadcrumbsTransmitter$ = this.breadcrumbsTransmitter.asObservable();

  breadcrumbs: Breadcrumbs = new Breadcrumbs();
  private lang: string;

  constructor(
    @Inject(DOCUMENT)
    private dom: Document,
    private title: Title,
    private meta: Meta,
    private languageService: LanguageService
    ) {
    this.breadcrumbs.items = new Array<UrlResponse>();
   }

  setLastItemTitle(title: string) {
    this.breadcrumbs.lastItemTitle = title;
  }

  addLevel(title: string, route: string) {
    const item = new UrlResponse();
    item.name = title;
    item.href = this.languageService.currentLang() + '/' + route;

    this.breadcrumbs.items.push(item);
  }

  addRootLevel() {
    this.breadcrumbs.rootItem = this.languageService.getTextById('breadcrumbsRootTitle');
  }

  seoItems(title: string, keywords: string, description: string, coverUrl: string,
    pageUrl: string, pageType: string, canonicalUrl: string) {

    this.title.setTitle(title);
    const keywordsTag: MetaDefinition = { content: keywords, name: 'keywords' };
    const descriptionTag: MetaDefinition = { content: description, name: 'description' };

    // facebook
    const fbTitle: MetaDefinition = { content: title, property: 'og:title' };
    const fbUrl: MetaDefinition = { content: pageUrl, property: 'og:url' };
    const fbDescription: MetaDefinition = { content: description, property: 'og:description' };
    const fbType: MetaDefinition = { content: pageType, property: 'og:type' };
    // const fbImage: MetaDefinition = { content: pageImage, name: 'og:image' };

    console.log('page url:' + pageUrl);

    this.meta.updateTag({ property: 'og:image', content: coverUrl, itemprop: 'image' });
    if (coverUrl !== undefined && coverUrl !== null && coverUrl.endsWith('.png')) {
      this.meta.updateTag({ property: 'og:image:type', content: 'image/png' });
    } else {
      this.meta.updateTag({ property: 'og:image:type', content: 'image/jpeg' });
    }
    this.meta.updateTag(keywordsTag);
    this.meta.updateTag(descriptionTag);
    this.meta.updateTag(fbTitle);

    // this.meta.removeTag("name='og:url'");
    // this.meta.removeTag("name='og:description'");
    // this.meta.removeTag("name='og:title'");
    this.meta.updateTag(fbUrl);
    this.meta.updateTag(fbDescription);
    this.meta.updateTag(fbType);
    this.createCanonicalURL(canonicalUrl);
  }

  createCanonicalURL(canonicalUrl: string) {
    const canonicalId = 'canonicalNodeId';
    const old_canonical = this.dom.getElementById(canonicalId);
    if (old_canonical !== null) {
      this.dom.head.removeChild(old_canonical);
    }

    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('id', canonicalId);
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canonicalUrl);
  }

  clearLevels() {
    // this.breadcrumbs.items.splice(0, this.breadcrumbs.items.length);
    this.breadcrumbs.items = new Array<UrlResponse>();
  }

  publishBreadcrumbs() {
    this.addRootLevel();
    this.breadcrumbsTransmitter.next(this.breadcrumbs);
  }

  emptyBreadcrumbs() {
    this.breadcrumbsTransmitter.next(null);
  }
}
