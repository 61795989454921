<div class="tile small-{{isSmall}}">
  <a *ngIf="tile.tileLinkType != '' && tile.tileLinkID != ''" [routerLink]="['/' + lang + '/' + tile.tileLinkType, tile.tileLinkID]">
    <div *ngIf="tile" class="tile-shadow-box tile-box-flex">
      <div class="tile-img-wrapper tile-wrapper-flex full-div"> 
        <img *ngIf="tile.image" class="tile-img full-div"
        [src]="tile.image.path"
        [alt]="tile.image.name"
        [title]="tile.title"
        />
        <div class="tile-caption tile-wrapper-flex full-div">
          <p class="tile-badge item-md-wrapper base-t-16 c-white t-b"><span class="tile-badge-txt tile-badge-color {{ tile.badgeColor }}">{{ tile.badge }}</span></p>
          <h2 *ngIf="!isSmall" class="tile-title title-t-25 c-white t-b">{{ tile.title}}</h2>
          <h2 *ngIf="isSmall" class="tile-title title-t-22 c-white t-b">{{ tile.title}}</h2>
          <div class="tile-md-wrapper base-t-16 c-white">
            <span class="tile-md border-{{this.dir}} italic-{{tile.italicMD1}}" *ngIf="tile.showMD1">{{ tile.metaData1 }}</span>
            <span class="tile-md" *ngIf="tile.showMD2">{{ tile.metaData2 }}</span>
          </div>
        </div>
      </div>
      <div class="tile-data-wrapper tile-sr-only">
        <div *ngIf="!isSmall" class="tile-text base-t-18">{{ tile.shortDescription }}</div>
        <div *ngIf="isSmall" class="tile-text base-t-16">{{ tile.shortDescription | truncatetext: 95}}</div>
        <div class="tile-bottom">
          <div class="tile-tags" *ngIf="tile.tags">
            <span *ngFor="let tag of tile.tags; let i=index;">
              <span class="tag-padding small-t-12" *ngIf="i<maxTags">         
                <i class="fas fa-tag c-green"></i>
                {{ tag.name }}
              </span>
            </span>  
          </div>
          <div class="tile-goto-items">
            <a [routerLink]="['/he/' + tile.tileLinkType, tile.tileLinkID]"><span><i class="read-more-icon fas fa-angle-double-left dir {{ this.dir }}" *ngIf="this.dir === 'R'" title="להמשך קריאה"></i></span></a>
            <a [routerLink]="['/en/' + tile.tileLinkType, tile.tileLinkID]"><span><i class="read-more-icon fas fa-angle-double-right dir-op {{ this.dir }}" *ngIf="this.dir === 'L'" title="Read more"></i></span></a>
          </div>
        </div>
      <div style="display: none;">{{ tile.filterData }}</div>
      </div>
    </div>
  </a>
  <div *ngIf="tile.tileLinkType == '' || tile.tileLinkID == ''">
    <div *ngIf="tile" class="tile-shadow-box tile-box-flex">
      <div class="tile-img-wrapper tile-wrapper-flex full-div"> 
        <img *ngIf="tile.image" class="tile-img full-div"
        [src]="tile.image.path"
        [alt]="tile.image.name"
        [title]="tile.title"
        />
        <div class="tile-caption tile-wrapper-flex full-div">
          <p class="tile-badge item-md-wrapper base-t-16 c-white t-b"><span class="tile-badge-txt tile-badge-color {{ tile.badgeColor }}">{{ tile.badge }}</span></p>
          <h2 *ngIf="!isSmall" class="tile-title title-t-25 c-white t-b">{{ tile.title}}</h2>
          <h2 *ngIf="isSmall" class="tile-title title-t-22 c-white t-b">{{ tile.title}}</h2>
          <div class="tile-md-wrapper base-t-16 c-white">
            <span class="tile-md border-{{this.dir}} italic-{{tile.italicMD1}}" *ngIf="tile.showMD1">{{ tile.metaData1 }}</span>
            <span class="tile-md" *ngIf="tile.showMD2">{{ tile.metaData2 }}</span>
          </div>
        </div>
      </div>
      <div class="tile-data-wrapper tile-sr-only">
        <div *ngIf="!isSmall" class="tile-text base-t-18">{{ tile.shortDescription }}</div>
        <div *ngIf="isSmall" class="tile-text base-t-16">{{ tile.shortDescription | truncatetext: 95}}</div>
        <div class="tile-bottom">
          <div class="tile-tags" *ngIf="tile.tags">
            <span *ngFor="let tag of tile.tags; let i=index;">
              <span class="tag-padding small-t-12" *ngIf="i<maxTags">         
                <i class="fas fa-tag c-green"></i>
                {{ tag.name }}
              </span>
            </span>  
          </div>
          <div class="tile-goto-items">
            <span class="c-darker-grey small-t-12"><i class="fas fa-tools dir {{ this.dir }} under-construction" *ngIf="this.dir === 'R'" title="פריט בעריכה"></i></span>
            <span class="c-darker-grey small-t-12"><i class="fas fa-tools dir-op {{ this.dir }} under-construction" *ngIf="this.dir === 'L'" title="Item in Editing"></i></span>
            <!-- <span class="under-construction"><i class="fas fa-tools" title="item in editing"></i></span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
