<section name="article-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="main-wrapper main-article-wrapper full-div">
      <div class="article-header">
        <div class="side-header third-div {{dir}} hide-mobile">
          <h1 class="article-title {{dir}} t-b">{{ article.title }}</h1>
          <div class="article-author-inf full-div">
            <p class="article-title-note {{this.dir}} c-white t-b article-type-caption ">{{ articleName }}</p>
            <app-side-pannel-title class="hide-mobile header-side-divider" [title] = ""></app-side-pannel-title>
            <a [routerLink]="['/' + lang + '/articles/author/', article.author]"><h3 class="t-b">{{ article.author }}</h3></a>
            <p class="article-date">{{ articleDate }}</p>
            <app-social-shares></app-social-shares>
          </div>
        </div>
        <div class="image-header two-third-div {{dir}}">
          <div name="article-header" *ngIf="!imgGalleryState" [@fadeInOut] class="article-img-container full-div">
            <div *ngIf="article && article.imageUrl">
              <!-- <input class="hidden-input" type="file" (change)="fileChangeEvent($event)" />
              <image-cropper class="hide-image-cropper"
                  [imageChangedEvent]="this.imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="827 / 480"
                  [resizeToWidth]="827"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady()"
                  (loadImageFailed)="loadImageFailed()"
              ></image-cropper>

              <img class="croped-image" [src]="this.croppedImage" /> -->
              
              <figure>
                <img
                  [src]="article.imageUrl.path"
                  [alt]="article.imageUrl.name"
                  [title]="article.imageUrl.title"
                  class="article-img full-div"
                  [ngStyle]="{'margin-top': cropTop}"
                />
              </figure>
            </div>
            <div class="image-element hide-desktop">
              <div>
                <div *ngIf="article && article.images && article.images.length > 1" class="img-tooltip hand" (click)="imgGalleryOn()">+ {{article.images.length}} {{moreImagesCaption}}</div>
              </div>
              <div>
                <p class="article-title-note {{this.dir}} c-white t-b article-type-caption ">{{ articleName }}</p>
                <h1 class="article-title  {{this.dir}} c-white t-b">{{ article.title }}</h1>
              </div>
            </div>    
          </div>
          <!-- <app-image-slider *ngIf="imageSliderOn" class="article-img full-div" [images]="article.images"></app-image-slider> -->
          <!-- <app-image-slider *ngIf="imageSliderOn" [images]="article.images" [isShow]="imageSliderOn"></app-image-slider> -->
          <app-image-gallery *ngIf="imgGalleryState" [title]= "article.title" [images]="article.images" [imgGalleryState]="this.imgGalleryState" (imgGalleryParentState) = "onImgGalleryStateOff($event)" [badge]="'article.articleSlug'"></app-image-gallery>  
        </div>
      </div>
      <div class="third-div {{dir}}">
        <div class="article-subtxt hide-desktop full-div">
          <h2>{{ article.shortDescription }}</h2>
        </div>
        <app-tags class="hide-desktop" *ngIf="article && article.tags && article.tags.length > 0" [tags]="article.tags"></app-tags>
        <app-linked-articles class="hide-mobile" 
          *ngIf="article && article.linkedArticles && article.linkedArticles.length > 0" 
          [linkedArticles]="article.linkedArticles" 
          [moreArticlesTitle]="article.moreArticlesTitle" 
          [lang]="lang"></app-linked-articles>
        <app-tags class="hide-mobile" *ngIf="article && article.tags && article.tags.length > 0" [tags]="article.tags"></app-tags>
        <app-links class="hide-mobile" *ngIf="article && article.links && article.links.length > 0" [links]="article.links" [linksTitle]="article.moreLinkesTitle"></app-links>
      </div>
      <div class="article-main two-third-div  {{dir}}">
        <div class="article-subtxt {{dir}} full-div hide-mobile">
          <h2>{{ article.shortDescription }}</h2>
        </div>
        <app-side-pannel-title class="hide-mobile" [title] = "" [width]="30"></app-side-pannel-title>
        <div class="inner-html-txt full-div">
          <slot [innerHTML]="article.description | imgHost | safe"></slot>
        </div>
        <app-comments *ngIf="article.fbflag"></app-comments>
        <!-- <app-words *ngIf="article && article.description" class="hide-mobile" [title]="article.title"
          [displayedWords]="article.description"></app-words> -->
      </div>
    </div>
  </div>
</section>

<app-linked-articles class="hide-desktop" 
*ngIf="article && article.linkedArticles && article.linkedArticles.length > 0" 
[linkedArticles]="article.linkedArticles" 
[moreArticlesTitle]="article.moreArticlesTitle" 
[lang]="lang"></app-linked-articles>

<app-links class="hide-desktop" *ngIf="article && article.links && article.links.length > 0" [links]="article.links" [linksTitle]="article.moreLinkesTitle"></app-links>
<app-scroll-top></app-scroll-top>
