
<section name="cms-menu-section" class="full-div">
  <div class="grid" style="overflow: initial;">
    <div class="full-div">
      <h1>מערכת ניהול התוכן של פורטל הצפרות</h1>

      <div class="full-div show-for-tablet">
        <div class="cms-nav-wrapper-desktop">

          <div class="sixth-div cms-card" *ngIf="isEbird">
            <h4 class="t-b">eBird</h4>
            <ul class="footer-nav-desktop-col full-div">
              <li class="full-div s-15 c-blue">
                <a class="c-blue" [routerLink]="['/' + lang + '/cms/ebirdtaxon']">איתור מינים לטעינת eBird</a>
              </li>
            </ul>
          </div>

          <div class="sixth-div cms-card" *ngIf="isRingingManager">
            <h4 class="t-b">טיבוע</h4>
            <ul class="footer-nav-desktop-col full-div">
              <li class="full-div s-15 c-blue">
                <a class="c-blue" [routerLink]="['/' + lang + '/cms/rings']">ניהול טבעות</a>
              </li>
            </ul>
          </div>

          <div class="sixth-div cms-card" *ngIf="isRinger">
            <h4 class="t-b">למטבע</h4>
            <ul class="cms-nav-desktop-col full-div">
              <li class="full-div s-15 c-blue">
                <a class="c-blue" [routerLink]="['/' + lang + '/cms/ringing-data']">הטיבועים שלי</a>
              </li>
            </ul>
          </div>

        </div>
      </div>
     
    </div>
  </div>
</section>