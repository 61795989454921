<div class="read-more-desktop full-div hide-mobile" *ngIf="linkedArticles">
  <app-side-pannel-title [title] = "moreArticlesTitle"></app-side-pannel-title>
  <div name="linked-articles" class="linked-articles-flex full-div">
    <div *ngFor="let item of artBannerItems" >
      <app-item-banner [item]=item></app-item-banner>
    </div>
  </div>
</div>

<section class="full-div read-more-section hide-desktop" *ngIf="linkedArticles">
  <div class="grid">
    <app-side-pannel-title [title] = "moreArticlesTitle"></app-side-pannel-title>
  </div>
  <div class="grid more-articles-flex">
    <div *ngFor="let item of artBannerItems" >
      <app-tile [tile]=item></app-tile>
      <!-- <app-item-banner [item]=item></app-item-banner> -->
    </div>    
  </div>
</section>
