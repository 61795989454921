import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { HomepageTileResponse } from 'src/app/models/homepage-tile-response';
import { LanguageService } from 'src/app/_services/language.service';
import { ArticleService } from 'src/app/_services/article.service';
import { CameraBroadcastResponse } from 'src/app/models/camera-broadcast-response';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/_services/api.service';
import { ImageResponse } from 'src/app/models/image-response';

@Component({
  selector: 'app-homepage-tile',
  templateUrl: './homepage-tile.component.html',
  styleUrls: ['./homepage-tile.component.css']
})
export class HomepageTileComponent implements OnInit, OnChanges {
  @Input() tile: HomepageTileResponse;
  @Input() isMobile: boolean;
  // @Input() isBanner = false;
  lang: string;
  dir: string;

  cameras: CameraBroadcastResponse[];
  OfflineCameraFile: ImageResponse;

  chartData: any[];

  constructor(
    private languageService: LanguageService,
    private articleService: ArticleService,
    private http: HttpClient,
    private apiService: ApiService
    ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tile != null) {
      switch (this.tile.tileType) {
        case 1: { // 1==articles
          this.tile.badge = this.articleService.getNameById(this.tile.articleTypeId, this.lang);
          this.tile.badgeColor = 'orange';
          this.tile.isRoute = true;
          break;
        }
        case 2: { // 2=highlights
          this.tile.badge = this.languageService.getTextById('HomePageBadgeHighlights');
          this.tile.badgeColor = 'red';
          this.tile.isRoute = false;
          break;
        }
        case 4: { // 4=blog
          this.tile.badge = this.languageService.getTextById('HomePageBadgeBlog');
          this.tile.badgeColor = 'green';
          this.tile.isRoute = false;
          break;
        }
        case 5: { // 5=cameras
          this.tile.tileImage = this.OfflineCameraFile;
          this.tile.isRoute = true;
          this.getCamerasByIds(this.tile.tileId);
          break;
        }
        case 6: { // 6=birdingCenter
          this.tile.badge = this.languageService.getTextById('baseBirdingCenterTitle');
          this.tile.badgeColor = 'blue';
          this.tile.isRoute = true;
          break;
        }
        case 7: { // 7=events
          this.tile.badge = this.languageService.getTextById('baseEventTitle');
          this.tile.badgeColor = 'light-blue';
          this.tile.isRoute = true;
          const dates = this.tile.metaData.split('|');
          const startDate = new Date(dates[0]);
          const endDate = new Date(dates[1]);
          this.tile.metaData = this.languageService.showDateRange(dates[0], dates[1], this.lang);
          break;
        }
        case 8: { // 8=ringing charts
          // this.tile.tileImage = this.OfflineCameraFile;
          this.tile.isRoute = false;
          this.getRingingChartData(this.tile.tileId);
          break;
        }
      case 9: { // 9==recorded lectures
          this.tile.badge = this.articleService.getNameById(this.tile.articleTypeId, this.lang);
          this.tile.badgeColor = 'light-blue';
          this.tile.isRoute = true;
          break;
        }
        default: {
          this.tile.badge = '';
          this.tile.badgeColor = '';
          this.tile.isRoute = false;
          break;
        }
      }
    }
  }

  private getCamerasByIds(camIds) {
    this.http.get(this.apiService.getServerUrl() + '/api/cameras/cameras/' + this.lang + '/' + camIds)
    .subscribe((response: CameraBroadcastResponse[]) => {
      this.cameras = response;
    },
    error => {
      console.log(error);
    });

    // get image file of "offline camera"
    this.http.get(this.apiService.getServerUrl() + '/api/files/byname/' + this.lang + '/Camera-Offline-587-' + this.lang)
    .subscribe((imgResponse: ImageResponse) => {
      this.OfflineCameraFile = imgResponse;
    },
    error => {
      console.log(error);
    });
  }

  private getRingingChartData(locId) {
    this.http.get(this.apiService.getServerUrl() +
        '/api/Observation/' + this.lang + '/ringing/' + locId).subscribe((response: any[]) => {
        this.chartData = response;
      },
    error => {
      console.log(error);
    });
  }
}
