import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ArticleTileResponse } from '../../models/article-tile-response';
import { Router } from '@angular/router';
import { Tile } from '../../models/tile';
import { LanguageService } from '../../_services/language.service';
import { ArticleService } from '../../_services/article.service';

@Component({
  selector: 'app-linked-articles',
  templateUrl: './linked-articles.component.html',
  styleUrls: ['./linked-articles.component.css']
})
export class LinkedArticlesComponent implements OnInit, OnChanges {

  @Input() linkedArticles: ArticleTileResponse[];
  @Input() moreArticlesTitle: string;
  @Input() lang: string;
  artBannerItems: Tile[];

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private articleService: ArticleService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createArtTiles();
    if (!this.moreArticlesTitle || this.moreArticlesTitle === '') {
      // console.log('has NO article title');
      this.moreArticlesTitle = this.languageService.getTextById('defaultMoreArticlesTitle');
    }
    // else { console.log('has article title');}
  }

  public createArtTiles() {
    // console.log('createArtTiles');
    this.artBannerItems = [];
    this.linkedArticles.forEach(article => {
      const item: Tile = {
        image: article.imageUrl,
        title: article.title,
        metaData1: article.author,
        metaData2: this.articleService.getNameById(article.typeId, this.lang),
        showMD1: true,
        showMD2: false,
        italicMD1: false,
        italicMD2: false,
        filterData: null,
        shortDescription: article.shortDescription,
        tags: article.tags,
        badge: this.articleService.getNameById(article.typeId, this.lang),
        badgeColor: 'orange',
        tileLinkType: 'article',
        tileLinkID: article.slug
      };

      this.artBannerItems.push(item);
    });

  }

}
