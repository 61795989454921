import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { BannerResponse } from 'src/app/models/banner-response';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banners-row',
  templateUrl: './banners-row.component.html',
  styleUrls: ['./banners-row.component.css']
})
export class BannersRowComponent implements OnInit {
  @Input() bannerType: string;
  banners: BannerResponse[];
  lang: string;
  dir: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private router: Router
    ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    this.languageService.allTextsLoaded$.subscribe(ready => {
      if (ready) {
        this.getBanners();
        }
    });

    if (this.languageService.hasKeys()) {
      this.getBanners();
    }
  }

  private getBanners() {
    let serviceUrl: string;

    serviceUrl = this.apiService.getServerUrl() + '/api/banners/' + this.lang + '/' + this.bannerType;

    if (serviceUrl) {
      this.http.get(serviceUrl)
        .subscribe((response: BannerResponse[]) => {
          if (response !== undefined && response.length !== 0) {
            this.banners = response;
          }
        }, error => {
          console.log(error);
          if (error.status === 404) {
            this.router.navigate([this.lang, 'not-found']);
          } else {
            this.router.navigate([this.lang, 'error']);
          }
        });
    }
  }

}
