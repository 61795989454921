import { NgModule, InjectionToken } from '@angular/core';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { HomePageComponent } from '../_pages/home-page/home-page.component';
import { ArticlesComponent } from '../_pages/articles/articles.component';
import { ArticleComponent } from '../_pages/article/article.component';
import { ContactUsComponent } from '../_pages/contact-us/contact-us.component';
import { ComingSoonComponent } from '../_pages/coming-soon/coming-soon.component';
import { BirdingCentersComponent } from '../_pages/birding-centers/birding-centers.component';
import { BirdingCenterComponent } from '../_pages/birding-center/birding-center.component';
import { SpeciesFamiliesComponent } from '../_pages/species-families/species-families.component';
import { SpeciesLobbyComponent } from '../_pages/species-lobby/species-lobby.component';
import { EventsComponent } from '../_pages/events/events.component';
import { EventComponent } from '../_pages/event/event.component';
import { SearchResultsComponent } from '../_pages/search-results/search-results.component';
import { AboutUsComponent } from '../_pages/about-us/about-us.component';
import { SpeciesPageComponent } from '../_pages/species-page/species-page.component';
import { SpeciesDescriptionComponent } from '../_pages/species-page/species-description/species-description.component';
import { SpeciesTrendsComponent } from '../_pages/species-page/species-trends/species-trends.component';
import { SpeciesMoreInfoComponent } from '../_pages/species-page/species-more-info/species-more-info.component';
import { ObsNewsComponent } from '../_pages/obs-news/obs-news.component';
import { NotFoundComponent } from '../_pages/not-found/not-found.component';
import { PathResolveService } from '../_services/path-resolve.service';
import { BlogPostComponent } from '../_pages/blog-post/blog-post.component';
import { BlogsComponent } from '../_pages/blogs/blogs.component';
import { SpeciesChecklistComponent } from '../_pages/species-checklist/species-checklist.component';
import { ObservationsComponent } from '../_pages/observations/observations.component';
import { ArticleNewComponent } from '../_pages/article-new/article-new.component';
import { WeeklyBarChartComponent } from '../_components/weekly-bar-chart/weekly-bar-chart.component';
import { CameraComponent } from '../_pages/camera/camera.component';
import { ErrorPageComponent } from '../_pages/error-page/error-page.component';
import { CamerasComponent } from '../_pages/cameras/cameras.component';
import { RingingDonutChartComponent } from '../_components/ringing-donut-chart/ringning-donut-chart.component';
import { OnlineCameraComponent } from '../_components/online-camera/online-camera.component';
import { Observable } from 'rxjs';
import { CountryResolver } from './CountryResolver';
import { AppComponent } from '../app.component';
import { TestBed } from '@angular/core/testing';
import { LoginComponent } from '../_pages/_cms/login/login.component';
import { AuthGuard } from '../_gaurd/auth.guard';
import { CmsMenuComponent } from '../_pages/_cms/cms-menu/cms-menu.component';
import { RingingRingsComponent } from '../_pages/_cms/ringing-rings/ringing-rings.component';
import { RingingDataComponent } from '../_pages/_cms/ringing-data/ringing-data.component';
import { EbirdTaxonSearchComponent } from '../_pages/_cms/ebird-taxon-search/ebird-taxon-search.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const routeByIPCountryProvider = new InjectionToken('routeByIPCountryResolver');
const counrtyProvider = new InjectionToken('counrtyResolver');

const routes: Routes = [
  {
    path: ':lang/chart/:speId',
    component: WeeklyBarChartComponent
  },
  {
    path: ':lang/ringing/chart/:locId',
    component: RingingDonutChartComponent
  },
  {
    path: ':lang/articles/type/:type',
    component: ArticlesComponent
  },
  {
    path: ':lang/articles/author/:author',
    component: ArticlesComponent
  },
  {
    path: ':lang/article/:slug',
    component: ArticleComponent
  },
  {
    path: ':lang/article/id/:id',
    component: ArticleComponent
  },
  {
    path: ':lang/article-v2/:slug',
    component: ArticleNewComponent
  },
  {
    path: ':lang/article-v2/id/:id',
    component: ArticleNewComponent
  },
  {
    path: ':lang/about-us/',
    component: AboutUsComponent
  },
  {
    path: ':lang/birding-centers',
    component: BirdingCentersComponent
  },
  {
    path: ':lang/birding-center/:slug',
    component: BirdingCenterComponent
  },
  {
    path: ':lang/birding-center/id/:id',
    component: BirdingCenterComponent
  },
  {
    path: ':lang/species-families',
    component: SpeciesFamiliesComponent
  },
  {
    path: ':lang/species-families/:filterParam',
    component: SpeciesFamiliesComponent
  },
  {
    path: ':lang/species-family/:id',
    component: SpeciesLobbyComponent
  },
  {
    path: ':lang/species-page/:id',
    component: SpeciesPageComponent,
    children: [
      {
        path: 'species-description',
        component: SpeciesDescriptionComponent
      },
      {
        path: 'species-trends',
        component: SpeciesTrendsComponent
      },
      {
        path: 'species-moreinfo',
        component: SpeciesMoreInfoComponent
      }
    ]
  },
  {
    path: ':lang/events',
    component: EventsComponent
  },
  {
    path: ':lang/event/:slug',
    component: EventComponent
  },
  {
    path: ':lang/event/id/:id',
    component: EventComponent
  },
  {
    path: ':lang/rarityObservationNews',
    component: ObsNewsComponent
  },
  {
    path: ':lang/contactus',
    component: ContactUsComponent
  },
  {
    path: ':lang/blogs',
    component: BlogsComponent
  },
  // {
  //   path: ':lang/blogPost/id/:id',
  //   component: BlogPostComponent
  // },
  {
    path: ':lang/blog/id/:id',
    component: BlogPostComponent
  },
  {
    path: ':lang/blog/id/:id/:pid',
    component: BlogPostComponent
  },
  {
    path: ':lang/species-checklist',
    component: SpeciesChecklistComponent
  },
  {
    path: ':lang/observations',
    component: ObservationsComponent
  },
  {
    path: ':lang/cameras/:code',
    component: CamerasComponent
  },
  {
    path: ':lang/cameras',
    component: CamerasComponent
  },
  {
    path: ':lang/camera/:id',
    component: CameraComponent
  },
  {
    path: ':lang/search/:query',
    component: SearchResultsComponent
  },
  {
    path: ':lang/coming-soon/:page_name',
    component: ComingSoonComponent
  },
  {
    path: ':lang/error',
    component: ErrorPageComponent
  },
  {
    path: ':lang/reg',
    component: HomePageComponent
  },
  
  {
    path: ':lang/externalRedirect/:toUrl',
    resolve: {
        url: externalUrlProvider,
    },
    component: NotFoundComponent,
  },
  {
    path: ':lang/login',
    component: LoginComponent
  },
  {
    path: ':lang/cms',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: 'menu', component: CmsMenuComponent },
      { path: 'rings', component: RingingRingsComponent },
      { path: 'ringing-data', component: RingingDataComponent },
      { path: 'ebirdtaxon', component: EbirdTaxonSearchComponent }
    ]
  },

  // {
  //   path: '',
  //   resolve: {
  //     countryCode: counrtyProvider,
  //   },
  //   component: HomePageComponent
  // },
  {
    path: ':lang',
    component: HomePageComponent
  },
  {
    path: '**',
    resolve: {
      path: PathResolveService
    },
    component: NotFoundComponent
  }
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = decodeURIComponent(route.paramMap.get('toUrl'));
        // console.log(externalUrl);
        window.open(externalUrl, '_blank');
        window.location.reload();
      },
    },
    // {
    //   provide: counrtyProvider,
    //   useValue: (route: ActivatedRouteSnapshot): string => {
    //     const isIL = this.languageService.isIsrael();
    //     if (isIL) {
    //         // this.router.navigate(['/he']);
    //         return 'he';
    //     } else {
    //         // this.router.navigate(['/en']);
    //         return 'en';
    //     }
    //   }
    // }
  ],
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top'
    }
  )],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
