import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ApiService } from 'src/app/_services/api.service';
import { WeeklyBarChartConfig } from './../../models/weekly-bar-chart-config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-speceis-obs-frequencies-chart',
  templateUrl: './speceis-obs-frequencies-chart.component.html',
  styleUrls: ['./speceis-obs-frequencies-chart.component.css']
})
export class SpeceisObsFrequenciesChartComponent implements OnInit {
  @Input() speId: number;
  elementId = 'weeklyChart';
  lang: string;
  dir: string;
  data: any[] = [];
  columnNames: any[];
  type = 'ComboChart';
  title = 'Demo';
  currentWeek: number;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = (this.lang === 'he') ? 'R' : 'L';

    this.getChartData(this.speId);

  }

  private getChartData(speId: number) {

    this.http.get(this.apiService.getServerUrl() + '/api/Observation/' + this.lang + '/frequencies/' + speId)
      .subscribe((response: any[]) => {
        this.columnNames = [];
        this.columnNames.push({ type: 'number', name: 'week' });
        this.columnNames.push({ type: 'number', name: 'fqCat' });
        this.columnNames.push({ type: 'string', role: 'tooltip', p: { 'html': true } });
        this.columnNames.push({ type: 'number', name: 'currentWeek' });

        this.data = this.buildDataArraySteppedArea(response);
      },
      error => {
        console.log(error);
        if (error.status === 404) {
          this.router.navigate([this.lang, 'not-found']);
        } else {
          this.router.navigate([this.lang, 'error']);
        }
      });
  }

  private buildDataArraySteppedArea(chartData: any[] ): any[] {
    const data = [];

    const now = new Date(Date.now());
    const currentYear = now.getFullYear();
    const onejan = new Date(currentYear, 0, 1);
    this.currentWeek = Math.ceil((((+now - +onejan) / 86400000) + onejan.getDay() + 1) / 7);

    for (let i = 0; i < chartData.length; i++) {
        const tooltipHtml = this.createCustomHTMLContent(currentYear, chartData[i]);
        data.push([chartData[i].week, chartData[i].fqCat, tooltipHtml, null]);
    }

    return data;
  }

  private createCustomHTMLContent(currentYear, data) {
    const data0 = (data.yearsData[0] == null) ? '-' : data.yearsData[0].toPrecision(3).toString() + '%';
    const data1 = (data.yearsData[1] == null) ? '-' : data.yearsData[1].toPrecision(3).toString() + '%';
    const data2 = (data.yearsData[2] == null) ? '-' : data.yearsData[2].toPrecision(3).toString() + '%';
    const data3 = (data.yearsData[3] == null) ? '-' : data.yearsData[3].toPrecision(3).toString() + '%';
    const data4 = (data.yearsData[4] == null) ? '-' : data.yearsData[4].toPrecision(3).toString() + '%';
    const data5 = (data.yearsData[5] == null) ? '-' : data.yearsData[5].toPrecision(3).toString() + '%';

    const result =
        '<div style="padding:5px 5px 5px 5px;direction: ltr;">' +
        '<span style="text-decoration: underline;"><b>' + 'Presence Data Week ' + data.week.toString() + '</b></span>' +
        '</br>' +
        '<table>' +
        '<tr><td>' + (currentYear - 5).toString() + '</td><td> ' + data0 + '</td></tr>' +
        '<tr><td>' + (currentYear - 4).toString() + '</td><td> ' + data1 + '</td></tr>' +
        '<tr><td>' + (currentYear - 3).toString() + '</td><td> ' + data2 + '</td></tr>' +
        '<tr><td>' + (currentYear - 2).toString() + '</td><td> ' + data3 + '</td></tr>' +
        '<tr><td>' + (currentYear - 1).toString() + '</td><td> ' + data4 + '</td></tr>' +
        '<tr><td>' + (currentYear).toString() + '</td><td> ' + data5 + '</td></tr>' +
        '<tr><td><b>' + 'Avg. Pres. ' + '</b></td><td><b> ' + data.fqAvg.toPrecision(3) + ' %</b></td></tr>' +
        '</table>' + '</div>';

    return result;
  }


}
