import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { SpeciesResponse } from 'src/app/models/species-response';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SpeciesService } from 'src/app/_services/species.service';
import { Tile } from 'src/app/models/tile';
import { SpeciesTileResponse } from 'src/app/models/species-tile-response';

@Component({
  selector: 'app-species-more-info',
  templateUrl: './species-more-info.component.html',
  styleUrls: ['./species-more-info.component.css']
})
export class SpeciesMoreInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  species: SpeciesResponse;
  lang: string;
  dir: string;
  videosTitle: string;
  audiosTitle: string;
  moreSpeciesFromFamilyTitle: string;
  relatedSpeciesTitle: string;
  speciesImagesTitle: string;
  moreSpeciesTiles: any[];
  relatedSpeciesTiles: any[];

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private speciesService: SpeciesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    this.videosTitle = this.languageService.getTextById('Videos');
    this.audiosTitle = this.languageService.getTextById('Sounds');

    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');
      const id = parseInt(params.get('id'), 0);

      this.species = this.speciesService.getSrvSpecies();
      if (this.species === undefined) {

        if (id !== undefined && !isNaN(id)) {
          this.router.navigate(['/' + this.lang + '/species-page/' + id]);
        } else if (slug !== null) {
          // currently - not implemented
        } else {
          this.router.navigate(['/' + this.lang + './species-families']);
        }
      }

      this.moreSpeciesFromFamilyTitle = (this.species.moreRelatedFamilySpeciesTitle === undefined ||
        this.species.moreRelatedFamilySpeciesTitle === null ||
        this.species.moreRelatedFamilySpeciesTitle === '') ?
        this.languageService.getTextById('MoreSpeciesFromFamily') + this.species.speciesFamilyName :
        this.species.moreRelatedFamilySpeciesTitle;

      this.relatedSpeciesTitle = (this.species.moreLinkedSpeciesTitle === undefined ||
        this.species.moreLinkedSpeciesTitle === null ||
        this.species.moreLinkedSpeciesTitle === '') ?
        this.languageService.getTextById('RelatedSpecies') :
        this.species.moreLinkedSpeciesTitle;

      this.speciesImagesTitle = this.languageService.getTextById('SpeciesImagesTitle');

      if (this.species.moreFromFamily) {
        this.moreSpeciesTiles = this.createSpeTiles(this.species.moreFromFamily);
      }

      if (this.species.relatedSpecies) {
        this.relatedSpeciesTiles = this.createSpeTiles(this.species.relatedSpecies);
      }
    });
  }

  ngAfterViewInit() {
    const fragment = this.speciesService.getFragment();
    if (fragment !== undefined && fragment !== '') {
      // window.scrollTo
      document.getElementById(fragment).scrollIntoView();
    }
  }
  ngOnDestroy() {
    this.speciesService.setFragment('');
  }

  private createSpeTiles(a: SpeciesTileResponse[]) {
    const lst = [];
    a.forEach(species => {
      const tile: Tile = {
        image: species.imageUrl,
        title: species.name,
        metaData1: species.latinName,
        metaData2: null,
        showMD1: true,
        showMD2: false,
        italicMD1: true,
        italicMD2: false,
        filterData: species.name + ', ' + species.latinName,
        shortDescription: species.shortDescription,
        tags: null,
        badge: species.speciesFamily,
        badgeColor: 'green',
        tileLinkType: 'species-page',
        tileLinkID: species.id.toString()
      };

      lst.push(tile);
    });
    return lst;
  }
}
