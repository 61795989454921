import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RingingYearResponse } from 'src/app/models/ringing-year-response';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { LanguageService } from 'src/app/_services/language.service';
import { LoadingAnimationService } from 'src/app/_services/loading-animation.service';
import { RingsService } from 'src/app/_services/rings.service';

@Component({
  selector: 'app-ringing-data',
  templateUrl: './ringing-data.component.html',
  styleUrls: ['./ringing-data.component.css']
})
export class RingingDataComponent implements OnInit {

  constructor(private languageService: LanguageService,
    private bcService: BreadcrumbsService,
    private ringsService: RingsService,
    private authService: AuthService,
    private alertifyService: AlertifyService,
    private loadingAnimationService: LoadingAnimationService) { }

  lang: string;
  dir: string;
  title: string;
  yearsData: RingingYearResponse[] = [];

  ExcelContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  ngOnInit() {
    this.loadingAnimationService.publishLoadingAnimation(true);

    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();
    this.title = this.languageService.getTextById('ringingDataTitle');

    // prepare the breadcrumbs
    this.bcService.clearLevels();
    this.bcService.setLastItemTitle(this.title);
    this.bcService.addLevel(this.languageService.getTextById('cmsMenuTitle'), 'cms/menu');

    const pageTitle = this.title
      + ' - ' + this.languageService.getTextById('baseSeoTitle');
    const keywords = this.title
      + ',' + this.languageService.getTextById('baseSeoKeywords');
    const desciption = this.title
      + ',' + this.languageService.getTextById('baseSeoDescription');
    const pagePath = '/cms/rings';
    const pageUrl = location.origin + pagePath;
    const canonicalUrl = location.origin + '/' + this.lang + pagePath;

    const pageType = this.title;
    this.bcService.seoItems(pageTitle, keywords, desciption, '', pageUrl, pageType, canonicalUrl);

    // publish
    this.bcService.publishBreadcrumbs();

    this.ringsService.getRingingYears()
      .subscribe(response => {
        this.yearsData = response;
        const userId = this.authService.getUserId();
        for (const year of this.yearsData) {
          const key = this.ringingDataKey(year.year, userId);
          const prevData = Number(localStorage.getItem(key));
          year.hasNewData = year.count !== prevData;
        }
        // console.log(this.yearsData);
        this.loadingAnimationService.publishLoadingAnimation(false);
      }, err => {
        console.log(err);
        this.loadingAnimationService.publishLoadingAnimation(false);
      });
  }

  downloadYear(year: RingingYearResponse) {
    
    this.ringsService.getRingingYearData(year.year, this.ExcelContentType)
      .subscribe(async data => {
        const url = this.saveFile(data);
        const anchor = document.createElement('a');
        anchor.download = `Ringing-${year.year}`;
        anchor.href = url;
        anchor.click();
        
        const userId = this.authService.getUserId();
        const key = this.ringingDataKey(year.year, userId);
        localStorage.setItem(key, year.count.toString());
        year.hasNewData = false;
      },
      error => {
        console.log(error);
        this.alertifyService.error("Error downloading file.");
      });

  }

  saveFile(data: any): string {
    const blob = new Blob([data], { type: this.ExcelContentType });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  private ringingDataKey(year: Number, userId: Number): string{
    return `ringing-u-${userId}-year-${year}`;
  }
}
