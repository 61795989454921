import { UrlResponse } from './url-response';
import { ImageResponse } from './image-response';
import { TagResponse } from './tag-response';
import { ArticleTileResponse } from './article-tile-response';
import { EventTileResponse } from './event-tile-response';

export class BirdingCenterResponse {
    id: number;
    langId: number;
    title: string;
    shortDescription: string;
    description: string;
    slug: string;
    poc: string;
    phone: string;
    email: string;
    region: number;
    lat: number;
    long: number;
    images: ImageResponse[];
    largeImage: ImageResponse[];
    tags: TagResponse[];
    linkedArticles: ArticleTileResponse[];
    linkedProjects: ArticleTileResponse[];
    linkedEvents: EventTileResponse[];
    links: UrlResponse[];
    moreArticlesTitle: string;
    moreProjectsTitle: string;
    moreEventsTitle: string;
    moreLinkesTitle: string;
    imageUrl: ImageResponse;
    largeImageUrl: ImageResponse;
    // badge: string;
}
