import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/_services/language.service';
import { ContactUsMessage } from 'src/app/models/contact-us-message';
import { ApiService } from 'src/app/_services/api.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { NgForm } from '@angular/forms';

const MAX_MSG_SIZE = 3 * 1048576;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {
  lang: string;
  dir: string;
  contactUsTitle: string;
  contactUsAddress: string;
  contactUsPhone: string;
  contactUsFacebook: string;
  contactUsIOC: string;
  contactUsFullName: string;
  contactUsEmail: string;
  contactUsSubject: string;
  contactUsMessage: string;
  contactUsSubmit: string;
  // model: ContactUsMessage;
  model: any = {};
  sendResult: boolean;
  sendResultMsg: string;
  errRequired: string;
  errShort: string;
  errEmail: string;
  attachments: File[] = [];
  addFilesFlag = false;
  addImagesBtn: string;
  inSend = false;
  modalId = 'register-modal-contactus';

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private apiService: ApiService,
    private bcService: BreadcrumbsService

  ) { }

  ngOnInit() {
    this.lang = this.languageService.currentLang();
    this.dir = this.languageService.currentDir();

    this.contactUsTitle = this.languageService.getTextById('ContactUsTitle');
    this.contactUsAddress = this.languageService.getTextById('ContactUsAddress');
    this.contactUsPhone = this.languageService.getTextById('ContactUsPhone');
    this.contactUsFacebook = this.languageService.getTextById('ContactUsFacebook');
    this.contactUsIOC = this.languageService.getTextById('ContactUsIOC');
    this.contactUsFullName = this.languageService.getTextById('ContactUsFullName');
    this.contactUsEmail = this.languageService.getTextById('ContactUsEmail');
    this.contactUsSubject = this.languageService.getTextById('ContactUsSubject');
    this.contactUsMessage = this.languageService.getTextById('ContactUsMessage');
    this.contactUsSubmit = this.languageService.getTextById('ContactUsSubmit');
    this.errRequired = this.languageService.getTextById('errRequired');
    this.errShort = this.languageService.getTextById('errShort');
    this.errEmail = this.languageService.getTextById('errEmail');
    this.addImagesBtn =  this.languageService.getTextById('AddImages');


    // prepare the breadcrumbs
    this.bcService.clearLevels();
    this.bcService.setLastItemTitle(this.contactUsTitle);
    const pageTitle = this.contactUsTitle
                      + ' - ' + this.languageService.getTextById('baseSeoTitle');
    const keywords =  this.contactUsTitle
                      + ',' + this.languageService.getTextById('baseSeoKeywords');
    const desciption = this.contactUsTitle
                        + ',' + this.languageService.getTextById('baseSeoDescription');
    const pagePath = '/contactus/';
    const pageUrl = location.origin + pagePath;
    const canonicalUrl = location.origin + '/' + this.lang + pagePath;

    const pageType = this.contactUsTitle;
    this.bcService.seoItems(pageTitle, keywords, desciption, '', pageUrl, pageType, canonicalUrl);

    // publish
    this.bcService.publishBreadcrumbs();


  }

  sendMessage(f: NgForm) {
    // const msg = {
    //   name: encodeURIComponent(this.model.name),
    //   email: encodeURIComponent(this.model.email),
    //   subject: encodeURIComponent(this.model.subject),
    //   message: encodeURIComponent(this.model.message),
    //   attachments: this.attachments
    // };

    // debugger;

    if (this.inSend) {
      return false;
    }

    
    this.inSend = true;

    console.log('attch: ' + this.attachments.length);

    const formData = new FormData();
    // formData.append('file', this.uploadForm.get('profile').value);
    formData.append('emailMessage.name', encodeURIComponent(this.model.name));
    formData.append('emailMessage.email', encodeURIComponent(this.model.email));
    formData.append('emailMessage.subject', encodeURIComponent(this.model.subject));
    formData.append('emailMessage.message', encodeURIComponent(this.model.message));


    let totalMsgSize = this.model.subject.length + this.model.message.length;

    if (this.attachments.length > 0) {
      for (let index = 0; index < this.attachments.length; index++) {
        formData.append('attachments.file-' + (index + 1).toString(), this.attachments[index]);
        totalMsgSize += this.attachments[index].size;
      }
    }
    
    if (totalMsgSize > MAX_MSG_SIZE) {
      console.error('total message size:' + totalMsgSize.toString());
      this.sendResult = false;
      this.inSend = false;

      if (this.attachments.length === 0) {
        this.sendResultMsg = this.languageService.getTextById('ContactUsErrorSize1');
      }
      else if (this.attachments.length === 1) {
        this.sendResultMsg = this.languageService.getTextById('ContactUsErrorSize2');
      }
      else {
        this.sendResultMsg = this.languageService.getTextById('ContactUsErrorSize3');
      }
      return false;
    }
  
    this.http.post<boolean>(this.apiService.getServerUrl() + '/api/message/contactus', formData)
      .subscribe((response: boolean) => {
        if (response) {
          this.inSend = false;
          this.sendResult = true;
          this.sendResultMsg = this.languageService.getTextById('ContactUsSendResultOK');
          this.model = {};
          this.attachments = [];
          f.resetForm();
        } else {
          this.sendResult = false;
          this.sendResultMsg = this.languageService.getTextById('ContactUsSendResultErr');
        }
        // this.model.name = decodeURIComponent(this.model.name);
        // this.model.email = decodeURIComponent(this.model.email);
        // this.model.subject = decodeURIComponent(this.model.subject);
        // this.model.message = decodeURIComponent(this.model.message);
      }, ((error: any) => {
          this.inSend = false;
          this.sendResult = false;
          this.sendResultMsg = this.languageService.getTextById('ContactUsSendResultErr');
          console.log(error);
      }));
  }

  addFiles() {
    this.addFilesFlag = !this.addFilesFlag;
  }
}
